import { createSlice } from "@reduxjs/toolkit";

export const pageLoadingSlice = createSlice({
    name: "pageLoading",
    initialState: { isLoading: false },
    reducers: {
        setIsLoading: (pageLoading, action) => {
            pageLoading.isLoading = action.payload;
        },
    }
});

export const getIsLoading = (state) => {
    return state.pageLoading.isLoading;
  };

export const { setIsLoading } = pageLoadingSlice.actions;
export default pageLoadingSlice.reducer;
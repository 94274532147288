import { React } from "react";
import { IconContext } from "react-icons";
import { IoMdCopy } from "@react-icons/all-files/io/IoMdCopy";

/**
 * 工程ID又は作業ID表示用のPopUpボックス
 * @param {*} props
 * @returns
 */
function IDShowPopUp(props) {
  function handleCopyClick() {
    props.onCopy(props.DisplayID);
  }

  return (
    <div onClick={props.onContainerDivClick} className="fixed inset-0 z-10" >
      <div className={props.className}>
        <div className="flex flex-row">
          <div className="w-[142px] flex items-center justify-center">
            {/*To Do: DisplayIDはDBから表示用の工程ID又は作業IDを取得する予定 */}
            <label>
              {props.Text} : {props.DisplayID}
            </label>
          </div>
          <div
            onClick={handleCopyClick}
            className="flex items-center justify-center w-[48px] h-[48px] ml-[26px] mt-[1px] hover:cursor-pointer hover:bg-[#D8D7D5]"
          >
            <IconContext.Provider value={{ color: "#0073CD", size: "23px" }}>
              <IoMdCopy />
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IDShowPopUp;

import { configureStore } from "@reduxjs/toolkit";
import detailDocReducer from "../features/editor/work/detailDocSlice";
import workListReducer from "../features/editor/work/workListSlice";
import processListReducer from "../features/editor/process/processListSlice";
import manageItemReducer from "../features/editor/work/manageItemSlice";
import toolListReducer from '../features/editor/work/toolListSlice';
import serialOperationReducer from "../features/operation/serialOperationSlice";
import lotOperationReducer from "../features/operation/lotOperationSlice";
import processChartReducer from "../features/editor/processChartSlice";
import btnClickCountReducer from "../features/editor/btnClickCountSlice";
import confirmBoxReducer from "../components/confirmBoxSlice"
import errorBoxReducer from "../components/errorBoxSlice";
import pageLoadingReducer from "../features/Loading/pageLoadingSlice";
import operationStartReducer from "../features/operation/operationStartWorkSlice";
import completedRecordReducer from "../features/completedRecord/completedRecordSlice";
import workRestoreReducer from "../features/workRestore/workRestoreSlice";
import serialPortReaderReducer from "../features/operation/SerialPortReaderSlice";

const store = configureStore({
  reducer: {
    processList: processListReducer,
    workList: workListReducer,
    detailDoc: detailDocReducer,
    manageItem: manageItemReducer,
    toolList: toolListReducer,
    serialOperation: serialOperationReducer,
    lotOperation: lotOperationReducer,
    processChart: processChartReducer,
    btnClickCount: btnClickCountReducer,
    confirmBox: confirmBoxReducer,
    errorBox: errorBoxReducer,
    pageLoading: pageLoadingReducer,
    operationStartWork: operationStartReducer,
    completedRecord: completedRecordReducer,
    workRestore: workRestoreReducer,
    serialPortReader: serialPortReaderReducer,
  },
});
export { store };

import React, { useEffect, useState } from "react";
import { DatePicker } from "../../components/DatePicker";
import Add from "../../components/Add";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";
import { TemplateFormDownload } from "../../components/TemplateFormDownload";
import { ExcelExport } from "../../components/ExcelExport";
import { ExcelImport } from "../../components/ExcelImport";
import SearchBox from "../../components/SearchBox";
import { ProductionPlanOrderColumn, ProductionPlanType, Sort, variables, StatusCode } from "../../Variables";
import { setIsLoading as setPageLoading } from "../Loading/pageLoadingSlice";
import { useDispatch } from "react-redux";
import { ConvertDateFormat, GetLastorNextDays, ConvertDisplayDateFormat, urlCompanyName, NavigateToCompanyLogin, DisplayDateFormatChange, IsEditing } from "../util/commonFun";
import TableTitle from "../../components/TableTitle";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router";
import { appInsights } from "../../features/util/ApplicationInsight";
import ProductionPlanImport from "./ProductionPlanImport";
import ProductionPlanInput from "./ProductionPlanInput";
import { emptyProductionPlanObj } from "./ProductionPlanInput";
import ConfirmBox from "../../components/ConfirmBox";
import ErrorBox from "../../components/ErrorBox";
import { errorCode } from "../../ErrorCode";
import { PagingBar } from "../../components/PagingBar";
import ImportForm from "../../files/productionPlanImportExportForm.xlsx"
import { nanoid } from "nanoid";

const axios = require('axios');

const defaultSortColumnAndSortType = {
    sortColumn: ProductionPlanOrderColumn.None,
    sortType: Sort.NONE
}

let defaultDisplayRows = 50;
let initialTimeout = 800;
export function ProductionPlanRegister(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const lastThreeDays = ConvertDateFormat(GetLastorNextDays(new Date(), -3));
    const nextThreeDays = ConvertDateFormat(GetLastorNextDays(new Date(), 5));
    const [startDate, setStartDate] = useState(lastThreeDays);
    const [endDate, setEndDate] = useState(nextThreeDays);
    const [dispItemCodeInput, setDispItemCodeInput] = useState("");
    const [itemNameInput, setItemNameInput] = useState("");
    const [serailLotNumberInput, setSerailLotNumberInput] = useState("");
    const [productionPlanList, setProductionPlanList] = useState([]);
    const [currPageNumber, setCurrPageNumber] = useState(1);
    const [showExcelImport, setShowExcelImport] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(null);
    const [showEditConfirmMessage, setShowEditConfirmMessage] = useState(null);
    const [showDeleteConfirmMessage, setShowDeleteConfirmMessage] = useState(false);
    const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(false);
    const [exportErrorMsg, setExportErrorMsg] = useState(null);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [sortColumnAndSortType, setSortColumnAndSortType] = useState(defaultSortColumnAndSortType);
    const [displayEditIcon, setDisplayEditIcon] = useState(null);

    useEffect(() => {
        const timeOutID = setTimeout(function () {
            setCurrPageNumber(1);
            fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, 1, sortColumnAndSortType.sortColumn, sortColumnAndSortType.sortType);
        }, initialTimeout);

        return function cleanUp() {
            clearTimeout(timeOutID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput]);

    /**
     * 期限指定の組み合わせは適当であるか確認する
     * @returns 
     */
    function isDateCombinationOK() {
        if (startDate.length > 0 && endDate.length > 0) {
            let firstDate = new Date(startDate);
            let secondDate = new Date(endDate);
            return firstDate <= secondDate;
        }
        return true;
    }

    /**
     * 削除した生産計画ボタンをクリックする操作
     */
    function handleRestoreTypeClick() {
        props.setSelectedWorkType(ProductionPlanType.RESTORE);
    }

    /**
    *新規登録ボタンクリック操作処理
    */
    function handleAddBtnClick(e) {
        setShowRegisterForm(<ProductionPlanInput
            key={nanoid()}
            productionPlanObj={{ ...emptyProductionPlanObj, quantity: 1 }}
            onClose={onProductionPlanInputClose}
            onComplete={onProductionPlanComplete}
        />);
    }

    /**
    * 生産計画の登録画面の破棄ボタンクリック操作
    */
    function onProductionPlanInputClose() {
        setShowRegisterForm(null);
    }

    /**
    * 生産計画の登録画面の登録完了する処理
    */
    function onProductionPlanComplete() {
        setShowRegisterForm(null);
        fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, currPageNumber, sortColumnAndSortType.sortColumn, sortColumnAndSortType.sortType);
    }

    /**
     * 削除ボタンをクリック操作
     * @param {*} e 
     */
    function handleDeleteBtnClick(e) {
        let checkedCnt = productionPlanList.filter(x => x.isChecked === true).length;
        if (checkedCnt > 0) {
            setShowDeleteConfirmMessage(true);
        }
    }

    function handleEditBtnClick(productionPlanId) {
        setDisplayEditIcon(null);
        let productionPlanObj = productionPlanList.find(x => x.productionPlanId === productionPlanId);

        if (IsEditing()) {
            setShowEditConfirmMessage(<ConfirmBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                title="変更の破棄" message={"作業が破棄されます。よろしいですか？"}
                onYesClick={() => {
                    setShowRegisterForm(<ProductionPlanInput
                        key={nanoid()}
                        productionPlanObj={productionPlanObj}
                        onClose={onProductionPlanInputClose}
                        onComplete={onProductionPlanComplete}
                    />); setShowEditConfirmMessage(null);
                }}
                onNoClick={() => { setShowEditConfirmMessage(null); }}
            />)
        }
        else {
            setShowRegisterForm(<ProductionPlanInput
                key={nanoid()}
                productionPlanObj={productionPlanObj}
                onClose={onProductionPlanInputClose}
                onComplete={onProductionPlanComplete}
            />);
        }

    }

    function clearEditButton() {
        setDisplayEditIcon(null);
    }

    function handleThreeDotsClick(productionPlanId, e) {
        const maxPositionX = 1800;
        let positionX = e.pageX + e.currentTarget.clientWidth - 72;
        positionX = positionX > maxPositionX ? maxPositionX : positionX;
        const positionY = e.pageY - (e.currentTarget.clientHeight / 2);
        setDisplayEditIcon(
            <div style={{ position: 'absolute', left: positionX + 'px', top: positionY + 'px' }}>
                <EditButton
                    onClick={() => handleEditBtnClick(productionPlanId)}
                />
            </div>
        );
        e.stopPropagation();
    }

    /**
     * 生産計画の1つ以上のレコードを削除する処理
     */
    async function deleteProductionPlan() {
        dispatch(setPageLoading(true));
        let ids = productionPlanList.filter(x => x.isChecked === true).map(function (item) { return item.productionPlanId; });
        await axios({
            method: 'PUT',
            url: variables.PRODUCTION_PLAN_URL + "/delete-productionplan-by-id",
            data: ids,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(response => {
            let responseData = response.data;
            if (responseData === errorCode.E2009) {
                setShowDeleteErrorMessage(true);
            }
            else {
                fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, currPageNumber, sortColumnAndSortType.sortColumn, sortColumnAndSortType.sortType);
            }
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify({ productionPlanIds: ids.toString() }) });
                appInsights.trackException({ ...error, errorFunction: "ProductionPlanRegister.deleteProductionPlan()" });
            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * 新規フォームダウンロード
     * @param {*} e 
     */
    function handleFormDownloadBtnClick(e) {
        let statusCd;
        axios({
            method: 'GET',
            url: variables.LOGIN_URL + "/validate",
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            statusCd = response.data.statusCode;
            if (statusCd === StatusCode.AuthorizedWithAdministrator) {
                let link = document.getElementById('planImportFormatDownFile');
                link.setAttribute('href', ImportForm);
                link.setAttribute('download', "生産計画登録フォーム.xlsx");
                link.click();
            } else {
                NavigateToCompanyLogin(navigate);
            }

        }).catch(function (error) {
            appInsights.trackException({ ...error, errorFunction: "ProductionPlanRegister.handleFormDownloadBtnClick()" });
        });
    }

    /**
     * Excel書き出し
     * @param {*} e 
     * @returns 
     */
    function handleExcelExportBtnClick(e) {
        if (productionPlanList.length === 0) {
            return;
        }
        dispatch(setPageLoading(true));
        let paramobj = {
            startDate: startDate,
            endDate: endDate,
            dispItemCode: dispItemCodeInput,
            itemName: itemNameInput,
            lotSerial: serailLotNumberInput,
            orderCol: sortColumnAndSortType.sortColumn,
            orderType: sortColumnAndSortType.sortType
        };
        axios({
            method: 'GET',
            url: variables.PRODUCTION_PLAN_URL + "/export-plan",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            const fileUploadResponse = response.data;
            if (fileUploadResponse && !fileUploadResponse.isSuccess) {
                let msg = "";
                // エラーメッセージを表示する。
                if (errorCode.hasOwnProperty(fileUploadResponse.errorCode)) {
                    msg = errorCode[fileUploadResponse.errorCode];
                } else {
                    msg = fileUploadResponse.errorCode;
                }
                // エラーを表示する。
                setExportErrorMsg(msg);
            } else if (fileUploadResponse.objUrl?.length > 0) {
                // ブロックを取得して、一旦ブラウザに保存してからダウンロードする。
                // 理由：　保存ファイル名を設定する為。cross origin の場合、ファイル名が設定出来ない為。
                fetch(fileUploadResponse.objUrl)
                    .then((response) => response.blob())
                    .then((myBlob) => {
                        const objectURL = URL.createObjectURL(myBlob);
                        let link = document.getElementById('planExportDownFile');
                        link.setAttribute('href', objectURL);
                        let today = DisplayDateFormatChange(new Date());
                        const fileName = `生産計画_${today.replaceAll("/", "-").replaceAll(":", "-")}.xlsx`;
                        link.setAttribute('download', fileName);
                        link.click();
                    });
            } else {
                // no data found to export
            }
        }).catch(function (error) {
            let errResponseStatus = error?.response?.status;
            if (errResponseStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                appInsights.trackException({ ...error, errorFunction: "ProductionPlanRegister.handleExcelExportBtnClick()" });
            }
        }).finally(() => {
            dispatch(setPageLoading(false));
        });
    }

    function handleExcelImportBtnClick(e) {
        setShowExcelImport(true);
    }

    function onProductionPlanImportClose() {
        setShowExcelImport(false);
    }

    function onProductionPlanImportSuccess() {
        setShowExcelImport(false);
        fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, currPageNumber, sortColumnAndSortType.sortColumn, sortColumnAndSortType.sortType);
    }

    /**
     * 完成予定日の検索するFromDate変更処理
     * @param {*} value 
     */
    function handleFromDateChange(value) {
        setStartDate(value);
    }

    /**
     * 完成予定日の検索するToDate変更処理
     * @param {*} value 
     */
    function handleToDateChange(value) {
        setEndDate(value);
    }

    /**
     * 製品コードの検索処理
     * @param {*} e 
     */
    function handleSearchItemCodeInputChange(e) {
        let inputItemCode = e.target.value;
        setDispItemCodeInput(inputItemCode);
    }

    /**
     * 製品名の検索処理
     * @param {*} e 
     */
    function handleSearchItemNameInputChange(e) {
        let inputItemName = e.target.value;
        setItemNameInput(inputItemName);
    }

    /**
     * 製造 / シリアル / ロット 番号の検索処理
     * @param {*} e 
     */
    function handleSearchNumberInputChange(e) {
        let inputSerialLotNumber = e.target.value;
        setSerailLotNumberInput(inputSerialLotNumber);
    }

    /**
     * 生産計画リストを取得する処理
     * @param {*} startDate 
     * @param {*} endDate 
     * @param {*} dispItemCode 
     * @param {*} itemName 
     * @param {*} serialLotNumber 
     * @param {*} currPageNumber 
     * @param {*} orderColumn 
     * @param {*} orderType 
     */
    async function fetchProductionPlanData(startDate, endDate, dispItemCode, itemName, serialLotNumber, currPageNumber, orderColumn, orderType) {
        dispatch(setPageLoading(true));
        if (isDateCombinationOK()) {
            let paramobj = {
                startDate: startDate,
                endDate: endDate,
                dispItemCode: dispItemCode,
                itemName: itemName,
                lotSerial: serialLotNumber,
                offsetRecord: (currPageNumber - 1) * defaultDisplayRows,
                deleteFlag: false,
                orderCol: orderColumn,
                orderType: orderType
            };
            await axios({
                method: 'GET',
                url: variables.PRODUCTION_PLAN_URL + "/get-production-plan",
                params: paramobj,
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                setTotalRowCount(response.data.totalRowCount);
                let responseData = response.data.productionPlanList;
                setProductionPlanList(responseData.map(plan => {
                    return {
                        ...emptyProductionPlanObj,
                        productionPlanId: plan.productionPlanId,
                        itemCode: plan.itemCode,
                        dispItemCode: plan.dispItemCode,
                        itemName: plan.itemName,
                        serialNo: plan.serialNo,
                        lotNo: plan.lotNo,
                        dispProcessId: plan.dispProcessId,
                        processName: plan.processName,
                        quantity: plan.quantity,
                        planDate: ConvertDisplayDateFormat(plan.planDate),
                        optionalText1: plan.optionalText1,
                        optionalText2: plan.optionalText2,
                        optionalText3: plan.optionalText3,
                        optionalText4: plan.optionalText4,
                        optionalText5: plan.optionalText5,
                        optionalNo: plan.optionalNo,
                    };
                }));
            }).catch(function (error) {
                let errResponseStatus = error?.response?.status;
                if (errResponseStatus === 401) {
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                    appInsights.trackException({ ...error, errorFunction: "ProductionPlanRegister.fetchProductionPlanData()" });
                }
            });
        }
        else {
            setTotalRowCount(0);
            setCurrPageNumber(1);
            setProductionPlanList([]);
        }
        dispatch(setPageLoading(false));
    }

    /**
     * チェックボックスの操作
     * @param {*} e 
     * @param {*} productionPlanId 
     */
    function handleCheckedChange(e, productionPlanId) {
        setProductionPlanList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((item) => item.productionPlanId === productionPlanId);
            if (idx > -1) {
                let updateObj = { ...tmp[idx] };
                updateObj.isChecked = e.target.checked;
                tmp[idx] = updateObj;
            }
            return tmp;
        });
    }

    /**
     * 生産計画データをソートする。
     * param: ProductionPlanOrderColumn
     * @param {*} sortCol 
     */
    function handleSort(sortCol) {
        let sortType = Sort.DESC;
        if (sortCol) {
            if (sortColumnAndSortType.sortColumn === sortCol
                && sortColumnAndSortType.sortType === Sort.DESC) {
                sortType = Sort.ASC;
            }
            let updateObj = {
                ...defaultSortColumnAndSortType,
                sortColumn: sortCol,
                sortType: sortType
            };
            setSortColumnAndSortType(updateObj);
            fetchDataWithoutTimer(currPageNumber, updateObj);
        }
    }

    /**
     * 最初のページに戻る処理
     * @param {*} e 
     */
    function handleGoToFirstPage(e) {
        let firstPage = 1;
        setCurrPageNumber(firstPage);
        fetchDataWithoutTimer(firstPage, sortColumnAndSortType);
    }

    /**
     * 前のページに移動する処理
     * @param {*} e 
     */
    function handleGoToPrevious(e) {
        let prevPage = currPageNumber - 1;
        setCurrPageNumber(prevPage);
        fetchDataWithoutTimer(prevPage, sortColumnAndSortType);
    }

    /**
     * 次のページに移動する処理
     * @param {*} e 
     */
    function handleGoToNext(e) {
        let nextPage = currPageNumber + 1;
        setCurrPageNumber(nextPage);
        fetchDataWithoutTimer(nextPage, sortColumnAndSortType);
    }

    /**
     * タイマー無しで生産計画データを取る
     * @param {*} pageNumber 
     * @param {*} sortColumnAndSortTypeObj 
     */
    function fetchDataWithoutTimer(pageNumber, sortColumnAndSortTypeObj) {
        fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, pageNumber, sortColumnAndSortTypeObj.sortColumn, sortColumnAndSortTypeObj.sortType);
    }

    return (
        <>
            <div onClick={clearEditButton}>
                {showRegisterForm !== null && <div className="absolute z-[4] w-[1682px] h-[312px]"></div>}
                <div className="flex h-[60px] ml-1">
                    <a id="planImportFormatDownFile" href="undefined" className="hidden">-</a>
                    <a id="planExportDownFile" href="undefined" className="hidden">-</a>
                    <div className={"w-[165px] h-[54px] cursor-pointer text-center mr-4 rounded-[2px] border-2 border-[#0073CD] bg-[white]"}>
                        <div className="flex relative">
                            <div className="text-left mt-3 ml-2 text-[20px] font-bold">生産計画登録</div>
                            <input
                                className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                                type="radio"
                                id={ProductionPlanType.REGISTER}
                                name={"register"}
                                checked={true}
                                readOnly={true} />
                        </div>
                    </div>
                    <div className={"w-[196px] h-[54px] cursor-pointer text-center mr-4 rounded-[2px] bg-[#D8D7D5] border-2 hover:border-[#707070] hover:opacity-100 opacity-60"}
                        onClick={handleRestoreTypeClick}>
                        <div className="flex relative">
                            <div className="text-left mt-3 ml-2 text-[20px] font-bold">削除した生産計画</div>
                            <input
                                className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                                type="radio"
                                id={ProductionPlanType.RESTORE}
                                name={"restore"}
                                checked={false}
                                readOnly={true} />
                        </div>
                    </div>
                </div>
                <div className=" mt-3">期間指定（完成予定日）</div>
                <div className=" flex">
                    <DatePicker value={startDate}
                        onDateChange={(value) => handleFromDateChange(value)} />
                    <span className=" px-2">～</span>
                    <DatePicker value={endDate}
                        onDateChange={(value) => handleToDateChange(value)} />
                </div>
                <div className="flex mt-5 h-[72px]">
                    <div className="h-[32px] mr-[15px]">
                        <label>製品コード</label>
                        <SearchBox width={220}
                            onChange={handleSearchItemCodeInputChange}
                            value={dispItemCodeInput} />
                    </div>
                    <div className="h-[32px] mr-[15px]">
                        <label>製品名</label>
                        <SearchBox width={220}
                            onChange={handleSearchItemNameInputChange}
                            value={itemNameInput} />
                    </div>
                    <div className="h-[32px] mr-[15px]">
                        <label>製造 / シリアル / ロット 番号</label>
                        <SearchBox width={220}
                            onChange={handleSearchNumberInputChange}
                            value={serailLotNumberInput} />
                    </div>
                </div>
                <div className="flex">
                    <Add className="w-[124px] h-[50px]"
                        Text="新規登録"
                        onClick={handleAddBtnClick} />
                    <DeleteButton className="w-[80px] h-[50px] mr-2"
                        isEnable={productionPlanList.some(x => x.isChecked === true)}
                        Text="削除"
                        onClick={handleDeleteBtnClick} />
                    <TemplateFormDownload
                        className="w-[210px] h-[50px]"
                        Text="新規フォームダウンロード"
                        onClick={handleFormDownloadBtnClick} />
                    <ExcelExport
                        className="w-[200px] h-[50px]"
                        isEnable={productionPlanList.length > 0}
                        Text="生産計画エクスポート"
                        onClick={handleExcelExportBtnClick} />
                    <ExcelImport
                        className="w-[200px] h-[50px]"
                        Text="生産計画インポート"
                        onClick={handleExcelImportBtnClick} />
                </div>
                <div className="h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] absolute" />
                <div className="absolute inset-0 top-[401px] left-[237px] h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] " />
                <div className="w-[1682px] h-[670px] overflow-x-scroll mt-3">
                    <table className="border-collapse">
                        <thead>
                            <tr>
                                <th className="header">
                                    <div className="h-[30px] w-[30px]"></div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[120px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="製品コード"
                                            Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.DispItemCode
                                                ? sortColumnAndSortType.sortType
                                                : Sort.NONE}
                                            onClick={() => handleSort(ProductionPlanOrderColumn.DispItemCode)} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[109px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="製品名"
                                            Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.ItemName
                                                ? sortColumnAndSortType.sortType
                                                : Sort.NONE}
                                            onClick={() => handleSort(ProductionPlanOrderColumn.ItemName)} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[185px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="製造/シリアル番号"
                                            Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.SerialNumber
                                                ? sortColumnAndSortType.sortType
                                                : Sort.NONE}
                                            onClick={() => handleSort(ProductionPlanOrderColumn.SerialNumber)} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[125px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="ロット番号"
                                            Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.LotNumber
                                                ? sortColumnAndSortType.sortType
                                                : Sort.NONE}
                                            onClick={() => handleSort(ProductionPlanOrderColumn.LotNumber)} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[90px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled" >
                                        <TableTitle horiPadding={5} Text="工程ID" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[109px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="工程名" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[75px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="計画数" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[130px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="完成予定日"
                                            Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.PlanDate
                                                ? sortColumnAndSortType.sortType
                                                : Sort.NONE}
                                            onClick={() => handleSort(ProductionPlanOrderColumn.PlanDate)} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="任意入力1" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="任意入力2" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="任意入力3" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="任意入力4" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                        <TableTitle horiPadding={5} Text="任意入力5" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="border-r-2 h-[30px] w-[145px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled" >
                                        <TableTitle horiPadding={5} Text="任意入力_数値" iconShow={false} />
                                    </div>
                                </th>
                                <th className="header">
                                    <div className="h-[30px] w-[30px]"></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {productionPlanList.map((singleObj) => (
                                <tr key={singleObj.productionPlanId} className={"h-[53px] border-y-[1px] border-[#C8C6C4]-[.56] " + variables.HOVER_CSS + (singleObj.isChecked ? " bg-[#D8D7D5]" : "")}>
                                    <td className="h-[30px] w-[30px]">
                                        <input key={singleObj.productionPlanId} type="checkbox" className="w-[21px] h-[21px] flex items-center ml-2 accent-[#0073CD] rounded-[2px]"
                                            checked={singleObj.isChecked}
                                            onChange={(e) => handleCheckedChange(e, singleObj.productionPlanId)} />
                                    </td>
                                    <td className="text-left pl-[8px] w-[50px] min-w-[50px] pr-3 hover:underline cursor-pointer text-[#0068B8]"
                                        onClick={(e) => handleEditBtnClick(singleObj.productionPlanId)}>
                                        <span className=" w-fit one-line">{singleObj.dispItemCode}</span>
                                    </td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.itemName}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.serialNo}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.lotNo}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.dispProcessId}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.processName}</span></td>
                                    <td className="text-right pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.quantity}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.planDate}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText1}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText2}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText3}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText4}</span></td>
                                    <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText5}</span></td>
                                    <td className="text-right pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalNo}</span></td>
                                    <td className="w-[30px] " onClick={(e) => handleThreeDotsClick(singleObj.productionPlanId, e)}>
                                        <div className="flex justify-center items-center cursor-pointer" >
                                            <BsThreeDots style={{ color: "Black", size: "20px" }} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <PagingBar
                    defaultDisplayRows={defaultDisplayRows}
                    totalCount={totalRowCount}
                    currPageNumber={currPageNumber}
                    goToFirstPage={handleGoToFirstPage}
                    goToPrevious={handleGoToPrevious}
                    goToNext={handleGoToNext} />
                {showExcelImport && (
                    <ProductionPlanImport
                        className="absolute right-0 top-6"
                        onClose={onProductionPlanImportClose}
                        onSuccess={onProductionPlanImportSuccess}
                    />
                )}
                {showRegisterForm && showRegisterForm}
                {showEditConfirmMessage && showEditConfirmMessage}
                {showDeleteConfirmMessage && <ConfirmBox
                    className="absolute left-[410px] top-[335px] w-[560px]"
                    title="生産計画の削除"
                    message={<p className='break-all'>
                        {"選択した生産計画を削除しますか？"}
                        <br />
                        {"削除しても必要に応じて再度、生産計画を復元できます。"}
                    </p>}
                    onYesClick={() => { deleteProductionPlan(); setShowDeleteConfirmMessage(false); }}
                    onNoClick={() => { setShowDeleteConfirmMessage(false); }}
                />}
                {showDeleteErrorMessage && (
                    <ErrorBox
                        className={"absolute left-[710px] top-[410px] w-[480px]"}
                        Title="生産計画の削除"
                        Message={errorCode[errorCode.E2009]}
                        onYesClick={() => { setShowDeleteErrorMessage(false); }}
                    />
                )}
                {
                    exportErrorMsg && (
                        <ErrorBox
                            className={"absolute left-[710px] top-[410px] w-[480px]"}
                            Title="生産計画のエクスポート"
                            Message={exportErrorMsg}
                            onYesClick={() => { setExportErrorMsg(null); }}
                        />
                    )
                }
                {displayEditIcon && displayEditIcon}
            </div>
        </>
    );
}
import { React, useState } from "react";
import { IconContext } from "react-icons";
import { HiOutlineChevronDoubleRight, HiOutlineChevronDoubleLeft } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import { WorkStatus, ManageItemType, HindoKaisuSetting } from "../../../Variables";
import { errorCode } from "../../../ErrorCode";
import { useNavigate } from 'react-router-dom';
import ErrorBox from "../../../components/ErrorBox";
import { setIsLoading as setPageLoading } from "../../Loading/pageLoadingSlice";
import LotNumberSaiKai from "./LotNumberSaiKai";
import {
  setPauseConfirmMsg,
  setPauseExpandMode,
  setWorkExpandMode,
  setLotOperationLogId
} from "../lotOperationSlice";

import {
  NavigateToOperationStartWork,
  RegisterLotOperationLog
} from "../../util/commonFun";

function LotChuudanWork(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.lotOperation);
  const isPauseExpandMode = items.isPauseExpandMode;
  const selectedWork = items.selectedWork;
  const showPauseConfirmMsg = items.isPauseConfirmMsg;
  const manageItemList = items.dataList.manageItemList;
  const tempIdx = manageItemList.findIndex((item) => item.workId === selectedWork.workId);
  const currManageItemType = (tempIdx === -1) ? 0 : manageItemList[tempIdx].manageItemType;
  const completedManageResult = items.completedManageResultObj.manageResult;
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [writeLogErrorCode, setWriteLogErrorCode] = useState(0);
  const kurikaeshiList = items.dataList.kurikaeshiList;
  const kurikaeshiLstbyWorkId = kurikaeshiList.filter((item) => item.dispWorkId.toLowerCase() === selectedWork.dispWorkId.toLowerCase());
  let completedKurikaeNo = kurikaeshiLstbyWorkId?.length ?? 0;

  function onClickExpandCollapse(e) {
    if (items.isWorkExpandMode) {
      dispatch(setWorkExpandMode(false));
    }
    if (isPauseExpandMode) {
      dispatch(setPauseExpandMode(false));
    } else {
      dispatch(setPauseExpandMode(true));
    }
  }

  async function handlePauseWork() {
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    window.speechSynthesis.cancel();
    let result = await writeLotOperationLog(WorkStatus.SUSPENSION, selectedWork);
    if (result.isFailed === false) {
      dispatch(setPauseExpandMode(false));
      dispatch(setPauseConfirmMsg(true));
    }
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  /**
   * ロットオペレーションのログ履歴登録処理
   * @param {*} workStatusId 
   * @param {*} workObj 
   * @returns 
   */
  async function writeLotOperationLog(workStatusId, workObj) {
    let result = { isFailed: false, data: null };
    if (workObj.workId.length === 0) {
      result.isFailed = true;
      return result;
    }
    let description;
    let manageItemObj = items.dataList.manageItemList.find((item) => item.workId.toLowerCase() === workObj.workId.toLowerCase());
    if (manageItemObj) {
      description = { ...manageItemObj.description };
      description.okNgResult = "";
      description.txtInputText = "";
      description.completedHardwareId = (currManageItemType === ManageItemType.Hardware && completedManageResult !== null) ? completedManageResult.completedHardwareId : "";
      description.handleName = "";
      description.completedCount = (currManageItemType === ManageItemType.Hardware && completedManageResult !== null) ? completedManageResult.completedCount : 0;
      description.rotationTime = 0;
    }
    let kurikaeshiNo = (manageItemObj && (description.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu || description.hardwareKurikaeshi === true)) ? completedKurikaeNo : 0;
    let lotOperation = {
      lockKey: items.lockKey,
      lotOperationLogLockId: items.lotOperationLogLockId,
      processChartID: items.processObj.processChartID,
      processId: items.processObj.processId,
      workId: workObj.workId,
      workStatusId: workStatusId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      userId: items.selectedUser.userId,
      manageResult: JSON.stringify(manageItemObj ? description : ""),
      manageItemTypeId: manageItemObj ? manageItemObj.manageItemType : 0,
      version: items.version,
      workDispOrder: workObj.dispOrder,
      dispProcessId: items.processObj.dispProcessId,
      dispWorkId: workObj.dispWorkId,
      matomesuu: items.matomesuu,
      kurikaeshiNo: kurikaeshiNo,
    }
    result = await RegisterLotOperationLog(lotOperation, navigate);
    if (result.data) {
      if (result.data.errorCode > 0) {
        setWriteLogErrorCode(result.data.errorCode);
        dispatch(setPauseExpandMode(false));
      }
      else {
        dispatch(setLotOperationLogId(result.data.lotOperationLogId));
      }
    }
    return result;
  }

  return (
    <div className="flex w-[50px] h-[224px]">
      {isPauseExpandMode === false ? (
        <div className="w-[40px] h-[224px] border-2 bg-zinc-100">
          <div
            className="w-[35px] h-[32px] cursor-pointer hover:bg-gray-200"
            onClick={(e) => onClickExpandCollapse(e)}
          >
            <IconContext.Provider value={{ size: "25px" }}>
              <HiOutlineChevronDoubleRight className="mt-[2px] ml-[8px]" />
            </IconContext.Provider>
          </div>
          <div className="w-[20px] ml-[10px]">
            <label className="font-bold">中断</label>
          </div>
        </div>
      ) : (
        <div className="z-10 w-[706px] h-[230px]">
          <div className="flex w-[706px] h-[35px] border-2 border-[#C8C6C4] bg-[#F3F2F1]">
            <div
              className="w-[35px] h-[32px] items-center cursor-pointer hover:bg-gray-200"
              onClick={(e) => onClickExpandCollapse(e)}
            >
              <IconContext.Provider value={{ size: "25px" }}>
                <HiOutlineChevronDoubleLeft className="mt-[2px] ml-[5px]" />
              </IconContext.Provider>
            </div>
            <div>
              <div className="w-[250px] h-[30px] textmiddle">
                <label className="font-bold text-[18px] ml-[5px]">中断</label>
              </div>
            </div>
          </div>
          <div className="w-[706px] h-[189px] border-2 border-[#C8C6C4] bg-white cursor-pointer">
            <div className="w-[702px] h-[185px] hover:bg-gray-200 textmiddle" onClick={isBtnDisabled ? void (0) : () => handlePauseWork()}>
              <label className="font-bold text-[29px] ml-[20px] inline-block cursor-pointer">
                中断
              </label>
            </div>
          </div>
        </div>
      )}
      {/* 作業中断確認メッセージ */}
      {(showPauseConfirmMsg && writeLogErrorCode === 0) && (
        <LotNumberSaiKai
          setWriteLogErrorCode={setWriteLogErrorCode} />
      )}
      {/*ログ登録エラーメッセージ表示「1009 =>工程設定の変更,1011 =>作業継続不可」*/}
      {writeLogErrorCode > 0 && (
        <ErrorBox
          className={"absolute top-[365px] " + (writeLogErrorCode === errorCode.E1011 ? "left-[700px] w-[528px]" : writeLogErrorCode === errorCode.E1008 ? "left-[500px] w-[830px]" : "left-[700px] w-[600px]")}
          Title={writeLogErrorCode === errorCode.E1011 ? "作業継続不可" : writeLogErrorCode === errorCode.E1008 ? "作業再開不可" : "工程設定の変更"}
          Message={<>
            {errorCode[writeLogErrorCode].split("\n").map((msg, idx) => (
              <div key={idx}>{msg}</div>))
            }
          </>}
          onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
        />
      )}
    </div>
  );
}

export default LotChuudanWork;
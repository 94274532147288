import Select from "react-select";
import { CompareMethod } from "../Variables";

function ComboxBoxValueSearchable(props) {
    let itemsSource = props.itemsSource;
    const labelCss = "py-" + props.verPadding + " overflow-hidden whitespace-nowrap overflow-ellipsis text-[" + props.fontSize + "px]";
    const colourStyles = {
        option: (base, state) => {
            return {
                ...base,
                backgroundColor: state.isFocused ? "#D8D7D5" : null,
                "&:active": {
                    backgroundColor: "#D8D7D5",
                },
                color: "#050505",
            };
        },
        placeholder: (base, state) => {
            return {
                ...base,
                color: "#71717a"
            };
        },
        control: (base, state) => ({
            ...base,
            boxShadow: 0,
            borderRadius: "2px",
            height: props.height + 'px',
            border: "2px solid " + (props.isError ? "#CD0000" : state.isFocused ? "#0073CD" : "#C8C6C4"),
            "&:hover": {
                borderColor: props.isError ? "#CD0000" : state.isFocused ? "#0073CD" : "#C8C6C4",
            }
        }),
    };

    function getOptions() {
        let options = [];
        if (itemsSource && itemsSource.length > 0) {
            if (props.selectedValuePath.length > 0 && props.displayMemberPath.length > 0) {
                itemsSource.forEach(item => {
                    let obj = {};
                    obj['value'] = item[props.selectedValuePath];
                    obj['label'] = props.displayOrder.length > 0 ?
                        <div className="flex">
                            <div className={"py-" + props.verPadding + " text-right w-[30px] text-[" + props.fontSize + "px]"}>{item[props.displayOrder]}</div>
                            <div className={"ml-[8px] w-[430px] " + labelCss}>{item[props.displayMemberPath]}</div>
                        </div> : <div className={labelCss}>{item[props.displayMemberPath]}</div>;
                    obj['targetValue'] = props.targetValue.length > 0 ? item[props.targetValue] : item[props.selectedValuePath];
                    options.push(obj);
                });
            }
        }
        return options;
    }

    const selectedValue = () => {
        if (props.selectedOption.length === 0) {
            return null;
        }
        let optionMatchValue = itemsSource.find((item) => item[props.selectedValuePath] === props.selectedOption);
        if (optionMatchValue) {
            return { label: <div className={labelCss + (props.fontBold ? " font-bold text-black" : "")}>{optionMatchValue[props.displayMemberPath]}</div>, value: optionMatchValue[props.selectedValuePath] };
        }
        return { label: <div className={labelCss + (props.fontBold ? " font-bold text-black" : "")}>{props.selectedOption}</div>, value: "" };
    }

    const filterOptions = (candidate, input) => {
        if (input) {
            if (input.length > 0) {
                if (props.searchableMethod === CompareMethod.AllMatch) {
                    if (candidate.data.value.toLowerCase() === input.toLowerCase()) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    if (candidate.data.value.toLowerCase().includes(input.toLowerCase())) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    function onSelectedChange(e) {
        props.onSelectedChange(e);
    }

    function onKeyDown(e) {
        props.onKeyDown(e);
    }

    function onFocusOut(e) {
        props.onFocusOut(e);
    }

    return (
        <>
            <Select
                className={"react-select w-[" + props.width + "px]"}
                styles={colourStyles}
                ref={props.refComboBox}
                isDisabled={props.isDisabled}
                classNamePrefix="select"
                menuPlacement={props.menuPlacement}
                maxMenuHeight={props.maxMenuHeight}
                isSearchable={props.isSearchable}
                options={getOptions()}
                value={selectedValue()}
                onChange={onSelectedChange}
                onKeyDown={onKeyDown}
                filterOption={filterOptions}
                onBlur={props.isSearchable ? onFocusOut : void (0)}
                placeholder={<div className={"text-[" + props.fontSize + "px]"}>{props.placeholder}</div>}
                components={{ IndicatorSeparator: () => null }}>
            </Select>
        </>
    );
}

ComboxBoxValueSearchable.defaultProps = {
    refComboBox: null,
    isSearchable: false,
    isDisabled: false,
    menuPlacement: "auto",
    verPadding: 2,
    placeholder: "",
    selectedOption: "",
    isError: false,
    maxMenuHeight: 540,
    height: 65,
    fontSize: 22,
    fontBold: true,
    width: 600,
    displayMemberPath: "",
    displayOrder: "",
    selectedValuePath: "",
    targetValue: "",
    searchableMethod: CompareMethod.AllMatch,
    onSelectedChange: () => void (0),
    onKeyDown: () => void (0),
    onFocusOut: () => void (0),
}

export default ComboxBoxValueSearchable;
import { useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md"
import { variables } from "../Variables"

let timeout, interval;
function InputNumberBox(props) {
    const [isFocus, setFocus] = useState(false);
    const inputCss = (props.textPosition) + " w-[" + (props.width - 30) + "px] px-1 outline-none inner-spin-disabled text-[" + props.fontSize + "px] " + ((Number(props.value) === 0) ? " opacity-50 " : "") + (props.fontBold ? " font-bold " : "");
    const disabledInputCss = (props.textPosition) + " w-[" + (props.width - 30) + "px] px-1 outline-none inner-spin-disabled bg-[#C8C6C4] text-[" + props.fontSize + "px]";
    const arrowUpCss = "cursor-pointer " + variables.HOVER_CSS + ((props.upBtnDisabled || (isNaN(props.max) === false && Number(props.value) >= Number(props.max))) ? " bg-[#D8D7D5] opacity-50" : "");
    const arrowDownCss = "cursor-pointer " + variables.HOVER_CSS + ((props.downBtnDisabled || (isNaN(props.min) === false && Number(props.value) <= Number(props.min))) ? " bg-[#D8D7D5] opacity-50" : "");

    function handleTextAreaFocus(e) {
        setFocus(true);
    }

    function handleTextAreaFocusOut(e) {
        setFocus(false);
        props.onFocusOut(e);
    }

    function handleTextChange(e) {
        let inputTxt = Number(e.target.value);
        if (/^\d*$/.test(inputTxt)) {
            if ((isNaN(props.max) === false && inputTxt > Number(props.max)) ||
                (isNaN(props.min) === false && inputTxt < Number(props.min))) {
                return;
            }
            props.onTextChange(inputTxt);
        }
    }

    function handleUpBtnOnClick(e) {
        setFocus(true);
        let val = Number(props.value);
        // [max]に制限すると、制限値以上であることを確認
        if (isNaN(props.max) === false && val >= Number(props.max)) {
            return;
        }
        props.onTextChange(++val);
    }

    function handleUpBtnMouseDown(e) {
        let val = Number(props.value);
        // [max]に制限すると、値が制限以上であることを確認
        if (isNaN(props.max) === false && val >= Number(props.max)) {
            return;
        }
        timeout = setTimeout(function () {
            setFocus(true);
            interval = setInterval(function () {
                if ((isNaN(props.max) === false && val < Number(props.max)) || isNaN(props.max)) {
                    props.onTextChange(++val);
                }
            }, 50);
        }, 300);
    }

    function handleDownBtnOnClick(e) {
        setFocus(true);
        let val = Number(props.value);
        // [min]に制限すると、値が制限以下であることを確認
        if (isNaN(props.min) === false && val <= Number(props.min)) {
            return;
        }
        props.onTextChange(--val);
    }

    function handleDownBtnMouseDown(e) {
        let val = Number(props.value);
        // [min]に制限すると、値が制限以下であることを確認
        if (isNaN(props.min) === false && val <= Number(props.min)) {
            return;
        }
        timeout = setTimeout(function () {
            setFocus(true);
            interval = setInterval(function () {
                if ((isNaN(props.min) === false && val > Number(props.min)) || isNaN(props.min)) {
                    props.onTextChange(--val);
                }
            }, 50);
        }, 300);
    }

    function clearTimers() {
        clearTimeout(timeout);
        clearInterval(interval);
    }

    return (
        <>
            {props.disabled === false ?
                <div className={"flex items-center relative w-[" + props.width + "px] focus:border-[#0073CD] border-2 h-[" + props.height + "px] select-none rounded-[2px]"
                    + (props.isError ? " border-[#CD0000] " : isFocus ? " border-[#0073CD] " : " border-[#C8C6C4] ")}
                    onKeyUp={(e) => { props.onKeyUp(e); }} onClick={(e) => { props.onClick(e); }}>
                    <div>
                        {(isNaN(props.max) && isNaN(props.min)) ? (
                            <>
                                {/* [max]制限なし, [min]制限なし */}
                                <input className={inputCss} type="number" ref={props.refNumericInput} value={Number(props.value)} readOnly={props.readOnly}
                                    onFocus={handleTextAreaFocus} onBlur={handleTextAreaFocusOut} onInput={handleTextChange} />
                            </>
                        ) : (isNaN(props.max) && isNaN(props.min) === false) ? (
                            <>
                                {/* [max]制限なし, [min]制限 */}
                                <input className={inputCss} type="number" ref={props.refNumericInput} value={Number(props.value)} min={Number(props.min)} readOnly={props.readOnly}
                                    onFocus={handleTextAreaFocus} onBlur={handleTextAreaFocusOut} onInput={handleTextChange} />
                            </>
                        ) : (isNaN(props.max) === false && isNaN(props.min)) ? (
                            <>
                                {/* [max]制限, [min]制限なし */}
                                <input className={inputCss} type="number" ref={props.refNumericInput} value={Number(props.value)} max={Number(props.max)} readOnly={props.readOnly}
                                    onFocus={handleTextAreaFocus} onBlur={handleTextAreaFocusOut} onInput={handleTextChange} />
                            </>
                        ) : (
                            <>
                                {/* [max]制限, [min]制限 */}
                                <input className={inputCss} type="number" ref={props.refNumericInput} value={Number(props.value)} max={Number(props.max)} min={Number(props.min)} readOnly={props.readOnly}
                                    onFocus={handleTextAreaFocus} onBlur={handleTextAreaFocusOut} onInput={handleTextChange} />
                            </>
                        )}
                    </div>
                    <div className="w-[22px]">
                        <div className="absolute top-[1px] right-[1px]" >
                            <MdKeyboardArrowUp className={arrowUpCss} size={props.iconBtnSize}
                                onClick={props.upBtnDisabled ? void (0) : () => handleUpBtnOnClick()}
                                onMouseDown={props.upBtnDisabled ? void (0) : () => handleUpBtnMouseDown()}
                                onMouseUp={props.upBtnDisabled ? void (0) : () => clearTimers()}
                                onMouseLeave={props.upBtnDisabled ? void (0) : () => clearTimers()} />
                        </div>
                        <div className="absolute bottom-[1px] right-[1px]" >
                            <MdKeyboardArrowDown className={arrowDownCss} size={props.iconBtnSize}
                                onClick={props.downBtnDisabled ? void (0) : () => handleDownBtnOnClick()}
                                onMouseDown={props.downBtnDisabled ? void (0) : () => handleDownBtnMouseDown()}
                                onMouseUp={props.downBtnDisabled ? void (0) : () => clearTimers()}
                                onMouseLeave={props.downBtnDisabled ? void (0) : () => clearTimers()} />
                        </div>
                    </div>
                </div>
                :
                <div className={"flex items-center relative w-[" + props.width + "px] bg-[#C8C6C4] opacity-30 h-[" + props.height + "px]"}>
                    <div>
                        <input className={disabledInputCss} type="number" value={Number(props.value)} disabled={true} />
                    </div>
                    <div className="w-[22px]">
                        <div className="absolute top-[2px] right-[2px]" >
                            <MdKeyboardArrowUp className="cursor-pointer hover:bg-[#707070]" size={props.iconBtnSize} />
                        </div>
                        <div className="absolute bottom-[2px] right-[2px]" >
                            <MdKeyboardArrowDown className="cursor-pointer hover:bg-[#707070]" size={props.iconBtnSize} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

InputNumberBox.defaultProps = {
    max: Number.NaN,
    min: Number.NaN,
    value: 0,
    width: 90,
    height: 65,
    fontSize: 22,
    iconBtnSize: 22,
    fontBold: true,
    isError: false,
    upBtnDisabled: false,
    downBtnDisabled: false,
    disabled: false,
    refNumericInput: null,
    textPosition: "text-left",
    readOnly: false,
    onKeyUp: () => void (0),
    onFocusOut: () => void (0),
    onClick: () => void (0),
    onTextChange: () => void (0),
}

export default InputNumberBox;
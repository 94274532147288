import { useState, useRef } from "react";

let touchIdentifier = null;
/**
 * ドラッグ可能コンポネント
 * @param {*} props 
 * @returns 
 */
function Draggable(props) {
    const [posLeft, setPosLeft] = useState(props.defaultPosition ? props.defaultPosition.x : 0);
    const [posTop, setPosTop] = useState(props.defaultPosition ? props.defaultPosition.y : 0);
    const containerRef = useRef();

    let relativeX = 0;
    let relativeY = 0;

    function onMouseDown(e) {
        e.preventDefault();
        relativeX = e.clientX - posLeft;
        relativeY = e.clientY - posTop;
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    }

    function onMouseUp(e) {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
    }

    function onMouseMove(e) {
        e.preventDefault();
        move(e.clientX, e.clientY);
    }

    function onTouchStart(e) {
        if (touchIdentifier) {
            return;
        }
        touchIdentifier = e.changedTouches[0].identifier;
        relativeX = e.changedTouches[0].clientX - posLeft;
        relativeY = e.changedTouches[0].clientY - posTop;
        document.addEventListener("touchmove", onTouchMove, { passive: false });
        document.addEventListener("touchend", onToucheEnd);
    }

    function onToucheEnd(e) {
        if (touchIdentifier !== e.changedTouches[0].identifier) {
            return;
        }
        touchIdentifier = null;
        document.removeEventListener("touchmove", onTouchMove);
        document.removeEventListener("touchend", onToucheEnd);
    }

    function onTouchMove(e) {
        if (e.cancelable) {
            e.preventDefault();
        }
        if (touchIdentifier === null || touchIdentifier !== e.changedTouches[0].identifier) {
            return;
        }
        move(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    }

    function move(clientX, clientY) {
        let posLeft = clientX - relativeX;
        let posTop = clientY - relativeY;
        if (props.bounds &&
            (props.bounds.right - props.bounds.left) > containerRef.current.clientWidth &&
            (props.bounds.bottom - props.bounds.top) > containerRef.current.clientHeight) {

            if (posLeft < props.bounds.left) {
                posLeft = props.bounds.left;
            }

            if (posLeft > props.bounds.right - containerRef.current.clientWidth) {
                posLeft = props.bounds.right - containerRef.current.clientWidth;
            }

            if (posTop < props.bounds.top) {
                posTop = props.bounds.top;
            }

            if (posTop > props.bounds.bottom - containerRef.current.clientHeight) {
                posTop = props.bounds.bottom - containerRef.current.clientHeight;
            }
        }

        // set the element's new position:
        setPosLeft(posLeft);
        setPosTop(posTop);
    }

    return (
        <>
            <div ref={containerRef} className={props.className}
                style={{ position: "absolute", top: posTop + "px", left: posLeft + "px" }}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onTouchStart={onTouchStart}
            >
                {props.children}
            </div >

        </>
    );
}

Draggable.defaultProps = {
    defaultPosition: null, // { x<int>, y<int> }
    bounds: null, // { left<int>, top<int>, right<int>, bottom<int> }
};

export default Draggable;
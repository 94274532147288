import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "../../../components/ToggleButton";
import WorkDropDown from "./WorkDropDown";
import { toggleOkNgBranch, setOkWorkID, setNgWorkID, setOkNgKurikaeshi, getAllMngItm } from "./manageItemSlice";
import { getSelectedWorkInfo } from "./workListSlice";
import { nanoid } from "nanoid";
import { selectWorkListByProcessId, getWorkListWithoutHindoSetting } from "./workListSlice";
import { selectProcessList } from "../process/processListSlice";
import { OperationManagementType, HindoKaisuSetting } from "../../../Variables";
import HindokaisuuSetting from "../../../components/HindokaisuuSetting";
import { selectitemManagementType } from "../../editor/processChartSlice";
import ErrorBox from '../../../components/ErrorBox';

/**
 * OK/NG判定の管理項目入力コンポネント
 * @param {*} props 
 * @returns 
 */
function ManageItemInputForOKNG(props) {
    const dispatch = useDispatch();
    const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
    const selectedProcessId = selectedWork.processID;
    const processList = useSelector(selectProcessList);
    const itemManagementType = useSelector(selectitemManagementType);
    const [showHindoKaisuInput, setShowHindoKaisuInput] = useState(false);
    const mngItm = props.mngItm;
    const allMngItm = useSelector(getAllMngItm);
    const [dialogBox, setDialogBox] = useState(null);
    let selectedProcess = processList.filter(p => p.processID === selectedProcessId);
    let workListByProcessId = useSelector(state => selectWorkListByProcessId(state, selectedProcessId));
    workListByProcessId = workListByProcessId.filter(x => x.workID !== selectedWork.workID);
    const wrklstWithoutHindoSetting = useSelector(state => getWorkListWithoutHindoSetting(state, workListByProcessId, allMngItm));

    const textColor = selectedWork.isLock ? " text-[#A19F9D]" : "";
    function onBunkiSetteiChange() {
        if (!mngItm.data.okNgBranch && mngItm.data.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
            setDialogBox(
                <ErrorBox
                    className="absolute right-[162px] top-[265px] w-[631px]"
                    Title="分岐設定"
                    Message="「回数設定」をしている場合は「分岐設定」はできません。"
                    onYesClick={() => setDialogBox(null)}
                />
            );
        }
        else if (selectedWork.workID !== "" && selectedWork.workID.length > 0 && !selectedWork.isLock) {
            dispatch(toggleOkNgBranch({ workID: props.workID }));
        }
    }

    function handleHindokaisuuSettingChange() {
        setShowHindoKaisuInput(!mngItm.data.okNgKurikaeshi);
        dispatch(setOkNgKurikaeshi({ workID: props.workID, okNgKurikaeshi: !mngItm.data.okNgKurikaeshi }));
    };

    function onOkDropDownChange(value) {
        if (mngItm.data.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
            setDialogBox(
                <ErrorBox
                    className="absolute right-[162px] top-[265px] w-[631px]"
                    Title="分岐設定"
                    Message="「回数設定」をしている場合は「分岐設定」はできません。"
                    onYesClick={() => setDialogBox(null)}
                />
            );
        }
        else {
            dispatch(setOkWorkID({ workID: props.workID, okWorkID: value }));
        }
    }

    function onNgDropDownChange(value) {
        if (mngItm.data.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
            setDialogBox(
                <ErrorBox
                    className="absolute right-[162px] top-[265px] w-[631px]"
                    Title="分岐設定"
                    Message="「回数設定」をしている場合は「分岐設定」はできません。"
                    onYesClick={() => setDialogBox(null)}
                />
            );
        }
        else {
            dispatch(setNgWorkID({ workID: props.workID, ngWorkID: value }));
        }
    }

    return (
        <>
            <div className="h-[148px] items-center">
                <div className="flex">
                    <span className={"font-bold mr-[13px] " + (selectedWork.isLock ? "text-[#A19F9D]" : "")}>分岐設定</span>
                    <ToggleButton isChecked={mngItm.data.okNgBranch} onChange={onBunkiSetteiChange} inputID={"bunkisettei"} isLock={selectedWork.isLock} />
                </div>
                {
                    mngItm.data.okNgBranch &&
                    <div className="flex ml-2">
                        <span className={"font-bold w-[22px] mr-[9px] ml-2 mt-1.5 " + textColor}>OK</span>
                        <WorkDropDown
                            key={nanoid()}
                            firstOption="分岐無し（通常のOK/NG判定処理）"
                            selectedOption={mngItm.data.okWorkID}
                            onSelectedIndexChange={onOkDropDownChange}
                            isLock={selectedWork.isLock}
                            processList={selectedProcess}
                            workList={wrklstWithoutHindoSetting} />
                        <div className="text-[26px] font-bold pt-2.5 pl-[2px] text-red-500">*</div>
                    </div>
                }

                {
                    mngItm.data.okNgBranch &&
                    <div className="flex mt-1 ml-2">
                        <span className={"font-bold w-[22px] mr-[9px] ml-2 mt-1.5 " + textColor}>NG</span>
                        <WorkDropDown
                            key={nanoid()}
                            firstOption="分岐無し（通常のOK/NG判定処理）"
                            selectedOption={mngItm.data.ngWorkID}
                            onSelectedIndexChange={onNgDropDownChange}
                            isLock={selectedWork.isLock}
                            processList={selectedProcess}
                            workList={wrklstWithoutHindoSetting}
                        />
                        <div className="text-[26px] font-bold pt-2.5 pl-[2px] text-red-500">*</div>
                    </div>
                }
                {itemManagementType === OperationManagementType.LOTNUMBER && (
                    <div className="mt-1">
                        <HindokaisuuSetting
                            workID={props.workID}
                            isChecked={mngItm.data.okNgKurikaeshi}
                            showHindoKaisuInput={showHindoKaisuInput}
                            onHindoKaisuToggleChange={handleHindokaisuuSettingChange}
                            onHindoKaisuInputClose={() => setShowHindoKaisuInput(false)}
                            onEditClick={() => setShowHindoKaisuInput(true)}
                            isLock={selectedWork.isLock}
                        />
                    </div>
                )}
            </div>
            {dialogBox}
        </>
    );
}

export default ManageItemInputForOKNG;
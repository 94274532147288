import React from "react"
import { IoIosSearch } from "react-icons/io";

function SearchBox(props) {
    let searchBoxCss = "w-[" + props.width + "px] h-[29px] focus:outline-none focus:placeholder-transparent";
    function handleOnChange(e) {
        props.onChange(e);
    }

    return (
        <div className="w-fit h-fit p-0 border-[2px] rounded-sm border-black outline-none outline-offset-0 focus-within:border-[#0073CD]">
            <div className={props.className || null}>
                <div className="flex flex-row">
                    <div className="self-center mx-[8px]">
                        <IoIosSearch />
                    </div>
                    <div>
                        <input type={"text"}
                            onChange={handleOnChange}
                            value={props.value}
                            className={searchBoxCss} placeholder="検索" />
                    </div>
                </div>
            </div>
        </div>
    );
}

SearchBox.defaultProps = {
    width: 373,
}
export default SearchBox;
import React from "react";
import { IconContext } from "react-icons";
import { MdOutlineClose } from "react-icons/md";
import { popupcss } from "../Variables";

function DetailDocMoviePlayer(props) {
    return (
        <div className={popupcss}>
            <div className="player absolute left-[400px] top-[130px] w-[1100px] h-[735px] border-2 bg-black" >
                <IconContext.Provider value={{ size: "30px" }}>
                    <MdOutlineClose className="mt-[1px] mb-[1px] ml-[1065px] bg-white cursor-pointer hover:bg-gray-200" onClick={props.Close} />
                </IconContext.Provider>
                <video className="w-[1100px] h-[700px]" autoPlay={true} controls>
                    <source src={props.src}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    );
}

export default DetailDocMoviePlayer;

import React from "react";

class ItemDropDown extends React.Component {
  render() {
    return (
      <div>
        <select
          value={this.props.selectedValue}
          onChange={this.props.onChange}
          className="w-[373px] h-[29px] border-[1px] rounded-sm border-black outline-none outline-offset-0
                              focus:outline-[#0073CD] focus:border-0 overflow-ellipsis">
          <option value={-1}>{this.props.firstOption}</option>
          {this.props.itemList && this.props.itemList.map((item) => (
            <option key={item.itemCode} value={item.itemCode}>
              {item.itemName}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default ItemDropDown;
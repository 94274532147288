import React from "react";
import { IconContext } from "react-icons";
import { MdOutlineClose } from "react-icons/md";
import { ImFilePicture } from "react-icons/im";
import { popupcss } from "../../src/Variables";

function MarketWarningViewer(props) {
    return (
        <div className={popupcss}>
            <div className="w-[1168px] h-[1075px] ml-[750px] border-2 border-[#C8C6C4]  bg-white shadow-md text-black" >
                <div className="flex w-[1168px] h-[34px]">
                    <label className="w-[1000px] h-[60px] font-bold text-[29px] ml-[20px] mt-[24px]">警告メッセージ</label>
                    <IconContext.Provider value={{ size: "30px" }}>
                        <MdOutlineClose className="mt-[22px] mb-[2px] ml-[100px] bg-white cursor-pointer hover:bg-gray-200" onClick={props.Close} />
                    </IconContext.Provider>
                </div>
                <div className="w-[1000px] h-[94px] mt-[40px] ml-[20px]">
                    <textarea disabled
                        className={"w-[1000px] h-[94px] text-[29px] font-bold py-2 break-all leading-8 bg-white overflow-hidden resize-none"}
                        value={props.text} />
                </div>
                <hr className="w-[1162px] border-[#C8C6C4]" />
                <div className="w-[1168px] h-[994px]">
                    {(props.src !== null && props.src !== "") ? (
                        <div className="w-[1110px] h-[834px] mt-[20px] ml-[27px] border-[3px] border-[#C8C6C4] shadow-lg">
                            <img
                                src={props.src}
                                alt="..."
                                className="w-[1064px] h-[600px] object-contain border-2 border-[#C8C6C4] mt-[115px] ml-[20px]"
                            />
                        </div>
                    ) : (
                        <div className="w-[1110px] h-[679px] ml-[27px]">
                            <div className="flex w-[927px] h-[465px] mt-[175px] ml-[90px] bg-[#D8D7D5] opacity-50">
                                <IconContext.Provider
                                    value={{ size: "100px", color: "#A19F9D" }}
                                >
                                    <ImFilePicture className="mt-[178px] ml-[180px]" />
                                </IconContext.Provider>
                                <span className="font-bold text-[80px] mt-[210px] ml-[30px] opacity-70">No IMAGE</span>
                            </div>
                        </div>
                    )}
                    {props.isPreview ? "" : <button
                        type="button"
                        className="w-[160px] h-[34px] mr-[7px] font-bold text-white mt-[10px] ml-[27px] bg-[#0073CD] border-[1px] border-[#C8C6C4] border-solid hover:bg-[#0068B8] rounded-sm"
                        onClick={props.OnClick}
                    >内容を確認しました</button>}
                </div>
            </div>
        </div>
    );
}

MarketWarningViewer.defaultProps = {
    isPreview: false,
    onClick: () => { void (0) },
}

export default MarketWarningViewer;
import React from "react";
import SectionTitle from "./SectionTitle";
import { popupcss } from '../Variables';

function CompleteBox(props) {
  return (
    <div className={popupcss}>
      <div className={props.className}>
        <div className='p-[15px] mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white sm-rounded'>
          <SectionTitle Text={props.title} className='text-[26px]' />
          <div className='m-[10px] text-[22px]'>{props.message}</div>
          <div className='flex mt-[15px] text-[22px]'>
            <button
              type='button'
              autoFocus
              onClick={props.onYesClick}
              className='w-[73px] h-[40px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] sm-rounded'
            >
              はい
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteBox;
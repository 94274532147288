import { React, useState } from "react";
import EditButton from "../../../components/EditButton";
import BunkiListInput from "./BunkiListInput";
import { useSelector } from "react-redux";
import { selectMngItmByWorkID } from "./manageItemSlice";
import { getSelectedWorkInfo, selectWorkListByProcessId } from "./workListSlice";
import { selectProcessList } from "../process/processListSlice";

function SagyouBunkiList(props) {
    const [displayBunkiInput, setDisplayBunkiInput] = useState(false);
    const processList = useSelector(selectProcessList);
    const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
    const selectedProcessId = selectedWork.processID;
    const selectedWorkID = selectedWork.workID;
    let workListByProcessId = useSelector(state => selectWorkListByProcessId(state, selectedProcessId));
    const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
    let bunkiDataList = mngItm.data.bunkiData;

    const dispayBunkiList = () => {
        let displayBunkiData = [];
        if (bunkiDataList.length >= 2) {
            let selectedProcess = processList.find((p) => p.processID === selectedProcessId);
            for (let i = 0; i < bunkiDataList.length; i++) {
                if (bunkiDataList[i].workID !== "") {
                    let work = workListByProcessId.find(w => w.workID === bunkiDataList[i].workID);
                    displayBunkiData.push({ dispOrder: bunkiDataList[i].dispOrder, processDispOrder: selectedProcess.dispOrder, workDispOrder: work.dispOrder, workName: work.workName });
                }
                else {
                    displayBunkiData.push({ dispOrder: bunkiDataList[i].dispOrder, processDispOrder: "", workDispOrder: "", workName: "" });
                }
            }
        }
        return displayBunkiData;
    }

    function handleEdit() {
        setDisplayBunkiInput(true);
    }

    function onBunkiListInputBoxClose() {
        setDisplayBunkiInput(false);
    }

    return (
        <div className="flex ml-[8px]">
            <div className="flex flex-row">
                <EditButton
                    isLock={props.isLock}
                    onClick={handleEdit}
                    editBtnCss={"flex mt-[-6px] items-center justify-center w-[80px] h-[48px] cursor-pointer bg-white "} />
            </div>
            <div className="mt-[-4px] overflow-y-scroll h-[102px] w-[330px] border-2">
                <table className="ml-[-2px] mt-[-2px]">
                    <tbody>
                        {dispayBunkiList() && dispayBunkiList().map((data, idx) => (
                            <tr className="border-b-2 w-[340px] h-[29px]" key={idx}>
                                <td className="pl-2 pt-[2px] w-[70px] font-bold">分岐{data.dispOrder}</td>
                                <td className="ellipse pt-[2px] w-[240px] one-line whitespace-nowrap">{data.processDispOrder}-{data.workDispOrder}<span className="ml-[6px]">{data.workName}</span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {displayBunkiInput && <BunkiListInput
                onClose={onBunkiListInputBoxClose}
                className={"absolute right-0 top-0"} />}
        </div>
    );
}

export default SagyouBunkiList;
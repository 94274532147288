import { createSlice } from '@reduxjs/toolkit';

const intState = {
    visible: false,
}

export const confirmBoxSlice = createSlice({
    name: 'confirmBox',
    initialState: intState,
    reducers: {
        setVisible: (confirmBox, action) => {
            confirmBox.visible = action.payload;
        }
    }
});

export const getVisible = (state) => {
    return state.confirmBox.visible;
}

export const { setVisible } = confirmBoxSlice.actions;
export default confirmBoxSlice.reducer;
let port = null;
let reader = null;
let isPortConnected = false;

export function GetReader() {
    return reader;
}

export function GetPort() {
    return port;
}

export function IsPortConnected() {
    return isPortConnected;
}

export async function ConnectSerialPort() {
    CloseSerialPort();
    try {
        port = await navigator.serial.requestPort();
        let selectedBaudRate = 38400;
        await port.open({ baudRate: parseInt(selectedBaudRate, 10) });
        if (!port.readable) {
            return false;
        }
        reader = port.readable.getReader();
        isPortConnected = true;
        return true;
    }
    catch (error) {
        return false;
    }
}

export function CloseSerialPort() {
    if (port !== null && reader !== null) {
        reader.releaseLock();
        if (isPortConnected) {
            port.close();
            isPortConnected = false;
        }
    }
}
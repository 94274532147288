import React from "react";
import { BsChevronBarLeft, BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { variables } from "../Variables";

export function PagingBar(props) {
    let totalRowCount = props.totalCount;
    let defaultDisplayRows = props.defaultDisplayRows;
    let currPageNumber = props.currPageNumber;

    /**
     * 現在ページに表示するStartRowNumberを取る
     * @returns 
     */
    const getCurrStartRowNumber = () => {
        let startNumber = totalRowCount > 0 ? ((currPageNumber - 1) * defaultDisplayRows) + 1 : 0;
        return startNumber;
    }

    /**
     * 現在ページに表示するEndRowNumberを取る
     * @returns 
     */
    const getCurrEndRowNumber = () => {
        let endNumber = (totalRowCount > 0 && totalRowCount > defaultDisplayRows) ? currPageNumber * defaultDisplayRows : totalRowCount;
        return endNumber > totalRowCount ? totalRowCount : endNumber;
    }

    /**
     * 総ページ数を取得する
     * @returns 
     */
    const getTotalPageNumber = () => {
        let totalPage = 1;
        if (totalRowCount > 0) {
            let divideValue = Math.trunc(totalRowCount / defaultDisplayRows);
            let modValue = totalRowCount % defaultDisplayRows;
            totalPage = modValue > 0 ? divideValue + 1 : divideValue;
        }
        return totalPage;
    }

    function handleGoToFirstPage() {
        if (currPageNumber === 1) {
            return;
        }
        props.goToFirstPage();
    }

    function handleGoToPrevious() {
        if (currPageNumber === 1) {
            return;
        }
        props.goToPrevious();
    }

    function handleGoToNext() {
        if (currPageNumber >= getTotalPageNumber()) {
            return;
        }
        props.goToNext();
    }

    return (
        <div className={" flex items-center select-none " + props.className}>
            <div className=" ml-2">{`${getCurrStartRowNumber()} - ${getCurrEndRowNumber()} / ${totalRowCount}`}</div>
            <div className="flex absolute right-4">
                <BsChevronBarLeft className={"m-1 cursor-pointer " + ((currPageNumber === 1) ? "" : variables.HOVER_CSS)} style={{ color: ((currPageNumber === 1) ? '#A19F9D' : '#0073CD'), fontSize: '18px' }}
                    onClick={handleGoToFirstPage} />
                <BsArrowLeft className={"m-1 cursor-pointer " + ((currPageNumber === 1) ? "" : variables.HOVER_CSS)} style={{ color: ((currPageNumber === 1) ? '#A19F9D' : '#0073CD'), fontSize: '18px' }}
                    onClick={handleGoToPrevious} />
                <span className=" ml-1 mr-1">{`ページ${props.currPageNumber} / ${getTotalPageNumber()}`}</span>
                <BsArrowRight className={"m-1 cursor-pointer " + ((currPageNumber >= getTotalPageNumber()) ? "" : variables.HOVER_CSS)} style={{ color: ((currPageNumber >= getTotalPageNumber()) ? '#A19F9D' : '#0073CD'), fontSize: '18px' }}
                    onClick={handleGoToNext} />
            </div>
        </div>
    );
}

PagingBar.defaultProps = {
    className: " w-[1682px] h-[30px] mt-1",
    defaultDisplayRows: 0,
    totalCount: 0,
    currPageNumber: 1,
    goToFirstPage: () => { void (0) },
    goToPrevious: () => { void (0) },
    goToNext: () => { void (0) },
}
import { React } from "react";
import SemiCircleProgress from "../../../components/SemiCircleProgress";
import Draggable from "../../../components/Draggable";
import { useSelector } from "react-redux";
import { ManageItemType } from "../../../Variables";
import { GetKaisuuCheckCountWithRoundUpAndDown } from "../../util/commonFun";

function LotKurikaeshiList(props) {
    const items = useSelector((state) => state.lotOperation);
    const selectedWork = items.selectedWork;
    const kurikaeshiList = items.dataList.kurikaeshiList;
    const kurikaeshiLstbyWorkId = kurikaeshiList.filter((item) => item.dispWorkId.toLowerCase() === selectedWork.dispWorkId.toLowerCase());
    kurikaeshiLstbyWorkId.sort((a, b) => b.kurikaeshiNo - a.kurikaeshiNo);
    let kaisuuCheckCount = GetKaisuuCheckCountWithRoundUpAndDown(props.manageItemType, props.description, items.matomesuu);
    let completedKurikaeNo = kurikaeshiLstbyWorkId?.length ?? 0;
    // 回数の割合設定でまとめ数変更した後、繰り返し完了数が回数カウントより大きい場合、回数カウントを繰り返し完了数に設定する
    kaisuuCheckCount = completedKurikaeNo > kaisuuCheckCount ? completedKurikaeNo : kaisuuCheckCount;
    let isHardwareOrOkNgJudgeType = props.manageItemType === ManageItemType.Hardware || props.manageItemType === ManageItemType.OkNgJudge;
    let displayTitle = isHardwareOrOkNgJudgeType ? "完了数" : "管理項目";

    return (
        <>
            <Draggable defaultPosition={{ x: 1423, y: (isHardwareOrOkNgJudgeType) ? 540 : 39 }} bounds={{ left: 0, top: 0, right: 1920, bottom: 1080 }}>
                <div className={"w-[490px] border-[1px] bg-white border-[#707070] " + (isHardwareOrOkNgJudgeType ? "h-[304px]" : "h-[848px]")}>
                    <div className="w-[490px] h-[34px] bg-[#0073CD] text-white border-[1px] border-[#707070] border-r-[2px]">
                        <div className="text-[18px] ml-[2px] mt-[3px]">{displayTitle}</div>
                    </div>
                    {isHardwareOrOkNgJudgeType === false ?
                        <div className="mt-[5px] ml-[16px] h-[544px] overflow-y-scroll overflow-x-hidden">
                            <table>
                                <tbody>
                                    <tr className="border-b-[1px] h-[67px] text-[22px] bg-[#0073CD] text-white" key={0}>
                                        <td className="w-[88px] text-center mt-[21px]">{kurikaeshiLstbyWorkId.length + 1}</td>
                                        <td className="w-[376px] max-w-[370px] text-left mt-[21px] one-line overflow-hidden text-ellipsis ml-[10px]"></td>
                                    </tr>
                                    {kurikaeshiLstbyWorkId && kurikaeshiLstbyWorkId.map((kurikaeshi, index) => (
                                        <tr className="border-b-[1px] h-[67px] text-[22px]" key={index + 1}>
                                            <td className="w-[88px] text-center mt-[21px]">{kurikaeshi.kurikaeshiNo}</td>
                                            <td className="w-[376px] max-w-[372px] text-left mt-[21px] one-line overflow-hidden text-ellipsis ml-[10px]">{kurikaeshi.manageResult.txtInputText}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> :
                        <div></div>}
                    <div className="ml-[61px] mr-[44px] mt-[20px]">
                        <SemiCircleProgress
                            width={385}
                            barThickness={50}
                            maxValue={kaisuuCheckCount}
                            currValue={props.isShowFullPercentage === true ? completedKurikaeNo + 1 : completedKurikaeNo} />
                    </div>
                    <div className="h-[40px] flex flex-row mt-[5px]">
                        <div className="ml-[70px] w-[40px] text-[32px] text-center">0</div>
                        <div className="ml-[255px] w-[100px] text-[32px] text-center">{kaisuuCheckCount}</div>
                    </div>
                </div>
            </Draggable>
        </>
    )
}

export default LotKurikaeshiList;
import { React, useEffect, useRef } from 'react';
import SectionTitle from './SectionTitle';
import { useDispatch } from "react-redux";
import { setVisible } from './confirmBoxSlice';
import { popupcss } from '../Variables';
import { v4 as uuidv4 } from "uuid";

function ChuuDanComfirmBox(props) {
  const dispatch = useDispatch();
  let yesBtnRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      yesBtnRef.current.focus();
    }, 0);
    dispatch(setVisible(true));
    return function cleanup() {
      dispatch(setVisible(false));
    }
  }, [dispatch]);

  function handleSaikaiBtnKeyUp(e) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      props.onSaikaiClick();
    }
  }

  function handleStartScreenBtnKeyUp(e) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      props.onStartScreenClick();
    }
  }

  return (
    <div className={popupcss}>
      <div className={props.className}>
        <div className='mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white rounded-sm'>
          <div className=' m-[7px] w-[372px] h-[56px] bg-[#707070]'>
            <SectionTitle Text="中断" className='ml-[-6px] p-[14px] text-[28px] text-white' />
          </div>
          <div className='ml-[16px] text-[22px]'>{props.message}
            <div className='flex text-[22px]'>
              <div>
                <div
                  key={uuidv4()}
                  tabIndex={0}
                  ref={yesBtnRef}
                  onClick={props.onSaikaiClick}
                  onKeyUp={handleSaikaiBtnKeyUp}
                  className={'w-[90px] h-[40px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid cursor-pointer textmiddle text-center hover:bg-[#0068B8] rounded-sm'}
                >
                  再開
                </div>
              </div>
              <div className='ml-[20px]'>
                <div
                  key={uuidv4()}
                  tabIndex={0}
                  onClick={props.onStartScreenClick}
                  onKeyUp={handleStartScreenBtnKeyUp}
                  className={'w-[180px] h-[40px] border-[1px] border-[#605E5C] border-solid cursor-pointer textmiddle text-center hover:bg-[#D8D7D5] rounded-sm'}
                >
                  作業開始画面へ
                </div>
              </div>
            </div>
          </div>
          <div className='mt-[40px] m-[7px] w-[372px] h-[56px] bg-[#707070]'>
            <SectionTitle Text="作業停止入力" className='ml-[-6px] p-[14px] text-[28px] text-white' />
          </div>
          <div className='text-[22px] mt-[6px] ml-[10px]'>停止内容をテキスト/画像で登録できます。</div>
          <div className='flex text-[22px]'>
            <div
              key={uuidv4()}
              tabIndex={0}
              onClick={props.onSettingFormClick}
              className={'w-[125px] h-[40px] ml-[15px] mt-[16px] mb-[18px] pt-[6px] border-[1px] border-[#605E5C] border-solid cursor-pointer textmiddle text-center hover:bg-[#D8D7D5] rounded-sm'}
            >
              入力画面へ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Specifies the default values for props:
ChuuDanComfirmBox.defaultProps = {
  onSaikaiClick: () => void (0),
  onStartScreenClick: () => void (0),
  onSettingFormClick: () => void (0),
};

export default ChuuDanComfirmBox;
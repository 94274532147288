import { React, useEffect, useRef } from 'react';
import SectionTitle from './SectionTitle';
import { useDispatch } from "react-redux";
import { setVisible } from './confirmBoxSlice';
import { popupcss } from '../Variables';
import { v4 as uuidv4 } from "uuid";

export const FocusButton = {
  None: 0,
  Btn1: 1,
  Btn2: 2
}
Object.freeze(FocusButton);

function ConfirmBox(props) {
  const dispatch = useDispatch();
  let yesBtnRef = useRef(null);
  let noBtnRef = useRef(null);

  useEffect(() => {
    if (props.foucsBtn === FocusButton.Btn1) {
      yesBtnRef.current.focus();
    } else if (props.foucsBtn === FocusButton.Btn2) {
      noBtnRef.current.focus();
    }

    dispatch(setVisible(true));
    return function cleanup() {
      dispatch(setVisible(false));
    }
  }, [dispatch]);

  function handleYesBtnKeyUp(e) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      props.onYesClick();
    }
  }

  function handleNoBtnKeyUp(e) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      props.onNoClick();
    }
  }

  return (
    <div className={popupcss}>
      <div className={props.className}>
        <div className='p-[15px] mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white rounded-sm'>
          <SectionTitle Text={props.title} className='text-[26px] font-bold' />
          <div className='m-[10px] text-[22px] break-all'>{props.message}</div>
          <div className='flex mt-[15px] text-[22px]'>
            <div>
              <div
                key={uuidv4()}
                tabIndex={0}
                ref={yesBtnRef}
                onClick={props.onYesClick}
                onKeyUp={handleYesBtnKeyUp}
                className={'w-[' + props.btn1Width + 'px] h-[40px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid cursor-pointer textmiddle text-center hover:bg-[#0068B8] rounded-sm'}
              >
                {props.btn1Text}
              </div>
            </div>
            <div className='ml-[7px]'>
              <div
                key={uuidv4()}
                tabIndex={0}
                ref={noBtnRef}
                onClick={props.onNoClick}
                onKeyUp={handleNoBtnKeyUp}
                className={'w-[' + props.btn2Width + 'px] h-[40px] border-[1px] border-[#605E5C] border-solid cursor-pointer textmiddle text-center hover:bg-[#D8D7D5] rounded-sm'}
              >
                {props.btn2Text}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmBox.defaultProps = {
  onYesClick: () => void (0),
  onNoClick: () => void (0),
  foucsBtn: FocusButton.Btn1,
  btn1Text: "はい",
  btn2Text: "いいえ",
  btn1Width: 83,
  btn2Width: 83,
}

export default ConfirmBox;
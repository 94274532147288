import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditButton from "../../../components/EditButton";
import ToggleButton from "../../../components/ToggleButton";
import HardwareInput from "./HardwareInput";
import { setHardwareAutoTransition, selectMngItmByWorkID, setHardwareKurikaeshi } from "./manageItemSlice";
import { BsGear } from "@react-icons/all-files/bs/BsGear";
import { IconContext } from "@react-icons/all-files";
import { OperationManagementType } from "../../../Variables";
import { selectitemManagementType } from "../../editor/processChartSlice";

function ManageItemHardware(props) {
    const dispatch = useDispatch();
    const selectedWorkID = props.workID;
    const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
    let hardwareList = mngItm.data.hardwareData;
    const [displayHardwareInput, setDisplayHardwareInput] = useState(false);
    const itemManagementType = useSelector(selectitemManagementType);

    const displayHardwareList = () => {
        let displayHardwareData = [];
        if (hardwareList.length >= 1) {
            for (let i = 0; i < hardwareList.length; i++) {
                displayHardwareData.push({ hardwareType: hardwareList[i].hardwareType === 1 ? "締結" : hardwareList[i].hardwareType === 2 ? "信号" : "", handleName: hardwareList[i].handleName, count: hardwareList[i].count });
            }
        }
        return displayHardwareData;
    }

    function handleKaisuSetting() {
        dispatch(setHardwareKurikaeshi({ workID: selectedWorkID, hardwareKurikaeshi: !mngItm.data.hardwareKurikaeshi }));
    }

    function onAutoTransitionToggleChange() {
        dispatch(setHardwareAutoTransition({ workID: selectedWorkID, hardwareAutoTransition: !mngItm.data.hardwareAutoTransition }));
    }

    function handleEditButtonClick() {
        setDisplayHardwareInput(true);
    }

    function onHardwareInputBoxClose() {
        setDisplayHardwareInput(false);
    }

    return (
        <div className="flex">
            <div className="flex-row w-[284px]">
                <div className="ml-[6px] flex flex-row">
                    <EditButton
                        isLock={props.isLock}
                        className="w-[64px] h-[23px]"
                        onClick={handleEditButtonClick}
                        editBtnCss={"flex items-center justify-center w-[80px] h-[28px] cursor-pointer bg-white "} />
                    <span className="font-bold text-red-500 mt-[5px] ml-[13px]" style={{ fontSize: "30px" }}>*</span>
                </div>
                <div className="flex flex-col ml-[5px] mt-1 w-[261px] h-[100px] border-2 border-[#C8C6C4] bg-white overflow-y-scroll overflow-x-hidden">
                    <div className="sticky top-0 w-[240px] h-[22px] bg-[#c8c6c4]">
                        <span className="ml-[4px]">種類</span>
                        <span className="ml-[17px]">ネーム/データ</span>
                        <span className="ml-[50px]">カウント</span>
                    </div>
                    <table className="border-collase w-full">
                        <tbody>
                            {displayHardwareList() && displayHardwareList().map((data, idx) => (
                                <tr className="h-[21px] w-[238px] border-b" key={idx}>
                                    <td className="w-[52px] pl-[4px]">{data.hardwareType}</td>
                                    <td className="ellipse one-line whitespace-nowrap w-[130px]">{data.handleName}</td>
                                    <td className="text-right pr-[10px]">{data.count === 0 ? "" : data.count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex-row w-[214px] mt-[30px]">
                <div className="flex flex-col w-[261px] mt-1 h-[120px]">
                    {itemManagementType === OperationManagementType.LOTNUMBER && (
                        <>
                            <div className="flex flex-row">
                                <div className="mt-1.5">
                                    <IconContext.Provider value={{ size: "15px" }}>
                                        <BsGear />
                                    </IconContext.Provider>
                                </div>
                                <div className="font-bold ml-[5px]">
                                    実行回数
                                </div>
                            </div>
                            <div className="ml-[20px]">
                                <input type="radio" id="radioBatchNo" disabled={props.isLock ? true : false} onChange={handleKaisuSetting}
                                    checked={mngItm.data.hardwareKurikaeshi === true}
                                    name="KaisuNo" />
                                <label htmlFor="radioBatchNo" className="pl-1">
                                    バッチサイズ全数
                                </label>
                            </div>
                            <div className="ml-[20px]">
                                <input type="radio" id="radioOneTimeNo" disabled={props.isLock ? true : false} onChange={handleKaisuSetting}
                                    checked={mngItm.data.hardwareKurikaeshi=== false}
                                    name="KaisuNo" />
                                <label htmlFor="radioOneTimeNo" className="pl-1">
                                    1回
                                </label>
                            </div>
                        </>
                    )}

                    <div className="flex flex-row mt-1">
                        <div className="mt-1.5">
                            <IconContext.Provider value={{ size: "15px" }}>
                                <BsGear />
                            </IconContext.Provider>
                        </div>
                        <div className="font-bold ml-[5px]">
                            自動遷移
                        </div>
                        <div className="ml-[5px]">
                            <ToggleButton
                                isChecked={mngItm.data.hardwareAutoTransition}
                                onChange={onAutoTransitionToggleChange}
                                isLock={props.isLock}
                                inputID={"hardwareSetting"} />
                        </div>
                    </div>
                </div>
            </div>
            {displayHardwareInput && <HardwareInput
                workID={selectedWorkID}
                onClose={onHardwareInputBoxClose}
                className={"absolute right-0 top-0"} />}
        </div>
    );
}
export default ManageItemHardware;
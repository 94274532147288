import { React, useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IconContext } from "react-icons";

function Collapsible(props) {
    let desiredIndex = props.desiredIndex;
    let id = props.id;
    let expandId = props.expandId;
    const [isOpen, setIsOpen] = useState(false);
    var div = document.getElementById("scrollDiv");

    useEffect(() => {
        if (expandId > -1) {
            setIsOpen(id === expandId);
            if (id === expandId && desiredIndex > -1) {
                var scrollPosition = desiredIndex * 53;
                div.scrollTop = scrollPosition;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, expandId, desiredIndex])

    function onchange() {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <div className={"p-3 w-fit h-[53px] border-bottom d-flex justify-content-between " + props.margin}>
                <button type="button" className="btn" onClick={onchange}>
                    <IconContext.Provider value={{ color: "black", size: "16px", className: "inline-flex ml-[42px]" }}>
                        {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
                    </IconContext.Provider>
                    <h6 className="font-weight-bold inline-flex pl-[13px] w-[1530px]">{props.title}</h6>
                </button>
            </div>
            {isOpen ? (
                <div>
                    {props.children}
                </div>
            ) : null}
        </div>
    );
}
export default Collapsible;
import { React, useState } from "react";

function InputBoxWithBlur(props) {
    const [text, setText] = useState(props.text);
    function onTextChange(e) {
        setText(e.target.value);
    }

    function onBlurInput(e) {
        props.onBlur(text);
    }

    return (
        <>
            <div className="w-full h-full">
                {
                    props.isEnable
                        ?
                        <input
                            className={"w-full h-full border-[2px] pl-[5px] rounded-sm self-center focus:outline-none focus:border-[#0073CD] "+ props.textsize}
                            type="text"
                            value={text}
                            maxLength={props.maxLength}
                            onChange={onTextChange}
                            onBlur={onBlurInput}
                            ref={props.setInputRef}
                        />
                        :
                        <input
                            className={"w-full h-full border-[2px] pl-[5px] rounded-sm self-center focus:outline-none bg-[#FAF9F8] " + props.textsize}
                            type="text"
                            value={text}
                            readOnly={true}
                            maxLength={props.maxLength}
                        />
                }

            </div>
        </>
    );
}
InputBoxWithBlur.defaultProps = {
    isEnable: false,
    textsize : "text-[16px]",
};
export default InputBoxWithBlur;

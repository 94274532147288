function ChuuDanMessage(props) {
    return (
        <>
            <div className="text-[22px]">作業を中断しています。</div>
            <div className="font-bold text-[22px] ml-2 mt-4">製品名</div>
            <div className="text-[22px] ml-5 mt-3 w-[330px] break-all">
                <span>{props.itemName}</span>
                <span className="ml-1">{props.version.toString().padStart(3, '0') + "版"}</span>
            </div>
            <div className="font-bold text-[22px] ml-2 mt-5">製造 / シリアル / ロット 番号</div>
            <div className="text-[22px] ml-5 mt-3 w-[330px] break-all">{props.serialLotNumber}</div>
            <div className="font-bold text-[22px] ml-2 mt-6">ユーザー名</div>
            <div className="text-[22px] ml-5 mt-3 w-[330px] break-all mb-5">{props.userName}</div>
        </>
    );
}

ChuuDanMessage.defaultProps = {
    itemName: "",
    version: "",
    serialLotNumber: "",
    userName: "",
}
export default ChuuDanMessage;
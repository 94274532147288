import React from "react";
import { IconContext } from "react-icons";
import { GoX } from "react-icons/go";
import { variables } from "../Variables";

class Discard extends React.Component {
    render() {
        return (
            <div onClick={this.props.onClick}
                className={this.props.className || "h-[53px] w-fit"}
            >
                <div className={"flex items-center h-full w-fit mx-[15px] hover:cursor-pointer " + variables.HOVER_CSS} >
                    <IconContext.Provider
                        value={{ color: "#0073CD", size: "16px" }}>
                        <GoX />
                    </IconContext.Provider>
                    <div className="mx-[5px]">破棄</div>
                </div>
            </div >
        );
    }
}

export default Discard;
import { createSlice } from "@reduxjs/toolkit";
import { OperationManagementType } from "../../Variables";

const initState = {
    chartID: "",
    itemCode: -1,
    itemName: "",
    version: -1,
    itemManagementType: OperationManagementType.NONE,
};

export const processChartSlice = createSlice({
    name: "processChart",
    initialState: initState,
    reducers: {
        /**
         * payload: {chartID<string>, itemCode<int>, itemName<string>, itemManagementType<int>}
         * @param {*} processChart 
         * @param {*} action 
         */
        setChart: (processChart, action) => {
            const { chartID, itemCode, itemName, version, itemManagementType } = action.payload;
            processChart.chartID = chartID;
            processChart.itemCode = itemCode;
            processChart.itemName = itemName;
            processChart.version = version;
            processChart.itemManagementType = itemManagementType;
        },
        /**
         * チャートIDを設定する。
         * param：string
         * @param {*} processChart 
         * @param {*} action 
         */
        setChartID: (processChart, action) => {
            processChart.chartID = action.payload;
        },
        /**
        * バージョンを設定する。
        * param：string
        * @param {*} processChart 
        * @param {*} action 
        */
        setVersion: (processChart, action) => {
            processChart.version = action.payload;
        },
        resetChart: (processChart, action) => {
            return { ...initState };
        }
    }

});

export const selectChartID = (state) => {
    return state.processChart.chartID;
};

export const selectChartVersion = (state) => {
    return state.processChart.version;
}

export const selectProcessChart = (state) => {
    return state.processChart;
};

export const selectitemManagementType = (state) => {
    return state.processChart.itemManagementType;
};

export const { setChart, setChartID, setVersion, resetChart } = processChartSlice.actions;
export default processChartSlice.reducer;
import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setWorkNote, getWorkNote, getSelectedWorkInfo } from "./workListSlice";
import { VscError } from "react-icons/vsc";
import { IconContext } from "react-icons";
import { popupcss, maxLengthWorkNote } from "../../../Variables";
import { MdOutlineClose } from "react-icons/md";

function WorkInstruction(props) {
    const dispatch = useDispatch();
    const selectedWork = useSelector(getSelectedWorkInfo);
    const workNote = useSelector((state) => getWorkNote(state, selectedWork.workID));
    const [isCharOverLenght, setCharOverLenght] = useState(false);
    const [isDisplayPreview, setDisplayPreview] = useState(false);
    const [isBtnDisabled, setBtnDisabled] = useState(false);

    useEffect(() => {
        setCharOverLenght(selectedWork.workNote.length > maxLengthWorkNote);
        setBtnDisabled(selectedWork.workNote.trim().length === 0);
    }, []);

    function onTextChange(e) {
        if (selectedWork.workID && selectedWork.workID.length > 0) {
            let inputText = e.target.value;
            setCharOverLenght(inputText.length > maxLengthWorkNote);
            setBtnDisabled(inputText.trim().length === 0);
            dispatch(setWorkNote({
                workID: selectedWork.workID,
                text: inputText
            }));
        }
    }

    return (
        <div>
            <div className="flex">
                <div className="font-bold text-[24px] w-[100px] h-[24px]" >
                    作業指示
                </div>
                <button className={"w-[100px] h-[24px] ml-[20px] text-center text-white rounded-sm " +
                    (isBtnDisabled ? " text-[#A19F9D] bg-[#C8C6C4] " : " bg-[#0073CD] hover:bg-[#0068B8] ")}
                    onClick={() => { setDisplayPreview(true); }} disabled={isBtnDisabled}>
                    表示確認
                </button>
                <div className="w-[350px] h-[24px] ml-1 text-right">
                    {isCharOverLenght &&
                        <label className="text-red-600">
                            <IconContext.Provider value={{ color: "red" }}>
                                <VscError className="w-[18px] h-[21px] inline-block pb-1" />
                                入力出来る文字数は最大{maxLengthWorkNote}文字です。
                            </IconContext.Provider>
                        </label>
                    }
                </div>
            </div>
            <div>
                {
                    (selectedWork.workID && selectedWork.workID.length > 0 && selectedWork.isLock === false) ?
                        <textarea
                            value={workNote}
                            onChange={onTextChange}
                            className={"w-[576px] h-[175px] mt-1.5 pl-[5px] resize-none border-2 outline-offset-0 "
                                + (isCharOverLenght ? " border-[#CD0000] focus:outline-[#CD0000] " : " border-[#C8C6C4] focus:outline-[#0073CD]")}
                        />
                        :
                        <textarea
                            readOnly={true}
                            value={workNote}
                            className="w-[576px] h-[175px] mt-1.5 pl-[5px] resize-none border-2 border-[#C8C6C4] outline-none bg-[#FAF9F8]"
                        />
                }
            </div>
            {isDisplayPreview &&
                <div className={popupcss}>
                    <div className="ml-[410px] mt-[845px] ">
                        <div className="flex w-[1221px] h-[34px] border-2 bg-zinc-100 ">
                            <label className="w-[100px] font-bold text-[18px] ml-2 mt-1">表示確認</label>
                            <IconContext.Provider value={{ size: "24px" }}>
                                <MdOutlineClose className="ml-[1082px] mt-[3px] cursor-pointer hover:bg-gray-200" onClick={() => { setDisplayPreview(false); }} />
                            </IconContext.Provider>
                        </div>
                        <div className="w-[1221px] h-[189px] border-2 border-[#C8C6C4] bg-white ">
                            <textarea disabled
                                className={"w-[1218px] h-[185px] font-bold text-[29px] leading-9 break-all px-2 bg-white overflow-x-hidden overflow-y-auto resize-none"}
                                value={selectedWork.workNote} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default WorkInstruction;

import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SaveButtonSm from '../../components/SaveButtonSm';
import HakkouButtonSm from '../../components/HakkouButtonSm';
import { variables, StatusCode, MasterPage, ManageItemType, HindoKaisuSetting, OperationManagementType } from "../../Variables";
import ConfirmBox from "../../components/ConfirmBox";
import CompleteBox from "../../components/CompleteBox";
import { selectProcessList, getCount, selectBackupProcessList, selectCurrentProcessList } from "./process/processListSlice";
import { setChartID, selectProcessChart, selectChartVersion } from "./processChartSlice";
import { getAllWorkList, getBackupWorkList, checkAllLocked, getCurrentWorkList } from './work/workListSlice';
import { selectAllDocs, selectBackupDocs } from './work/detailDocSlice';
import { getBackupMngItm } from './work/manageItemSlice';
import { increaseTempSaveCount } from './btnClickCountSlice';
import { generatePath } from 'react-router';
import { getAllMngItm } from './work/manageItemSlice';
import { selectAllTools, selectBackupTools } from './work/toolListSlice';
import { setIsLoading as setPageLoding } from '../Loading/pageLoadingSlice';
import { ToLocalDateTime, NavigateToCompanyLogin, NavigateToUserLogin, NavigateToVersion, urlCompanyName } from '../util/commonFun';
import ErrorBox from '../../components/ErrorBox';
import LoginUserDisplay from '../../components/LoginUserDisplay';
import Logout from "../../components/Logout";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');
const hash = require('object-hash');

let confirmMsg = "";

function EditorTitleBar(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isAllLocked = useSelector(checkAllLocked);
  const processCount = useSelector(getCount);

  const processChart = useSelector(selectProcessChart);
  const [dialog, setDialog] = useState(null);
  const procList = useSelector(selectProcessList);
  const allWorkList = useSelector(getAllWorkList);
  const allDtlDocs = useSelector(selectAllDocs);
  const allToolList = useSelector(selectAllTools);
  const allMngItm = useSelector(getAllMngItm);

  const currentProcList = useSelector(selectCurrentProcessList);
  const currentWorkList = useSelector(getCurrentWorkList);
  const backupProcList = useSelector(selectBackupProcessList);
  const backupWorkList = useSelector(getBackupWorkList);
  const backupAllDtlDocs = useSelector(selectBackupDocs);
  const backupAllToolList = useSelector(selectBackupTools);
  const backupAllMngItm = useSelector(getBackupMngItm);
  const chartVersion = useSelector(selectChartVersion);

  const [isBusy, setIsBusy] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  /**
   * 一時保存もしくは発行する。
   * プロセスチャート内のデータを保存する。
   * 「工程一覧」、「作業一覧」、「詳細資料」、「工具一覧」、「管理項目データ」
   * @param {bool} isHakkoue 
   */
  async function prepareAndSendData(isHakkoue) {
    if (procList && procList.length > 0) {
      // 一時保存の処理中に他の処理を防ぐ為に、 画面の読み込み状態を[true]にして置く。
      // 一時保存処理が完了して、データの再読み込み処理が完了した時、画面の読み込み状態を[false]にします。
      dispatch(setPageLoding(true));

      let successFileUpload = true;
      // 工程のID一覧
      const procIDList = procList.map(p => p.processID);
      let workListOfChart = [];
      let dtlDocListOfChart = [];
      let toolListOfChart = [];
      let mngItmListOfChart = [];
      // 作業のID一覧
      let workIDListOfChart = [];
      const formData = new FormData();
      if (allWorkList && allWorkList.length > 0) {
        if (procIDList && procIDList.length > 0) {
          workListOfChart = allWorkList.filter(w => procIDList.findIndex(pID => pID.toUpperCase() === w.processID.toUpperCase()) >= 0);
        }

        if (workListOfChart && workListOfChart.length > 0) {
          workIDListOfChart = workListOfChart.map(w => w.workID);
        }

        //#region ファイル送信用にコード　Start
        if (allDtlDocs && allDtlDocs.length > 0) {
          dtlDocListOfChart = allDtlDocs.filter(d => workIDListOfChart.findIndex(wID => wID.toUpperCase() === d.workID.toUpperCase()) >= 0);
          // 詳細資料のデータが有る場合、それをAPIへ送信する。
          if (dtlDocListOfChart && dtlDocListOfChart.length > 0) {
            for (let idx = 0; idx < dtlDocListOfChart.length; idx++) {
              const fileExtension = "." + dtlDocListOfChart[idx].docFileName.split(".").pop();
              if (fileExtension === ".txt" && dtlDocListOfChart[idx].objUrl && dtlDocListOfChart[idx].objUrl.length > 0) {
                try {
                  const url = new URL(dtlDocListOfChart[idx].objUrl);
                  // UI 側で作成したファイルのみ送信します。サーバーに登録済みのファイルは送信しません。
                  if (url.host === "") {
                    // オブジェクトURLに有るファイル[blob]を取得
                    const blob = await fetch(dtlDocListOfChart[idx].objUrl).then(r => r.blob());
                    const file = new File([blob], dtlDocListOfChart[idx].docFileName);
                    formData.append("files", file, dtlDocListOfChart[idx].docFileName);
                  }
                } catch (error) {
                  console.error(error);
                }
              }
            }
          }
        }

        // 管理項目の警告メッセージの写真データ準備
        if (allMngItm && allMngItm.length > 0) {
          mngItmListOfChart = allMngItm.filter(itm => workIDListOfChart.findIndex(wID => wID.toUpperCase() === itm.workID.toUpperCase()) >= 0);
        }

        //　工具の写真データ準備
        if (allToolList && allToolList.length > 0) {
          toolListOfChart = allToolList.filter(t => workIDListOfChart.findIndex(wID => wID.toUpperCase() === t.workId.toUpperCase()) >= 0);
        }

        // ファイル送信
        await axios({
          method: 'POST',
          url: variables.EDITOR_URL + "/fileupload",
          withCredentials: true,
          headers: { 'company': urlCompanyName },
          data: formData
        }).catch(function (error) {
          alert("送信できません。");
          successFileUpload = false;
          setIsBusy(false);
          dispatch(setPageLoding(false));

          let status = error.response.status;
          if (status === 401) {
            NavigateToCompanyLogin(navigate);
          }
          else {
            appInsights.trackTrace({ data: JSON.stringify(formData) });
            appInsights.trackException({ ...error, errorFunction: "EditorTitleBar.prepareAndSendData()" });
          }
        });

        // ファイル送信用にコード　End
        //#endregion
      }
      if (successFileUpload) {
        //#region 工程一覧データと作業一覧データ送信
        const _data = {
          processChartRequestPost: {
            processChartID: processChart.chartID,
            itemCode: processChart.itemCode
          },
          processPostList: procList,
          workPostList: workListOfChart,
          detailDocList: dtlDocListOfChart,
          mngItmRequestPost: mngItmListOfChart,
          toolRequestPostList: toolListOfChart
        };

        let _url = variables.EDITOR_URL + "/tempsave";
        if (isHakkoue) {
          _url = variables.EDITOR_URL + "/hakkoue";
        }

        // データを送信する。
        axios({
          method: 'POST',
          url: _url,
          withCredentials: true,
          headers: { 'company': urlCompanyName },
          data: _data
        }).then(function (response) {
          if (isHakkoue) {
            dispatch(setPageLoding(false));
            setDialog(
              <CompleteBox
                className="absolute right-[162px] top-[100px] w-[471px]"
                title="発行" message={"発行が完了しました。TOP画面に戻ります。"}
                onYesClick={onHakkouCompleteBoxYes} />
            );
          } else {
            if (response.data.chartID && response.data.chartID.length > 0) {
              // サーバー側にチャートIDを変更して登録する場合があるので、サーバーから貰ったIDで次処理を行う。
              dispatch(setChartID(response.data.chartID));
              // データを再取得する為に、カウントを増加する。影響箇所：　Editor.jsx -> useEffect
              dispatch(increaseTempSaveCount());
            } else {
              dispatch(setPageLoding(false));
            }
            setDialog(
              <CompleteBox
                className="absolute right-[162px] top-[100px] w-[471px]"
                title="一時保存" message={"一時保存が完了しました。"}
                onYesClick={() => { setIsBusy(false); clearDialog(); }} />
            );
          }
        }).catch(function (error) {
          alert("送信できません。");
          dispatch(setPageLoding(false));
          let status = error.response.status;
          if (status === 401) {
            NavigateToCompanyLogin(navigate);
          }
          else {
            appInsights.trackTrace({ data: JSON.stringify(_data) });
            appInsights.trackException({ ...error, errorFunction: "EditorTitleBar.prepareAndSendData()" });
          }
        }).finally(() => {
          setIsBusy(false);
        });
        //#endregion
      }
    }
  }

  /**
   * 他のユーザーが保存した一時保存データがあるか確認して、
   * 他ユーザーの一時保存データが有る場合、確認メッセージを表示する。
   * ない場合、確認無しで、一時保存する。
   * @param {*} e 
   */
  async function onTemporarySave(e) {
    setIsBusy(true);
    if (!isDataChanged()) {
      // データ変更無しの時、エラーメッセージを表示して、処理を終了する。
      setDialog(
        <ErrorBox
          className="absolute right-[162px] top-[100px] w-[528px]"
          Title="一時保存" Message={"工程設計の変更点が無い為、一時保存できません。"}
          onYesClick={() => { setIsBusy(false); clearDialog() }} />
      );
      return;
    }
    axios({
      method: 'GET',
      url: variables.PROCESS_CHART_URL + "/get-chart-of-other-user",
      headers: { 'company': urlCompanyName },
      withCredentials: true,
      params: {
        itemCode: processChart.itemCode,
      }
    }).then((response) => {
      // 他ユーザーの一時保存データが有る場合、確認メッセージを出す。
      if (response.data && response.data.userName.length > 0) {
        confirmMsg = (
          <>
            <p className='break-all'>
              {`「${response.data.userName}」が「${ToLocalDateTime(response.data.updateDate)}」に作成したデータがあります。`}
              <br />
              {"上書きしますか。"}
            </p>
          </>
        );
        setDialog(
          <ConfirmBox
            className="absolute right-[162px] top-[100px] w-[528px]"
            title="一時保存" message={confirmMsg}
            onYesClick={onTempSaveConfirmBoxYes} onNoClick={() => { setIsBusy(false); clearDialog(); }} />
        );
      } else {
        prepareAndSendData(false);
      }
    }).catch((error) => {
      setIsBusy(false);
      if (error.response.status === 401) {
        NavigateToCompanyLogin(navigate);
      } else {
        appInsights.trackTrace({ params: JSON.stringify({ itemCode: processChart.itemCode }) });
        appInsights.trackException({ ...error, errorFunction: "EditorTitleBar.onTemporarySave()" });
        alert("送信できません。");
      }
    });
  }

  /**
   * 一時保存確認ボックスの[はい]ボタンイベントハンドラ
   */
  function onTempSaveConfirmBoxYes() {
    clearDialog();
    prepareAndSendData();
  }

  /**
   * 完了出来ない工程になっているかどうかを確認する。
   * 発行時に文字列比較の比較先が比較元よりも後にあり、 最後の作業でOKの場合、前の作業に分岐させる設定がある又は
   * 途中の作業でOK/NG両方とも前の作業（自作業含む）に分岐させる設定、return true;
   * 発行時に文字列比較の比較先が比較元よりも後にあり、作業分岐の分岐設定の分岐先がどちらも戻る設定の場合、return true;
   */
  function isInfiniteProcess() {
    if (allMngItm && allMngItm.length > 0) {
      const sortedListOfAllWork = createSortedListOfAllWork();
      if (sortedListOfAllWork && sortedListOfAllWork.length > 0) {
        if (isTxtInfiniteProcess()) {
          if (isOKNGInfiniteProcess() || isBunkiWorkInfiniteProcess()) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * 完了出来ない工程になっているかどうかを確認する。
   * 以下２点のどちかが満たされる場合、return true;
   *     - 最後の作業でOKの場合、前の作業に分岐させる設定
   *     - 途中の作業でOK/NG両方とも前の作業（自作業含む）に分岐させる設定
   */
  function isOKNGInfiniteProcess() {
    if (allMngItm && allMngItm.length > 0) {
      const sortedListOfAllWork = createSortedListOfAllWork();
      if (sortedListOfAllWork && sortedListOfAllWork.length > 0) {
        for (let idx = 0; idx < allMngItm.length; idx++) {
          // OK/NG判定の分岐作業がある場合のみ、確認処理を行う。
          if (allMngItm[idx].data.okWorkID.length > 0 || allMngItm[idx].data.ngWorkID.length > 0) {
            // 最後作業か確認。
            if (allMngItm[idx].workID === sortedListOfAllWork.at(-1).workID) {
              // 最後作業の場合、OKに分岐設定があったら、完了出来ない工程になるので、return true;
              if (allMngItm[idx].data.okWorkID.length > 0) {
                return true;
              }
            } else {
              // 途中作業の場合。

              // 当作業の位置
              const thisWorkPosition = sortedListOfAllWork.findIndex(w => w.workID === allMngItm[idx].workID);
              // OK分岐に設定されている作業の位置
              const okWorkPosition = allMngItm[idx].data.okWorkID.length > 0 ? sortedListOfAllWork.findIndex(w => w.workID === allMngItm[idx].data.okWorkID) : -1;
              // NG分岐に設定されている作業の位置
              const ngWorkPosition = allMngItm[idx].data.ngWorkID.length > 0 ? sortedListOfAllWork.findIndex(w => w.workID === allMngItm[idx].data.ngWorkID) : -1;
              // OK/NG両方とも前の作業（自作業含む）に分岐していたら、完了出来ない工程になるので、return true;
              if (okWorkPosition >= 0 && okWorkPosition <= thisWorkPosition
                && ngWorkPosition >= 0 && ngWorkPosition <= thisWorkPosition) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }
  /**
   *各工程の全作業で「頻度設定」をしている場合は発行ができない
   */
  function isCheckHindoSettingForAllWork() {
    if (processChart.itemManagementType === OperationManagementType.LOTNUMBER) {
      if (procList && procList.length > 0 && allWorkList && allWorkList.length > 0) {
        for (let idx = 0; idx < procList.length; idx++) {
          let wList = allWorkList.filter(w => w.processID === procList[idx].processID);
          if (wList && wList.length > 0) {
            let count = 0;
            for (let wIdx = 0; wIdx < wList.length; wIdx++) {
              let hindoKaisuSettingType = allMngItm.find(x => x.workID === wList[wIdx].workID)?.data.hindoKaisuSetting.hindoKaisuSettingType;
              if (hindoKaisuSettingType === HindoKaisuSetting.Hindo) {
                count++;
              }
            }
            if (count === wList.length) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  /**
   * 分岐設定の分岐先が全て前の作業に設定されているか確認する。
   * @returns bool
   */
  function isBunkiWorkInfiniteProcess() {
    let isBunkiWorkInifinite = false;
    if (allMngItm && allMngItm.length > 0) {
      const sortedListOfAllWork = createSortedListOfAllWork();
      if (sortedListOfAllWork && sortedListOfAllWork.length > 0) {
        for (let idx = 0; idx < allMngItm.length; idx++) {
          // 当作業の位置
          const thisWorkPosition = sortedListOfAllWork.findIndex(w => w.workID === allMngItm[idx].workID);
          if (allMngItm[idx].mngItmType === ManageItemType.SagyouBunki) {
            const bunkiData = allMngItm[idx].data.bunkiData;
            let bunkiSakiPosList = [];
            for (let i = 0; i < bunkiData.length; i++) {
              //分岐先の位置
              let bunkiSakiPosition = sortedListOfAllWork.findIndex(w => w.workID === bunkiData[i].workID);
              bunkiSakiPosList.push(bunkiSakiPosition);
            }
            // 全ての分岐が前の作業に分岐していたら、完了出来ない工程になるので、return true;break;
            let bunkiNotInifinite = bunkiSakiPosList.some(sakiPostion => sakiPostion > thisWorkPosition);
            if (!bunkiNotInifinite) {
              isBunkiWorkInifinite = true;
              break;
            }
          }
        }
      }
    }
    return isBunkiWorkInifinite;
  }

  /**
* 完了出来ない工程になっているかどうかを確認する。
* 発行時に文字列比較の比較先が比較元よりも後にある場合、return true;
*/
  function isTxtInfiniteProcess() {
    if (allMngItm && allMngItm.length > 0) {
      const sortedListOfAllWork = createSortedListOfAllWork();
      if (sortedListOfAllWork && sortedListOfAllWork.length > 0) {
        for (let idx = 0; idx < allMngItm.length; idx++) {
          // 文字列比較がある場合のみ、確認処理を行う。
          if (allMngItm[idx].data.txtCompWorkID.length > 0) {
            // 当作業の位置
            const thisWorkPosition = sortedListOfAllWork.findIndex(w => w.workID === allMngItm[idx].workID);
            const sakiPosition = allMngItm[idx].data.txtCompWorkID.length > 0 ? sortedListOfAllWork.findIndex(w => w.workID === allMngItm[idx].data.txtCompWorkID) : -1;
            // 文字列比較の比較先が比較元よりも後にある場合、return true;
            if (sakiPosition >= 0 && thisWorkPosition <= sakiPosition) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  /**
   * 工程のdispOrderと作業のdispOrderでソートして、全作業のListを作成する。
   */
  function createSortedListOfAllWork() {
    let sortedList = [];
    if (procList && procList.length > 0 && allWorkList && allWorkList.length > 0) {
      for (let idx = 0; idx < procList.length; idx++) {
        let wList = allWorkList.filter(w => w.processID === procList[idx].processID);
        if (wList && wList.length > 0) {
          wList.sort((a, b) => a.dispOrder - b.dispOrder);
          sortedList = sortedList.concat(wList);
        }
      }
    }
    return sortedList;
  }

  /**
   * 発行ボタンイベントハンドラ
   */
  function onHakkou() {
    if (chartVersion === 0 || isDataChanged()) {
      setIsBusy(true);
      if (isInfiniteProcess()) {
        confirmMsg = (
          <>
            <p className='break-all'>
              {"作業完了できない分岐設定と文字列比較設定になっている為、発行できません。"}
              <br />
              {"設定を確認してください。"}
            </p>
          </>
        );
        setDialog(
          <ErrorBox
            className="absolute right-[162px] top-[100px] w-[755px]"
            Title="発行" Message={confirmMsg}
            onYesClick={() => { setIsBusy(false); clearDialog() }} />
        );
      }
      else if (isOKNGInfiniteProcess() || isBunkiWorkInfiniteProcess()) {
        confirmMsg = (
          <>
            <p className='break-all'>
              {"作業完了できない分岐設定になっている為、発行できません。"}
              <br />
              {"分岐設定を確認してください。"}
            </p>
          </>
        );
        setDialog(
          <ErrorBox
            className="absolute right-[162px] top-[100px] w-[585px]"
            Title="発行" Message={confirmMsg}
            onYesClick={() => { setIsBusy(false); clearDialog() }} />
        );
      }
      else if (isTxtInfiniteProcess()) {
        confirmMsg = (
          <>
            <p className='break-all'>
              {"作業完了できない文字列比較設定になっている為、発行できません。"}
              <br />
              {"文字列比較の設定を確認してください。"}
            </p>
          </>
        );
        setDialog(
          <ErrorBox
            className="absolute right-[162px] top-[100px] w-[650px]"
            Title="発行" Message={confirmMsg}
            onYesClick={() => { setIsBusy(false); clearDialog() }} />
        );
      }
      else if (isCheckHindoSettingForAllWork()) {
        setDialog(
          <ErrorBox
            className="absolute right-[162px] top-[100px] w-[448px]"
            Title="発行" Message={<>すべての作業に管理項目の「頻度設定」を<br></br>設定している工程がある為、発行できません。<br></br>工程設計を確認してください。</>}
            onYesClick={() => { setIsBusy(false); clearDialog() }} />
        );
      }
      else {
        confirmMsg = (
          <>
            <p className='break-all'>
              {`「${processChart.itemName}」を発行します。`}
              <br />
              {"よろしいですか？"}
            </p>
          </>
        );
        setDialog(
          <ConfirmBox
            className="absolute right-[162px] top-[100px] w-[471px]"
            title="発行" message={confirmMsg}
            onYesClick={onHakkouConfirmYes} onNoClick={() => { setIsBusy(false); clearDialog(); }} />
        );
      }
    }
    else {
      setDialog(
        <ErrorBox
          className="absolute right-[162px] top-[100px] w-[528px]"
          Title="発行" Message={"工程設計の変更点が無い為、発行できません。"}
          onYesClick={() => { setIsBusy(false); clearDialog() }} />
      );
    }
  }

  function onHakkouConfirmYes() {
    prepareAndSendData(true);
  }

  function onHakkouCompleteBoxYes() {
    redirectToChart();
  }

  function clearDialog() {
    setDialog(null);
  }

  function onHomeConfirmYes() {
    redirectToChart();
  }

  function onAppNameClick() {
    // 発行処理中、もしくは一時保存処理中はホームへ移動しません。
    if (isBusy) {
      return;
    }

    // ユーザー権限を確認する。
    axios({
      method: 'GET',
      url: variables.LOGIN_URL + "/validate",
      headers: { 'company': urlCompanyName },
      withCredentials: true
    }).then(function (response) {
      if (response.data.statusCode !== StatusCode.AuthorizedWithAdministrator) {
        // ユーザー権限確認がNGの場合、ログイン画面へ移動する。
        NavigateToCompanyLogin(navigate);
      } else {
        // ユーザー権限確認がOKの場合、以下の処理を行う。
        // 変更データが有るか確認する
        if (isDataChanged()) {
          // 確認メッセージを表示
          setDialog(
            <ConfirmBox
              className="absolute left-[60px] top-[34px] w-[471px]"
              title="変更の破棄" message={"作業が破棄されます。よろしいですか？"}
              onYesClick={onHomeConfirmYes} onNoClick={() => { clearDialog(); }} />
          );
        } else {
          redirectToChart();
        }
      }
    }).catch(function (error) {
      appInsights.trackException({ ...error, errorFunction: "EditorTitleBar.onAppNameClick()" });
    });
  }

  /**
   * データが変更されたかを確認する。
   */
  function isDataChanged() {
    const backupDataObj = {
      procList: backupProcList,
      wrkList: backupWorkList,
      docList: backupAllDtlDocs,
      toolList: backupAllToolList,
      mngItmList: backupAllMngItm
    };

    const currentDataObj = {
      procList: currentProcList,
      wrkList: currentWorkList,
      docList: allDtlDocs,
      toolList: allToolList,
      mngItmList: allMngItm
    };
    const hashOfBackupData = hash(backupDataObj, {
      excludeKeys: (key) => {
        if (key === 'objUrl') {
          return true;
        }
        return false;
      }
    });
    const hashOfCurrentData = hash(currentDataObj, {
      excludeKeys: (key) => {
        if (key === 'objUrl') {
          return true;
        }
        return false;
      }
    });
    if (hashOfBackupData !== hashOfCurrentData) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 作業が無しの工程が有るかまたは工程名が入力済みかを確認する。
   */
  function hasEmptyProcess() {
    if (allWorkList && allWorkList.length > 0) {
      const procIDListOfWork = allWorkList.map(w => w.processID);
      for (let idx = 0; idx < procList.length; idx++) {
        if (procList[idx].processName === "" || procIDListOfWork.includes(procList[idx].processID) === false) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  }

  function onClickVersionBtn() {
    if (isDataChanged()) {
      setDialog(
        <ConfirmBox
          className="absolute left-[235px] top-[34px] w-[471px]"
          title="変更の破棄"
          message={"作業が破棄されます。よろしいですか？"}
          onYesClick={handleVersionConfirmYes} onNoClick={clearDialog} />
      );
    }
    else {
      NavigateToVersion(navigate);
    }
  }

  function handleVersionConfirmYes() {
    setDialog(null);
    NavigateToVersion(navigate);
  }

  function redirectToChart() {
    navigate(generatePath("/:company", {
      company: urlCompanyName
    }), { replace: true, state: { id: MasterPage.PROCESS_CHART } });
  }

  function onUserNameClick() {
    if (isBusy) {
      return;
    }
    setShowLogout(true);
  }

  function onLogoutContainDivClick() {
    // サインアウトコンポネントを非表示する。
    setShowLogout(false);
  }

  function onLogoutClick() {
    setShowLogout(false);
    if (isDataChanged()) {
      // 確認メッセージを表示
      setDialog(
        <ConfirmBox
          className="absolute top-[80px] right-[10px] w-[471px]"
          title="変更の破棄" message={"作業が破棄されます。よろしいですか？"}
          onYesClick={() => { logoutUser(); }} onNoClick={() => { clearDialog(); }} />
      );
    } else {
      logoutUser();
    }
  }

  /**
   * エディターのデータをクリアして、
   *　ユーザーログイン画面へ移動する。
   */
  function logoutUser() {
    axios({
      method: 'GET',
      url: variables.LOGIN_URL + "/logout",
      withCredentials: true,
      headers: { 'company': urlCompanyName },
    }).then(function (response) {
      NavigateToUserLogin(navigate);
    }).catch(function (error) {
      if (error.response.status === 401) {
        NavigateToCompanyLogin(navigate);
      }
      else {
        appInsights.trackException({ ...error, errorFunction: "EditorTitleBar.logoutUser()" });
      }
    });
  }

  return (
    <>
      <div className='grid grid-cols-3 items-center bg-[#0073CD] opacity-90 h-[47px]'>
        <div className='flex text-[#FFFFFF] h-full mx-[15px] font-bold'>
          <div onClick={onAppNameClick} className='flex items-center w-fit h-full px-5 hover:cursor-pointer hover:bg-[#1980D1]'>
            Roland DG Assemble
          </div>
          <div className="flex items-center w-fit h-full">
            <button
              type="button"
              className={"w-[70px] h-[28px] font-bold text-[#0073CD] text-center ml-[10px] bg-white rounded-full " + variables.HOVER_CSS}
              onClick={onClickVersionBtn}
            >{variables.VERSION}
            </button>
          </div>
        </div>
        <div className='place-self-center text-[#FFFFFF] font-bold whitespace-nowrap'>
          {"編集：" + processChart.itemName}
        </div>
        <div className='grow flex flex-row-reverse'>
          <LoginUserDisplay onClick={onUserNameClick} />
          <HakkouButtonSm
            onClick={onHakkou}
            isEnable={isAllLocked && hasEmptyProcess() === false && !isBusy}
            text='発行' />
          <SaveButtonSm
            onClick={onTemporarySave}
            isEnable={processCount > 0 && !isBusy}
            text='一時保存' />
        </div>
      </div>
      {
        dialog && dialog
      }
      {
        showLogout &&
        <Logout onContainerDivClick={onLogoutContainDivClick}
          onLogout={onLogoutClick}
          className="absolute top-[47px] right-[10px]"
        />
      }
    </>
  );
}

export default EditorTitleBar;
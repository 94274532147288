import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { variables } from "../../../Variables";
import { urlCompanyName } from "../../util/commonFun";
import { appInsights } from "../../util/ApplicationInsight";

const axios = require('axios');

export const emptyProcessObj = {
  processChartID: "",
  dispProcessID: "",
  processID: "",
  processName: "",
  dispOrder: -1,
};
const initState = {
  selectedProcess: { ...emptyProcessObj },
  dataList: [],
  backupDataList: [],
};

export const fetchProcessByChartID = createAsyncThunk("processList/fetchProcessByChartID",
  async chartID => {
    // workIDs = ["workid1", "workid2"]
    const response = await axios.get(variables.PROCESS_URL + "/getbychartid", {
      params: {
        chartID: chartID
      },
      headers: { 'company': urlCompanyName },
      withCredentials: true
    });
    return response.data;
  });

export const processListSlice = createSlice({
  name: "processList",
  initialState: initState,
  reducers: {
    addProcess: (processList, action) => {
      processList.dataList.push(action.payload);
      for (let i = 0; i < processList.dataList.length; i++) {
        processList.dataList[i].dispOrder = i + 1;
      }
      // set selectedProcess
      processList.selectedProcess = processList.dataList.at(-1);
    },

    addBetweenProcess: (processList, action) => {
      const idx = processList.dataList.findIndex(
        (w) => w.processID === action.payload.processID
      );
      if (idx >= 0) {
        processList.dataList.splice(idx + 1, 0, action.payload.newRow);
        processList.selectedProcess = action.payload.newRow;
      }
      for (let i = idx; i < processList.dataList.length; i++) {
        processList.dataList[i].dispOrder = i + 1;
      }
    },

    setProcessName: (processList, action) => {
      const process = processList.dataList.find(
        (w) => w.processID === action.payload.processID
      );
      if (process) {
        process.processName = action.payload.processName;
        // set selectedProcess
        processList.selectedProcess = process;
      }
    },
    deleteProcess: (processList, action) => {
      let sortedList = processList.dataList.slice();
      sortedList.sort((a, b) => a.dispOrder - b.dispOrder);
      const idxToDelete = sortedList.findIndex(
        (w) => w.processID === action.payload.processID
      );
      if (idxToDelete >= 0) {
        // delete process
        sortedList.splice(idxToDelete, 1);
      }

      if (sortedList.length > 0) {
        // dispOrder を１から採番する。
        for (let i = idxToDelete; i < sortedList.length; i++) {
          sortedList[i].dispOrder = i + 1;
        }
        // set selectedProcess
        if (idxToDelete <= sortedList.length - 1) {
          processList.selectedProcess = sortedList[idxToDelete];
        } else {
          processList.selectedProcess = sortedList[sortedList.length - 1];
        }
      } else {
        processList.selectedProcess = { ...emptyProcessObj };
      }
      processList.dataList = sortedList;
    },

    moveProcessUpward: (processList, action) => {
      const idx = processList.dataList.findIndex(
        (w) => w.processID === action.payload.processID
      );
      if (idx >= 0) {
        const tempProcess = processList.dataList[idx - 1];
        const tempDispOrder = tempProcess.dispOrder;
        const selectedProcess = processList.dataList[idx];
        tempProcess.dispOrder = selectedProcess.dispOrder;
        selectedProcess.dispOrder = tempDispOrder;
        processList.selectedProcess.dispOrder = tempDispOrder;
        processList.dataList.splice(idx - 1, 1, selectedProcess);
        processList.dataList.splice(idx, 1, tempProcess);
      }
    },

    moveProcessDownward: (processList, action) => {
      const idx = processList.dataList.findIndex(
        (w) => w.processID === action.payload.processID
      );
      if (idx >= 0) {
        const tempProcess = processList.dataList[idx + 1];
        const tempDispOrder = tempProcess.dispOrder;
        const selectedProcess = processList.dataList[idx];
        tempProcess.dispOrder = selectedProcess.dispOrder;
        selectedProcess.dispOrder = tempDispOrder;
        processList.selectedProcess.dispOrder = tempDispOrder;
        processList.dataList.splice(idx + 1, 1, selectedProcess);
        processList.dataList.splice(idx, 1, tempProcess);
      }
    },

    setSelectedProcess: (processList, action) => {
      const proc = processList.dataList.find(
        (p) => p.processID === action.payload.processID
      );
      if (proc) {
        processList.selectedProcess = proc;
      } else {
        processList.selectedProcess = { ...emptyProcessObj };
      }
    },
    /**
     * 程完了通知フラグを設定する。
     * action.payload: {processID, notifyOfEnd}
     * @param {*} processList 
     * @param {*} action 
     */
        setEndofNotify: (processList, action) => {
          const proc = processList.dataList.find(
            (p) => p.processID === action.payload.processID
          );
          if (proc) {
            proc.notifyOfEnd = action.payload.notifyOfEnd;
            processList.selectedProcess = proc;
          }
        },
    /**
     * stateを初期かする。
     * @param {*} processList 
     * @param {*} action 
     * @returns 
     */
    resetProcessList: (processList, action) => {
      return { ...initState }
    }
  },
  extraReducers: (builder) => {
    // fulfilled(完了)/pending(処理中)/rejected(エラー)時の処理を書く
    // 完了
    builder.addCase(fetchProcessByChartID.fulfilled, (processList, action) => {
      const responseProcList = action.payload;
      if (responseProcList && responseProcList.length > 0) {
        let sortedProcList = responseProcList.slice();
        sortedProcList.sort((a, b) => a.dispOrder - b.dispOrder);
        processList.dataList = sortedProcList;

        // Update selectedProcess data
        if (processList.selectedProcess.dispOrder > 0) {
          const procToSelect = sortedProcList.find(p => p.dispOrder === processList.selectedProcess.dispOrder);
          if (procToSelect) {
            processList.selectedProcess = { ...procToSelect }
          } else {
            processList.selectedProcess = { ...sortedProcList[0] }
          }
        } else {
          processList.selectedProcess = { ...sortedProcList[0] }
        }
      } else {
        // データをクリアする
        processList.selectedProcess = { ...emptyProcessObj };
        processList.dataList = [];
      }
      processList.backupDataList = processList.dataList.slice();
    });
    // エラー
    // エラー時はエラー内容が返されることを期待している
    builder.addCase(fetchProcessByChartID.rejected, (state, action) => {
      appInsights.trackException(action.error);
    });
  },
});

/**
 * 工程数を取得する。
 * @param {*} state 
 * @returns 
 */
export const getCount = (state) => {
  return state.processList.dataList.length;
}

export const selectProcessList = (state) => {
  let dataList = state.processList.dataList.slice();
  return dataList.sort((a, b) => a.dispOrder - b.dispOrder);
};

export const selectBackupProcessList = (state) => {
  return state.processList.backupDataList;
};

export const selectCurrentProcessList = (state) => {
  return state.processList.dataList;
};

export const selectedProcID = (state) => {
  return state.processList.selectedProcess.processID;
};

export const getSelectedProcessInfo = (state) => {
  return state.processList.selectedProcess;
};

export const {
  addProcess,
  setProcessName,
  // changeProcessName,
  deleteProcess,
  addBetweenProcess,
  moveProcessUpward,
  moveProcessDownward,
  setSelectedProcess,
  setEndofNotify,
  resetProcessList,
} = processListSlice.actions;
export default processListSlice.reducer;

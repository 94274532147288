import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ConfirmBox from "../../../components/ConfirmBox";
import UpwardButton from "../../../components/UpwardButton";
import DownwardButton from "../../../components/DownwardButton";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import { IconContext } from "react-icons";
import { ImImages } from "react-icons/im";
import ImageInputBox from "./ImageInputBox";
import { v4 as uuidv4 } from "uuid";
import { TextType, ToolType } from "../../../Variables";
import ErrorBox from "../../../components/ErrorBox";
import { getSelectedWorkInfo } from "./workListSlice";
import { FileUpload, NavigateToCompanyLogin } from "../../util/commonFun";
import SpinnerLoading from "../../../components/SpinnerLoading";
import { useNavigate } from 'react-router-dom';
import { appInsights } from "../../util/ApplicationInsight";

import {
  addTool,
  deleteTool,
  setSelectedToolId,
  codeTextChange,
  nameTextChange,
  countTextChange,
  toolTypeChange,
  toolUpDownChange,
  setToolImgInfo,
} from "./toolListSlice";
import { LockAttributes } from "../../../Variables";
import InputBox from "../../../components/InputBox";

const emptyToolObj = {
  workId: -1,
  toolId: -1,
  identifyCode: "",
  toolName: "",
  memberCnt: -1,
  toolType: ToolType.Buhin,
  fileName: "",
  objUrl: null,
  dispOrder: -1,
};

let errMsg = "";
const regex = /^[\d]{1}\d*\.?\d*$/;
const fullWidthPattern = /[\uFF00-\uFFEF\u4E00-\u9FFF\u3000-\u303F]/g;

function ToolList(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
  const workId = selectedWork.workID;
  const items = useSelector((state) => state.toolList);
  const toolList = [...items.dataList.filter((tool) => tool.workId === workId)];
  toolList.sort((a, b) => a.dispOrder - b.dispOrder);
  const isWorkIdNoChange = toolList.some(
    (item) => item.toolId === items.selectedToolId
  );
  const selectedIdx =
    toolList.length === 0
      ? -1
      : isWorkIdNoChange
        ? toolList.findIndex((item) => item.toolId === items.selectedToolId)
        : 0;
  const currChangedToolId =
    selectedIdx === -1 ? -1 : toolList[selectedIdx].toolId;

  useEffect(() => {
    if (workId !== "" && isWorkIdNoChange === false) {
      dispatch(setSelectedToolId(currChangedToolId));
    }
  }, [dispatch, isWorkIdNoChange, currChangedToolId, workId]);

  const [dialogBox, setDialogBox] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const [showError, setShowError] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(null);

  const handleAddClick = () => {
    if (toolList.length < 10) {
      const toolObj = {
        ...emptyToolObj,
        workId: workId,
        toolId: uuidv4(),
        dispOrder: 1
      };
      dispatch(addTool({ currToolId: items.selectedToolId, toolObj: toolObj }));
    }
  };

  const handleDeleteClick = () => {
    const currTool = toolList[selectedIdx];
    let msg = (
      <>
        <p className="break-all">
          {"「識別コード：" + currTool.identifyCode + "」"} <br />
          {"「名称：" + currTool.toolName + "」"} <br />
          {"を削除しますか？"}
        </p>
      </>
    );
    setDialogBox(
      <ConfirmBox
        className="absolute left-[1200px] top-[500px] w-[471px]"
        title="部品/工具削除"
        message={msg}
        onYesClick={() => handleDelConfirmYes(currTool.toolId)}
        onNoClick={handleDelConfirmNo}
      />
    );
  };

  function handleDelConfirmYes(toolId) {
    if (toolId < 0) {
      return;
    }
    setDialogBox(null);
    dispatch(deleteTool({ toolId: toolId, workId: workId }));
  }

  function handleDelConfirmNo() {
    setDialogBox(null);
  }

  const handleUpDownClick = (type) => {
    dispatch(toolUpDownChange({ upDownType: type, workId: workId }));
  };

  const onSelectChange = (index) => {
    const currTool = toolList[index];
    dispatch(setSelectedToolId(currTool.toolId));
  };

  const onRadioBtnCheckChange = (toolId, val) => {
    dispatch(
      toolTypeChange({
        toolId: toolId,
        workId: workId,
        newValue: val,
      })
    );
  };

  const onCodeTextChange = (e, toolId) => {
    let inputText = e.target.value.replace(/[\r\n]/gm, '');
    dispatch(
      codeTextChange({
        toolId: toolId,
        workId: workId,
        newValue: inputText,
      })
    );
  };

  const onNameTextChange = (e, toolId) => {
    let inputText = e.target.value.replace(/[\r\n]/gm, '');
    dispatch(
      nameTextChange({
        toolId: toolId,
        workId: workId,
        newValue: inputText,
      })
    );
  };

  const onCountTextChange = (e, toolId) => {
    const number = e.target.value.trim();
    if (isExistFullWidthCharacter(number) || number.length === 0 || regex.test(number)) {
      dispatch(
        countTextChange({
          toolId: toolId,
          workId: workId,
          newValue: number,
        })
      );
    }
  };

  function handleMemberCntKeyUp(e, str, toolId) {
    if (e.key === 'Enter') {
      let memberCount = inputTextFilter(str);
      dispatch(
        countTextChange({
          toolId: toolId,
          workId: workId,
          newValue: memberCount,
        })
      );
    }
  }

  function handleMemberCntFocusOut(txt, toolId) {
    let inputMemberCnt = txt;
    let memberCount = inputMemberCnt.endsWith('.') ? inputMemberCnt.replace('.', '') : inputMemberCnt;
    memberCount = inputTextFilter(memberCount);
    dispatch(
      countTextChange({
        toolId: toolId,
        workId: workId,
        newValue: memberCount,
      })
    );
  }

  function inputTextFilter(str) {
    let value = "";
    for (let i = 0; i < str.length; i++) {
      if (str[i].match(regex) || str[i] === ".") {
        value += str[i];
      }
    }
    return value;
  }

  /**
     * 記入するテキストが全角かをチェックする
     * @param {*} str 
     * @returns 
     */
  function isExistFullWidthCharacter(str) {
    for (let i = 0; i < str.length; i++) {
      if (fullWidthPattern.test(str[i])) {
        return true;
      }
    }
    return false;
  }

  function handleFileAdd() {
    setShowFileInput(true);
  }

  function onImageInputBoxClose() {
    // ダイアログを閉じる
    setShowFileInput(false);
  }

  /**
   * 画像登録ボックスの登録ボタンイベントハンドラ。
   * @param {*} objUrl 
   * @param {*} hashCode 
   * @param {*} fileName 
   */
  function onImageInputBoxResister(objUrl, hashCode, fileName) {
    if (objUrl) {
      dispatch(
        setToolImgInfo({
          fileName: fileName,
          objUrl: objUrl,
        })
      );
    }
    // ダイアログを閉じる
    setShowFileInput(false);
  }

  async function handleDrop(e, tool) {
    e.preventDefault();
    if (selectedWork.isLock) {
      return;
    }
    dispatch(setSelectedToolId(tool.toolId));
    const acceptExtList = [".jpg", ".jpeg", ".jfif", ".png"];
    let fileData = e.dataTransfer.files[0];
    if (fileData) {
      const fileExtension = "." + fileData.name.split(".").pop();
      if (acceptExtList.some((ext) => ext === fileExtension.toLowerCase())) {
        if (fileData.size > 0) {
          const sizeInMb = fileData.size / (1024 * 1024);
          if (sizeInMb > 10) {
            // ファイルサイズ　10 MB を超える場合エラーメッセージを出す。
            errMsg = "ファイル容量制限 10MB を超えています。";
            setShowError(true);
          } else {
            let isSuccessFileUpload = true;
            setSpinnerLoading(<SpinnerLoading />);
            let fileName = uuidv4() + fileExtension;
            let objUrl = null;
            try {
              // ファイルのアップロード
              objUrl = await FileUpload(fileData, fileName);
            } catch (error) {
              appInsights.trackException({ ...error, errorFunction: "ToolList.handleDrop()" });
              isSuccessFileUpload = false;
              if (error === 401) {
                NavigateToCompanyLogin(navigate);
              } else {
                errMsg = "登録に失敗しました。";
                setShowError(true);
              }
            } finally {
              setSpinnerLoading(null);
            }
            if (isSuccessFileUpload) {
              dispatch(
                setToolImgInfo({
                  fileName: fileName,
                  objUrl: objUrl,
                })
              );
            }
          }
        }
      } else {
        errMsg = (
          <>
            選択したファイルは登録できません。
            <br />
            登録できるファイルは「jpg」、「jpeg」、「jfif」、「png」です。
          </>
        );
        setShowError(true);
      }
    }
  }

  return (
    <div>
      <div className="-mt-1 ml-[67px]">
        {/* 左側のメニュー */}
        <div className="w-fit h-[85px]">
          <div className="font-bold text-[24px] text-[#000000] px-[33px] mt-[11px] mb-[5px]">
            部品/工具リスト
          </div>
          <div className="inline-flex h-[48px] w-fit px-[35px] hover:cursor-pointer">
            <AddButton
              className="w-[80px] h-[48px]"
              onClick={handleAddClick}
              isEnable={
                workId !== "" && toolList.length < 10 && !selectedWork.isLock
              }
              Text="追加"
            />
            <DeleteButton
              className="w-[80px] h-[48px]"
              onClick={() => handleDeleteClick()}
              isEnable={toolList.length > 0 && !selectedWork.isLock}
              Text="削除"
            />
            <UpwardButton
              onClick={() => handleUpDownClick(-1)}
              isEnable={
                toolList.length > 1 &&
                selectedIdx > 0 &&
                selectedIdx <= toolList.length - 1 &&
                !selectedWork.isLock
              }
              Text="上に移動"
            />
            <DownwardButton
              onClick={() => handleUpDownClick(1)}
              isEnable={
                (toolList.length === 0 ||
                  (toolList.length > 0 && selectedIdx === toolList.length - 1)
                  ? false
                  : true) && !selectedWork.isLock
              }
              Text="下に移動"
            />
          </div>
          {/* 部品/工具リスト表示スコープ */}
          <div className="w-[471px] h-[599px] ml-[16px] p-[4px] border-2 border-[#C8C6C4] bg-white border-b overflow-y-scroll">
            {toolList.map((singleTool, Index) => (
              <div
                className={"w-[436px] h-[199px] mb-1 border-2 " + (selectedIdx === Index && !selectedWork.isLock ? "border-[#0073CD] bg-[#D8D7D5]" : "border-[#C8C6C4] border-b")}
                key={singleTool.toolId}
                onClick={() => onSelectChange(Index)}>
                <div className="flex flex-row">
                  <div className="ml-[3px] w-[180px]">
                    <div className="flex items-center ml-[15px]">
                      <AddButton
                        onClick={handleFileAdd}
                        isEnable={Index < 10 && !selectedWork.isLock}
                        Text="ファイル追加"
                      />
                      <span className="text-2xl font-bold text-red-500 ml-[3px]">
                        *
                      </span>
                    </div>
                    <div
                      className="ml-[2px]"
                      onDrop={(e) => handleDrop(e, singleTool)}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      {singleTool.objUrl !== null ? (
                        <img
                          src={singleTool.objUrl}
                          alt="..."
                          className="w-[159px] h-[139px] object-contain border-2 border-[#C8C6C4]"
                        />
                      ) : (
                        <div className="w-[159px] h-[139px] border-2 border-[#C8C6C4] bg-neutral-50">
                          <div className="flex flex-col items-center mt-[30px] opacity-50">
                            <IconContext.Provider
                              value={{ size: "50px", color: "#0073CD" }}
                            >
                              <ImImages />
                            </IconContext.Provider>
                            <span className="font-bold text-[11px] text-[#0073CD]">
                              ドラッグ＆ドロップで画像を追加
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-[268px]">
                    <label htmlFor="code">識別コード</label>
                    <textarea
                      className={
                        "w-[255px] h-[46px] pl-[5px] leading-tight overflow-hidden resize-none border-2 border-[#C8C6C4] outline-none outline-offset-0 " +
                        (selectedWork.isLock ? LockAttributes.ATTRIBUTES : "focus:border-[#0073CD]")
                      }
                      maxLength={50}
                      value={singleTool.identifyCode}
                      onChange={(e) => onCodeTextChange(e, singleTool.toolId)}
                      readOnly={selectedWork.isLock}
                    />
                    <label htmlFor="name" className="ml-[0px]">
                      名称
                    </label>
                    <span className="text-2xl font-bold text-red-500">*</span>
                    <textarea
                      className={
                        "w-[255px] h-[46px] pl-[5px] leading-tight overflow-hidden resize-none border-2 border-[#C8C6C4] outline-none outline-offset-0 " +
                        (selectedWork.isLock ? LockAttributes.ATTRIBUTES : "focus:border-[#0073CD]")
                      }
                      required={true}
                      maxLength={50}
                      value={singleTool.toolName}
                      onChange={(e) => onNameTextChange(e, singleTool.toolId)}
                      readOnly={selectedWork.isLock}
                    />
                    <div className="flex items-center">
                      <label htmlFor="count" className="ml-[5px]">
                        員数
                      </label>
                      <span className="text-2xl font-bold text-red-500">*</span>
                      <InputBox
                        className={"w-[52px] h-[26px] ml-[5px] border-[2px] focus:placeholder-transparent outline-none outline-offset-0 "}
                        textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                        name="count"
                        maxLength={5}
                        value={singleTool.memberCnt === -1 ? "" : singleTool.memberCnt}
                        onChange={(e) => onCountTextChange(e, singleTool.toolId)}
                        onKeyUp={(e, str) => handleMemberCntKeyUp(e, str, singleTool.toolId)}
                        onFocusOut={(txt) => handleMemberCntFocusOut(txt, singleTool.toolId)}
                        disabled={selectedWork.isLock}
                      />

                      <input
                        key={uuidv4()}
                        className="w-[15px] h-[15px] ml-[24px]"
                        type="radio"
                        id={"part" + Index}
                        name={Index}
                        value={singleTool.toolType}
                        onChange={() =>
                          onRadioBtnCheckChange(
                            singleTool.toolId,
                            ToolType.Buhin
                          )
                        }
                        checked={singleTool.toolType === ToolType.Buhin}
                        disabled={selectedWork.isLock}
                      />
                      <label htmlFor={"part" + Index} className="ml-[5px]">
                        部品
                      </label>

                      <input
                        key={uuidv4()}
                        className="w-[15px] h-[15px] ml-[10px]"
                        type="radio"
                        id={"tool" + Index}
                        name={Index}
                        value={singleTool.toolType}
                        onChange={() =>
                          onRadioBtnCheckChange(
                            singleTool.toolId,
                            ToolType.Kougu
                          )
                        }
                        checked={singleTool.toolType === ToolType.Kougu}
                        disabled={selectedWork.isLock}
                      />
                      <label htmlFor={"tool" + Index} className="ml-[5px]">
                        工具
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showFileInput && (
        <ImageInputBox
          onClose={onImageInputBoxClose}
          onRegister={onImageInputBoxResister}
          className="absolute right-0 top-6"
        />
      )}
      {/* {ConfirmDialogBox} */}
      {dialogBox}
      {/* サイズ制限とファイルタイプエラーボックス */}
      {showError && (
        <ErrorBox
          className="absolute right-[162px] top-[265px] w-[550px]"
          Title="ファイル登録"
          Message={errMsg}
          onYesClick={() => setShowError(false)}
        />
      )}
      {/* Loading... */}
      {spinnerLoading}
    </div>
  );
}

export default ToolList;

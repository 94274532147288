import React from "react";
import { variables, Sort } from "../Variables";
import { IconContext } from "@react-icons/all-files"
import { CgArrowUp } from "@react-icons/all-files/cg/CgArrowUp";
import { CgArrowDown } from "@react-icons/all-files/cg/CgArrowDown";

function SortIcon(props) {
    let upColor = null;
    let downColor = null;
    switch (props.Sort) {
      case Sort.ASC:
        downColor = variables.COLOR_DISABLED;
        break;
      case Sort.DESC:
        upColor = variables.COLOR_DISABLED;
        break;
      default:
        upColor = "#000000";
        downColor = "#000000";
    }
  
    return (
      <div className="flex">
        <IconContext.Provider
          value={{ color: upColor, size: "16px", className: "self-center" }}>
          <CgArrowUp />
        </IconContext.Provider>
        <IconContext.Provider
          value={{ color: downColor, size: "16px", className: "self-center" }}>
          <CgArrowDown className="ml-[-8px] mt-[5px]" />
        </IconContext.Provider>
      </div>
    );
  
  }
  SortIcon.defaultProps = {
    Sort: Sort.NONE
  };

  export default SortIcon;
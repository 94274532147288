import { React, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SectionTitle from "../../components/SectionTitle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import ReadOnlyTextBox from "../../components/ReadOnlyTextBox";
import { popupcss, variables } from "../../Variables";
import { NavigateToCompanyLogin, urlCompanyName } from "../util/commonFun";
import { errorCode } from "../../ErrorCode";
import ErrorBox from "../../components/ErrorBox";
import ConfirmBox from "../../components/ConfirmBox";
import { setIsLoading as setPageLoding } from "../Loading/pageLoadingSlice";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');
// 最大ファイルサイズ： 0.5 MB
const maxFileSize = 0.5;
let file = null;
let errMsg = "";
/**
 * 製品マスタのインポート画面
 * @param {*} props 
 */
function ItemImport(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const inputFile = useRef(null);
    const [fileName, setFileName] = useState("");
    const [showError, setShowError] = useState(false);
    const [showConfirmBox, setShowConfirmBox] = useState(false);

    function handleTextBoxClick() {
        // <input type="file" /> のクリックイベントを発生させる。
        inputFile.current.click();
    }

    function handleInputFileChange(e) {
        if (e.target.files.length > 0) {
            const fileSize = e.target.files[0].size / 1024 / 1024;
            //ファイルの拡張子を取る
            const fileExtension = "." + e.target.files[0].name.split(".").pop();
            let allowedFileExtensions = [".xlsx"];
            if (allowedFileExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
                // ファイル形式がxlsx以外の場合エラーメッセージを出す
                let msg = "";
                msg = (
                    <>
                        インポートファイルが正しい形式ではありません。
                        <br />
                        ファイルの選択をやり直してください。
                    </>
                );
                showErrorBox(msg);
            } else if (fileSize > maxFileSize) {
                // ファイルサイズ　500KB を超える場合エラーメッセージを出す。
                showErrorBox("ファイル容量制限 500KB を超えています。");
            }
            else {
                file = e.target.files[0];
                setFileName(file.name);
            }
        }
    }

    async function handleRegister() {
        setShowConfirmBox(true);
    }

    function handleImportConfirmYes() {
        setShowConfirmBox(false);
        importItem();
    }

    function handleImportConfirmNo() {
        setShowConfirmBox(false);
    }

    function showErrorBox(msg) {
        errMsg = msg;
        setShowError(true);
        // エラーの場合、fileをクリアする。
        clearFile();
    }

    /**
     * 選択されたファイルをクリアする。
     */
    function clearFile() {
        if (inputFile.current) {
            inputFile.current.value = "";
        }
        file = null;
        setFileName("");
    }

    function importItem(){
        dispatch(setPageLoding(true));
        if (file) {
            const formData = new FormData();
            formData.append("file", file, fileName);
            axios({
                method: 'POST',
                url: variables.ITEM_URL + "/import-item",
                withCredentials: true,
                headers: { 'company': urlCompanyName },
                data: formData
            }).then(function (response) {
                if (response.data.isSuccess) {
                    props.onSuccess();
                } else {
                     let msg = "";
                    // エラーメッセージを表示する。
                    if (errorCode.hasOwnProperty(response.data.lstErrorCode[0])) {
                        msg = errorCode[response.data.lstErrorCode[0]];
                    } else {
                        msg = response.data.lstErrorCode[0];
                    }
                    showErrorBox(msg);
                }
            }).catch(function (error) {
                console.error(error);
                let status = error?.response?.status;
                if (status === 401) {
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    // applicationInsight
                    appInsights.trackException({...error, errorFunction: "ItemImport.importItem()"});
                }
            }).finally(() => {
                dispatch(setPageLoding(false));
            });
        }
    }

    return (
        <div className={popupcss}>
            <div className="w-0 h-0 opacity-0">
                <input
                    className="w-0 h-0 opacity-0"
                    type="file"
                    ref={inputFile}
                    onChange={handleInputFileChange}
                    accept=".xlsx"
                />
            </div>
            <div className={props.className}>
                <div className="w-[512px] h-[1033px] p-[15px] mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white sm-rounded">
                    <div className="grid grid-cols-2">
                        <SectionTitle Text="製品マスタ インポート" className="text-[18px]" />
                        <div className="justify-self-end">
                            <button onClick={props.onClose}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div>
                    <div className="my-[15px]">ファイル選択</div>
                    <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />
                    <div className="my-[15px]">
                        <ReadOnlyTextBox text={fileName} onClick={handleTextBoxClick} />
                    </div>
                    <div className="mb-[15px]">
                        登録済みの製品マスタとインポートするExcelの差分が追加/変更されます。
                        <br />
                        ・新規製品の行が追加されている場合、製品マスタに追加されます
                        <br />
                        ・内容の変更をしている行は、製品マスタが変更されます
                        <br />
                        ・内容の変更をしていない行は、製品マスタは変更されません
                    </div>
                    <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />
                    <div className="my-[15px]">
                        {fileName && fileName.length > 0 ? (
                            <button
                                type="button"
                                onClick={handleRegister}
                                className="w-[73px] h-[25px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] sm-rounded"
                            >
                                インポート
                            </button>
                        ) : (
                            <button
                                type="button"
                                disabled="disabled"
                                className={
                                    "w-[73px] h-[25px] mr-[7px] text-white border-[1px] border-[#A19F9D] border-solid sm-rounded bg-[#A19F9D]"
                                }
                            >
                                インポート
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {showError && (
                <ErrorBox
                    className="absolute right-[162px] top-[265px] w-[538px]"
                    Title="製品マスタのインポート"
                    Message={errMsg}
                    onYesClick={() => setShowError(false)}
                />
            )}
            {
                showConfirmBox && (
                    <ConfirmBox
                        className="absolute right-[162px] top-[265px] w-[471px]"
                        title="製品マスタのインポート"
                        message={<>選択されたExcelファイルをインポートします。<br />よろしいですか？</>}
                        onYesClick={handleImportConfirmYes}
                        onNoClick={handleImportConfirmNo}
                    />
                )
            }
        </div>
    );
}
// Specifies the default values for props:
ItemImport.defaultProps = {
    onSuccess: void (0),
};
export default ItemImport
import { React } from "react";

function InValidURL(props) {
  return (
    <div className=" flex flex-col w-[1920px] h-[1080px] items-center justify-center bg-white">
      <span className="text-[28px]">このサイトにアクセスできません</span>
    </div>
  );
}
export default InValidURL;

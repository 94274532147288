import { React, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import MasterLayout from './layout/MasterLayout';
import Editor from './features/editor/Editor';
import Operation from './features/operation/Operation';
import EditorCompanyLogin from './features/login/editor/EditorCompanyLogin';
import EditorUserLogin from './features/login/editor/EditorUserLogin';
import OperationLogin from './features/login/operation/OperationLogin';
import OperationStartWork from './features/operation/OperationStartWork';
import InValidURL from './features/login/editor/InValidURL';
import "./App.css";
import Version from './features/version';
import { CompanyLoginPath, UserLoginPath, OperationPath, OperationLoginPath, OperationStartWorkPath } from './Variables';
import withAITracking from "./features/util/ApplicationInsight";

function App() {
  useEffect(() => {
    const referrer = document.referrer;
    if (!referrer || referrer.length === 0) {
      document.location.reload();
    }
  }, []);

  return (
    <div className="relative w-[1920px] h-[1080px] font-['Yu Gothic UI'] text-base">
      <Routes>
        <Route path='/' element={<InValidURL />} />
        <Route path='/:company' element={<MasterLayout />} />
        <Route path='/:company/version' element={<Version />} />
        <Route path='/:company/editor' element={<Editor />} />
        <Route path={CompanyLoginPath} element={<EditorCompanyLogin />} />
        <Route path={UserLoginPath} element={<EditorUserLogin />} />
        <Route path={OperationPath} element={<Operation />} />
        <Route path={OperationLoginPath} element={<OperationLogin />} />
        <Route path={OperationStartWorkPath} element={<OperationStartWork />} />
      </Routes>
    </div>
  );
}
export default withAITracking(App);

import React from "react";
import { useSelector } from "react-redux";
import DetailDocMovie from "./DetailDocMovie";
import DetailDocPDF from "./DetailDocPDF";
import DetailDocText from "./DetailDocText";
import { getSelectedWorkInfo } from "./workListSlice";

/**
 * 詳細資料追加コンポネント。
 * ３種類の資料が追加出来る。「動画」「PDF」「テキスト」
 * @param {*} props
 * @returns
 */
function DetailDoc(props) {
  const selectedWorkID = useSelector(getSelectedWorkInfo).workID;

  return (
    <div>
      <div className="font-bold text-[24px]">詳細資料</div>
      <div className="grid grid-rows-3 gap-1 mt-[6px]">
        {/* 動画追加 */}
        <DetailDocMovie workID={selectedWorkID} />
        {/* PDF追加 */}
        <DetailDocPDF workID={selectedWorkID} />
        {/* テキスト追加 */}
        <DetailDocText workID={selectedWorkID} />
      </div>
    </div>
  );
}
export default DetailDoc;

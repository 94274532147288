import React, { useState } from "react";
import { ProductionPlanRegister } from "./ProductionPlanRegister";
import { ProductionPlanType } from "../../Variables";
import { ProductionPlanRestore } from "./ProductionPlanRestore";

export function ProductionPlan(props) {
    const [selectedWorkType, setSelectedWorkType] = useState(ProductionPlanType.REGISTER);

    return (
        <div>
            <div className="my-[11px] h-[21px]">生産計画</div>
            {selectedWorkType === ProductionPlanType.REGISTER ?
                <ProductionPlanRegister key={ProductionPlanType.REGISTER} setSelectedWorkType={setSelectedWorkType} /> :
                <ProductionPlanRestore key={ProductionPlanType.RESTORE} setSelectedWorkType={setSelectedWorkType} />
            }
        </div>
    );
}
import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HardwareType, TextType, popupcss, variables } from "../../../Variables";
import SectionTitle from "../../../components/SectionTitle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import {
    selectMngItmByWorkID,
    setHardwareData,
} from "./manageItemSlice";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import UpwardButton from "../../../components/UpwardButton";
import DownwardButton from "../../../components/DownwardButton";
import DropDown from "../../../components/DropDown";
import InputBox from "../../../components/InputBox";
import { v4 as uuidv4 } from "uuid";
const regex = /^\d*$/;
const hash = require('object-hash');

function HardwareInput(props) {
    const dispatch = useDispatch();
    const selectedWorkID = props.workID;
    const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
    const hardwareDataList = mngItm.data.hardwareData;
    const [tempHardwareDataList, setTempHardwareDataList] = useState(hardwareDataList);
    const [selectedHardwareOrder, setSelectedHardwareOrder] = useState(1);
    const hardwareTypeList = [
        { label: "締結カウント", value: HardwareType.FiotCount },
    ];

    function handleAddClick() {
        let hardwareData = { dispOrder: selectedHardwareOrder + 1, hardwareId: uuidv4(), hardwareType: HardwareType.None, handleName: "", tramsmitData: "", count: 0 };
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            for (let i = selectedHardwareOrder; i < prevState.length; i++) {
                tmp[i] = {
                    ...tmp[i],
                    dispOrder: tmp[i].dispOrder + 1
                };
            };
            tmp.push(hardwareData);
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedHardwareOrder(hardwareData.dispOrder);
    }

    function handleDeleteClick() {
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            let filterData = tmp.filter(item => item.dispOrder !== selectedHardwareOrder);
            filterData.sort((a, b) => a.dispOrder - b.dispOrder);
            for (let i = 0; i < filterData.length; i++) {
                filterData[i] = {
                    ...filterData[i],
                    dispOrder: i + 1
                };
            }
            tmp = filterData;;
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedHardwareOrder(selectedHardwareOrder === tempHardwareDataList.length ? selectedHardwareOrder - 1 : selectedHardwareOrder)
    }

    function handleUpwardClick() {
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            let sortedList = tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            const selectedIdx = sortedList.findIndex(itm => itm.dispOrder === selectedHardwareOrder);
            if (selectedIdx >= 1) {
                sortedList[selectedIdx - 1] = {
                    ...sortedList[selectedIdx - 1],
                    dispOrder: sortedList[selectedIdx].dispOrder
                };
                sortedList[selectedIdx] = {
                    ...sortedList[selectedIdx],
                    dispOrder: sortedList[selectedIdx].dispOrder - 1
                };
            }
            tmp = sortedList;;
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedHardwareOrder(selectedHardwareOrder - 1);
    }

    function handleDownwardClick() {
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            let sortedList = tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            const selectedIdx = sortedList.findIndex(itm => itm.dispOrder === selectedHardwareOrder);
            if (sortedList.length - 1 > selectedIdx) {
                sortedList[selectedIdx + 1] = {
                    ...sortedList[selectedIdx + 1],
                    dispOrder: sortedList[selectedIdx].dispOrder
                };
                sortedList[selectedIdx] = {
                    ...sortedList[selectedIdx],
                    dispOrder: sortedList[selectedIdx].dispOrder + 1
                };
            }
            tmp = sortedList;;
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedHardwareOrder(selectedHardwareOrder + 1);
    }

    function onSelectedHardware(dispOrder) {
        setSelectedHardwareOrder(dispOrder);
    }

    function isDataChanged() {
        const hashOfTempHardwareData = hash(tempHardwareDataList);
        const hashOfHardwareData = hash(hardwareDataList);
        return hashOfTempHardwareData !== hashOfHardwareData;
    }

    function handleClose() {
        props.onClose();
    }

    function handleRegister() {
        dispatch(setHardwareData({ hardwareData: tempHardwareDataList, workID: selectedWorkID }));
        handleClose();
    }

    function handleHardwareChange(e, index) {
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((data) => data.dispOrder === index + 1);
            if (idx >= 0) {
                tmp[idx] = { ...tmp[idx], hardwareType: Number(e.target.value), handleName: "", tramsmitData: "", count: 0 }
            }
            return tmp;
        });
    }

    /**
     * ハンドルネーム設定を変更するイベント
     * @param {*} e 
     * @param {*} index 
     */
    function onHandleNameChange(e, index) {
        let inputHandleName = e.target.value.trim();
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((data) => data.dispOrder === index + 1);
            if (idx >= 0) {
                tmp[idx] = { ...tmp[idx], handleName: inputHandleName }
            }
            return tmp;
        });
    }

    /**
     * ハンドルネーム変更のキーアップイベント
     * @param {*} e 
     * @param {*} str 
     * @param {*} index
     */
    function handleNameKeyUp(e, str, index) {
        if (e.key === 'Enter') {
            inputHandleNameFilter(str, index);
        }
    }

    /**
     * ハンドルネーム入力をフィルターする処理
     * @param {*} str 
     * @param {*} index 
     */
    function inputHandleNameFilter(str, index) {
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((data) => data.dispOrder === index + 1);
            if (idx >= 0) {
                tmp[idx] = { ...tmp[idx], handleName: str };
            }
            return tmp;
        });
    }

    /**
     * カウント回数設定を変更するイベント
     * @param {*} e 
     * @param {*} index 
     */
    function onCountChange(e, index) {
        let inputCount = e.target.value.trim();
        if (isExistFullWidthCharacter(inputCount) || regex.test(inputCount)) {
            if (inputCount.length > 0) {
                inputCount = isNaN(inputCount) ? inputCount : Number(inputCount) > 0 ? Number(inputCount) : 0;
            }
            setTempHardwareDataList((prevState) => {
                let tmp = prevState.slice();
                let idx = tmp.findIndex((data) => data.dispOrder === index + 1);
                if (idx >= 0) {
                    tmp[idx] = { ...tmp[idx], count: inputCount };
                }
                return tmp;
            });
        }
    }

    /**
     * 記入するテキストが全角かをチェックする
     * @param {*} str 
     * @returns 
     */
    function isExistFullWidthCharacter(str) {
        const fullWidthPattern = /[\uFF00-\uFFEF\u4E00-\u9FFF\u3000-\u303F]/g;
        for (let i = 0; i < str.length; i++) {
            if (fullWidthPattern.test(str[i])) {
                return true;
            }
        }
        return false;
    }

    /**
     * カウント回数入力のEnterキーアップイベント
     * @param {*} e 
     * @param {*} str 
     * @param {*} index 
     */
    function handleInputTextKeyUp(e, str, index) {
        if (e.key === 'Enter') {
            inputCountFilter(str, index);
        }
    }

    /**
     * カウント回数入力をフィルタする処理
     * @param {*} str 
     * @param {*} index 
     */
    function inputCountFilter(str, index) {
        let value = "";
        for (let i = 0; i < str.length; i++) {
            if (str[i].match(regex)) {
                value += str[i];
            }
        }
        if (value.length > 0) {
            value = isNaN(value) ? value : Number(value) > 0 ? Number(value) : 0;
        }
        setTempHardwareDataList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((data) => data.dispOrder === index + 1);
            if (idx >= 0) {
                tmp[idx] = { ...tmp[idx], count: isNaN(value) ? value : Number(value) > 0 ? Number(value) : 0 };
            }
            return tmp;
        });
    }

    /**
     * ハードウェア設定画面ですべての項目で入力されているかチェック
     * @param {*} tempHardwareDataList
     * @returns bool
     */
    const isActiveRegisterBtn = () => {
        const isErrorCheckInputText = tempHardwareDataList.some(x => x.handleName.length === 0 || x.count === 0 || x.count.length === 0);
        return isErrorCheckInputText === false;
    }

    return (
        <div className={popupcss}>
            <div className={props.className}>
                <div className="w-[512px] h-[1033px] p-[16px] px-[24px] mt-[47px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-[#FFFFFF] sm-rounded">
                    <div className="grid grid-clos-2">
                        <SectionTitle Text="ハードウエア連携" className='text-[24px]' />
                        <div className="justify-self-end">
                            <button onClick={handleClose}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div>
                    <div className="mt-[-10px]">
                        最大で5種類まで設定することができます
                    </div>
                    <div className="inline-flex h-[42px] w-fit py-[5px] mb-[13px]">
                        <AddButton
                            className="w-[80px] h-[48px]"
                            Text="追加"
                            onClick={handleAddClick}
                            isEnable={tempHardwareDataList.length < 5}
                        />
                        <DeleteButton
                            className="w-[80px] h-[48px]"
                            Text="削除"
                            onClick={handleDeleteClick}
                            isEnable={tempHardwareDataList.length > 1}
                        />
                        <UpwardButton
                            className="w-[100px] h-[48px]"
                            Text="上に移動"
                            onClick={handleUpwardClick}
                            isEnable={tempHardwareDataList.length > 0 && selectedHardwareOrder !== 1}
                        />
                        <DownwardButton
                            className="w-[100px] h-[48px]"
                            Text="下に移動"
                            onClick={handleDownwardClick}
                            isEnable={tempHardwareDataList.length > 0 && selectedHardwareOrder !== tempHardwareDataList.length}
                        />
                    </div>
                    <div className="h-[1px] mx-[-15px] bg-[#C8C6C4] opacity-[.56]" />
                    <div className="mx-[-20px] mt-[10px]">
                        {tempHardwareDataList && tempHardwareDataList.map((singleItem, index) => (
                            <div key={index} className={"flex-row w-[500px] h-[125px] ml-[2px]  border-2" + (selectedHardwareOrder === index + 1 ? " border-[#0073CD] bg-[#D8D7D5]" : " border-[#FFFFFF] border-opacity-0 ")}
                                onClick={() => onSelectedHardware(singleItem.dispOrder)}>
                                <div className="flex ml-[15px] mt-[10px]">
                                    <span className={"font-bold mr-[11px] mt-[2px] w-[120px]"}>ハードウエア連携{singleItem.dispOrder}</span>
                                    <DropDown
                                        itemsSource={hardwareTypeList}
                                        firstOption={"ハードウエアを選択してください"}
                                        selectedValuePath={"value"}
                                        displayMemberPath={"label"}
                                        width={"w-[250px]"}
                                        borderColor={"border-[#C8C6C4]"}
                                        onChange={(e) => handleHardwareChange(e, index)}
                                        selectedValue={singleItem.hardwareType}>
                                    </DropDown>
                                </div>
                                {singleItem.hardwareType === HardwareType.FiotCount &&
                                    <div>
                                        <div className="flex ml-[16px] mt-[5px]">
                                            <span className={"font-bold mr-[10px] w-[120px]"}>ハンドルネーム</span>
                                            <InputBox
                                                className={"border-[#C8C6C4] border-[1px] w-[339px] h-[29px] mt-[1px] pl-[5px] outline-none rounded-sm"}
                                                maxLength={24}
                                                onChange={(e) => onHandleNameChange(e, index)}
                                                onKeyUp={(e, str) => handleNameKeyUp(e, str, index)}
                                                onFocusOut={(txt) => inputHandleNameFilter(txt, index)}
                                                value={singleItem.handleName}
                                                textType={TextType.HALFWIDTH_HANDLE_NAME_AUTOCHANGE}
                                                placeholder="送信デバイスのハンドルネームを入力してください。">
                                            </InputBox>
                                        </div>
                                        <div className="flex ml-[16px] mt-[5px]">
                                            <span className={"font-bold mr-[10px] w-[120px]"}>カウント回数</span>
                                            <InputBox
                                                className={"border-[#C8C6C4] border-[1px] w-[45px] pr-[5px] h-[29px] mt-[1px] outline-none text-right rounded-sm"}
                                                maxLength={2}
                                                onChange={(e) => onCountChange(e, index)}
                                                onKeyUp={(e, str) => handleInputTextKeyUp(e, str, index)}
                                                onFocusOut={(txt) => inputCountFilter(txt, index)}
                                                value={singleItem.count === 0 ? "" : singleItem.count}
                                                textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}>
                                            </InputBox>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                    <div className="h-[1px] mt-[16px] mx-[-15px] bg-[#C8C6C4] opacity-[.56]" />
                    <div className="mt-[15px]">
                        {(isActiveRegisterBtn() === true && isDataChanged()) ? (
                            <button
                                type="button"
                                onClick={handleRegister}
                                className="w-[62px] h-[29px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] sm-rounded"
                            >
                                登録
                            </button>
                        ) : (
                            <button
                                type="button"
                                disabled="disabled"
                                className={
                                    "w-[62px] h-[29px] mr-[7px] text-[#A19F9D] border-solid sm-rounded bg-[#F3F2F1]"
                                }
                            >
                                登録
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HardwareInput;
import { React } from "react";

let errorInputCss = "w-[373px] h-[29px] border-[1px] rounded-sm border-red-800 outline-none outline-offset-0 focus:outline-[#0073CD] focus:border-0";
let inputCss = "w-[373px] h-[29px] border-[1px] rounded-sm border-black outline-none outline-offset-0 focus:outline-[#0073CD] focus:border-0";
const passwordPattern = /^[0-9a-zA-Z]*$/;

function PasswordInput(props) {
    function onPasswordChange(e) {
        if (isValid(e.target.value)) {
            props.onChange(e);
        } else {
            props.onError("半角英数字で入力してください。");
        }
    }

    function isValid(val) {
        return passwordPattern.test(val);
    }

    return (
        <div>
            <div>
                <input
                    maxLength={props.MaxLength}
                    type="password"
                    className={props.IsErrorState ? errorInputCss : inputCss}
                    value={props.Password}
                    onChange={onPasswordChange}
                />
            </div>
        </div>
    );
}
PasswordInput.defaultProps = {
    MaxLength: 50,
    Password: "",
    IsErrorState: false,
}
export default PasswordInput;
import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoLockOpenOutline } from "@react-icons/all-files/io5/IoLockOpenOutline";
import { IoLockClosed } from "@react-icons/all-files/io5/IoLockClosed";
import { VscChecklist } from "@react-icons/all-files/vsc/VscChecklist";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { IconContext } from "@react-icons/all-files";
import { v4 as uuidv4 } from "uuid";
import ConfirmBox from "../../../components/ConfirmBox";
import UpwardButton from "../../../components/UpwardButton";
import DownwardButton from "../../../components/DownwardButton";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import IDShowPopUp from "../../../components/IDShowPopUp";
import { deleteDtlDocByWorkIDList } from "./detailDocSlice";
import { HardwareType, SagyouBunkiType, maxLengthWorkNote, HindoKaisuSetting } from "../../../Variables";
import {
    addWork,
    selectWorkListByProcessId,
    setWorkName,
    deleteWorkByWorkIDList,
    moveUpwardInWork,
    moveWorkToOtherProc,
    moveDownwardInWork,
    getSelectedWorkInfo,
    setSelectedWork,
    setSelectedWorkByProcessID,
    setLockWork,
    emptyWorkObj,
    setIrregularWork,
    getAllWorkList,
    setMultiSelectedWorks,
    getMultiSelectedWorksInfo,
    setClearMultiSelectedWorks,
    moveMultiSelectWorkToOtherProc,
} from "./workListSlice";
import {
    selectProcessList,
    selectedProcID,
    setSelectedProcess,
} from "./../process/processListSlice";

import { selectToolListByWorkId, deleteToolByWorkIDList } from "./../work/toolListSlice";
import ErrorBox from "../../../components/ErrorBox";
import { selectMngItmByWorkID, selectBunkiAriToNashiWork, getAllMngItm, deleteMngItmByWorkIDList } from "./manageItemSlice";
import InputBoxWithBlur from "../../../components/InputBoxWithBlur";
import ToggleButton from "../../../components/ToggleButton";
import { GiAutoRepair } from "@react-icons/all-files/gi/GiAutoRepair";
import { ManageItemType } from "../../../Variables";
import { RiLinksFill } from "react-icons/ri";

let isClearMultiSelection = true;

function WorkList(props) {
    const dispatch = useDispatch();
    const selectedProcessID = useSelector((state) => selectedProcID(state));
    const allWorkList = useSelector((state) => getAllWorkList(state));
    const allManageItemList = useSelector((state) => getAllMngItm(state));
    const workList = useSelector((state) => selectWorkListByProcessId(state, selectedProcessID));
    const bunkiAriToNashiWorkList = useSelector((state) => selectBunkiAriToNashiWork(state));
    const processList = useSelector((state) => selectProcessList(state));
    const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
    const multiSelectedWorks = useSelector((state) => getMultiSelectedWorksInfo(state));

    const selectedProcessIdx = processList.findIndex((p) => p.processID === selectedProcessID);
    const [dialogBox, setDialogBox] = useState("");
    const [showIDPopUp, setshowIDPopUp] = useState(false);
    const toolListByWorkID = useSelector((state) => selectToolListByWorkId(state, selectedWork.workID));
    const manageItem = useSelector((state) => selectMngItmByWorkID(state, selectedWork.workID));
    const bunkiData = manageItem.data.bunkiData;
    const [showError, setShowError] = useState(false);
    const [showBunkiWorkDelError, setShowBunkiWorkDelError] = useState(false);
    const [showBunkiWorkMoveError, setShowBunkiWorkMoveError] = useState(false);
    const [showBunkiWorkSetError, setShowBunkiWorkSetError] = useState(false);
    const [showBunkiBtnTextSetError, setShowBunkiBtnTextSetError] = useState(false);
    const [showNumericalJudgeSettingError, setShowNumericalJudgeSettingError] = useState(false);
    let isHaveEmptyValInToolInfo = false;
    let isHaveEmptyValInManageInfo = false;
    let isBunkiWorkSetError = false;
    let isBunkiBtnTextSetError = false;
    let isNumericalJudgeSettingError = false;

    useEffect(() => {
        dispatch(setSelectedWorkByProcessID({ processID: selectedProcessID }));
    }, [dispatch, selectedProcessID]);

    useEffect(() => {
        document.addEventListener("click", handleClearMultiSelectionRows);
        return function cleanup() {
            document.removeEventListener("click", handleClearMultiSelectionRows);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleAddClick() {
        let newRow = {
            ...emptyWorkObj,
            processID: selectedProcessID,
            workID: uuidv4(),
        };
        dispatch(addWork(newRow));
    }

    function handleSingleSelectionRow(event, idx) {
        if (event.ctrlKey === false && event.shiftKey === false) {
            if (workList && workList.length > idx) {
                dispatch(setSelectedWork({ workID: workList[idx].workID }));
                if (textInput) {
                    textInput.focus();
                }
            }
        }
    }

    function handleMultiSelectionRows(event, index) {
        if (event.ctrlKey || event.shiftKey) {
            let isChooseable = false;
            if (multiSelectedWorks.length === 0) {
                let currIdx = workList.findIndex(x => x.workID === selectedWork.workID);
                isChooseable = index === currIdx - 1 || index === currIdx + 1;
            }
            else {
                let firstMultiSelectedIdx = workList.findIndex(x => x.workID === multiSelectedWorks[0].workID);
                let lastMultiSelectedIdx = workList.findIndex(x => x.workID === multiSelectedWorks.at(-1).workID);
                isChooseable = index === firstMultiSelectedIdx - 1 || index === lastMultiSelectedIdx + 1;
            }
            if (workList && workList.length > index && isChooseable) {
                dispatch(setMultiSelectedWorks({ workID: workList[index].workID }));
            }
        }
    }

    function handleClearMultiSelectionRows(event) {
        if (isClearMultiSelection && event.ctrlKey === false && event.shiftKey === false) {
            dispatch(setClearMultiSelectedWorks());
        }
        isClearMultiSelection = true;
    }

    /**
     * 作業名を設定する。
     * @param {*} wName 
     */
    function onBlurWorkName(wName) {
        dispatch(
            setWorkName({
                workID: selectedWork.workID,
                workName: wName,
            })
        );
    }

    function handleDeleteClick() {
        isClearMultiSelection = false;
        if ((selectedWork && selectedWork.workID.length > 0) || multiSelectedWorks.length > 0) {
            // 分岐元リストに削除可能かどうか確認する
            let isCheckErrorBunkiMotoList = bunkiAriToNashiWorkList && (
                multiSelectedWorks.length > 0 ? bunkiAriToNashiWorkList.bunkiMotoList.filter(l => multiSelectedWorks.some(m => m.workID === l.workId)).length > 0 :
                    bunkiAriToNashiWorkList.bunkiMotoList.filter(l => l.workId === selectedWork.workID).length > 0);

            // 分岐先リストに削除可能かどうか確認する
            let isCheckErrorBunkiSakiList = bunkiAriToNashiWorkList && (
                multiSelectedWorks.length > 0 ? bunkiAriToNashiWorkList.bunkiSakiList.filter(l => multiSelectedWorks.some(m => m.workID === l.workId)).length > 0 :
                    bunkiAriToNashiWorkList.bunkiSakiList.filter(l => l.workId === selectedWork.workID).length > 0);

            // 削除不可能の場合、エラーメッセージを表示する
            if (isCheckErrorBunkiMotoList || isCheckErrorBunkiSakiList) {
                setShowBunkiWorkDelError(true);
            }
            // 削除可能の場合、確認メッセージを表示する
            else {
                let msgWorkInfo = "";
                if (multiSelectedWorks.length > 0) {
                    msgWorkInfo = "選択された作業";
                }
                else if (selectedWork && selectedWork.workID.length > 0) {
                    const idx = workList.findIndex((w) => w.workID === selectedWork.workID);
                    msgWorkInfo = "「作業No" + (idx + 1) + (selectedWork.workName.length > 0 ? " : " + selectedWork.workName + "」" : "」");
                }

                setDialogBox(
                    <div onClick={() => { isClearMultiSelection = false; }}>
                        <ConfirmBox
                            className="absolute left-[520px] top-[500px] w-[471px]"
                            title="作業削除"
                            message={<>
                                <p className="break-all">
                                    {msgWorkInfo} <br /> を削除しますか？
                                </p>
                            </>}
                            onYesClick={() => handleDelConfirmYes()}
                            onNoClick={handleDelConfirmNo}
                        />
                    </div>
                );
            }
        }
    }

    function handleDelConfirmYes() {
        setDialogBox(null);
        let nextSelectWorkIndex = -1;
        let deleteWorkIDs = [];
        if (multiSelectedWorks.length > 0) {
            const firstIdx = workList.findIndex(w => w.workID === multiSelectedWorks[0].workID);
            const lastIdx = workList.findIndex(w => w.workID === multiSelectedWorks.at(-1).workID);
            nextSelectWorkIndex = workList.length - 1 > lastIdx ? lastIdx + 1 : firstIdx - 1;
            deleteWorkIDs = multiSelectedWorks.map(w => w.workID);
        }
        else {
            const idx = workList.findIndex((w) => w.workID === selectedWork.workID);
            nextSelectWorkIndex = workList.length - 1 > idx ? idx + 1 : idx - 1;
            deleteWorkIDs = [selectedWork.workID];
        }

        let nextSelectWorkId = nextSelectWorkIndex > -1 ? workList[nextSelectWorkIndex].workID : "-1";
        // 作業を削除
        dispatch(deleteWorkByWorkIDList({
            workIDs: deleteWorkIDs,
            processID: selectedWork.processID,
            nextSelectWorkID: nextSelectWorkId
        }));
        // 詳細資料削除
        dispatch(deleteDtlDocByWorkIDList(deleteWorkIDs));
        // 工具削除
        dispatch(deleteToolByWorkIDList(deleteWorkIDs));
        // 管理項目削除
        dispatch(deleteMngItmByWorkIDList(deleteWorkIDs));
    }

    function handleDelConfirmNo() {
        setDialogBox(null);
        isClearMultiSelection = false;
    }

    const bunKiDeleteErrorMsg = () => {
        if (multiSelectedWorks.length > 0) {
            return (
                <>
                    <div>{"選択された作業の中に 分岐／文字列比較 設定"} </div>
                    <div>{"されている作業が含まれている為、削除できません。"}</div>
                </>
            );
        }
        else {
            let bunkiWorkID = "";
            let msgWorkInfo = "";
            let msgProcessInfo = "";

            // 分岐元リストの作業が削除不可能の場合、
            if (bunkiAriToNashiWorkList.bunkiMotoList.filter(l => l.workId === selectedWork.workID).length > 0) {
                // 現在選択している分岐元作業の先作業「workID」を取る
                switch (true) {
                    case (manageItem && manageItem.data.txtCompWorkID.length > 0):
                        bunkiWorkID = manageItem.data.txtCompWorkID;
                        break;
                    case (manageItem && manageItem.data.okWorkID.length > 0):
                        bunkiWorkID = manageItem.data.okWorkID;
                        break;
                    case (manageItem && manageItem.data.ngWorkID.length > 0):
                        bunkiWorkID = manageItem.data.ngWorkID;
                        break;
                    case (manageItem && manageItem.data.bunkiData[0].workID.length > 0):
                        bunkiWorkID = manageItem.data.bunkiData[0].workID;
                        break;
                    case (manageItem && manageItem.data.bunkiData[1].workID.length > 0):
                        bunkiWorkID = manageItem.data.bunkiData[1].workID;
                        break;
                    default:
                        bunkiWorkID = "";
                }
            }
            // 分岐先リストの作業が削除不可能の場合、
            else {
                // 現在選択している分岐先作業の元作業「workID」を取る
                bunkiWorkID = allManageItemList.find(m => [m.data.txtCompWorkID, m.data.okWorkID, m.data.ngWorkID].includes(selectedWork.workID) ||
                    m.data.bunkiData.some(x => x.workID === selectedWork.workID)).workID;
            }

            const bunkiWorkObj = allWorkList.find(w => w.workID === bunkiWorkID);
            if (bunkiWorkObj) {
                let processIdx = processList.findIndex(p => p.processID === bunkiWorkObj.processID);
                if (processIdx > -1) {
                    const processName = processList[processIdx].processName;
                    msgProcessInfo = "「工程No" + (processIdx + 1) + (processName.length > 0 ? " : " + processName + "」" : "」");

                    let workIdx = allWorkList.filter(w => w.processID === bunkiWorkObj.processID)
                        .sort((a, b) => a.dispOrder - b.dispOrder)
                        .findIndex(w1 => w1.workID === bunkiWorkObj.workID);
                    msgWorkInfo = "「作業No" + (workIdx + 1) + (bunkiWorkObj.workName.length > 0 ? " : " + bunkiWorkObj.workName + "」" : "」");

                    return (
                        <>
                            {msgProcessInfo} <br /> {msgWorkInfo} <br /> で 分岐／文字列比較 設定されている為、削除できません。
                        </>
                    );
                }
            }
        }
        return null;
    }

    function bunkiMoveErrMsg() {
        if (multiSelectedWorks.length > 0) {
            return (
                <>
                    <div>{"選択された作業の中に 分岐設定 されている"}</div>
                    <div>{"作業が含まれている為、工程間の位置変更はできません。"}</div>
                </>
            );
        }
        else if (selectedWork && selectedWork.workID.length > 0) {
            const procName = processList[selectedProcessIdx].processName;
            let msgProcessInfo = "「工程No" + (selectedProcessIdx + 1) + (procName.length > 0 ? " : " + procName + "」" : "」");

            const idx = workList.findIndex((w) => w.workID === selectedWork.workID);
            let msgWorkInfo = "「作業No" + (idx + 1) + (selectedWork.workName.length > 0 ? " : " + selectedWork.workName + "」" : "」");

            return (
                <>
                    {msgProcessInfo}<br /> {msgWorkInfo}<br /> は 分岐設定されている為、工程間の位置変更はできません。
                </>
            );
        }
        return null;
    }

    function handleUpwardClick() {
        isClearMultiSelection = false;
        let isMoveToOtherProc = false;
        if (workList.length > 0) {
            isMoveToOtherProc = multiSelectedWorks.length > 0 ? workList[0].workID === multiSelectedWorks[0].workID : workList[0].workID === selectedWork.workID;
        }
        //工程リスト内の移動処理
        if (isMoveToOtherProc) {
            let isMngTypeOKNG = false;
            let isSagyouBunki = false;
            // 複数作業を選択した場合、
            if (multiSelectedWorks.length > 0) {
                isMngTypeOKNG = bunkiAriToNashiWorkList.bunkiMotoList.find(l => l.mngType === ManageItemType.OkNgJudge && multiSelectedWorks.some(w => w.workID === l.workId)) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(l => l.mngType === ManageItemType.OkNgJudge && multiSelectedWorks.some(w => w.workID === l.workId));
                isSagyouBunki = bunkiAriToNashiWorkList.bunkiMotoList.find(m => m.mngType === ManageItemType.SagyouBunki && multiSelectedWorks.some(x => x.workID === m.workId)) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(m => m.mngType === ManageItemType.SagyouBunki && multiSelectedWorks.some(w => w.workID === m.workId));
            }
            // 単一の作業を選択した場合、
            else {
                isMngTypeOKNG = bunkiAriToNashiWorkList.bunkiMotoList.find(l => l.mngType === ManageItemType.OkNgJudge && l.workId === selectedWork.workID) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(l => l.mngType === ManageItemType.OkNgJudge && l.workId === selectedWork.workID);
                isSagyouBunki = bunkiAriToNashiWorkList.bunkiMotoList.find(m => m.mngType === ManageItemType.SagyouBunki && m.workId === selectedWork.workID) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(m => m.mngType === ManageItemType.SagyouBunki && m.workId === selectedWork.workID);
            }
            if (isMngTypeOKNG || isSagyouBunki) {
                setShowBunkiWorkMoveError(true);
            }
            else {
                let msgWorkInfo = "";
                let msgProcessInfo = "";
                if (multiSelectedWorks.length > 0) {
                    msgWorkInfo = "選択された作業";
                    msgProcessInfo = "1つ前の工程";
                }
                else if (selectedWork && selectedWork.workID.length > 0) {
                    const idx = workList.findIndex((w) => w.workID === selectedWork.workID);
                    msgWorkInfo = "「作業No" + (idx + 1) + (selectedWork.workName.length > 0 ? " : " + selectedWork.workName + "」" : "」");
                    const procName = processList[selectedProcessIdx - 1].processName;
                    msgProcessInfo = "「工程No" + selectedProcessIdx + (procName.length > 0 ? " : " + procName + "」" : "」");
                }

                setDialogBox(
                    <div onClick={() => { isClearMultiSelection = false; }}>
                        <ConfirmBox
                            className="absolute left-[500px] top-[255px] w-[471px]"
                            title="作業位置の変更"
                            message={<>
                                <p className="break-all">
                                    {msgWorkInfo}<br />を<br />{msgProcessInfo}<br />へ移動しますか？
                                </p>
                            </>}
                            onYesClick={handleUpwardConfirmYes}
                            onNoClick={handleUpwardConfirmNo}
                        />
                    </div>
                );
            }
        }
        //作業リスト内の移動処理
        else {
            dispatch(
                moveUpwardInWork({
                    workID: selectedWork.workID,
                    selectedProcID: selectedProcessID,
                })
            );
        }
    }

    function handleUpwardConfirmYes() {
        //工程間で移動する処理
        setDialogBox(null);
        let targetProcID = "";
        if (selectedProcessIdx > 0) {
            targetProcID = processList[selectedProcessIdx - 1].processID;
        }
        if (multiSelectedWorks.length > 0) {
            dispatch(
                moveMultiSelectWorkToOtherProc({
                    workID: selectedWork.workID,
                    targetProcessID: targetProcID,
                    isMoveToLast: true,
                })
            );
        }
        else {
            dispatch(
                moveWorkToOtherProc({
                    workID: selectedWork.workID,
                    targetProcessID: targetProcID,
                    isMoveToLast: true,
                })
            );
        }
        dispatch(setSelectedProcess({ processID: targetProcID }));
    }

    function handleUpwardConfirmNo() {
        isClearMultiSelection = false;
        setDialogBox(null);
    }

    function handleDownwardClick() {
        isClearMultiSelection = false;
        let isMoveToOtherProc = false;
        if (workList.length > 0) {
            isMoveToOtherProc = multiSelectedWorks.length > 0 ? multiSelectedWorks.at(-1).workID === workList.at(-1).workID : selectedWork.workID === workList.at(-1).workID;
        }
        //工程リスト内の移動処理
        if (isMoveToOtherProc) {
            let isMngTypeOKNG = false;
            let isSagyouBunki = false;
            // 複数作業を選択した場合、
            if (multiSelectedWorks.length > 0) {
                isMngTypeOKNG = bunkiAriToNashiWorkList.bunkiMotoList.find(l => l.mngType === ManageItemType.OkNgJudge && multiSelectedWorks.some(w => w.workID === l.workId)) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(l => l.mngType === ManageItemType.OkNgJudge && multiSelectedWorks.some(w => w.workID === l.workId));
                isSagyouBunki = bunkiAriToNashiWorkList.bunkiMotoList.find(m => m.mngType === ManageItemType.SagyouBunki && multiSelectedWorks.some(w => w.workID === m.workId)) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(m => m.mngType === ManageItemType.SagyouBunki && multiSelectedWorks.some(w => w.workID === m.workId));
            }
            // 単一の作業を選択した場合、
            else {
                isMngTypeOKNG = bunkiAriToNashiWorkList.bunkiMotoList.find(l => l.mngType === ManageItemType.OkNgJudge && l.workId === selectedWork.workID) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(l => l.mngType === ManageItemType.OkNgJudge && l.workId === selectedWork.workID);
                isSagyouBunki = bunkiAriToNashiWorkList.bunkiMotoList.find(m => m.mngType === ManageItemType.SagyouBunki && m.workId === selectedWork.workID) ||
                    bunkiAriToNashiWorkList.bunkiSakiList.find(m => m.mngType === ManageItemType.SagyouBunki && m.workId === selectedWork.workID);
            }
            if (isMngTypeOKNG || isSagyouBunki) {
                setShowBunkiWorkMoveError(true);
            }
            else {
                let msgWorkInfo = "";
                let msgProcessInfo = "";
                if (multiSelectedWorks.length > 0) {
                    msgWorkInfo = "選択された作業";
                    msgProcessInfo = "1つ後ろの工程";
                }
                else if (selectedWork && selectedWork.workID.length > 0) {
                    const idx = workList.findIndex((w) => w.workID === selectedWork.workID);
                    msgWorkInfo = "「作業No" + (idx + 1) + (selectedWork.workName.length > 0 ? " : " + selectedWork.workName + "」" : "」");
                    const procName = processList[selectedProcessIdx + 1].processName;
                    msgProcessInfo = "「工程No" + (selectedProcessIdx + 2) + (procName.length > 0 ? " : " + procName + "」" : "」");
                }

                setDialogBox(
                    <div onClick={() => { isClearMultiSelection = false; }}>
                        <ConfirmBox
                            className="absolute left-[500px] top-[255px] w-[471px]"
                            title="作業位置の変更"
                            message={<>
                                <p className="break-all">
                                    {msgWorkInfo}<br />を<br />{msgProcessInfo}<br />へ移動しますか？
                                </p>
                            </>}
                            onYesClick={handleDownwardConfirmYes}
                            onNoClick={handleDownwardConfirmNo}
                        />
                    </div>
                );
            }
        }
        //作業リスト内の移動処理
        else {
            dispatch(
                moveDownwardInWork({
                    workID: selectedWork.workID,
                    selectedProcID: selectedProcessID,
                })
            );
        }
    }

    function handleDownwardConfirmYes() {
        //工程間で移動する処理
        setDialogBox(null);
        let targetProcID = "";
        if (selectedProcessIdx < processList.length - 1) {
            targetProcID = processList[selectedProcessIdx + 1].processID;
        }
        if (multiSelectedWorks.length > 0) {
            dispatch(
                moveMultiSelectWorkToOtherProc({
                    workID: selectedWork.workID,
                    targetProcessID: targetProcID,
                    isMoveToLast: false,
                })
            );
        }
        else {
            dispatch(
                moveWorkToOtherProc({
                    workID: selectedWork.workID,
                    targetProcessID: targetProcID,
                    isMoveToLast: false,
                })
            );
        }
        dispatch(setSelectedProcess({ processID: targetProcID }));
    }

    function handleDownwardConfirmNo() {
        isClearMultiSelection = false;
        setDialogBox(null);
    }

    function handleIDClick(e) {
        setshowIDPopUp(true);
    }

    function closeIDShowPopUp(e) {
        setshowIDPopUp(false);
    }

    function handleLockClick() {
        // 部品/工具リストがある場合、全ての必須項目が入力済みかをチェック
        if (!selectedWork.isLock) {
            if (toolListByWorkID.length !== 0) {
                for (let i in toolListByWorkID) {
                    if (
                        toolListByWorkID[i].objUrl === null ||
                        toolListByWorkID[i].toolName === "" ||
                        toolListByWorkID[i].memberCnt === -1
                    ) {
                        isHaveEmptyValInToolInfo = true;
                        break;
                    }
                }
            }

            // 管理項目の設定がある場合、管理項目によって全ての必須項目が入力済みかをチェック
            if (manageItem && manageItem.workID !== "") {
                switch (manageItem.mngItmType) {
                    case 0:
                        isHaveEmptyValInManageInfo = true;
                        break;
                    case 1:
                        isHaveEmptyValInManageInfo = manageItem.data.audioText === "";
                        break;
                    case 2:
                        isHaveEmptyValInManageInfo = manageItem.data.mketWarnText === "";
                        break;
                    case 4:
                        isHaveEmptyValInManageInfo =
                            (manageItem.data.txtCompSource === 1 && manageItem.data.txtCompText === "") || (manageItem.data.txtCompSource === 2 && manageItem.data.txtCompWorkID === "")
                        break;
                    case 5:
                        if (manageItem.data.numJudgeUpper === "" || manageItem.data.numJudgeLower === "" || isNaN(manageItem.data.numJudgeUpper) || isNaN(manageItem.data.numJudgeLower)) {
                            isHaveEmptyValInManageInfo = true;
                        }
                        else if (manageItem.data.numJudgeUpper.length > 0 && manageItem.data.numJudgeLower.length > 0 && Number(manageItem.data.numJudgeUpper) < Number(manageItem.data.numJudgeLower)) {
                            isNumericalJudgeSettingError = true;
                        }
                        break;
                    case 7:
                        const bunki1BtnData = bunkiData[0];
                        const bunki2BtnData = bunkiData[1];
                        if (manageItem.data.sagyouBunkiType === SagyouBunkiType.BtnType) {
                            if (bunki1BtnData.workID === "" || bunki2BtnData.workID === "" ||
                                bunki1BtnData.btnText === "" || bunki2BtnData.btnText === "") {
                                isHaveEmptyValInManageInfo = true;
                            }
                            else if (bunki1BtnData.workID === bunki2BtnData.workID) {
                                isBunkiWorkSetError = true;
                            }
                            else if (bunki1BtnData.btnText === bunki2BtnData.btnText) {
                                isBunkiBtnTextSetError = true;
                            }
                        }
                        else {
                            isHaveEmptyValInManageInfo = bunkiData.some(data => data.workID === "");
                        }
                        break;
                    case 8:
                        const hardwareDataList = manageItem.data.hardwareData;
                        isHaveEmptyValInManageInfo = hardwareDataList.some(data => data.hardwareType === HardwareType.None);
                        break;
                    default:
                        isHaveEmptyValInManageInfo = false;
                }
            }
        }
        if (selectedWork.isLock === false
            && (manageItem.data.txtInputKurikaeshi
                || manageItem.data.numJudgeKurikaeshi
                || manageItem.data.txtCompKurikaeshi
                || manageItem.data.okNgKurikaeshi
                || manageItem.data.sagyouBunkiKurikaeshi)
            && (manageItem.data.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.NONE)) {
            isHaveEmptyValInToolInfo = true;
        }
        if (selectedWork.workName === "" ||
            selectedWork.objUrl === null ||
            isHaveEmptyValInToolInfo ||
            isHaveEmptyValInManageInfo ||
            selectedWork.workNote.length > maxLengthWorkNote) {
            setShowError(true);
        }
        else if (isBunkiWorkSetError) {
            setShowBunkiWorkSetError(true);
        }
        else if (isBunkiBtnTextSetError) {
            setShowBunkiBtnTextSetError(true);
        }
        else if (isNumericalJudgeSettingError) {
            setShowNumericalJudgeSettingError(true);
        }
        else {
            dispatch(
                setLockWork({
                    workID: selectedWork.workID,
                    isLock: selectedWork.isLock,
                })
            );
        }
    }

    function onCopyIDClick(dispID) {
        navigator.clipboard.writeText(dispID);
        setshowIDPopUp(false);
    }

    let textInput = null;
    const setInputRef = element => {
        textInput = element;
    };

    function ShuuseiSagyouToggleChage() {
        dispatch(
            setIrregularWork({
                workID: selectedWork.workID,
                isIrregular: !selectedWork.isIrregular,
            })
        );
    }

    function bunKiIconDisplay(workId) {
        let motoListByWorkId = bunkiAriToNashiWorkList.bunkiMotoList.filter(l => l.workId === workId);
        let sakiListByWorkId = bunkiAriToNashiWorkList.bunkiSakiList.filter(l => l.workId === workId);
        let isMngTypeBunki = motoListByWorkId.find(l => l.mngType === ManageItemType.OkNgJudge || l.mngType === ManageItemType.SagyouBunki)
            || sakiListByWorkId.find(l => l.mngType === ManageItemType.OkNgJudge || l.mngType === ManageItemType.SagyouBunki);
        return (<>
            {
                (bunkiAriToNashiWorkList && bunkiAriToNashiWorkList.bunkiMotoList.length > 0 && motoListByWorkId.length > 0)
                    ?
                    (isMngTypeBunki ?
                        <><VscTypeHierarchySub /><VscChecklist /></>
                        :
                        <><RiLinksFill /><VscChecklist /></>)
                    : null
            }
            {
                (bunkiAriToNashiWorkList && bunkiAriToNashiWorkList.bunkiSakiList.length > 0 && sakiListByWorkId.length > 0 &&
                    motoListByWorkId.length === 0)
                    ?
                    (isMngTypeBunki ?
                        <VscTypeHierarchySub />
                        :
                        <RiLinksFill />)
                    : null
            }
            {
                (bunkiAriToNashiWorkList && bunkiAriToNashiWorkList.kanriWorkList.length > 0 && bunkiAriToNashiWorkList.kanriWorkList.includes(workId))
                    ?
                    <VscChecklist className="ml-[19px]" />
                    : null
            }
        </>)
    }

    const isActiveUpwardButton = () => {
        let result = false;
        if (workList.length > 0 && processList.length > 0) {
            let isCheckMaxLimit = selectedProcessIdx !== 0 && allWorkList.length > 0 && allWorkList.filter(w => w.processID.toUpperCase() === processList[selectedProcessIdx - 1].processID.toUpperCase()).length < 99;
            let isCheckSelectedWorks = multiSelectedWorks.length > 0 ? workList[0].workID !== multiSelectedWorks[0].workID : workList[0].workID !== selectedWork.workID;
            result = isCheckMaxLimit || isCheckSelectedWorks;
        }
        return result;
    }

    const isActiveDownwardButton = () => {
        let result = false;
        if (workList.length > 0 && processList.length > 0) {
            let isCheckMaxLimit = selectedProcessIdx !== processList.length - 1 && allWorkList.length > 0 && allWorkList.filter(w => w.processID.toUpperCase() === processList[selectedProcessIdx + 1].processID.toUpperCase()).length < 99;
            let isCheckSelectedWorks = multiSelectedWorks.length > 0 ? workList.at(-1).workID !== multiSelectedWorks.at(-1).workID : workList.at(-1).workID !== selectedWork.workID;
            result = isCheckMaxLimit || isCheckSelectedWorks;
        }
        return result;
    }

    return (
        <div>
            {/* 左側のメニュー */}
            <div className="w-fit">
                <div className="font-bold text-[24px] text-[#000000] px-[15px] pt-[28px]">
                    作業リスト
                </div>
                <div className="inline-flex h-[42px] w-fit px-[13px] py-[10px] mb-[13px]">
                    <AddButton
                        className="w-[80px] h-[45px]"
                        onClick={() => handleAddClick()}
                        isEnable={processList.length > 0 && workList.length < 99 && multiSelectedWorks.length === 0}
                        Text="追加"
                    />
                    <DeleteButton
                        className="w-[80px] h-[45px]"
                        onClick={() => handleDeleteClick()}
                        isEnable={selectedWork && selectedWork.workID.length > 0}
                        Text="削除"
                    />
                    <UpwardButton
                        className="h-[45px]"
                        onClick={() => handleUpwardClick()}
                        isEnable={isActiveUpwardButton()}
                        Text="上に移動"
                    />
                    <DownwardButton
                        className="h-[45px]"
                        onClick={() => handleDownwardClick()}
                        isEnable={isActiveDownwardButton()}
                        Text="下に移動"
                    />
                </div>
                {/* 作業リスト表示スコープ */}
                <div className="flex flex-col w-[504px] h-[524px] ml-[1px] border-solid border-2 border-[#C8C6C4] bg-white">
                    <div className="h-[524px] ml-[10px] overflow-y-scroll overflow-x-hidden">
                        <div className="sticky top-0 flex w-full h-[29px] text-left border-b-2 bg-white">
                            <div className="font-bold w-[42px]">管理</div>
                            <div className="font-bold w-[47px]">状態</div>
                            <div className="font-bold w-[47px]">No</div>
                            <div className="font-bold">作業名</div>
                            <div className="grow flex justify-end">
                                <div className="font-bold w-[42px]">修正</div>
                            </div>
                        </div>
                        <table className="border-collapse w-full">
                            <tbody>
                                {workList &&
                                    workList.map((rowItem, idx) => (
                                        <tr
                                            className={
                                                "h-[48px] w-[461px] border-b text-left bg-white" +
                                                (rowItem.workID === selectedWork.workID || multiSelectedWorks.some(x => x.workID === rowItem.workID)
                                                    ? " bg-[#D8D7D5] "
                                                    : " ")
                                            }
                                            key={idx}
                                            onMouseDown={(e) => handleMultiSelectionRows(e, idx)}
                                            onClick={(e) => handleSingleSelectionRow(e, idx)}
                                        >
                                            <td className="bg-white w-[42px]">
                                                <div className="flex space-x-1">
                                                    {bunKiIconDisplay(rowItem.workID)}
                                                </div>
                                            </td>
                                            {/* <td>作業Noを表示処理を追加予定</td> */}
                                            <td className="bg-white p-[6px] w-[47px]">
                                                {rowItem.isLock ? (
                                                    <IoLockClosed className="ml-[7px]" />
                                                ) : (
                                                    <IoLockOpenOutline className="ml-[7px]" />
                                                )}
                                            </td>
                                            <td className="w-[47px]">{idx + 1}</td>
                                            <td className="w-[280px] ellipse one-line pt-3 whitespace-nowrap">{rowItem.workName}</td>
                                            <td className="w-[42px]">
                                                {
                                                    rowItem.isIrregular &&
                                                    <>
                                                        <IconContext.Provider
                                                            value={{
                                                                size: "20px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            <GiAutoRepair />
                                                        </IconContext.Provider>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="flex absolute left-[554px] top-[55px] h-[94px] w-[1353px]">
                <div className="flex items-center justify-end h-[94px] w-[1150px] border-2 border-[#C8C6C4] rounded-sm">
                    {workList.length > 0 && (
                        <div
                            onClick={handleLockClick}
                            className="flex-none w-[130px] h-[90px] pl-[25px] hover:bg-[#D8D7D5] hover:cursor-pointer"
                        >
                            <IconContext.Provider
                                value={{
                                    size: "65px",
                                    color: "#0073CD",
                                }}
                            >
                                {selectedWork.isLock ? <IoLockClosed className="ml-[5px]" /> : <IoLockOpenOutline className="ml-[5px]" />}
                            </IconContext.Provider>
                            {
                                <div className="flex h-[20px] font-semibold text-[#0073CD] text-opacity-100">
                                    {selectedWork.isLock ? "状態:ロック" : <span className="-ml-[8px]">状態:アンロック</span>}
                                </div>
                            }
                        </div>
                    )}
                    <div className="mx-1 px-1">
                        <label className="w-[358px] h-fit font-bold text-[24px] text-[#000000]">
                            作業
                        </label>
                        <button
                            type="button"
                            onClick={handleIDClick}
                            disabled={workList.length === 0}
                            className={
                                "block font-semibold mx-4 text-opacity-100 " +
                                (workList.length !== 0
                                    ? " hover:underline text-[#0073CD]"
                                    : "text-[#A19F9D]")
                            }
                        >
                            ID
                        </button>
                    </div>
                    <div className="w-[60px] text-right font-bold text-[24px] mx-1">
                        {workList.length > 0
                            ? workList.findIndex((w) => w.workID === selectedWork.workID) + 1
                            : ""}
                    </div>
                    <div className="w-[860px] h-[53px] mx-1 px-1">
                        <InputBoxWithBlur
                            textsize={"text-[24px]"}
                            key={selectedWork.workID}
                            isEnable={selectedWork.workID.length > 0 && !selectedWork.isLock}
                            setInputRef={setInputRef}
                            text={selectedWork.workName}
                            maxLength={50}
                            onBlur={onBlurWorkName}
                        />
                    </div>
                    <div className="mx-1">
                        <span className="text-3xl font-bold text-red-500">*</span>
                    </div>
                </div>
                <div className="flex items-center grow ml-1 border-2 border-[#C8C6C4] rounded-sm">
                    <div className="ml-2">
                        <IconContext.Provider
                            value={{
                                size: "24px",
                                color: "#000000",
                            }}
                        >
                            <GiAutoRepair />
                        </IconContext.Provider>
                    </div>
                    <div className="mx-1 font-bold">修正作業</div>
                    <div className="mx-1">
                        <ToggleButton
                            isChecked={selectedWork.isIrregular}
                            onChange={ShuuseiSagyouToggleChage}
                            inputID={"shuuSeiSagyou"}
                            isLock={selectedWork.isLock}
                        />
                    </div>
                </div>
            </div>
            {/* {ConfirmDialogBox} */}
            {dialogBox}
            {showIDPopUp && (
                <IDShowPopUp
                    Text="作業ID"
                    onCopy={onCopyIDClick}
                    onContainerDivClick={closeIDShowPopUp}
                    DisplayID={selectedWork.dispWorkID}
                    className="absolute left-[721px] top-[145px] w-[210px] h-[52px] border-[1px] border-[#050505] bg-[#F8F8F9]"
                />
            )}

            {/* 必須項目が未入力の場合、ロックされないためエラーボックスを表示 */}

            {showError && (
                <ErrorBox
                    className="absolute left-[552px] top-[150px] w-[528px]"
                    Title="作業ロック"
                    Message={<><div>ロックできません。</div>
                        <div>必須項目が入力されていない、又は</div>
                        <div>作業指示の文字数最大{maxLengthWorkNote}文字を超えています。</div></>}
                    onYesClick={() => setShowError(false)}
                />
            )
            }
            {
                showBunkiWorkDelError && (
                    <div onClick={() => { isClearMultiSelection = false; }}>
                        <ErrorBox
                            className="absolute left-[530px] top-[500px] w-[528px]"
                            Title="作業削除"
                            Message={bunKiDeleteErrorMsg()}
                            onYesClick={() => { setShowBunkiWorkDelError(false); }}
                        />
                    </div>
                )
            }
            {
                showBunkiWorkMoveError && (
                    <div onClick={() => { isClearMultiSelection = false; }}>
                        <ErrorBox
                            className="absolute left-[530px] top-[500px] w-[590px]"
                            Title="作業位置の変更"
                            Message={bunkiMoveErrMsg()}
                            onYesClick={() => { setShowBunkiWorkMoveError(false); }}
                        />
                    </div>
                )
            }
            {
                showBunkiWorkSetError && (
                    <ErrorBox
                        className="absolute left-[552px] top-[150px] w-[590px]"
                        Title="作業ロック"
                        Message={<><div>分岐設定で同じ作業が設定されている為、ロックできません。</div>
                            <div>分岐設定を確認してください。</div></>}
                        onYesClick={() => setShowBunkiWorkSetError(false)}
                    />
                )
            }
            {
                showBunkiBtnTextSetError && (
                    <ErrorBox
                        className="absolute left-[552px] top-[150px] w-[670px]"
                        Title="作業ロック"
                        Message={<><div>分岐設定1、2のボタン表示の設定が重複している為、ロックできません。</div>
                            <div>設定を確認してください。</div></>}
                        onYesClick={() => setShowBunkiBtnTextSetError(false)}
                    />
                )
            }
            {
                showNumericalJudgeSettingError && (
                    <ErrorBox
                        className="absolute left-[552px] top-[150px] w-[528px]"
                        Title="作業ロック"
                        Message={<><div>ロックできません。</div>
                            <div>数値判定の下限値が上限値を上回っています。</div></>}
                        onYesClick={() => setShowNumericalJudgeSettingError(false)}
                    />
                )
            }
        </div>
    );
}

export default WorkList;

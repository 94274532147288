import React from "react";
class DropDown extends React.Component {
  render() {
    return (
      <div>
        <select
          value={this.props.selectedValue}
          onChange={this.props.onChange}
          className={this.props.width + " h-[29px] border-[1px] rounded-sm outline-none outline-offset-0 " +
            "focus:outline-[#0073CD] focus:border-0 overflow-ellipsis "+ this.props.borderColor}>
          <option value={-1}>{this.props.firstOption}</option>
          {this.props.itemsSource && this.props.itemsSource.map((item) => (
            <option key={item[this.props.selectedValuePath]} value={item[this.props.selectedValuePath]}>
              {item[this.props.displayMemberPath]}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

DropDown.defaultProps = {
  width: "w-[373px]",
  borderColor: "border-black",
}
export default DropDown;

import React from 'react';
import { IconContext } from 'react-icons';
import { FaArrowDown } from 'react-icons/fa';
import { variables } from '../Variables';

class DownwardButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleDownwardClick = this.handleDownwardClick.bind(this);
  }

  handleDownwardClick() {
    if (this.props.isEnable) {
      this.props.onClick();
    }
  }

  render() {
    let iconColor = '#0073CD';
    let textColor = '';
    let hoverbgColor = variables.HOVER_CSS;
    if (!this.props.isEnable) {
      iconColor = '#A19F9D';
      textColor = 'text-[#A19F9D]';
      hoverbgColor = '';
    }
    return (
      <div
        onClick={this.handleDownwardClick}
        className={this.props.className || 'w-[100px] h-[48px]'}
      >
        <div
          className={
            'flex items-center w-full h-full px-[10px] hover:cursor-pointer ' +
            hoverbgColor
          }
        >
          <IconContext.Provider value={{ color: iconColor }}>
            <FaArrowDown />
          </IconContext.Provider>
          <div className={'h-fit font-bold ' + textColor}>
            {this.props.Text}
          </div>
        </div>
      </div>
    );
  }
}
export default DownwardButton;

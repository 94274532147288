import React from "react";
import { IconContext } from "react-icons";
import { VscRefresh } from "react-icons/vsc";
import { variables } from "../Variables";
function Update(props) {
    return (
        <div onClick={props.onClick}
            className={props.className|| "h-[53px] w-fit"}
             >
            <div className={"flex flex-row  pl-[13px] w-full h-full hover:cursor-pointer " + variables.HOVER_CSS}>
                <IconContext.Provider
                    value={{ color: "#0073CD", size: "16px", className: "self-center" }}>
                    <VscRefresh />
                </IconContext.Provider>
                <div className="mx-[5px] text-[#000000] self-center">{props.Text}</div>
            </div>

        </div >
    );
}
export default Update;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { variables } from "../../Variables";
import { urlCompanyName } from "../util/commonFun";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');

const emptyItem = {
    itemCode: -1,
    itemName: "",
    displayItemCode: "",
    processCnt: 0,
    isEnd: false,
}

const emptyProcess = {
    processId: "",
    processName: "",
    displayText: "",
    dispProcessId: "",
    version: -1,
};

export const fetchItemData = createAsyncThunk("workrestore/fetchItemData",
    async () => {
        const response = await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-itemData",
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).catch((error) => {
            appInsights.trackException({...error, errorFunction: "workRestoreSlice.fetchItemData()"});    
            throw (error.response.status);
        });
        return response.data;
    });

export const fetchProcessList = createAsyncThunk("workrestore/fetchProcessList",
    async itemCode => {
        const response = await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-process-list-by-itemcode",
            params: { itemCode: itemCode },
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).catch((error) => {
            appInsights.trackTrace({params: JSON.stringify({ itemCode: itemCode }) });
            appInsights.trackException({...error, errorFunction: "workRestoreSlice.fetchProcessList()"});    
            throw (error.response.status);
        });
        return response.data;
    });

const initState = {
    itemList: [],
    processList: []
};

const workRestoreSlice = createSlice({
    name: "workRestore",
    initialState: initState,
    reducers: {
        setResetProcessList: (state, action) => {
            state.processList = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchItemData.fulfilled, (state, action) => {
            const itemList = action.payload;
            if (itemList && itemList.length > 0) {
                state.itemList = itemList.map(item => {
                    return {
                        ...emptyItem,
                        itemCode: item.itemCode,
                        itemName: item.itemName,
                        displayItemCode: item.displayItemCode,
                        processCnt: item.processCnt,
                        isEnd: item.isEnd,
                    };
                });
            }
        });
        builder.addCase(fetchItemData.rejected, (state, action) => {
            appInsights.trackException({...action.error, errorFunction: "workRestoreSlice.builder.addCase(fetchItemData.rejected)"});    
        });
        builder.addCase(fetchProcessList.fulfilled, (state, action) => {
            const processList = action.payload;
            processList.sort((a, b) => a.dispOrder - b.dispOrder);
            if (processList && processList.length > 0) {
                state.processList = processList.map(item => {
                    return {
                        ...emptyProcess,
                        processId: item.processID,
                        processName: item.processName,
                        displayText: item.dispOrder + " " + item.processName,
                        dispProcessId: item.dispProcessID,
                        version: item.version
                    };
                });
            }
        });
        builder.addCase(fetchProcessList.rejected, (state, action) => {
            appInsights.trackException({...action.error, errorFunction: "workRestoreSlice.builder.addCase(fetchProcessList.rejected)"});    
        });
    },
});

export const {
    setResetProcessList,
} = workRestoreSlice.actions;
export default workRestoreSlice.reducer;

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { resetInitState, setPauseConfirmMsg, setPauseExpandMode, setShowTeishiInput } from "../lotOperationSlice";
import {
    NavigateToOperationStartWork,
    NavigateToOperation,
    urlCompanyName,
    NavigateToOperationLogin
} from "../../util/commonFun";
import { OperationManagementType, variables } from "../../../Variables";
import ChuuDanMessage from "../../../components/ChuuDanMessage";
import { setConnectionLost, getConnectionStatus } from "../SerialPortReaderSlice";
import ChuuDanComfirmBox from "../../../components/ChuuDanComfirmBox";
import { appInsights } from "../../util/ApplicationInsight";
const axios = require('axios');

function LotNumberSaiKai(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector((state) => state.lotOperation);
    const isConnectionLost = useSelector(getConnectionStatus);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    /**
     * 作業再開ボタンの操作
     */
    async function handleRestartWork() {
        setIsBtnDisabled(true);
        let paramobj = {
            userId: items.selectedUser.userId,
            itemCode: items.selectedItem.itemCode,
            lotNo: items.lotNumber,
            dispProcessId: items.processObj.dispProcessId,
            lotOperationLogLockId: items.lotOperationLogLockId,
            version: items.version
        };
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/resume-work-by-lotno",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let responseData = response.data;
            if (responseData) {
                if (responseData.errorCodeList.length > 0) {
                    // 再開処理についてのエラー「1008 =>作業再開不可エラー又は1009 =>工程設定の変更」
                    props.setWriteLogErrorCode(responseData.errorCodeList[0]);
                    dispatch(setPauseConfirmMsg(false));
                    dispatch(setPauseExpandMode(false));
                }
                else {
                    dispatch(setPauseConfirmMsg(false));
                    if (responseData.newLockKey.length > 0) {
                        dispatch(resetInitState());
                        let dataObj = {
                            selectedItem: items.selectedItem,
                            selectedUser: items.selectedUser,
                            lotNumber: items.lotNumber,
                            managementType: OperationManagementType.LOTNUMBER,
                            lockKey: responseData.newLockKey,
                            lotOperationLogLockId: items.lotOperationLogLockId,
                            version: items.version
                        }
                        NavigateToOperation(navigate, dataObj);
                    }
                }
            }
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                console.error(error);
                appInsights.trackTrace({ data: JSON.stringify(paramobj) });
                appInsights.trackException({ ...error, errorFunction: "LotNumberSaiKai.handleRestartWork()" });
            }
        });
        setIsBtnDisabled(false);
    }

    /**
     * 作業開始画面へボタンの操作
     */
    async function handleBackOperationStartWork() {
        setIsBtnDisabled(true);
        dispatch(setPauseConfirmMsg(false));
        dispatch(setPauseExpandMode(false));
        NavigateToOperationStartWork(navigate, items.lotOperationLogLockId);
        setIsBtnDisabled(false);
        if (isConnectionLost) {
            dispatch(setConnectionLost(false));
        }
    }

    function handleLotTeishiInput() {
        dispatch(setPauseConfirmMsg(false));
        dispatch(setShowTeishiInput(true));
    }

    return (
        <ChuuDanComfirmBox
            className="absolute left-[700px] top-[160px] w-[388px] h-[604px]"
            message={<ChuuDanMessage
                itemName={items.selectedItem.itemName}
                version={items.version}
                serialLotNumber={items.lotNumber}
                userName={items.selectedUser.userName} />}
            onSaikaiClick={isBtnDisabled ? void (0) : () => handleRestartWork()}
            onStartScreenClick={isBtnDisabled ? void (0) : () => handleBackOperationStartWork()}
            onSettingFormClick={isBtnDisabled ? void (0) : () => handleLotTeishiInput()}
        />
    )
}
export default LotNumberSaiKai;
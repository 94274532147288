import React from "react";
import SectionTitle from "../../components/SectionTitle";
import Add from "../../components/Add";
import CategoryInput from "../../components/CategoryInput";
import { variables, Sort, CompanyLoginPath } from "../../Variables";
import { BsThreeDots } from "react-icons/bs";
import EditButton from "../../components/EditButton";
import SearchBox from "../../components/SearchBox";
import { IconContext } from "@react-icons/all-files"
import TableTitle from "../../components/TableTitle";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { IsEditing, urlCompanyName } from "../util/commonFun";
import ConfirmBox from "../../components/ConfirmBox";
import { nanoid } from "nanoid";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');
const initialState = {
  categories: [],
  categoriesWithoutFilter: [],
  categoryFilterText: "",
  editBtn: { id: -1, btn: null },
  categoryInput: null,
  sortedOrder: Sort.NONE,
  isRedirectToCompanyLogin: false,
  dialogBox: null,
};

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.clearEditButton = this.clearEditButton.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCategoryAddComplete = this.handleCategoryAddComplete.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleCategoryNameClick = this.handleCategoryNameClick.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleThreeDotsClick = this.handleThreeDotsClick.bind(this);
    this.sortCategiesByName = this.sortCategiesByName.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleDiscardClick = this.handleDiscardClick.bind(this);
    this.deleteDialog = this.deleteDialog.bind(this);
    this.showCategoryInput = this.showCategoryInput.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  // カテゴリ追加ボタンのイベントハンドラ
  handleAddClick() {
    if (IsEditing() === true) {
      this.handleDiscardClick(-1);
    }
    else {
      this.setState({
        categoryInput: <CategoryInput key={nanoid()} onComplete={this.handleCategoryAddComplete} Category={{ id: -1, name: "" }} />
      });
    }
  }

  // 編集ボタンのイベントハンドラ
  handleEdit(targetId) {
    if (IsEditing() === true) {
      this.handleDiscardClick(targetId);
    }
    else {
      this.setState((state) => {
        return {
          categoryInput: <CategoryInput key={nanoid()} onComplete={this.handleCategoryAddComplete} Category={state.categories.find(c => c.id === targetId)} />
        };
      });
    }
  }

  // カテゴリの登録もしくは編集が終了した時、<CategoryInput>　をクリアして
  //　カテゴリ一覧を更新する。
  handleCategoryAddComplete() {
    this.refreshList();
  }

  redirect() {
    this.setState({
      isRedirectToCompanyLogin: true
    });
  }

  // カテゴリ一覧を更新する。
  refreshList() {
    axios({
      method: 'GET',
      url: variables.CATEGORY_URL,
      headers: { 'company': urlCompanyName },
      withCredentials: true
    }).then(
      response => {
        let data = response.data;
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({
          ...initialState,
          categories: data,
          categoriesWithoutFilter: data,
        });
      }).catch((error) => {
        let errResponseStatus = error.response.status;
        if (errResponseStatus === 401) {
          this.redirect();
        }
        else{
          appInsights.trackException({...error, errorFunction: "category.refreshList()"});
        }
      });
  }

  // 「…」を押す時のイベントハンドラ
  // 「…」を押すと、編集ボタンを表示する。
  handleThreeDotsClick(cateId, e) {
    const positionX = e.pageX + e.currentTarget.clientWidth + 10;
    const positionY = e.pageY - (e.currentTarget.clientHeight / 2);
    this.setState({
      editBtn: {
        id: cateId,
        btn: (
          <div style={{ position: 'absolute', left: positionX + 'px', top: positionY + 'px' }}>
            <EditButton onClick={() => this.handleEdit(cateId)} />
          </div>
        ),
      }
    });
    e.stopPropagation();
  }

  handleDiscardClick(targetId) {
    const msg = <>作業が破棄されます。よろしいですか？</>
    this.setState({
      dialogBox: (
        <ConfirmBox
          className="absolute right-[162px] top-[265px] w-[471px]"
          title="変更の破棄" message={msg}
          onYesClick={() => this.showCategoryInput(targetId)} onNoClick={this.deleteDialog} />
      ),
    });
  }

  deleteDialog() {
    this.setState({
      dialogBox: null,
    });
  }

  showCategoryInput(targetId) {
    this.setState({
      dialogBox: null,
    });
    if (targetId === -1) {
      this.setState({
        categoryInput: <CategoryInput key={nanoid()} onComplete={this.handleCategoryAddComplete} Category={{ id: -1, name: "" }} />
      });
    }
    else {
      this.setState((state) => {
        return {
          categoryInput: <CategoryInput key={nanoid()} onComplete={this.handleCategoryAddComplete} Category={state.categories.find(c => c.id === targetId)} />
        };
      });
    }
  }

  // カテゴリ名を押すと、編集画面を表示する。
  handleCategoryNameClick(cateId, e) {
    this.handleEdit(cateId)
    e.stopPropagation();
  }

  // 編集ボタンをクリアする。
  clearEditButton() {
    this.setState({
      editBtn: { id: -1, btn: null }
    });
  }

  // 入力したカテゴリ名でフィルターする。
  handleSearchInputChange(e) {
    const filteredCategories = this.state.categoriesWithoutFilter.filter(
      function (category) {
        return category.name.toString().toLowerCase().includes(
          e.target.value.toString().toLowerCase()
        );
      }
    );

    this.setState({
      categories: filteredCategories,
      categoryFilterText: e.target.value,
      sortedOrder: Sort.NONE
    });
  }

  sortCategiesByName() {
    const sortedOrder = this.state.sortedOrder;
    const sortedCategories = this.state.categories.sort(function (firstItem, secondItem) {
      if (sortedOrder === Sort.DESC || sortedOrder === Sort.NONE) {
        return firstItem.name.localeCompare(secondItem.name);
      }
      else {
        return secondItem.name.localeCompare(firstItem.name);
      }
    });
    this.setState({
      categories: sortedCategories,
      sortedOrder: (sortedOrder === Sort.DESC || sortedOrder === Sort.NONE) ? Sort.ASC : Sort.DESC
    });
  }

  render() {
    return (
      <div onClick={this.clearEditButton}>
        {this.state.isRedirectToCompanyLogin && <><Navigate to={generatePath(CompanyLoginPath, {
          company: urlCompanyName
        })} /></>}
        <div className="flex flex-row">
          {/* 中央パネル */}
          <div>
            <div className="my-[11px] h-[21px]">マスタ設定</div>
            <SectionTitle Text="カテゴリ" />
            <Add onClick={this.handleAddClick} Text="カテゴリの追加" />
            <div className="h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] absolute" />
            <div className="h-[32px] px-[15px] my-[15px]">
              {/* 検索ボックス */}
              <SearchBox onChange={this.handleSearchInputChange} value={this.state.categoryFilterText} />
            </div>
            {/* タイトル「カテゴリ名」 */}
            <TableTitle
              onClick={this.sortCategiesByName}
              Sort={this.state.sortedOrder}
              Text="カテゴリ名" />
            <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />
            <div className="w-[960px] h-[790px] overflow-y-scroll">
              <table className="border-collapse mt-[-2px]">
                <tbody>
                  {this.state.categories.map((category) => (
                    <tr key={category.id} className={"border-y-[1px] border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                      <td className="w-[960px] h-[53px] text-left pl-[20px] font-bold">
                        <div
                          onClick={(e) => this.handleCategoryNameClick(category.id, e)}
                          className="w-fit hover:cursor-pointer hover:underline" >
                          {category.name}
                        </div>
                      </td>
                      <td>
                        <div
                          onClick={(e) => this.handleThreeDotsClick(category.id, e)}
                          className="h-[53px] mx-[15px] flex items-center cursor-pointer">
                          <IconContext.Provider
                            value={{ color: "Black", size: "20px", }}>
                            <BsThreeDots />
                          </IconContext.Provider>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* 右側パネル  */}
          {
            this.state.categoryInput
          }
        </div>
        {/* 編集ボタン */}
        {this.state.editBtn.btn}
        <div>
          {this.state.dialogBox}
        </div>
      </div>
    );
  };
}

export default Category;

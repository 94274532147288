function SemiCircleProgress(props) {
    const width = props.width;
    const maxValue = props.maxValue;
    const currValue = props.currValue;
    const progressValue = currValue > 0 ? (currValue / maxValue) * 100 : 0;
    const progress = progressValue < 0 ? 0 : (progressValue > 100 ? 100 : progressValue);
    const padding = props.padding;
    const barThickness = props.barThickness;

    const outterRadius = (width - (2 * padding)) / 2;
    const innerRadius = outterRadius - barThickness;
    const height = outterRadius + (padding * 2);
    const centerX = padding + outterRadius;
    const centerY = height - padding;
    const outterStartX = padding;
    const outterStartY = height - padding;
    const outterEndX = outterStartX + 2 * outterRadius;
    const outterEndY = outterStartY;

    const innerStatX = outterStartX + barThickness;
    const innerStartY = outterStartY;
    const innerEndX = outterEndX - barThickness;
    const innerEndY = outterStartY;

    const circleBarPath = `M ${outterStartX} ${outterStartY} `
        + `A ${outterRadius} ${outterRadius} 0 1 1 ${outterEndX} ${outterEndY} `
        + `L ${innerEndX} ${innerEndY} `
        + `A ${innerRadius} ${innerRadius} 0 1 0 ${innerStatX} ${innerStartY}`;

    /*
    find (x, y) of angle(t) on a circle
    x(t) = r cos(t) + j
    y(t) = r sin(t) + k
	
    t = angel
    j = centerX
    k = centerY
    */
    const progressOutterX = outterRadius * Math.cos((180 + (progress * 1.8)) * (Math.PI / 180)) + centerX;
    const progressOutterY = outterRadius * Math.sin((180 + (progress * 1.8)) * (Math.PI / 180)) + centerY;
    const progressInnerX = innerRadius * Math.cos((180 + (progress * 1.8)) * (Math.PI / 180)) + centerX;
    const progressInnerY = innerRadius * Math.sin((180 + (progress * 1.8)) * (Math.PI / 180)) + centerY;
    const progressPath = `M ${outterStartX} ${outterStartY} 
    A ${outterRadius} ${outterRadius} 0 0 1 ${progressOutterX} ${progressOutterY} 
    L ${progressInnerX} ${progressInnerY} 
    A ${innerRadius} ${innerRadius} 0 0 0 ${innerStatX} ${innerStartY} z`;

    return (
        <div style={{ width: width + "px", height: height + "px" }} >
            <div className="relative flex items-end justify-center">
                <svg version="1.1"
                    width={width} height={height}
                    xmlns="http://www.w3.org/2000/svg">
                    <path d={circleBarPath} fill={props.barBgColor} />
                    <path d={progressPath} fill={props.progressColor} />
                </svg>
                <div className="absolute mb-[30px] font-bold text-[70px]">{props.currValue}</div>
            </div>
        </div>
    );
}
SemiCircleProgress.defaultProps = {
    width: 250,
    padding: 5,
    barThickness: 30,
    barBgColor: "#F3F2F0",
    progressColor: "#128EFF",
    currValue: 0,
    maxValue: 100,
};
export default SemiCircleProgress;
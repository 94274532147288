import { React } from "react";

function SpinnerLoading(props) {
    return (
        <div className={"flex items-center justify-center absolute inset-0 z-[20]"}>
            <div className="loading-spinner">
            </div>
        </div>
    );
}

export default SpinnerLoading;
import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { popupcss } from "../../../Variables";
import SectionTitle from "../../../components/SectionTitle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import { variables } from "../../../Variables";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import UpwardButton from "../../../components/UpwardButton";
import DownwardButton from "../../../components/DownwardButton";
import WorkDropDown from "./WorkDropDown";
import ErrorBox from "../../../components/ErrorBox";
import {
    selectMngItmByWorkID,
    setBunkiData,
    getAllMngItm
} from "./manageItemSlice";
import { getSelectedWorkInfo, selectWorkListByProcessId, getWorkListWithoutHindoSetting } from "./workListSlice";
import { selectProcessList } from "../process/processListSlice";

const hash = require('object-hash');
let errMsg = "";
function BunkiListInput(props) {
    const dispatch = useDispatch();
    const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
    const selectedWorkID = selectedWork.workID;
    const selectedProcessId = selectedWork.processID;
    const processList = useSelector(selectProcessList);
    const allMngItm = useSelector(getAllMngItm);
    let selectedProcess = processList.filter(p => p.processID === selectedProcessId);
    let workListByProcessId = useSelector(state => selectWorkListByProcessId(state, selectedProcessId));
    let filteredWorkList = workListByProcessId.filter(w => w.workID !== selectedWorkID);
    const wrklstWithoutHindoSetting = useSelector(state => getWorkListWithoutHindoSetting(state, filteredWorkList, allMngItm));
    const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
    const bunkiDataList = mngItm.data.bunkiData;
    const [tempBunkiDataList, setTempBunkiDataList] = useState(bunkiDataList);
    const [selectedBunkiOrder, setSelectedBunkiOrder] = useState(1);
    const [showError, setShowError] = useState(false);

    function handleAddClick() {
        let bunkiData = { dispOrder: selectedBunkiOrder + 1, btnText: "", workID: "" };
        setTempBunkiDataList((prevState) => {
            let tmp = prevState.slice();
            for (let i = selectedBunkiOrder; i < prevState.length; i++) {
                tmp[i] = {
                    ...tmp[i],
                    dispOrder: tmp[i].dispOrder + 1
                };
            };
            tmp.push(bunkiData);
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedBunkiOrder(bunkiData.dispOrder);
    }

    function handleDeleteClick() {
        setTempBunkiDataList((prevState) => {
            let tmp = prevState.slice();
            let filterData = tmp.filter(item => item.dispOrder !== selectedBunkiOrder);
            filterData.sort((a, b) => a.dispOrder - b.dispOrder);
            for (let i = 0; i < filterData.length; i++) {
                filterData[i] = {
                    ...filterData[i],
                    dispOrder: i + 1
                };
            }
            tmp = filterData;;
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedBunkiOrder(selectedBunkiOrder === tempBunkiDataList.length ? selectedBunkiOrder - 1 : selectedBunkiOrder)
    }

    function handleUpwardClick() {
        setTempBunkiDataList((prevState) => {
            let tmp = prevState.slice();
            let sortedList = tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            const selectedIdx = sortedList.findIndex(itm => itm.dispOrder === selectedBunkiOrder);
            if (selectedIdx >= 1) {
                sortedList[selectedIdx - 1] = {
                    ...sortedList[selectedIdx - 1],
                    dispOrder: sortedList[selectedIdx].dispOrder
                };
                sortedList[selectedIdx] = {
                    ...sortedList[selectedIdx],
                    dispOrder: sortedList[selectedIdx].dispOrder - 1
                };
            }
            tmp = sortedList;;
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedBunkiOrder(selectedBunkiOrder - 1);
    }

    function handleDownwardClick() {
        setTempBunkiDataList((prevState) => {
            let tmp = prevState.slice();
            let sortedList = tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            const selectedIdx = sortedList.findIndex(itm => itm.dispOrder === selectedBunkiOrder);
            if (sortedList.length - 1 > selectedIdx) {
                sortedList[selectedIdx + 1] = {
                    ...sortedList[selectedIdx + 1],
                    dispOrder: sortedList[selectedIdx].dispOrder
                };
                sortedList[selectedIdx] = {
                    ...sortedList[selectedIdx],
                    dispOrder: sortedList[selectedIdx].dispOrder + 1
                };
            }
            tmp = sortedList;;
            tmp.sort((a, b) => a.dispOrder - b.dispOrder);
            return tmp;
        });
        setSelectedBunkiOrder(selectedBunkiOrder + 1);
    }

    function onBunkiDropDownChange(value) {
        setTempBunkiDataList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((data) => data.dispOrder === selectedBunkiOrder);
            if (idx >= 0) {
                tmp[idx] = { ...tmp[idx], workID: value }
            }
            return tmp;
        });
    }

    function onSelectedBunki(dispOrder) {
        setSelectedBunkiOrder(dispOrder);
    }

    function handleRegister() {
        if (isBunkiWorkIDDuplicate(tempBunkiDataList)) {
            errMsg = (
                <>
                    分岐設定で同じ作業が設定されている為、登録できません。
                    <br />
                    分岐設定を確認してください。
                </>
            );
            setShowError(true);
        }
        else if (isInfiniteProcess()) {
            errMsg = (
                <>
                    作業完了できない分岐設定になっている為、登録できません。
                    <br />
                    分岐設定を確認してください。
                </>
            );
            setShowError(true);
        }
        else {
            dispatch(setBunkiData({ bunkiData: tempBunkiDataList, workID: selectedWorkID }));
            handleClose();
        }
    }

    /**
     * WorkID設定しない分岐があるかどうかチェック
     * @returns bool
     */
    const isRegisterDisable = () => {
        let isRegisterDisable = tempBunkiDataList.some(data => data.workID === "");
        return isRegisterDisable;
    }

    /**
     * 分岐先の作業は分岐1~分岐10で同じ作業を設定しているかどうかチェック
     * @param {*} tempBunkiDataList 
     * @returns bool
     */
    const isBunkiWorkIDDuplicate = (tempBunkiDataList) => {
        var data = [];
        var isWorkIdDuplicate = false;
        for (let i = 0; i < tempBunkiDataList.length; i++) {
            if (data.find(d => d.workID === tempBunkiDataList[i].workID)) {
                isWorkIdDuplicate = true;
                break;
            }
            if (isWorkIdDuplicate === false) {
                data.push({ workID: tempBunkiDataList[i].workID });
            }
        }
        return isWorkIdDuplicate;
    }

    /**
     * 分岐1~分岐10で全て前の作業に設定しているかどうかチェック
     * @returns bool
     */
    const isInfiniteProcess = () => {
        let infiniteProcess = true;
        workListByProcessId.sort((a, b) => a.dispOrder - b.dispOrder);
        const thisWorkPosition = workListByProcessId.findIndex(w => w.workID === selectedWorkID);
        for (let i = 0; i < tempBunkiDataList.length; i++) {
            const bunkiSakiPosition = workListByProcessId.findIndex(w => w.workID === tempBunkiDataList[i].workID);
            if (bunkiSakiPosition > thisWorkPosition) {
                infiniteProcess = false;
                break;
            }
        }
        return infiniteProcess;
    }

    /**
     * Data 変更があるかどうかチェック
     * @returns bool
     */
    function isDataChanged() {
        const hashOfTempBunkiData = hash(tempBunkiDataList);
        const hashofBunkiData = hash(bunkiDataList);
        if (hashOfTempBunkiData !== hashofBunkiData) {
            return true;
        } else {
            return false;
        }
    }

    function handleClose() {
        props.onClose();
    }

    return (
        <div className={popupcss}>
            <div className={props.className}>
                <div className="w-[512px] h-[1033px] p-[15px] mt-[47px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-[#FFFFFF] sm-rounded">
                    <div className="grid grid-cols-2">
                        <SectionTitle Text="作業分岐の設定" className='text-[18px]' />
                        <div className="justify-self-end">
                            <button onClick={handleClose}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div><div className="mt-[15px]">
                        最大で10分岐まで設定することができます
                    </div>
                    <div className="inline-flex h-[42px] w-fit px-[13px] py-[5px] mb-[13px]">
                        <AddButton
                            className="w-[80px] h-[48px]"
                            Text="追加"
                            onClick={handleAddClick}
                            isEnable={tempBunkiDataList.length < 10} />
                        <DeleteButton
                            className="w-[80px] h-[48px]"
                            Text="削除"
                            onClick={handleDeleteClick}
                            isEnable={tempBunkiDataList.length > 2} />
                        <UpwardButton
                            className="w-[100px] h-[48px]"
                            Text="上に移動"
                            onClick={handleUpwardClick}
                            isEnable={tempBunkiDataList.length > 0 && selectedBunkiOrder !== 1}
                        />
                        <DownwardButton
                            className="w-[100px] h-[48px]"
                            Text="下に移動"
                            onClick={handleDownwardClick}
                            isEnable={tempBunkiDataList.length > 0 && selectedBunkiOrder !== tempBunkiDataList.length} />
                    </div>
                    <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />
                    <div>
                        {tempBunkiDataList && tempBunkiDataList.map((data, index) => (
                            <div key={index} className={"w-[410px] h-[62px] mb-1 ml-[2px] mt-[13px] " + (selectedBunkiOrder === index + 1 ? "border-2 border-[#0073CD] bg-[#D8D7D5]" : "")}
                                onClick={() => onSelectedBunki(data.dispOrder)}>
                                <div className="ml-[6px]">分岐{data.dispOrder}</div>
                                <div className="ml-[6px]">
                                    <WorkDropDown
                                        firstOption={"分岐する作業を選択してください"}
                                        key={index}
                                        onSelectedIndexChange={onBunkiDropDownChange}
                                        width={397}
                                        height={30}
                                        selectedOption={tempBunkiDataList[index].workID}
                                        processList={selectedProcess}
                                        workList={wrklstWithoutHindoSetting}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="h-[1px] mt-[10px] bg-[#C8C6C4] opacity-[.56]" />
                    <div className="mt-[15px]">
                        {(isRegisterDisable() === false && isDataChanged()) ? (
                            <button
                                type="button"
                                onClick={handleRegister}
                                className="w-[62px] h-[29px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] sm-rounded"
                            >
                                登録
                            </button>
                        ) : (
                            <button
                                type="button"
                                disabled="disabled"
                                className={
                                    "w-[62px] h-[29px] mr-[7px] text-[#A19F9D] border-solid sm-rounded bg-[#F3F2F1]"
                                }
                            >
                                登録
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {showError && (
                <ErrorBox
                    className="absolute right-[162px] top-[265px] w-[590px]"
                    Title="作業分岐の設定"
                    Message={errMsg}
                    onYesClick={() => setShowError(false)}
                />
            )}
        </div>)
};

export default BunkiListInput;
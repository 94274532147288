import React from "react";
import { useRef, useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { BiChevronDown } from "react-icons/bi";

function SelectWithCheckBox(props) {
    const ref = useRef();
    const [showDropDown, setShowDropDown] = useState(false);

    let itemList = props.categoryList;
    let checkedItemList = props.checkedCategoryList;

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // ドロップダウンメニューが開いていて、ドロップダウンメニュー以外のところをクリックすると、ドロップダウンメニューが閉じます。
            if (showDropDown && ref.current && !ref.current.contains(e.target)) {
                setShowDropDown(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);
        return function cleanup() {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    })

    function CheckBox(props) {
        return (
            <div onClick={props.onClick}>
                {
                    props.checkStatus === 0 &&
                    <div className="w-[21px] h-[21px] flex items-center justify-center bg-white border rounded-[2px] border-[#767676]" readOnly>
                        <>{/* 空白 */}</>
                    </div>
                }
                {
                    props.checkStatus === 1 &&
                    <input type={"checkbox"} className="w-[21px] h-[21px] flex items-center p-[-1px] accent-[#0073CD] rounded-[2px]" defaultChecked />
                }
                {
                    props.checkStatus === 2 &&
                    <div className="w-[21px] h-[21px] flex items-center justify-center bg-white border rounded-[2px] border-[#0073CD]" readOnly
                    >
                        <div className="w-[9px] h-[9px] pt-[6px] pl-[6px] rounded-sm bg-[#0073CD]">
                        </div>
                    </div>
                }
            </div>
        );
    }

    function onSelectBlxClick() {
        setShowDropDown(prevState => !prevState);
    }

    function onCheckBoxChange(e) {
        props.onCheckBoxChange(e);
    }

    function getDisplayText() {
        let txt = "";
        let splitter = ",";

        if (checkedItemList.length === itemList.length) {
            txt = "すべて選択";
        }
        else {
            for (let idx = 0; idx < checkedItemList.length; idx++) {
                txt += (idx === 0 ? "" : splitter) + checkedItemList[idx].name;
            }
        }
        return txt;
    }

    function getCheckStatus() {
        // 「すべて選択」を外した場合
        if (checkedItemList.length === 0) {
            return 0;
        }
        // 「すべて選択」を選択した場合
        else if (checkedItemList.length === itemList.length) {
            return 1;
        }
        //個別を選択した場合
        else {
            return 2;
        }
    }

    function handleAllCheckClick() {
        props.handleAllCheckClick();
    }

    return (
        <div className="w-[373px] h-[29px] bg-white relative mt-[3px]" ref={ref}>
            <div className="w-fit h-fit border-[2px] rounded-sm border-[#00000099] outline-none outline-offset-0 focus-within:border-[#0073CD]"
                onClick={() => onSelectBlxClick()}>
                <div className="flex flex-row">
                    <input type="text" className="w-[345px] h-[29px] pl-[8px] focus:outline-none focus:placeholder-transparent"
                        value={getDisplayText()}
                        readOnly >
                    </input>
                    <div className="w-[25px] h-[29px] hover:bg-[#D8D7D5]">
                        <IconContext.Provider
                            value={{ color: "#605E5C", size: "27px" }}>
                            <BiChevronDown />
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
            {
                showDropDown &&
                <div className="w-[368px] h-[360px] overflow-x-hidden overflow-y-scroll mt-[5px] absolute top-full z-20 border border-solid rounded-[2px] bg-white border-[#C8C6C4] shadow-md">
                    <ul >
                        <li key={-1} className="h-[48px] bg-white hover:bg-[#D8D7D5] mb-[8px]">
                            <div className="h-[48px] flex items-center mt-[8px] pl-[8px] ml-[8px] ">
                                <CheckBox checkStatus={getCheckStatus()} id="selectAll" onClick={handleAllCheckClick} />
                                <label htmlFor="selectAll" className="pl-[12px] w-full">
                                    <div className="h-[48px] flex items-center" onClick={handleAllCheckClick}>
                                        すべて選択
                                    </div>
                                </label>
                            </div></li>
                        <li className="h-[2px] w-[352px] ml-[8px] bg-[#C8C6C4] opacity-[1] mb-[8px]"></li>
                        {
                            itemList.map((itm, index) =>
                                <li key={index} className="flex items-center pl-[16px] bg-white h-[48px] hover:bg-[#D8D7D5]">
                                    <div>
                                        <input type={"checkbox"} id={itm[props.valueMemberPath]} className="w-[21px] h-[21px] accent-[#0073CD] rounded-[2px]"
                                            value={itm[props.valueMemberPath]}
                                            checked={checkedItemList.some(item => item.id === itm[props.valueMemberPath])}
                                            onChange={(e) => onCheckBoxChange(e)} />
                                    </div>
                                    <label htmlFor={itm[props.valueMemberPath]} className="pl-[12px] w-full">
                                        <div className="h-11 flex-row flex-wrap pt-[7px] items-center max-w-[285px] overflow-hidden text-ellipsis whitespace-nowrap">
                                            {itm[props.displayMemberPath]}
                                        </div>
                                    </label>
                                </li>
                            )
                        }
                    </ul>
                </div>
            }
        </div>
    );
}

SelectWithCheckBox.defaultProps = {
    valueMemberPath: null,
    displayMemberPath: null
};

export default SelectWithCheckBox;
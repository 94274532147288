import React from "react";
import SortIcon from "./SortIcon";

function TableTitle(props) {
  return (
    <div onClick={props.onClick}
      className={"w-fit h-[30px] px-[" + props.horiPadding + "px]" + (props.iconShow ? " hover:cursor-pointer" : "  cursor-default")}>
      <div className={props.className}>
        <div className="flex flex-nowrap">
          <div className={"self-center font-bold whitespace-nowrap " + props.fontSizeCss}>
            {props.Text}
          </div>
          <div className={"self-center mx-" + props.horiMargin}>
            {props.iconShow === true ? <SortIcon Sort={props.Sort} /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

TableTitle.defaultProps = {
  iconShow: true,
  horiPadding: 15,
  horiMargin: 2,
  fontSizeCss: "text-[18px]",
}

export default TableTitle;
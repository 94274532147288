import { React, useState } from 'react';
import SectionTitle from './SectionTitle';
import { popupcss, HindoKaisuSetting, KaisuSetting, TextType, variables, ManageItemType } from '../Variables';
import InputBox from "./InputBox";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMngItmByWorkID,
  setHindoKaisuSetting,
  emptyHindoKaisuSettingObj,
  selectBunkiAriToNashiWork
} from "../features/editor/work/manageItemSlice";
import ErrorBox from './ErrorBox';

const regex = /^[0-9]*$/;
const fullWidthPattern = /[\uFF00-\uFFEF\u4E00-\u9FFF\u3000-\u303F]/g;
const hash = require('object-hash');

function HindoKaisuInput(props) {
  const dispatch = useDispatch();
  const selectedWorkID = props.workID;
  const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
  const hindoKaisuSettingObj = mngItm.data.hindoKaisuSetting;
  const [tempHindoKaisuSettingObj, setTempHindoKaisuSettingObj] = useState({ ...hindoKaisuSettingObj });
  const [dialogBox, setDialogBox] = useState(null);
  const bunkiAriToNashiWorkList = useSelector((state) => selectBunkiAriToNashiWork(state));

  function handleHindoKaisuChange(hindoKaisuSettingType) {
    let isCheckErrorBunkiMotoList = bunkiAriToNashiWorkList?.bunkiMotoList.filter(l => l.workId === selectedWorkID).length > 0;
    let isCheckErrorBunkiSakiList = bunkiAriToNashiWorkList?.bunkiSakiList.filter(l => l.workId === selectedWorkID).length > 0;
    // 頻度設定のエラーチェック
    let isErrorHindoSetting = isCheckErrorBunkiSakiList && hindoKaisuSettingType === HindoKaisuSetting.Hindo;
    // 回数設定のエラーチェック
    let isErrorKaisuuSetting = mngItm.mngItmType === ManageItemType.OkNgJudge ? (isCheckErrorBunkiMotoList && hindoKaisuSettingType === HindoKaisuSetting.Kaisu)
      : mngItm.mngItmType === ManageItemType.SagyouBunki ? hindoKaisuSettingType === HindoKaisuSetting.Kaisu : false;

    if (isErrorHindoSetting) {
      setDialogBox(
        <ErrorBox
          className="absolute right-[162px] top-[265px] w-[631px]"
          Title="分岐設定"
          Message="分岐先に設定されている為、「頻度設定」はできません。"
          onYesClick={() => setDialogBox(null)}
        />
      );
    }
    else if (isErrorKaisuuSetting) {
      setDialogBox(
        <ErrorBox
          className="absolute right-[162px] top-[265px] w-[631px]"
          Title="分岐設定"
          Message="「分岐設定」をしている場合は「回数設定」はできません。"
          onYesClick={() => setDialogBox(null)}
        />
      );
    }
    else {
      setTempHindoKaisuSettingObj({
        ...emptyHindoKaisuSettingObj,
        hindoKaisuSettingType: hindoKaisuSettingType,
        hindoSetting: { ...emptyHindoKaisuSettingObj.hindoSetting },
        kaisuSetting: { ...emptyHindoKaisuSettingObj.kaisuSetting },
      });
    }
  }

  function onShoHinChange() {
    setTempHindoKaisuSettingObj((previousState) => {
      return {
        ...previousState,
        hindoSetting: {
          ...previousState.hindoSetting,
          shohin: !previousState.hindoSetting.shohin,
        }
      };
    });
  }

  function onChuKanHinChange() {
    setTempHindoKaisuSettingObj((previousState) => {
      return {
        ...previousState,
        hindoSetting: {
          ...previousState.hindoSetting,
          chukanhin: !previousState.hindoSetting.chukanhin,
        }
      };
    });
  }

  function onShuHinChange() {
    setTempHindoKaisuSettingObj((previousState) => {
      return {
        ...previousState,
        hindoSetting: {
          ...previousState.hindoSetting,
          shuhin: !previousState.hindoSetting.shuhin,
        }
      };
    });
  }

  function onToKanKaKuChange() {
    setTempHindoKaisuSettingObj((previousState) => {
      return {
        ...previousState,
        hindoSetting: {
          ...previousState.hindoSetting,
          toukankaku: !previousState.hindoSetting.toukankaku,
          toukankakuData: 0,
        }
      };
    });
  }

  function handleKaisuRadioChange(kaisuType) {
    setTempHindoKaisuSettingObj((previousState) => {
      let newKaisuSetting = {
        batchSize: false,
        kotei: false,
        wariai: false,
        koteiData: 0,
        wariaiData: 0,
      };

      if (kaisuType === KaisuSetting.BatchSize) {
        newKaisuSetting.batchSize = true;
      } else if (kaisuType === KaisuSetting.KoTei) {
        newKaisuSetting.kotei = true;
      } else if (kaisuType === KaisuSetting.Wariai) {
        newKaisuSetting.wariai = true;
      }

      return {
        ...previousState,
        kaisuSetting: newKaisuSetting,
      };
    });
  }

  function isExistFullWidthCharacter(str) {
    for (let i = 0; i < str.length; i++) {
      if (fullWidthPattern.test(str[i])) {
        return true;
      }
    }
    return false;
  }

  /**
 * 全角入力テキストをフィルタする処理
 * @param {*} str 
 * @returns 
 */
  function inputTextFilter(str) {
    let value = "";
    for (let i = 0; i < str.length; i++) {
      if (str[i].match(regex)) {
        value += str[i];
      }
    }
    return value;
  }

  function onTxtToKanKakuChange(e) {
    let number = e.target.value.trim();
    if (isExistFullWidthCharacter(number) || regex.test(number)) {
      if (number.length > 0) {
        number = isNaN(number) ? number : Number(number) > 0 ? Number(number) : 0;
      }
      setTempHindoKaisuSettingObj((previousState) => {
        return {
          ...previousState,
          hindoSetting: {
            ...previousState.hindoSetting,
            toukankakuData: number,
          }
        };
      });
    }
  }

  function handleToKanKaKuKeyUp(e, str) {
    if (e.key === 'Enter') {
      toKanKaKuInputFilter(str);
    }
  }

  function toKanKaKuInputFilter(str) {
    let number = inputTextFilter(str);
    if (number.length > 0) {
      number = isNaN(number) ? number : Number(number) > 0 ? Number(number) : 0;
    }
    return setTempHindoKaisuSettingObj((previousState) => {
      return {
        ...previousState,
        hindoSetting: {
          ...previousState.hindoSetting,
          toukankakuData: number,
        }
      };
    });
  }

  function onTxtKoteiChange(e) {
    let number = e.target.value.trim();
    if (isExistFullWidthCharacter(number) || regex.test(number)) {
      if (number.length > 0) {
        number = isNaN(number) ? number : Number(number) > 0 ? Number(number) : 0;
      }
      setTempHindoKaisuSettingObj((previousState) => {
        return {
          ...previousState,
          kaisuSetting: {
            ...previousState.kaisuSetting,
            koteiData: number,
          }
        };
      });
    }
  }

  function handleTxtKoteiKeyUp(e, str) {
    if (e.key === 'Enter') {
      koteiInputFilter(str);
    }
  }

  function koteiInputFilter(str) {
    let number = inputTextFilter(str);
    if (number.length > 0) {
      number = isNaN(number) ? number : Number(number) > 0 ? Number(number) : 0;
    }
    setTempHindoKaisuSettingObj((previousState) => {
      return {
        ...previousState,
        kaisuSetting: {
          ...previousState.kaisuSetting,
          koteiData: number,
        }
      };
    });
  }

  function onTxtWariaiChange(e) {
    let number = e.target.value.trim();
    if (isExistFullWidthCharacter(number) || regex.test(number)) {
      if (number.length > 0) {
        number = isNaN(number) ? number : Number(number) > 0 ? Number(number) : 0;
      }
      setTempHindoKaisuSettingObj((previousState) => {
        return {
          ...previousState,
          kaisuSetting: {
            ...previousState.kaisuSetting,
            wariaiData: number,
          }
        };
      });
    }

  }

  function handleTxtWariaiKeyUp(e, str) {
    if (e.key === 'Enter') {
      wariaiInputFilter(str);
    }
  }

  function wariaiInputFilter(str) {
    let number = inputTextFilter(str);
    if (number.length > 0) {
      number = isNaN(number) ? number : Number(number) > 0 ? Number(number) : 0;
    }
    setTempHindoKaisuSettingObj((previousState) => {
      return {
        ...previousState,
        kaisuSetting: {
          ...previousState.kaisuSetting,
          wariaiData: number,
        }
      };
    });
  }

  function isDataChanged() {
    const hashOfTempHindKaisuSetting = hash(tempHindoKaisuSettingObj);
    const hashOfHindKaisuSetting = hash(hindoKaisuSettingObj);
    return hashOfTempHindKaisuSetting !== hashOfHindKaisuSetting;
  }

  const isActiveWork = () => {
    if (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Hindo) {
      if (tempHindoKaisuSettingObj.hindoSetting.shohin === true
        || tempHindoKaisuSettingObj.hindoSetting.chukanhin === true
        || tempHindoKaisuSettingObj.hindoSetting.shuhin === true
        || (tempHindoKaisuSettingObj.hindoSetting.toukankaku === true)) {
        return tempHindoKaisuSettingObj.hindoSetting.toukankaku === true ? tempHindoKaisuSettingObj.hindoSetting.toukankakuData >= 2 : true;
      }
    }
    else if (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
      if ((tempHindoKaisuSettingObj.kaisuSetting.batchSize === true)
        || (tempHindoKaisuSettingObj.kaisuSetting.kotei === true && tempHindoKaisuSettingObj.kaisuSetting.koteiData >= 2)
        || (tempHindoKaisuSettingObj.kaisuSetting.wariai === true && tempHindoKaisuSettingObj.kaisuSetting.wariaiData >= 1)) {
        return true;
      }
    }
    return false;
  }

  function handleRegister() {
    dispatch(setHindoKaisuSetting({ hindoKaisuSetting: tempHindoKaisuSettingObj, workID: selectedWorkID }));
    props.onClose();
  }
  return (
    <>
      <div className={popupcss}>
        <div className={props.className}>
          <div className='p-[15px] ml-[1420px] mt-[433px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white rounded-sm' style={{ width: '490px' }}>
            <div className='flex justify-between items-center mt-1'>
              <SectionTitle Text="頻度/回数設定" className='text-[24px] font-bold' />
              <button onClick={props.onClose}>
                <IoMdClose className={variables.HOVER_CSS} />
              </button>
            </div>
            <div className='my-[9px] text-[16px]'>
              作業内容に応じて、作業頻度と回数を設定することができます。
            </div>
            <hr />
            <div className='flex mt-[15px]'>
              <div className="w-[245px]">
                <input
                  type="radio"
                  id="rdoHindo"
                  name="HindoKaisuSetting"
                  className='w-[14px] h-[14px] border-2'
                  checked={tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Hindo}
                  onChange={() => handleHindoKaisuChange(HindoKaisuSetting.Hindo)}
                />
                <label htmlFor="rdoHindo" className="pl-1 text-[18px]">
                  頻度
                </label>
                <div className='ml-5 text-[14px]'>
                  計画数（ロットサイズ）を基準に<br></br>作業を表示する頻度を設定
                </div>
                <div>
                  <div className="w-[195px] ml-5 my-2">
                    <input
                      type="checkbox"
                      id="chkShoHin"
                      name="shoHin"
                      className='w-[16px] h-[16px] border-2'
                      checked={tempHindoKaisuSettingObj.hindoSetting.shohin}
                      onChange={onShoHinChange}
                      disabled={tempHindoKaisuSettingObj.hindoKaisuSettingType !== HindoKaisuSetting.Hindo}
                    />
                    <label htmlFor="chkShoHin" className={"pl-1 text-[16px] " + (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Hindo ? "" : "text-[#A19F9D]")}>
                      初品
                    </label>
                  </div>
                  <div className="w-[95px] ml-5 my-2">
                    <input
                      type="checkbox"
                      id="chkChuKanHin"
                      name="chuKanHin"
                      className='w-[16px] h-[16px] border-2'
                      checked={tempHindoKaisuSettingObj.hindoSetting.chukanhin}
                      onChange={onChuKanHinChange}
                      disabled={tempHindoKaisuSettingObj.hindoKaisuSettingType !== HindoKaisuSetting.Hindo}
                    />
                    <label htmlFor="chkChuKanHin" className={"pl-1 text-[16px] " + (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Hindo ? "" : "text-[#A19F9D]")}>
                      中間品
                    </label>
                  </div>
                  <div className="w-[95px] ml-5 my-2">
                    <input
                      type="checkbox"
                      id="chkShuHin"
                      name="shuHin"
                      className='w-[16px] h-[16px] border-2'
                      checked={tempHindoKaisuSettingObj.hindoSetting.shuhin}
                      onChange={onShuHinChange}
                      disabled={tempHindoKaisuSettingObj.hindoKaisuSettingType !== HindoKaisuSetting.Hindo}
                    />
                    <label htmlFor="chkShuHin" className={"pl-1 text-[16px] " + (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Hindo ? "" : "text-[#A19F9D]")}>
                      終品
                    </label>
                  </div>
                  <div className="w-[195px] ml-5 my-2">
                    <input
                      type="checkbox"
                      id="chkToKanKaKu"
                      name="toKanKaKu"
                      className='w-[16px] h-[16px] border-2'
                      checked={tempHindoKaisuSettingObj.hindoSetting.toukankaku}
                      onChange={onToKanKaKuChange}
                      disabled={tempHindoKaisuSettingObj.hindoKaisuSettingType !== HindoKaisuSetting.Hindo}
                    />
                    <label htmlFor="chkToKanKaKu" className={"pl-1 text-[16px] " + (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Hindo ? "" : "text-[#A19F9D]")}>
                      等間隔
                    </label>
                    <div className='flex'>
                      <InputBox
                        className={" w-[75px] border-[1px] ml-[19px] pr-2 rounded text-right " +
                          (tempHindoKaisuSettingObj.hindoSetting.toukankaku === true ? "border-[#00000099] focus:outline-[#0073CD] outline-0" : "outline-none w-[75px] ml-[19px] border-[#C8C6C4]")}
                        textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                        id="txtTokankaku"
                        maxLength={5}
                        value={tempHindoKaisuSettingObj.hindoSetting.toukankakuData === 0 ? "" : tempHindoKaisuSettingObj.hindoSetting.toukankakuData}
                        onChange={onTxtToKanKakuChange}
                        onKeyUp={(e, str) => handleToKanKaKuKeyUp(e, str)}
                        onFocusOut={(txt) => toKanKaKuInputFilter(txt)}
                        disabled={tempHindoKaisuSettingObj.hindoSetting.toukankaku === false}
                      />
                      <label className={"ml-2 text-[16px] " + (tempHindoKaisuSettingObj.hindoSetting.toukankaku === true ? "" : "text-[#A19F9D]")} >毎に表示</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[245px] ml-2">
                <input
                  type="radio"
                  id="rdoKaisu"
                  name="HindoKaisuSetting"
                  className='w-[14px] h-[14px] border-2'
                  checked={tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Kaisu}
                  onChange={() => handleHindoKaisuChange(HindoKaisuSetting.Kaisu)}
                />
                <label htmlFor="rdoKaisu" className="pl-1 text-[18px]">
                  回数
                </label>
                <div className="ml-4 text-[14px]">
                  バッチサイズを基準に<br></br>管理項目を実行する回数を設定
                </div>
                <div className="w-[195px] ml-4 my-2">
                  <input
                    type="radio"
                    id="rdoBatchSize"
                    name="radioKaisu"
                    className='w-[14px] h-[14px] border-2'
                    checked={tempHindoKaisuSettingObj.kaisuSetting.batchSize}
                    onChange={() => handleKaisuRadioChange(KaisuSetting.BatchSize)}
                    disabled={tempHindoKaisuSettingObj.hindoKaisuSettingType !== HindoKaisuSetting.Kaisu}
                  />
                  <label htmlFor="rdoBatchSize" className={"pl-1 text-[16px] " + (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Kaisu ? "" : "text-[#A19F9D]")}>
                    バッチサイズ全数
                  </label>
                </div>
                <div className="w-[95px] ml-4 my-2">
                  <input
                    type="radio"
                    id="rdoKotei"
                    name="radioKaisu"
                    className='w-[14px] h-[14px] border-2'
                    checked={tempHindoKaisuSettingObj.kaisuSetting.kotei}
                    onChange={() => handleKaisuRadioChange(KaisuSetting.KoTei)}
                    disabled={tempHindoKaisuSettingObj.hindoKaisuSettingType !== HindoKaisuSetting.Kaisu}
                  />
                  <label htmlFor="rdoKotei" className={"pl-1 " + (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Kaisu ? "" : "text-[#A19F9D]")}>
                    固定
                  </label>
                  <div className='flex'>
                    <InputBox
                      className={" w-[75px] border-[1px] ml-[19px] pr-2 rounded text-right " +
                        (tempHindoKaisuSettingObj.kaisuSetting.kotei === true ? "border-[#00000099] focus:outline-[#0073CD] outline-0 ml-[19px] w-[75px]" : "outline-none w-[75px] ml-[19px] border-[#C8C6C4]")}
                      textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                      id="kotei"
                      maxLength={5}
                      value={tempHindoKaisuSettingObj.kaisuSetting.koteiData === 0 ? "" : tempHindoKaisuSettingObj.kaisuSetting.koteiData}
                      onChange={onTxtKoteiChange}
                      onKeyUp={(e, str) => handleTxtKoteiKeyUp(e, str)}
                      onFocusOut={(txt) => koteiInputFilter(txt)}
                      disabled={tempHindoKaisuSettingObj.kaisuSetting.kotei === false}
                    />
                    <label className={"ml-2 text-[16px] " + (tempHindoKaisuSettingObj.kaisuSetting.kotei === true ? "" : "text-[#A19F9D]")}>回</label>
                  </div>
                </div>
                <div className="w-[95px] ml-4 my-3">
                  <input
                    type="radio"
                    id="rdoWariai"
                    name="radioKaisu"
                    className='w-[14px] h-[14px] border-2'
                    checked={tempHindoKaisuSettingObj.kaisuSetting.wariai}
                    onChange={() => handleKaisuRadioChange(KaisuSetting.Wariai)}
                    disabled={tempHindoKaisuSettingObj.hindoKaisuSettingType !== HindoKaisuSetting.Kaisu}
                  />
                  <label htmlFor="rdoWariai" className={"pl-1 text-[16px] " + (tempHindoKaisuSettingObj.hindoKaisuSettingType === HindoKaisuSetting.Kaisu ? "" : "text-[#A19F9D]")}>
                    割合
                  </label>
                  <div className='flex'>
                    <InputBox
                      className={" w-[75px] border-[1px] ml-[19px] pr-2 rounded text-right " +
                        (tempHindoKaisuSettingObj.kaisuSetting.wariai === true ? "border-[#00000099] focus:outline-[#0073CD] outline-0 ml-[19px] w-[75px]" : "outline-none w-[75px] ml-[19px] border-[#C8C6C4]")}
                      textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                      id="wariai"
                      maxLength={2}
                      value={tempHindoKaisuSettingObj.kaisuSetting.wariaiData === 0 ? "" : tempHindoKaisuSettingObj.kaisuSetting.wariaiData}
                      onChange={onTxtWariaiChange}
                      onKeyUp={(e, str) => handleTxtWariaiKeyUp(e, str)}
                      onFocusOut={(txt) => wariaiInputFilter(txt)}
                      disabled={tempHindoKaisuSettingObj.kaisuSetting.wariai === false}
                    />
                    <label className={"ml-2 text-[16px] " + (tempHindoKaisuSettingObj.kaisuSetting.wariai === true ? "" : "text-[#A19F9D]")}>%</label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {(isActiveWork() === true && isDataChanged()) ? (
              <button
                type="button"
                onClick={handleRegister}
                className="w-[62px] h-[29px] text-[14px] mt-[10px] bg-[#0073CD] text-white hover:bg-[#0068B8]"
              >
                登録
              </button>
            ) : (
              <button
                type="button"
                disabled={true}
                className={
                  "w-[62px] h-[29px] text-[14px] mt-[10px] bg-[#F3F2F1] text-[#A19F9D]"
                }
              >
                登録
              </button>
            )}
          </div>
        </div>
      </div>
      {dialogBox}
    </>
  );
}

HindoKaisuInput.defaultProps = {};

export default HindoKaisuInput;
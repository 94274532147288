import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { IconContext } from "react-icons";
import { MdOutlineClose } from "react-icons/md";
import InputNumberBox from "../../../components/InputNumberBox";
import {
  resetInitState,
  setMatomesuu,
  resetCompletedManageResult,
  fetchLotNumberWorkList
} from "../lotOperationSlice";
import { setIsLoading as setPageLoading } from "../../Loading/pageLoadingSlice";
import { OperationManagementType, WorkStatus, popupcss, variables, ManageItemType, HindoKaisuSetting } from "../../../Variables";
import {
  urlCompanyName,
  RegisterMatomesuuChangeLog,
  NavigateToOperationStartWork,
  NavigateToOperationLogin,
  NavigateToOperation,
  GetKaisuuCheckCountWithRoundUpAndDown
} from "../../util/commonFun";
import ErrorBox from "../../../components/ErrorBox";
import { errorCode } from "../../../ErrorCode";
import ConfirmBox, { FocusButton } from "../../../components/ConfirmBox";
import { appInsights } from "../../util/ApplicationInsight";
import { v4 as uuidv4 } from "uuid";

const axios = require('axios');
const btnEnable = "bg-[#0073CD] w-[92px] h-[40px] text-[18px] text-white hover:bg-[#0068B8] cursor-pointer text-center textmiddle rounded-[2px]";
const btnDisable = "bg-[#F3F2F1] w-[92px] h-[40px] text-[18px] text-black cursor-pointer text-center textmiddle rounded-[2px]";
let matomesuuBoxRefs = null;

function LotMatomesuuChange(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const items = useSelector((state) => state.lotOperation);
  const selectedWork = items.selectedWork;
  const kurikaeshiList = items.dataList.kurikaeshiList;
  const kurikaeshiLstbyWorkId = kurikaeshiList.filter((item) => item.dispWorkId.toLowerCase() === selectedWork.dispWorkId.toLowerCase());
  let completedKurikaeNo = kurikaeshiLstbyWorkId?.length ?? 0;
  const workList = items.dataList.workList;
  const currIdx = (workList.length > 0 && selectedWork.workId !== "") ? workList.findIndex((item) => item.workId.toLowerCase() === selectedWork.workId.toLowerCase()) : 0;
  const manageItemList = items.dataList.manageItemList;
  const tempIdx = manageItemList.findIndex((item) => item.workId === selectedWork.workId);
  const currManageItemType = (tempIdx === -1) ? 0 : manageItemList[tempIdx].manageItemType;
  const completedManageResult = items.completedManageResultObj.manageResult;
  const currDescription = (tempIdx === -1) ? "" : manageItemList[tempIdx].description;
  let minKeizokuSuu = (completedKurikaeNo === 0) ? 1 : completedKurikaeNo;
  const [keizokuMatomesuu, setKeizokuMatomesuu] = useState(items.matomesuu);
  const [chuudanMatomesuu, setChuudanMatomesuu] = useState(0);
  const [writeLogErrorCode, setWriteLogErrorCode] = useState(0);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isShowNextWorkDialog, setIsShowNextWorkDialog] = useState(false);
  const [isShowNotifyOfEndDialog, setIsShowNotifyOfEndDialog] = useState(false);
  const [isShowLastProcessNoticeDialog, setIsShowLastProcessNoticeDialog] = useState(false);
  const [isShowConfirmBoxByBatchSize, setIsShowNotifyOfEndDialogByBatchSize] = useState(false);
  const [isShowLastProcessNoticeDialogByBatchSize, setIsShowLastProcessNoticeDialogByBatchSize] = useState(false);
  const [nextDispProcessId, setNextDispProcessId] = useState("");
  const isLastWork = currIdx === (workList.length - 1);
  const [isShowConfirmMsg, setIsShowConfirmMsg] = useState(false);
  const [saikaiWorkDispProcessId, setSaikaiWorkDispProcessId] = useState("");
  const [saikaiWorkDispProcessOrder, setSaikaiWorkDispProcessOrder] = useState(-1);
  const [isLastWorkOfTargetListFlg, setIsLastWorkOfTargetListFlg] = useState(false);
  const [batchSizeErrLst, setBatchSizeErrLst] = useState([]);

  useEffect(() => {
    matomesuuBoxRefs.select();
  }, []);

  /**
  * バッチサイズ変更処理。
  * @param {*} value 
  */
  function handleMatomesuuChange(value) {
    setKeizokuMatomesuu(value);
    setChuudanMatomesuu(items.matomesuu - value);
    matomesuuBoxRefs.value = value;
  }

  /**
   * バッチサイズ変更のキーアップイベント
   * @param {*} e 
   */
  function handleMatomesuuKeyUp(e) {
    if (e.key === 'Enter' && isEnableChangeBtn()) {
      setIsShowConfirmMsg(true);
    }
  }

  /**
   * 変更ボタンの有効／無効フラグ
   * @returns 
   */
  const isEnableChangeBtn = () => {
    return chuudanMatomesuu > 0 && keizokuMatomesuu >= minKeizokuSuu;
  }

  /**
   * バッチサイズ変更ボタンクリック処理。 
   */
  function handleMatomesuuChangeBtnClick() {
    setIsShowConfirmMsg(true);
  }

  /**
   * バッチサイズ変更ログ登録処理。 
   */
  async function doMatomesuuChangeWork() {
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    let result = await writeMatomesuuChangeLog(WorkStatus.MATOMESUUCHANGE, selectedWork);
    if (result.isFailed === false) {
      sessionStorage.removeItem('matomesuu');
      dispatch(setMatomesuu(keizokuMatomesuu));
      if (completedKurikaeNo === keizokuMatomesuu || result.data.isLastWorkOfTargetListFlg || result.data.isNextWorkOfTargetListFlg) {
        if (isLastWork || result.data.isLastWorkOfTargetListFlg) {
          if (items.processObj.isLastProcess) {
            if (result.data.isCheckBatchSizeFlg === true) {
              setIsShowLastProcessNoticeDialogByBatchSize(true);
            }
            else {
              setIsShowLastProcessNoticeDialog(true);
            }
          }
          else if (items.processObj.notifyOfEnd) {
            if (result.data.isCheckBatchSizeFlg === true) {
              setIsShowNotifyOfEndDialogByBatchSize(true);
            }
            else {
              setIsShowNotifyOfEndDialog(true);
            }
          }
          else {
            setNextDispProcessId(result.data.nextDispProcessId);
            setIsShowNextWorkDialog(true);
          }
        }
        else {
          setIsShowNextWorkDialog(true);
        }
      }
      else {
        // バッチサイズ変更によって、対象作業一覧を取得する
        await getLotNumberWorkList();
        props.setIsMatomesuuExpand(false);
      }
    }
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  /**
   * バッチサイズ変更のログ履歴登録処理
   * @param {*} workStatusId 
   * @param {*} workObj 
   * @returns 
   */
  async function writeMatomesuuChangeLog(workStatusId, workObj) {
    let result = { isFailed: false, data: null };
    if (workObj.workId.length === 0) {
      result.isFailed = true;
      return result;
    }
    let description;
    let manageItemObj = items.dataList.manageItemList.find((item) => item.workId.toLowerCase() === workObj.workId.toLowerCase());
    if (manageItemObj) {
      description = { ...manageItemObj.description };
      description.okNgResult = "";
      description.txtInputText = "";
      description.completedHardwareId = (currManageItemType === ManageItemType.Hardware && completedManageResult !== null) ? completedManageResult.completedHardwareId : "";
      description.handleName = "";
      description.completedCount = (currManageItemType === ManageItemType.Hardware && completedManageResult !== null) ? completedManageResult.completedCount : 0;
      description.rotationTime = 0;
    }
    let kurikaeshiNo = (currDescription.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu || currDescription.hardwareKurikaeshi === true) ? completedKurikaeNo : 0;
    let kaisuuCheckCount = GetKaisuuCheckCountWithRoundUpAndDown(currManageItemType, currDescription, keizokuMatomesuu);
    let lotOperation = {
      lockKey: items.lockKey,
      lotOperationLogLockId: items.lotOperationLogLockId,
      processChartID: items.processObj.processChartID,
      processId: items.processObj.processId,
      workId: workObj.workId,
      workStatusId: workStatusId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      userId: items.selectedUser.userId,
      manageResult: JSON.stringify(manageItemObj ? description : ""),
      manageItemTypeId: manageItemObj ? manageItemObj.manageItemType : 0,
      version: items.version,
      workDispOrder: workObj.dispOrder,
      dispProcessId: items.processObj.dispProcessId,
      dispWorkId: workObj.dispWorkId,
      matomesuu: keizokuMatomesuu,
      chuudanMatomesuu: chuudanMatomesuu,
      kurikaeshiNo: kurikaeshiNo,
      isLastWork: isLastWork,
      kaisuuCheckCount: kurikaeshiNo > kaisuuCheckCount ? kurikaeshiNo : kaisuuCheckCount,
    }
    result = await RegisterMatomesuuChangeLog(lotOperation, navigate);
    if (result.data.errorCode && result.data.errorCode > 0) {
      setWriteLogErrorCode(result.data.errorCode);
    }
    else {
      setSaikaiWorkDispProcessId(result.data.saikaiWorkDispProcessId);
      setSaikaiWorkDispProcessOrder(result.data.saikaiWorkDispProcessOrder);
      setIsLastWorkOfTargetListFlg(result.data.isLastWorkOfTargetListFlg);
    }
    return result;
  }

  /**
   * バッチサイズ変更エリアを閉じる。
   */
  function onClickCloseBtn(e) {
    props.setIsMatomesuuExpand(false);
  }

  /**
   * 次の作業又は次の工程の最初作業に行く処理。
   * 
   */
  async function handleMoveNextWorkOrProcess() {
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    //最後の作業の場合、次の工程の最初作業に行く処理。
    if (isLastWork || isLastWorkOfTargetListFlg) {
      await doStartWorkOfNextProcess();
    }
    else {
      // バッチサイズ変更によって、対象作業一覧を取得する
      await getLotNumberWorkList();
      // CompleteManageResultをレセットする。
      dispatch(resetCompletedManageResult());
    }
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  /**
 * バッチサイズ変更によって、対象作業一覧を取得する
 */
  async function getLotNumberWorkList() {
    try {
      await dispatch(fetchLotNumberWorkList({ lotNo: items.lotNumber, lotOperationLogLockId: items.lotOperationLogLockId, itemCode: items.selectedItem.itemCode })).unwrap();
    } catch (error) {
      let errResponseStatus = error.message;
      // eslint-disable-next-line eqeqeq
      if (errResponseStatus == 401) {
        NavigateToOperationLogin(navigate);
      }
      else {
        appInsights.trackException({ ...error, errorFunction: "LotMatomesuuChange.getLotNumberWorkList()" });
        NavigateToOperationStartWork(navigate);
        console.error(error);
      }
    }
  }

  /**
  * 工程完了通知フラグが「OFF」の場合、次の工程の最初の作業へ行く処理
  */
  async function doStartWorkOfNextProcess() {
    if (nextDispProcessId.length === 0) {
      return;
    }
    let paramobj = {
      userId: items.selectedUser.userId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      nextDispProcessId: nextDispProcessId,
      version: items.version,
      matomesuu: keizokuMatomesuu
    }
    await axios({
      method: 'GET',
      url: variables.OPERATION_URL + "/start-work-of-next-process-by-lotno",
      params: paramobj,
      headers: { 'company': urlCompanyName },
      withCredentials: true,
    }).then(function (response) {
      let responseData = response.data;
      let err = responseData.errorCode;
      if (err === errorCode.E1009 || err === errorCode.E1010) {
        setWriteLogErrorCode(err);
      }
      else {
        dispatch(resetInitState());
        let dataObj = {
          selectedItem: items.selectedItem,
          selectedUser: items.selectedUser,
          lotNumber: items.lotNumber,
          managementType: OperationManagementType.LOTNUMBER,
          lockKey: responseData.newLockKey,
          lotOperationLogLockId: responseData.lotOperationLogLockId,
          version: items.version
        };
        NavigateToOperation(navigate, dataObj);
      }
    }).catch(function (error) {
      let errResponseStatus = error.response.status;
      if (errResponseStatus === 401) {
        NavigateToOperationLogin(navigate);
      }
      else {
        console.error(error);
        appInsights.trackTrace({ params: JSON.stringify(paramobj) });
        appInsights.trackException({ ...error, errorFunction: "LotMatomesuuChange.doStartWorkOfNextProcess()" });
      }
    });
  }

  /**
   *工程完了通知ダイアログ（ボタン2つ）で「はい」を押したときの処理
   */
  async function handleYesNotifyOfEndDialog() {
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    let paramobj = {
      userId: items.selectedUser.userId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      dispProcessId: saikaiWorkDispProcessId,
      version: items.version,
      ischeckPlanQty: saikaiWorkDispProcessOrder === 1,
      matomesuu: items.matomesuu
    }
    await axios({
      method: 'GET',
      url: variables.OPERATION_URL + "/start-work-by-lotno",
      params: paramobj,
      headers: { 'company': urlCompanyName },
      withCredentials: true,
    }).then(function (response) {
      let responseData = response.data;
      let errLst = responseData.errorCodeList;
      if (errLst.length > 0) {
        setBatchSizeErrLst(errLst);
        setIsShowNotifyOfEndDialogByBatchSize(false);
        setIsShowLastProcessNoticeDialogByBatchSize(false);
      }
      if (errLst.length === 0) {
        dispatch(resetInitState());
        let dataObj = {
          selectedItem: items.selectedItem,
          selectedUser: items.selectedUser,
          lotNumber: items.lotNumber,
          managementType: OperationManagementType.LOTNUMBER,
          lockKey: responseData.newLockKey,
          lotOperationLogLockId: responseData.lotOperationLogLockId,
          version: items.version
        };
        NavigateToOperation(navigate, dataObj);
      }
    }).catch(function (error) {
      let errResponseStatus = error.response.status;
      if (errResponseStatus === 401) {
        NavigateToOperationLogin(navigate);
      }
      else {
        appInsights.trackTrace({ params: JSON.stringify(paramobj) });
        appInsights.trackException({ ...error, errorFunction: "LotMatomesuuChange.handleYesNotifyOfEndDialog()" });
        console.error(error);
      }
    });
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  return (
    <>
      <div className={popupcss}>
        <div className="absolute grid grid-cols-1 justify-items-center top-[90px] right-2 border-[1px] w-fit border-[#707070] h-[312px] bg-[#FFFFFF] rounded-sm">
          <div className="mt-[8px] mx-[8px] flex border-b-2">
            <div className="h-[38px] font-bold text-[29px] ml-[8px] mt-[4px]">バッチサイズ 変更</div>
            <div className="cursor-pointer hover:bg-gray-200 mt-[5px] ml-[20px] h-[28px]" onClick={(e) => onClickCloseBtn(e)}>
              <IconContext.Provider value={{ size: "25px" }}>
                <MdOutlineClose />
              </IconContext.Provider>
            </div>
          </div>
          <div className="text-[22px] font-bold mt-[16px]">継続するバッチサイズ</div>
          <div className="w-[128px] h-[48px] mt-[4px] mb-[6px]">
            <InputNumberBox
              refNumericInput={(e) => { matomesuuBoxRefs = e; }}
              textPosition="text-right"
              max={items.matomesuu}
              min={0}
              height={48}
              width={128}
              fontSize={28}
              readOnly={isShowConfirmMsg}
              value={keizokuMatomesuu}
              onTextChange={handleMatomesuuChange}
              onKeyUp={handleMatomesuuKeyUp}
              onClick={() => { matomesuuBoxRefs.focus(); }}
            />
          </div>
          <div className="w-[192px] h-[1px] bg-[#C8C6C4] opacity-[.56]" />
          <div className="text-[22px] font-bold mt-[16px]">中断するバッチサイズ</div>
          <div className="w-[192px]">
            <input
              className="w-[192px] text-center font-bold text-[29px] bg-[#FFFFFF]"
              placeholder="0"
              disabled={true}
              value={chuudanMatomesuu} />
          </div>
          <div className="w-[192px] h-[1px] bg-[#C8C6C4] opacity-[.56]" />
          <div className="m-4">
            <div className={(isEnableChangeBtn() ? btnEnable : btnDisable)}
              onClick={(isEnableChangeBtn() ? (e) => handleMatomesuuChangeBtnClick(e) : void (0))}>変更</div>
          </div>
        </div>
      </div>
      {/*ログ登録エラーメッセージ表示「1009 =>工程設定の変更,1010 =>作業開始不可,1011 =>作業継続不可」*/}
      {(writeLogErrorCode > 0 || (batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1009, errorCode.E1010, errorCode.E1011].includes(err)))) && (
        <ErrorBox
          className={"absolute left-[700px] top-[365px] " + ((writeLogErrorCode === errorCode.E1009 || batchSizeErrLst.some(errCode => errCode === errorCode.E1009)) ? "w-[600px]" : (writeLogErrorCode === errorCode.E1010 || batchSizeErrLst.some(errCode => errCode === errorCode.E1010) ? "w-[830px]" : "w-[528px]"))}
          Title={(writeLogErrorCode === errorCode.E1009 || batchSizeErrLst.some(errCode => errCode === errorCode.E1009)) ? "工程設定の変更" : (writeLogErrorCode === errorCode.E1010 || batchSizeErrLst.some(errCode => errCode === errorCode.E1010)) ? "作業開始不可" : "作業継続不可"}
          Message={<>
            {errorCode[writeLogErrorCode > 0 ? writeLogErrorCode : batchSizeErrLst.find(err => [errorCode.E1009, errorCode.E1010, errorCode.E1011].includes(err))].split("\n").map((msg, idx) => (
              <div key={idx}>{msg}</div>))
            }
          </>}
          onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
        />
      )}
      {/* 工程完了通知・全作業完了ダイアログのエラーメッセージ表示
      ダイアログが表示されてから一定時間放置した間に別のユーザーが工程を完了させた場合の考慮 */}
      {(batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1007, errorCode.E1016].includes(err))) && (
        <ErrorBox
          className={"absolute left-[700px] top-[365px] w-[528px]"}
          Title={"作業継続不可"}
          Message={<>
            {"同じバッチサイズで次のバッチを作業できません。"} <br />
            &nbsp;{"原因："}
            {errorCode[batchSizeErrLst.find(err => [errorCode.E1007, errorCode.E1016].includes(err))].split("\n").map((msg, idx) => (
              <span key={idx}>{msg}</span>))
            } <br />
            {"作業開始画面に戻ります。"}
          </>}
          onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
        />
      )}
      {/* 工程完了通知・全作業完了ダイアログのエラーメッセージ表示「1003, 1004」は
      エラー発生頻度が非常に少ない && オペレーション開始画面に戻った際に確認できることからダイアログを共通化して表示する */}
      {(batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1003, errorCode.E1004].includes(err))) && (
        <ErrorBox
          className={"absolute left-[700px] top-[365px] w-[528px]"}
          Title={"作業継続不可"}
          Message={<>
            {"管理者によって作業を継続できない状況になりました。"} <br />
            {"管理者へ報告をしてください。"}
          </>}
          onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
        />
      )}
      {/* 工程完了通知・全作業完了ダイアログのエラーメッセージ表示「1005, 1006, 1015」は、
      ユーザー操作では発生しないため、例外エラーとして処理する*/}
      {(batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1005, errorCode.E1006, errorCode.E1015].includes(err))) && (
        <ErrorBox
          className={"absolute left-[700px] top-[365px] w-[528px]"}
          Title={"作業継続不可"}
          Message={<>
            {"工程情報、製品マスタ、生産計画のいずれかが登録されていません。"} <br />
            {"作業開始画面に戻ります。"}
          </>}
          onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
        />
      )}
      {isShowConfirmMsg && (
        <ConfirmBox
          className="absolute right-[13px] top-[390px] w-[450px]"
          title="バッチサイズ変更"
          message="継続するバッチサイズは正しいですか？"
          foucsBtn={FocusButton.None}
          onYesClick={isBtnDisabled ? void (0) : () => { doMatomesuuChangeWork(); setIsShowConfirmMsg(false); }}
          onNoClick={() => { setIsShowConfirmMsg(false); matomesuuBoxRefs.select() }}
        />
      )}
      {/* 次の作業又は工程完了通知フラグが「OFF」の場合 */}
      {isShowNextWorkDialog && (
        <ErrorBox
          className="absolute left-[700px] top-[365px] w-[528px]"
          Title="バッチサイズ変更"
          Message={<>
            {"継続するバッチサイズの作業は完了しています。"} <br />
            {"次の作業へ遷移します。"}
          </>}
          onYesClick={isBtnDisabled ? void (0) : () => { handleMoveNextWorkOrProcess(); setIsShowNextWorkDialog(false); }}
        />
      )}
      {/* 全作業完了（バッチサイズ >= (計画数-仕掛かり数-完了数)の場合） */}
      {isShowLastProcessNoticeDialogByBatchSize && (
        <ConfirmBox
          key={uuidv4()}
          className="absolute left-[700px] top-[365px] w-[528px]"
          title="全作業完了"
          message={<>
            {"すべての作業が完了しました。"} <br />
            {"同じバッチサイズで次のバッチを作業しますか？"}
          </>}
          btn1Width={92}
          btn2Width={180}
          btn1Text="はい"
          btn2Text="作業開始画面へ"
          onYesClick={isBtnDisabled ? void (0) : () => { handleYesNotifyOfEndDialog(); }}
          onNoClick={() => { NavigateToOperationStartWork(navigate); }}
        />
      )}
      {/* 全作業完了（バッチサイズ < (計画数-仕掛かり数-完了数)の場合） */}
      {isShowLastProcessNoticeDialog && (
        <ErrorBox
          className="absolute left-[700px] top-[365px] w-[528px]"
          Title="全作業完了"
          Message={<>
            {"すべての作業が完了しました。"} <br />
            {"作業開始画面に戻ります。"}
          </>}
          onYesClick={() => { NavigateToOperationStartWork(navigate); }}
        />
      )}
      {/* 工程完了通知フラグが「ON」&& バッチサイズが計画数あるいは前の工程の完了数以下の場合 */}
      {isShowConfirmBoxByBatchSize && (
        <ConfirmBox
          key={uuidv4()}
          className="absolute left-[700px] top-[365px] w-[528px]"
          title="工程完了"
          message={<>
            {"「工程No" + items.processObj.dispOrder + " : " + items.processObj.processName + "」"} <br />
            {"すべての作業が完了しました。"} <br />
            {"同じバッチサイズで次のバッチを作業しますか？"}
          </>}
          btn1Width={92}
          btn2Width={180}
          btn1Text="はい"
          btn2Text="作業開始画面へ"
          onYesClick={isBtnDisabled ? void (0) : () => { handleYesNotifyOfEndDialog(); }}
          onNoClick={() => { NavigateToOperationStartWork(navigate); }}
        />
      )}
      {/* 工程完了通知フラグが「ON」&& バッチサイズが計画数あるいは前の工程の完了数を上回るの場合 */}
      {isShowNotifyOfEndDialog && (
        <ErrorBox
          className="absolute left-[700px] top-[365px] w-[528px]"
          Title="工程完了"
          Message={<>
            {"「工程No" + items.processObj.dispOrder + " : " + items.processObj.processName + "」"} <br />
            {"すべての作業が完了しました。"} <br />
            {"作業開始画面に戻ります。"}
          </>}
          onYesClick={() => { NavigateToOperationStartWork(navigate); }}
        />
      )}

    </>
  )
}

export default LotMatomesuuChange;
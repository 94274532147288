import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import {
  addProcess,
  addBetweenProcess,
  selectProcessList,
  setProcessName,
  deleteProcess,
  moveProcessUpward,
  moveProcessDownward,
  setSelectedProcess,
  getSelectedProcessInfo,
  emptyProcessObj,
  setEndofNotify
} from "./processListSlice";
import AddButton from "../../../components/AddButton";
import ConfirmBox from "../../../components/ConfirmBox";
import UpwardButton from "../../../components/UpwardButton";
import DownwardButton from "../../../components/DownwardButton";
import DeleteButton from "../../../components/DeleteButton";
import {
  selectWorkListByProcessId,
  deleteWorkByProcessID,
  getAllWorkList,
} from "../work/workListSlice";
import IDShowPopUp from "../../../components/IDShowPopUp";
import { selectChartID } from "../processChartSlice";
import { selectBunkiAriToNashiWork, deleteMngItmByWorkIDList } from "../work/manageItemSlice";
import ErrorBox from "../../../components/ErrorBox";
import InputBoxWithBlur from "../../../components/InputBoxWithBlur";
import { deleteDtlDocByWorkIDList } from "../work/detailDocSlice";
import { deleteToolByWorkIDList } from "../work/toolListSlice";
import ToggleButton from "../../../components/ToggleButton";

let bunkiErrmsg = "";

function ProcessList(props) {
  const dispatch = useDispatch();
  const processChartID = useSelector(selectChartID);
  const processList = useSelector(selectProcessList);
  const selectedProcess = useSelector((state) => getSelectedProcessInfo(state));
  const workListByProcessID = useSelector((state) =>
    selectWorkListByProcessId(state, selectedProcess.processID)
  );
  const allWorkList = useSelector((state) => getAllWorkList(state));
  const [dialogBox, setDialogBox] = useState("");
  const [showIDPopUp, setshowIDPopUp] = useState(false);
  const [showBunkiAriProcessDelError, setShowBunkiAriProcessDelError] = useState(false);
  const bunkiAriToNashiWorkList = useSelector((state) => selectBunkiAriToNashiWork(state));

  function handleAddClick() {
    const newRow = {
      ...emptyProcessObj,
      processChartID: processChartID,
      processID: uuidv4(), //工程IDの為にダミーデータとして使用、後でDBから取得予定
    };

    if (processList.length > 1) {
      dispatch(
        addBetweenProcess({
          processID: selectedProcess.processID,
          newRow: newRow,
        })
      );
    } else {
      dispatch(addProcess(newRow));
    }
  }

  function onSelectedRow(idx) {
    let selectedRow = { ...processList[idx] };
    dispatch(setSelectedProcess({ processID: selectedRow.processID }));
    txtInput_proName.focus();
  }

  function handleDeleteClick() {
    if (selectedProcess && selectedProcess.processID.length > 0) {
      let isBunkiWorkAri = false;
      if (workListByProcessID && workListByProcessID.length > 0 && bunkiAriToNashiWorkList) {
        for (let i = 0; i < workListByProcessID.length; i++) {
          if (bunkiAriToNashiWorkList.bunkiMotoList.filter(l => l.workId === workListByProcessID[i].workID).length > 0
            || bunkiAriToNashiWorkList.bunkiSakiList.filter(l => l.workId === workListByProcessID[i].workID).length > 0) {
            isBunkiWorkAri = true;
            break;
          }
        }
        if (isBunkiWorkAri) {
          bunkiErrmsg = "「工程No" + (processList.findIndex(p => p.processID === selectedProcess.processID) + 1) + " : " + selectedProcess.processName + "」";
          bunkiErrmsg = (
            <>
              {bunkiErrmsg} <br /> には 分岐／文字列比較 設定をしている作業が含まれている為、削除できません。
            </>
          );
          setShowBunkiAriProcessDelError(true);
        }
      }
      if (!isBunkiWorkAri) {
        let msg = "";
        const idx = processList.findIndex(
          (w) => w.processID === selectedProcess.processID
        );
        if (selectedProcess.processName.length > 0) {
          msg =
            "「工程No" + (idx + 1) + " : " + selectedProcess.processName + "」";
        } else {
          msg = "「工程No" + (idx + 1) + "」";
        }

        msg = (
          <>
            <p className="break-all">
              {msg} <br /> を削除しますか？
            </p>
          </>
        );
        setDialogBox(
          <ConfirmBox
            className="absolute left-[520px] top-[200px] w-[471px]"
            title="工程削除"
            message={msg}
            onYesClick={() => handleDelConfirmYes(idx)}
            onNoClick={handleDelConfirmNo}
          />
        );
      }

    }
  }

  function handleDelConfirmYes(idx) {
    if (idx < 0) {
      return;
    }
    setDialogBox(null);
    dispatch(deleteProcess({ processID: selectedProcess.processID }));
    if (workListByProcessID.length > 0) {
      dispatch(
        deleteWorkByProcessID({ processID: selectedProcess.processID })
      );

      const wIDs = workListByProcessID.map(w => w.workID);
      if (wIDs && wIDs.length > 0) {
        // 作業の詳細資料削除
        dispatch(deleteDtlDocByWorkIDList(wIDs));
        // 作業の工具削除
        dispatch(deleteToolByWorkIDList(wIDs));
        // 作業の管理項目削除
        dispatch(deleteMngItmByWorkIDList(wIDs));
      }
    }
  }

  function handleDelConfirmNo() {
    setDialogBox(null);
  }

  function handleUpwardClick() {
    const idx = processList.findIndex(
      (p) => p.processID === selectedProcess.processID
    );
    let msg = "";
    let no = idx + 1;
    if (selectedProcess.processName.length > 0) {
      msg = "「工程No" + no + " : " + selectedProcess.processName + "」";
    } else {
      msg = "「工程No" + no + "」";
    }

    msg = (
      <>
        <p className="break-all">
          {msg} <br /> を上に移動しますか？
        </p>
      </>
    );
    setDialogBox(
      <ConfirmBox
        className="absolute left-[500px] top-[255px] w-[471px]"
        title="工程の移動"
        message={msg}
        onYesClick={handleUpwardConfirmYes}
        onNoClick={handleUpwardConfirmNo}
      />
    );
  }

  function handleUpwardConfirmYes() {
    setDialogBox(null);
    dispatch(
      moveProcessUpward({
        processID: selectedProcess.processID,
      })
    );
  }

  function handleUpwardConfirmNo() {
    setDialogBox(null);
  }

  function handleDownwardClick() {
    const idx = processList.findIndex(
      (p) => p.processID === selectedProcess.processID
    );
    let msg = "";
    let no = idx + 1;
    if (selectedProcess.processName.length > 0) {
      msg = "「工程No" + no + " : " + selectedProcess.processName + "」";
    } else {
      msg = "「工程No" + no + "」";
    }

    msg = (
      <>
        <p className="break-all">
          {msg} <br /> を下に移動しますか？
        </p>
      </>
    );
    setDialogBox(
      <ConfirmBox
        className="absolute left-[500px] top-[255px] w-[471px]"
        title="工程の移動"
        message={msg}
        onYesClick={handleDownwardConfirmYes}
        onNoClick={handleDownwardConfirmNo}
      />
    );
  }

  function handleDownwardConfirmYes() {
    setDialogBox(null);
    dispatch(
      moveProcessDownward({
        processID: selectedProcess.processID,
      })
    );
  }

  function handleDownwardConfirmNo() {
    setDialogBox(null);
  }

  function handleIDClick(e) {
    setshowIDPopUp(true);
  }

  function onCopyIDClick(dispID) {
    navigator.clipboard.writeText(dispID);
    setshowIDPopUp(false);
  }

  function closeIDShowPopUp(e) {
    setshowIDPopUp(false);
  }

  function onBlurProcessName(text) {
    dispatch(
      setProcessName({
        processID: selectedProcess.processID,
        processName: text,
      })
    );
  }

  function isAllworkLocked(procID) {
    if (allWorkList && allWorkList.length > 0 && procID && procID.length > 0) {
      // 対象工程の作業リスト
      const filteredWork = allWorkList.filter(w => w.processID.toUpperCase() === procID.toUpperCase());
      if (filteredWork && filteredWork.length > 0) {
        return filteredWork.findIndex(w => w.isLock === false) < 0;
      }
    }
    return false;
  }

  function processEndNotiToggleChange() {
    dispatch(
      setEndofNotify({
        processID: selectedProcess.processID,
        notifyOfEnd: !selectedProcess.notifyOfEnd,
      }))
  }

  let txtInput_proName = null;
  const setInputRef = element => {
    txtInput_proName = element;
  };

  return (
    <div>
      {/* 左側のメニュー */}
      <div className="w-fit">
        <div className="font-bold text-[24px] text-[#000000] px-[15px] pt-[15px]">
          工程リスト
        </div>
        <div className="inline-flex h-[42px] w-fit px-[13px] py-[10px] mb-[13px]">
          <AddButton
            className="w-[80px] h-[45px]"
            onClick={handleAddClick}
            isEnable={processList.length < 50}
            Text="追加"
          />
          <DeleteButton
            className="w-[80px] h-[45px]"
            onClick={handleDeleteClick}
            isEnable={selectedProcess && selectedProcess.processID.length > 0}
            Text="削除"
          />
          <UpwardButton
            className="h-[45px]"
            onClick={handleUpwardClick}
            isEnable={
              processList.length > 0 &&
              processList[0].processID !== selectedProcess.processID
            }
            Text="上に移動"
          />
          <DownwardButton
            className="h-[45px]"
            onClick={handleDownwardClick}
            isEnable={
              processList.length > 0 &&
              processList.at(-1).processID !== selectedProcess.processID
            }
            Text="下に移動"
          />
        </div>
        {/* 工程入力スコープ */}
        <div className="w-[504px] h-[82px] ml-[1px] p-[9px] border-2 border-[#C8C6C4] bg-white border-b-0">
          <div className="w-[48px] mt-[5px]">
            <label className="font-bold text-[24px] text-[#000000]">
              工程
            </label>

            <button
              type="button"
              onClick={handleIDClick}
              disabled={processList.length === 0}
              className={
                "block font-semibold mx-4 text-opacity-100 " +
                (processList.length !== 0
                  ? " hover:underline text-[#0073CD]"
                  : "text-[#A19F9D]")
              }
            >
              ID
            </button>
          </div>
          <div className="absolute left-24 top-[165px] w-[40px]">
            <label className="absolute left-13 -top-2 font-bold text-[24px]">
              {processList.length > 0
                ? processList.findIndex(
                  (p) => p.processID === selectedProcess.processID
                ) + 1
                : ""}
            </label>
            <div className="absolute left-8 -top-3 block w-[380px] h-[32px]">
              <InputBoxWithBlur
                key={selectedProcess.processID}
                isEnable={processList && processList.length > 0}
                setInputRef={setInputRef}
                text={selectedProcess.processName}
                maxLength={25}
                onBlur={onBlurProcessName}
              />
            </div>
            <div className="inline absolute left-[410px] -top-2">
              <span className="text-3xl font-bold text-red-500">*</span>
            </div>
            <div className="absolute left-8 top-7 block w-[84px] h-[19px] text-[14px] text-[#000000]">工程完了通知</div>
            <div className="inline absolute left-[120px] top-7 mx-1">
              <ToggleButton
                isChecked={selectedProcess.notifyOfEnd}
                onChange={processEndNotiToggleChange}
                inputID={"processEndNoti"}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[504px] h-[230px] ml-[1px] border-2 border-[#C8C6C4] bg-white">
          <div className="h-[230px] ml-[10px] overflow-y-scroll overflow-x-hidden">
            <div className="sticky top-0 w-[475px] h-[29px] text-left border-b-2 bg-white">
              <span className="font-bold">状態</span>
              <span className="font-bold ml-[18px]">No</span>
              <span className="font-bold ml-[18px]">工程名</span>
            </div>
            <table className="border-collapse w-full">
              <tbody>
                {processList &&
                  processList.map((rowItem, idx) => (
                    <tr
                      className={
                        "h-[48px] w-[461px] border-b text-left" +
                        (rowItem.processID === selectedProcess.processID
                          ? " bg-[#D8D7D5] "
                          : " ")
                      }
                      key={idx}
                      onClick={() => onSelectedRow(idx)}
                    >
                      {/* <td>作業Noを表示処理を追加予定</td> */}
                      <td className="bg-white w-[40px]">
                        {isAllworkLocked(rowItem.processID) ? <BsFillCheckCircleFill /> : ""}
                      </td>
                      <td className="pl-2 w-[50px]">{idx + 1}</td>
                      <td className="ellipse one-line pt-3 whitespace-nowrap">{rowItem.processName}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {/* {ConfirmDialogBox} */}
          {dialogBox}
        </div>
      </div>
      {showIDPopUp && (
        <IDShowPopUp
          Text="工程ID"
          DisplayID={selectedProcess.dispProcessID}
          onCopy={onCopyIDClick}
          onContainerDivClick={closeIDShowPopUp}
          className="absolute left-[48px] top-[227px] w-[210px] h-[52px] border-[1px] border-[#050505] bg-[#F8F8F9]"
        />
      )}
      {
        showBunkiAriProcessDelError && (
          <ErrorBox
            className="absolute left-[530px] top-[400px] w-[528px]"
            Title="工程削除"
            Message={bunkiErrmsg}
            onYesClick={() => setShowBunkiAriProcessDelError(false)}
          />
        )
      }
    </div>
  );
}

export default ProcessList;

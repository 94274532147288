import { React, useRef, useState } from "react";
import SectionTitle from "../../../components/SectionTitle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import ErrorBox from "../../../components/ErrorBox";
import ReadOnlyTextBox from "../../../components/ReadOnlyTextBox";
import sha256 from "crypto-js/sha256";
import { popupcss, variables } from "../../../Variables";
import { FileUpload, NavigateToCompanyLogin } from "../../util/commonFun";
import SpinnerLoading from "../../../components/SpinnerLoading"
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router-dom';
import { appInsights } from "../../util/ApplicationInsight";

// 最大ファイルサイズ： 10 MB
const maxFileSize = 10;

let fileHashCode = "";
let file = null;
let errMsg = "";

/**
 * 画像登録ボックス
 * @param {*} props
 * @returns
 */
function ImageInputBox(props) {
  const inputFile = useRef(null);
  let navigate = useNavigate();
  // 画像ファイル名
  const [fileName, setFileName] = useState(props.fileName);
  const [showError, setShowError] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(null);

  function handleTextBoxClick() {
    // <input type="file" /> のクリックイベントを発生させる。
    inputFile.current.click();
  }

  /**
   * ファイルのハッシュ値を出す。
   * ファイルのコンテンツを読取り完了時のイベントハンドラ。
   * @param {*} event
   */
  function readFile(event) {
    fileHashCode = sha256(event.target.result).toString();
  }

  // Initializing the file reader
  const fReader = new FileReader();
  fReader.onload = (e) => {
    readFile(e);
  };

  function handleInputFileChange(e) {
    if (e.target.files.length > 0) {
      const fileSize = e.target.files[0].size / 1024 / 1024;
      //ファイルの拡張子を取る
      const fileExtension = "." + e.target.files[0].name.split(".").pop();
      let allowedFileExtensions = [".jpg", ".jpeg", ".jfif", ".png"];
      if (allowedFileExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
        // ファイル形式がjpg,jpeg,jfif,png以外の場合エラーメッセージを出す
        errMsg = (
          <>
            選択したファイルは登録できません。
            <br />
            登録できるファイルは「jpg」、「jpeg」、「jfif」、「png」です。
          </>
        );
        setShowError(true);
        e.target.value = "";
        file = null;
        setFileName("");
      }
      else if (fileSize > maxFileSize) {
        // ファイルサイズ　10 MB を超える場合エラーメッセージを出す。
        errMsg = "ファイル容量制限 10MB を超えています。";
        setShowError(true);
        e.target.value = "";
        file = null;
        setFileName("");
      } else {
        file = e.target.files[0];
        fReader.readAsBinaryString(file);
        setFileName(file.name);
      }
    }
  }

  async function handleRegister() {
    if (file) {
      let isSuccessFileUpload = true;
      setSpinnerLoading(<SpinnerLoading />);
      const ext = '.' + file.name.split('.').pop();
      let name = uuidv4() + ext;
      let objUrl = null;
      try {
        // ファイルのアップロード
        objUrl = await FileUpload(file, name);
      } catch (error) {
        isSuccessFileUpload = false;
        if (error === 401) {
          NavigateToCompanyLogin(navigate);
        }
        else {
          errMsg = "登録に失敗しました。";
          setShowError(true);
          setFileName("");
          file = null;
          inputFile.current.value = "";
          appInsights.trackException({ ...error, errorFunction: "ImageInputBox.handleRegister()" });
        }
      } finally {
        setSpinnerLoading(null);
      }
      if (isSuccessFileUpload) {
        props.onRegister(objUrl, fileHashCode, name);
      }
    }
  }

  return (
    <div className={popupcss}>
      <div className="w-0 h-0 opacity-0">
        <input
          className="w-0 h-0 opacity-0"
          type="file"
          ref={inputFile}
          onChange={handleInputFileChange}
          accept=".jpg, .jpeg, .jfif, .png"
        />
      </div>

      <div className={props.className}>
        <div className="w-[512px] h-[1033px] p-[15px] mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white sm-rounded">
          <div className="grid grid-cols-2">
            <SectionTitle Text="画像ファイル登録" className="text-[18px]" />
            <div className="justify-self-end">
              <button onClick={props.onClose}>
                <IoMdClose className={variables.HOVER_CSS} />
              </button>
            </div>
          </div>
          <div className="my-[15px]">ファイル選択</div>
          <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />
          <div className="my-[15px]">
            <ReadOnlyTextBox text={fileName} onClick={handleTextBoxClick} />
          </div>
          <div className="mb-[15px]">
            ファイルが登録済みの場合は上書きされます
          </div>
          <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />
          <div className="my-[15px]">
            {fileName && fileName.length > 0 ? (
              <button
                type="button"
                onClick={handleRegister}
                className="w-[73px] h-[25px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] sm-rounded"
              >
                登録
              </button>
            ) : (
              <button
                type="button"
                disabled="disabled"
                className={
                  "w-[73px] h-[25px] mr-[7px] text-white border-[1px] border-[#A19F9D] border-solid sm-rounded bg-[#A19F9D]"
                }
              >
                登録
              </button>
            )}
          </div>
        </div>
      </div>

      {/* サイズ制限とファイルタイプエラーボックス */}
      {showError && (
        <ErrorBox
          className="absolute right-[162px] top-[265px] w-[550px]"
          Title="ファイル登録"
          Message={errMsg}
          onYesClick={() => setShowError(false)}
        />
      )}
      {/* Loading... */}
      {spinnerLoading}
    </div>
  );
}

export default ImageInputBox;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ChuuDanComfirmBox from "../../../components/ChuuDanComfirmBox";
import { WorkStatus, OperationManagementType, ManageItemType } from "../../../Variables";
import { resetInitState, setPauseExpandMode } from "../lotOperationSlice";
import {
    NavigateToOperationStartWork,
    NavigateToOperation,
    RegisterSerialOperationLog
} from "../../util/commonFun";
import ChuuDanMessage from "../../../components/ChuuDanMessage";
import { setConnectionLost, getConnectionStatus } from "../SerialPortReaderSlice";
import { setPauseConfirmMsg, setShowTeishiInput } from "../serialOperationSlice";

function SerialNumberSaikai(props) {
    const dispatch = useDispatch();
    const items = useSelector((state) => state.serialOperation);
    const isConnectionLost = useSelector(getConnectionStatus);
    const selectedWork = items.selectedWork;
    const selectedWorkId = selectedWork.workId;
    const manageItemList = items.dataList.manageItemList;
    const tempIdx = manageItemList.findIndex((item) => item.workId === selectedWork.workId);
    const currManageItemType = (tempIdx === -1) ? 0 : manageItemList[tempIdx].manageItemType;
    const completedManageResult = items.completedManageResultObj.manageResult;
    const completedManageResultworkId = items.completedManageResultObj.workId;
    const completedCount = (currManageItemType === ManageItemType.Hardware && selectedWorkId === completedManageResultworkId
        && completedManageResult !== null) ? completedManageResult.completedCount : 0;
    const completedHardwareId = (currManageItemType === ManageItemType.Hardware && completedManageResultworkId === selectedWorkId
        && completedManageResult !== null) ? completedManageResult.completedHardwareId : "";
    const completedHardware = (currManageItemType === ManageItemType.Hardware && completedManageResultworkId === selectedWorkId
        && completedManageResult !== null && completedHardwareId !== "") ? completedManageResult.hardwareData.find(itm => itm.hardwareId.toLowerCase() === completedHardwareId.toLowerCase()) : null;
    const registerCount = (currManageItemType === ManageItemType.Hardware && completedManageResultworkId === selectedWorkId
        && completedHardware) ? completedHardware.count : 0;
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    let navigate = useNavigate();
    /**
     * 作業再開ボタンの操作
     */
    async function handleRestartWork() {
        setIsBtnDisabled(true);
        let result = await writeSerialOperationLog(WorkStatus.RESUMEWORK, selectedWork);
        if (result.isFailed === false) {
            dispatch(setPauseConfirmMsg(false));
            if (result.data.newLockKey.length > 0) {
                dispatch(resetInitState());
                let dataObj = {
                    selectedItem: items.selectedItem,
                    selectedUser: items.selectedUser,
                    serialNumber: items.serialNumber,
                    managementType: OperationManagementType.SERIALNUMBER,
                    lockKey: result.data.newLockKey,
                    serialOperationLogLockId: items.serialOperationLogLockId,
                    version: items.version
                };
                NavigateToOperation(navigate, dataObj);
            }
        }
        setIsBtnDisabled(false);
    }

    /**
     * 作業開始画面へボタンの操作
     */
    async function handleBackOperationStartWork() {
        setIsBtnDisabled(true);
        dispatch(setPauseExpandMode(false));
        dispatch(setPauseConfirmMsg(false));
        NavigateToOperationStartWork(navigate);
        setIsBtnDisabled(false);
        if (isConnectionLost) {
            dispatch(setConnectionLost(false));
        }
    }

    function handleSerialTeishiInput() {
        dispatch(setPauseConfirmMsg(false));
        dispatch(setShowTeishiInput(true));
    }

    /**
     * シリアルオペレーションのログ履歴登録処理
     * @param {*} workStatusId 
     * @param {*} workObj 
     * @returns 
     */
    async function writeSerialOperationLog(workStatusId, workObj) {
        let result = { isFailed: false, data: null };
        if (workObj.workId.length === 0) {
            result.isFailed = true;
            return result;
        }
        let description;
        const index = items.dataList.workList.length > 0 ? items.dataList.workList.findIndex((item) => item.workId.toLowerCase() === workObj.workId.toLowerCase()) : 0;
        let manageItemObj = items.dataList.manageItemList.find((item) => item.workId.toLowerCase() === workObj.workId.toLowerCase());
        if (manageItemObj) {
            description = { ...manageItemObj.description };
            description.okNgResult = "";
            description.txtInputText = "";
            description.completedHardwareId = (currManageItemType === ManageItemType.Hardware) ? completedHardwareId : "";
            description.handleName = "";
            description.completedCount = (currManageItemType === ManageItemType.Hardware && registerCount === completedCount) ? completedCount : 0;
            description.rotationTime = 0;
        }
        let serialOperation = {
            lockKey: items.lockKey,
            serialOperationLogLockId: items.serialOperationLogLockId,
            processChartID: items.processObj.processChartID,
            processId: items.processObj.processId,
            workId: workObj.workId,
            workStatusId: workStatusId,
            itemCode: items.selectedItem.itemCode,
            serialNo: items.serialNumber,
            userId: items.selectedUser.userId,
            manageResult: JSON.stringify(manageItemObj ? description : ""),
            manageItemTypeId: manageItemObj ? manageItemObj.manageItemType : 0,
            version: items.version,
            workDispOrder: index + 1,
            dispProcessId: items.processObj.dispProcessId,
            dispWorkId: workObj.dispWorkId,
        }
        result = await RegisterSerialOperationLog(serialOperation, navigate);
        if (result.data && result.data.errorCode > 0) {
            props.setWriteLogErrorCode(result.data.errorCode);
            dispatch(setPauseConfirmMsg(false));
            dispatch(setPauseExpandMode(false));
        }
        return result;
    }

    return (
        <ChuuDanComfirmBox
            className="absolute left-[700px] top-[160px] w-[388px] h-[604px]"
            message={<ChuuDanMessage
                itemName={items.selectedItem.itemName}
                version={items.version}
                serialLotNumber={items.serialNumber}
                userName={items.selectedUser.userName} />}
            onSaikaiClick={isBtnDisabled ? void (0) : () => handleRestartWork()}
            onStartScreenClick={isBtnDisabled ? void (0) : () => handleBackOperationStartWork()}
            onSettingFormClick={isBtnDisabled ? void (0) : () => handleSerialTeishiInput()}
        />
    )
}
export default SerialNumberSaikai;
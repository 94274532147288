import ToggleButton from "./ToggleButton";
import { BsGear } from "@react-icons/all-files/bs/BsGear";
import { IconContext } from "@react-icons/all-files";
import { nanoid } from "nanoid";

function KurikaeshiToggle(props){
  return(
    <div className="flex flex-row">
      <div className="mt-2">
        <IconContext.Provider value={{ size: "15px" }}>
          <BsGear />
        </IconContext.Provider>
      </div>
      <div className={"mx-1 mt-1 font-bold"}>
        頻度/回数設定
      </div>
      <div className="mt-1">
        <ToggleButton
          isChecked={props.isChecked}
          onChange={props.onChange}
          inputID={props.inputID.length === 0 ? nanoid() : props.inputID}
          isLock={props.isLock}
        />
      </div>
    </div>
  );
}
KurikaeshiToggle.defaultProps={
  isChecked: false,
  onChange: void(0),
  inputID: "",
  isLock: false,
};
export default KurikaeshiToggle;
import { LockAttributes, TextType } from "../Variables";

function InputBox(props) {
    function onChange(e) {
        const str = e.target.value;
        const textTypeWithoutChecking = [TextType.ALL, TextType.HALFWIDTH_SYMBOL_AUTOCHANGE, TextType.HALFWIDTH_NUMBER_AUTOCHANGE, TextType.HALFWIDTH_HANDLE_NAME_AUTOCHANGE];
        if (props.textType === TextType.HALFWIDTH_INPUT) {
            if (str.match(/^[A-Za-z0-9]*$/)) {
                props.onChange(e);
            }
        } else if (props.textType === TextType.HALFWIDTH_NUMBER) {
            if (str.match(/^[0-9]*$/)) {
                props.onChange(e);
            }
        } else if (props.textType === TextType.HALFWIDTH_SYMBOL_INPUT) {
            if (str.match(/^[A-Za-z0-9#_-]*$/)) {
                props.onChange(e);
            }
        } else if (textTypeWithoutChecking.some((textType) => textType === props.textType)) {
            props.onChange(e);
        }
    }

    function onKeyUp(e) {
        let str = e.target.value;
        if (props.textType === TextType.HALFWIDTH_SYMBOL_AUTOCHANGE) {
            const hankakuStr = convertFullWidthToHalfWidth(str);
            str = textFilter(hankakuStr);
        }
        if (props.textType === TextType.HALFWIDTH_NUMBER_AUTOCHANGE) {
            str = str.replace(/[０-９]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
            });
        }
        if (props.textType === TextType.HALFWIDTH_HANDLE_NAME_AUTOCHANGE) {
            const hankakuHandleNameStr = convertFullWidthToHalfWidthHandleName(str);
            str = handleNameFilter(hankakuHandleNameStr);
        }
        props.onKeyUp(e, str);
    }

    function onFocusOut(e) {
        let str = e.target.value;
        if (props.textType === TextType.HALFWIDTH_SYMBOL_AUTOCHANGE) {
            const hankakuStr = convertFullWidthToHalfWidth(str);
            str = textFilter(hankakuStr);
        }
        if (props.textType === TextType.HALFWIDTH_NUMBER_AUTOCHANGE) {
            str = str.replace(/[０-９]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
            });
        }
        if (props.textType === TextType.HALFWIDTH_HANDLE_NAME_AUTOCHANGE) {
            const hankakuHandleNameStr = convertFullWidthToHalfWidthHandleName(str);
            str = handleNameFilter(hankakuHandleNameStr);
        }
        props.onFocusOut(str);
    }

    function convertFullWidthToHalfWidth(val) {
        var s = val;
        s = s.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
        s = s.replace(/[＃]/g, '#');
        s = s.replace(/[♯]/g, '#');
        s = s.replace(/[#]/g, '#');
        s = s.replace(/[＿]/g, '_');
        s = s.replace(/[ー]/g, '-');
        s = s.replace(/[—]/g, '-');
        s = s.replace(/[－]/g, '-');
        s = s.replace(/[―]/g, '-');
        s = s.replace(/[₋]/g, '-');
        s = s.replace(/[‐]/g, '-');
        return s;
    }

    function textFilter(str) {
        let value = "";
        for (let i = 0; i < str.length; i++) {
            if (str[i].match(/^[A-Za-z0-9#_-]*$/)) {
                value += str[i];
            }
        }
        return value;
    }

    function convertFullWidthToHalfWidthHandleName(val) {
        var s = val;
        s = s.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
        s = s.replace(/[／]/g, '/');
        s = s.replace(/[＿]/g, '_');
        s = s.replace(/[ー]/g, '-');
        s = s.replace(/[—]/g, '-');
        s = s.replace(/[－]/g, '-');
        s = s.replace(/[―]/g, '-');
        s = s.replace(/[₋]/g, '-');
        s = s.replace(/[‐]/g, '-');
        return s;
    }

    function handleNameFilter(str) {
        let value = "";
        for (let i = 0; i < str.length; i++) {
            if (str[i].match(/^[A-Za-z0-9_\-/]*$/)) {
                value += str[i];
            }
        }
        return value;
    }

    return (
        <>
            <input
                ref={props.refInputBox}
                value={props.value}
                maxLength={props.maxLength}
                onChange={(e) => onChange(e)}
                onKeyUp={(e) => onKeyUp(e)}
                onBlur={(e) => onFocusOut(e)}
                className={(props.isError ? "focus:border-[#CD0000] border-[#CD0000] " :
                    (props.disabled ? LockAttributes.ATTRIBUTES : "focus:border-[#0073CD] border-[#C8C6C4] ")) +
                    props.className + " placeholder-[#71717a] placeholder:font-normal"}
                placeholder={props.placeholder}
                type="text" autoComplete="off"
                readOnly={props.disabled}></input>
        </>
    );
}

InputBox.defaultProps = {
    refInputBox: null,
    placeholder: "",
    isError: false,
    maxLength: 50,
    value: "",
    textType: TextType.ALL,
    className: "focus:outline-none border-2 w-[600px] h-[65px] px-2 text-[22px] font-bold text-ellipsis rounded-[2px]",
    disabled: false,
    onChange: () => void (0),
    onKeyUp: () => void (0),
    onFocusOut: () => void (0),
}

export default InputBox;
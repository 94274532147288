import { IconContext } from "react-icons";
import { ImCheckmark } from "react-icons/im";
function CheckMarkIcon(props) {
    return (
        <>
            <IconContext.Provider value={{ size: props.size + "px" }}>
                <ImCheckmark />
            </IconContext.Provider>
        </>
    );
}

CheckMarkIcon.defaultProps = {
    size: 18,
}
export default CheckMarkIcon;
import { React, useState } from "react";
import SectionTitle from "../../../components/SectionTitle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import { popupcss, variables } from "../../../Variables";
import ConfirmBox from "../../../components/ConfirmBox"

let initialText = "";

/**
 * テキスト登録ボックス。
 * @param {*} props 
 * @returns 
 */
function TextInputBox(props) {
    // text string
    initialText = props.text;
    const [inputText, setInputText] = useState(props.text);
    const [showConfirmBox, setShowConfirmBox] = useState(false);

    /**
       * 登録ボタンの有効無効判断をする。
       * return true: テキストエリアに文字数が1以上で、変更された場合.
       * @returns 
       */
    function isEnableRegiserButton() {
        if (inputText && inputText.length > 0 && initialText !== inputText) {
            return true;
        } else {
            return false;
        }
    }

    function handleClose() {
        if (isEnableRegiserButton()) {
            // 破棄確認メッセージを表示する。
            setShowConfirmBox(true);
        } else {
            props.onClose();
        }
    }

    function handleInputTextChange(e) {
        setInputText(e.target.value);
    }

    function handleRegister() {
        props.onRegister(inputText);
    }

    return (
        <div className={popupcss} >
            <div className={props.className}>
                <div className='w-[512px] h-[1033px] p-[15px] mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white sm-rounded'>
                    <div className="grid grid-cols-2">
                        <SectionTitle Text="詳細テキスト入力" className='text-[18px]' />
                        <div className="justify-self-end">
                            <button onClick={handleClose}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div>
                    <div className="my-[15px]">
                        1000文字以内でテキストを入力（改行可能）
                    </div>
                    <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />

                    <div className="my-[15px]">
                        <textarea
                            value={inputText}
                            onChange={handleInputTextChange}
                            maxLength={1000}
                            className="w-[472px] h-[450px] pl-[5px] resize-none border-2 border-[#C8C6C4] outline-offset-0 focus:outline-[#0073CD]"
                        />
                    </div>

                    <div className="h-[1px] bg-[#C8C6C4] opacity-[.56]" />
                    <div className="my-[15px]">
                        {
                            (isEnableRegiserButton()) ?
                                <button
                                    type='button'
                                    onClick={handleRegister}
                                    className='w-[73px] h-[25px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] sm-rounded'
                                >
                                    登録
                                </button>
                                :
                                <button
                                    type='button'
                                    disabled="disabled"
                                    className={'w-[73px] h-[25px] mr-[7px] text-white border-[1px] border-[#A19F9D] border-solid sm-rounded bg-[#A19F9D]'}
                                >
                                    登録
                                </button>
                        }
                    </div>
                </div>
            </div>
            {
                showConfirmBox && <ConfirmBox
                    className="absolute left-[1425px] top-[96px] w-[471px]"
                    title="変更の破棄" message={"作業が破棄されます。よろしいですか？"}
                    onYesClick={props.onClose} onNoClick={() => setShowConfirmBox(false)} />
            }
        </div>
    );
}

export default TextInputBox;
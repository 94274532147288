import React from "react";
import SectionTitle from "../../components/SectionTitle";
import { variables, CompanyLoginPath } from "../../Variables";
import Collapsible from "../../components/Collapsible";
import { IconContext } from "react-icons";
import { BsThreeDots } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import ProcessChartEditMenu from "../../components/ProcessChartEditMenu";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { ToLocalDateTime, urlCompanyName, IsEditing } from "../util/commonFun";
import ProcessChartCopy from "../../components/ProcessChartCopy";
import ConfirmBox from "../../components/ConfirmBox";
import { appInsights } from "../util/ApplicationInsight";
import SearchBox from "../../components/SearchBox";
import { ProcessChartTimeSetting } from "../../components/ProcessChartTimeSetting";

const axios = require('axios');
const processChartID = uuidv4();

const initialState = {
    categories: [],
    items: [],
    processChartDatas: [],
    editBtn: { id: -1, btn: null },
    isRedirectToCompanyLogin: false,
    copyLayout: null,
    isNavigateToEditor: false,
    processChartObj: null,
    dialogBox: null,
    expandCateCode: -1,
    expandItemCode: -1,
    bindDataCategories: [],
    bindDataItems: [],
    categoryFilterText: "",
    itemCodeFilterText: "",
    itemNameFilterText: "",
    timeSettingLayout: null,
};

class ProcessChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.refreshList = this.refreshList.bind(this);
        this.getitemdata = this.getitemdata.bind(this);
        this.clearEditButton = this.clearEditButton.bind(this);
        this.getProcessChartData = this.getProcessChartData.bind(this);
        this.handleThreeDotsClick = this.handleThreeDotsClick.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    componentDidMount() {
        this.getitemdata();
        this.getProcessChartData();
        this.refreshList();
    }

    redirect() {
        this.setState({
            isRedirectToCompanyLogin: true
        });
    }

    //カテゴリのDataを取得する。
    refreshList() {
        axios({
            method: 'GET',
            url: variables.CATEGORY_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(
            response => {
                let data = response.data;
                data.sort((a, b) => a.name.localeCompare(b.name));
                let bindDataItems = this.state.items;
                let bindDataCategories = data;
                if (this.state.itemCodeFilterText.length > 0) {
                    // 製品コードテキストボックスの入力テキストでフィルタリングする
                    bindDataItems = bindDataItems.filter(item => item.displayItemCode.toString().toLowerCase().includes(this.state.itemCodeFilterText.toString().toLowerCase()));
                    // 現在のフィルタリングした製品一覧によってカテゴリーリストを変更する
                    bindDataCategories = bindDataCategories.filter(cate => bindDataItems.some(item => item.categoryId === cate.id));
                }
                if (this.state.itemNameFilterText.length > 0) {
                    // 製品名テキストボックスの入力テキストでフィルタリングする
                    bindDataItems = bindDataItems.filter(item => item.itemName.toString().toLowerCase().includes(this.state.itemNameFilterText.toString().toLowerCase()));
                    // 現在のフィルタリングした製品一覧によってカテゴリーリストを変更する
                    bindDataCategories = bindDataCategories.filter(cate => bindDataItems.some(item => item.categoryId === cate.id));
                }
                if (this.state.categoryFilterText.length > 0) {
                    // カテゴリーテキストボックスの入力テキストでフィルタリングする             
                    bindDataCategories = bindDataCategories.filter(cate => cate.name.toString().toLowerCase().includes(this.state.categoryFilterText.toString().toLowerCase()));
                }
                this.setState((prStat) => ({
                    ...prStat,
                    categories: data,
                    bindDataCategories: bindDataCategories,
                }));
            }).catch((error) => {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    this.redirect();
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChart.refreshList()" });
                }
            });
    }

    // 編集ボタンをクリアする。
    clearEditButton() {
        this.setState({
            editBtn: { id: -1, btn: null }
        });
    }

    //製品のDataを取得する。
    getitemdata() {
        axios({
            method: 'GET',
            url: variables.ITEM_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(
            response => {
                let data = response.data.filter(itm => itm.isEnd === false);
                let bindDataItems = data;
                if (this.state.itemCodeFilterText.length > 0) {
                    // 製品コードテキストボックスの入力テキストでフィルタリングする
                    bindDataItems = bindDataItems.filter(item => item.displayItemCode.toString().toLowerCase().includes(this.state.itemCodeFilterText.toString().toLowerCase()));
                }
                if (this.state.itemNameFilterText.length > 0) {
                    // 製品名テキストボックスの入力テキストでフィルタリングする
                    bindDataItems = bindDataItems.filter(item => item.itemName.toString().toLowerCase().includes(this.state.itemNameFilterText.toString().toLowerCase()));
                }
                this.setState((prevState) => ({
                    ...prevState,
                    items: data,
                    bindDataItems: bindDataItems,
                }));
            }).catch((error) => {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    this.redirect();
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChart.getitemdata()" });
                }
            });
    }

    //Process Chart Dataを取得する。
    getProcessChartData() {
        axios({
            method: 'GET',
            url: variables.PROCESS_CHART_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(
            response => {
                let data = response.data;
                this.setState((prState) => ({
                    ...prState,
                    processChartDatas: data,
                }
                ));
            }).catch((error) => {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    this.redirect();
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChart.getProcessChartData()" });
                }
            });
    }

    // 「…」を押す時のイベントハンドラ
    handleThreeDotsClick(processChartId, itemName, itemCode, chartVersion, displayItemCode, isShowTimeSettingBtn, e) {
        const positionX = e.pageX + e.currentTarget.clientWidth + 10;
        const positionY = e.pageY - (e.currentTarget.clientHeight / 2);
        this.setState({
            processChartObj: { id: processChartId, itemName: itemName, itemCode: itemCode, version: chartVersion, displayItemCode: displayItemCode },
            editBtn: {
                id: processChartId,
                btn: (
                    <div style={{ position: 'absolute', left: positionX + 'px', top: positionY + 'px' }}>
                        <ProcessChartEditMenu
                            isShowTimeSettingBtn={isShowTimeSettingBtn}
                            onEditBtnClick={() => this.handleEditBtnClick()}
                            onCopyBtnClick={() => this.handleCopyBtnClick()}
                            onTimeSettingBtnClick={() => this.handleTimeSettingBtnClick()}
                        />
                    </div>
                ),
            }
        });
        e.stopPropagation();
    }

    // エディタ画面へ移動するカラムのクリックイベント
    handleLinkColOnClick(processChartObj, e) {
        e.stopPropagation();
        if (IsEditing()) {
            this.setState({
                dialogBox: (
                    <ConfirmBox
                        className="absolute right-[162px] top-[265px] w-[471px]"
                        title="変更の破棄" message={"作業が破棄されます。よろしいですか？"}
                        onYesClick={() => { this.setState({ isNavigateToEditor: true }); }}
                        onNoClick={() => { this.setState({ dialogBox: null }); }}
                    />
                ),
                processChartObj: processChartObj
            });
        }
        else {
            this.setState({ isNavigateToEditor: true, processChartObj: processChartObj });
        }
    }

    // エディタ画面へ移動する
    handleEditBtnClick() {
        if (IsEditing()) {
            this.setState({
                dialogBox: (
                    <ConfirmBox
                        className="absolute right-[162px] top-[265px] w-[471px]"
                        title="変更の破棄" message={"作業が破棄されます。よろしいですか？"}
                        onYesClick={() => { this.setState({ isNavigateToEditor: true }); }}
                        onNoClick={() => { this.setState({ dialogBox: null }); }}
                    />
                ),
            });
        }
        else {
            this.setState({ isNavigateToEditor: true });
        }
    }

    // 複製ボタンをクリアする
    handleCopyBtnClick() {
        if (IsEditing()) {
            this.setState({
                dialogBox: (
                    <ConfirmBox
                        className="absolute right-[162px] top-[265px] w-[471px]"
                        title="変更の破棄" message={"作業が破棄されます。よろしいですか？"}
                        onYesClick={() => this.handleCopyDiscardYesBtnClick()}
                        onNoClick={() => { this.setState({ dialogBox: null }); }}
                    />
                ),
            });
        }
        else {
            this.setState({
                copyLayout: (
                    <ProcessChartCopy key={uuidv4()}
                        processChartObj={this.state.processChartObj}
                        onCopyBtnClick={(cateCode, itemCode) => this.handleCopyCompleteAndRefresh(cateCode, itemCode)}
                        onDiscardBtnClick={() => { this.setState({ copyLayout: null }); }}
                    />
                ),
                expandCateCode: -1,
                expandItemCode: -1
            });
        }
    }

    // 破棄ダイアログの「はい」ボタンをクリアする
    handleCopyDiscardYesBtnClick() {
        this.setState({
            copyLayout: (
                <ProcessChartCopy key={uuidv4()}
                    processChartObj={this.state.processChartObj}
                    onCopyBtnClick={(cateCode, itemCode) => this.handleCopyCompleteAndRefresh(cateCode, itemCode)}
                    onDiscardBtnClick={() => { this.setState({ copyLayout: null }); }}
                />
            ),
            dialogBox: null,
            expandCateCode: -1,
            expandItemCode: -1
        });
    }

    // 複製処理が成功した上で、リフレッシュする処理
    handleCopyCompleteAndRefresh(cateCode, itemCode) {
        this.setState({
            copyLayout: null,
            expandCateCode: cateCode,
            expandItemCode: itemCode
        });
        this.getitemdata();
        this.getProcessChartData();
        this.refreshList();
    }

    handleSearchCategoryInputChange(e) {
        this.setState({
            categoryFilterText: e.target.value,
        });
    }

    handleSearchItemCodeInputChange(e) {
        this.setState({
            itemCodeFilterText: e.target.value,
        });
    }

    handleSearchItemNameInputChange(e) {
        this.setState({
            itemNameFilterText: e.target.value,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.itemCodeFilterText !== prevState.itemCodeFilterText ||
            this.state.itemNameFilterText !== prevState.itemNameFilterText ||
            this.state.categoryFilterText !== prevState.categoryFilterText) {
            let bindDataItems = this.state.items;
            let bindDataCategories = this.state.categories;

            if (this.state.itemCodeFilterText.length > 0) {
                // 製品コードテキストボックスの入力テキストでフィルタリングする
                bindDataItems = bindDataItems.filter(item => item.displayItemCode.toString().toLowerCase().includes(this.state.itemCodeFilterText.toString().toLowerCase()));
                // 現在のフィルタリングした製品一覧によってカテゴリーリストを変更する
                bindDataCategories = bindDataCategories.filter(cate => bindDataItems.some(item => item.categoryId === cate.id));
            }

            if (this.state.itemNameFilterText.length > 0) {
                // 製品名テキストボックスの入力テキストでフィルタリングする
                bindDataItems = bindDataItems.filter(item => item.itemName.toString().toLowerCase().includes(this.state.itemNameFilterText.toString().toLowerCase()));
                // 現在のフィルタリングした製品一覧によってカテゴリーリストを変更する
                bindDataCategories = bindDataCategories.filter(cate => bindDataItems.some(item => item.categoryId === cate.id));
            }

            if (this.state.categoryFilterText.length > 0) {
                // カテゴリーテキストボックスの入力テキストでフィルタリングする             
                bindDataCategories = bindDataCategories.filter(cate => cate.name.toString().toLowerCase().includes(this.state.categoryFilterText.toString().toLowerCase()));
            }
            this.setState({
                bindDataCategories: bindDataCategories,
                bindDataItems: bindDataItems,
            });
        }
    }

    // 標準工数の設定ボタンをクリック操作
    handleTimeSettingBtnClick() {
        this.setState({
            timeSettingLayout: (
                <ProcessChartTimeSetting key={uuidv4()}
                    processChartObj={this.state.processChartObj}
                    onClose={() => { this.setState({ timeSettingLayout: null }); }}
                />
            ),
            dialogBox: null,
            copyLayout: null,
        });
    }

    render() {
        return (
            <div onClick={this.clearEditButton}>
                {this.state.isRedirectToCompanyLogin && <><Navigate to={generatePath(CompanyLoginPath, {
                    company: urlCompanyName
                })} /></>}
                {this.state.isNavigateToEditor && <><Navigate to={generatePath("/:company/editor", {
                    company: urlCompanyName
                })} state={this.state.processChartObj} /></>}
                <div className="flex flex-row">
                    <div>
                        <div className="my-[11px] h-[21px]">工程設計</div>
                        <SectionTitle Text="製品選択" />
                        <div className="flex mt-5 h-[48px]">
                            <div className="h-[32px] mr-[15px]">
                                <label>カテゴリ</label>
                                <SearchBox width={224}
                                    onChange={(e) => this.handleSearchCategoryInputChange(e)}
                                    value={this.state.categoryFilterText}
                                />
                            </div>
                            <div className="h-[32px] mr-[15px]">
                                <label>製品コード</label>
                                <SearchBox width={224}
                                    onChange={(e) => this.handleSearchItemCodeInputChange(e)}
                                    value={this.state.itemCodeFilterText}
                                />
                            </div>
                            <div className="h-[32px] mr-[15px]">
                                <label>製品名</label>
                                <SearchBox width={224}
                                    onChange={(e) => this.handleSearchItemNameInputChange(e)}
                                    value={this.state.itemNameFilterText}
                                />
                            </div>
                        </div>
                        <div className="h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] mt-[20px]" />
                        <div className="w-[1683px] h-[875px] overflow-y-scroll" id={"scrollDiv"}>
                            {this.state.bindDataCategories.map((category, cateIdx) => (
                                <div className="w-fit hover:cursor-pointer" key={category.id}>
                                    <Collapsible margin="ml-0" type="categoryName" id={category.id} expandId={Number(this.state.expandCateCode)}
                                        title={
                                            <>
                                                {category.name} ({this.state.bindDataItems.filter(item => item.categoryId === (category.id)).length})
                                            </>
                                        }>
                                        {this.state.bindDataItems
                                            .filter(item => item.categoryId === (category.id)).sort((a, b) => a.itemName.localeCompare(b.itemName))
                                            .map((filterItem, itmIdx) => (
                                                <div className="w-fit hover:cursor-pointer" key={filterItem.id}>
                                                    <Collapsible margin="ml-[42px]" type="itemName" id={filterItem.id} expandId={Number(this.state.expandItemCode)} desiredIndex={cateIdx + itmIdx}
                                                        title={
                                                            <div>
                                                                {filterItem.displayItemCode}<span className="ml-4 mr-1">{filterItem.itemName}</span>({this.state.processChartDatas.filter(processChartData => processChartData.itemCode === filterItem.id).length})
                                                            </div>
                                                        }>
                                                        {this.state.processChartDatas
                                                            .filter(processChartData => processChartData.itemCode === filterItem.id).length === 0 ?
                                                            <div>
                                                                <table className="border-collpase ml-[140px] border-b-2 mt-[-2px] w-[730px]">
                                                                    <tbody>
                                                                        <tr key={processChartID}
                                                                            className={"w-[730px] h-[53px] border-b-2 border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                                                                            <td className="w-[247px] h-[53px] text-left pl-[20px] font-bold hover:underline">
                                                                                <div className="w-fit hover:cursor-pointer" onClick={(e) => this.handleLinkColOnClick({ id: uuidv4(), itemName: filterItem.itemName, itemCode: filterItem.id, version: -1 , itemManagementType: filterItem.managementType }, e)}>
                                                                                    新規作成
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div> :
                                                            ((this.state.processChartDatas
                                                                .filter(processChartData => processChartData.itemCode === filterItem.id).length === 1) &&
                                                                (this.state.processChartDatas
                                                                    .filter(processChartData => processChartData.itemCode === filterItem.id &&
                                                                        processChartData.version === 0).length === 1)) ?
                                                                this.state.processChartDatas
                                                                    .filter(processChartData => processChartData.itemCode === filterItem.id)
                                                                    .map(filteredData => (
                                                                        <div key={filteredData.processChartId} >
                                                                            <table className="border-collpase ml-[140px] mt-[-2px] w-[730px]">
                                                                                <tbody>
                                                                                    <tr key={filteredData.processChartId}
                                                                                        className={"w-[730px] border-b-2 h-[53px] border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                                                                                        <td className="w-[247px] h-[53px] text-left pl-[20px] font-bold hover:underline" onClick={(e) => this.handleLinkColOnClick({ id: filteredData.processChartId, itemName: filteredData.itemName, itemCode: filteredData.itemCode, version: filteredData.version, itemManagementType: filterItem.managementType }, e)}>
                                                                                            一時保存
                                                                                        </td>
                                                                                        <td className="w-[300px] h-[53px] text-left  text-black  ml-[616px]">
                                                                                            {ToLocalDateTime(filteredData.updateDate)}
                                                                                        </td>
                                                                                        <td className="w-[157px] max-w-[157px] h-[53px] text-left pl-[20px] text-black overflow-hidden text-ellipsis whitespace-nowrap">
                                                                                            {filteredData.userName}
                                                                                        </td>
                                                                                        <td>
                                                                                            <div onClick={(e) => this.handleThreeDotsClick(filteredData.processChartId, filteredData.itemName, filteredData.itemCode, filteredData.version, filterItem.displayItemCode, false, e)}
                                                                                                className="h-[53px] mx-[15px] flex items-center cursor-pointer text-black">
                                                                                                <IconContext.Provider value={{ color: "Black", size: "20px" }} >
                                                                                                    <BsThreeDots />
                                                                                                </IconContext.Provider>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr key={processChartID}
                                                                                        className={"w-[730px] h-[53px] border-b-2  border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                                                                                        <td className="w-[247px] h-[53px] text-left pl-[20px] font-bold hover:underline" onClick={(e) => this.handleLinkColOnClick({ id: uuidv4(), itemName: filteredData.itemName, itemCode: filteredData.itemCode, version: -1 }, e)}>
                                                                                            新規作成
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>))
                                                                : this.state.processChartDatas
                                                                    .filter(processChartData => processChartData.itemCode === filterItem.id)
                                                                    .sort((a, b) => a.updateDate < b.updateDate ? 1 : -1)
                                                                    .map(filteredData => (
                                                                        <div key={filteredData.processChartId} >
                                                                            <table className="border-collpase ml-[140px] mt-[-2px] w-[730px]">
                                                                                <tbody>
                                                                                    <tr key={filteredData.processChartId}
                                                                                        className={"w-[730px] h-[53px] border-b-2 border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                                                                                        <td className="w-[247px] h-[53px] text-left pl-[20px] font-bold hover:underline" onClick={(e) => this.handleLinkColOnClick({ id: filteredData.processChartId, itemName: filteredData.itemName, itemCode: filteredData.itemCode, version: filteredData.version, itemManagementType: filterItem.managementType }, e)}>
                                                                                            {filteredData.version === 0 ? "一時保存" : filteredData.version.toString().padStart(3, '0') + "版"}
                                                                                        </td>
                                                                                        <td className="w-[300px] h-[53px] text-left  text-black ml-[616px]">
                                                                                            {ToLocalDateTime(filteredData.updateDate)}
                                                                                        </td>
                                                                                        <td className="w-[157px] max-w-[157px] h-[53px] text-left pl-[20px] text-black overflow-hidden text-ellipsis whitespace-nowrap">
                                                                                            {filteredData.userName}
                                                                                        </td>
                                                                                        <td>
                                                                                            <div onClick={(e) => this.handleThreeDotsClick(filteredData.processChartId, filteredData.itemName, filteredData.itemCode, filteredData.version, filterItem.displayItemCode, filteredData.version > 0, e)}
                                                                                                className="h-[53px] mx-[15px] flex items-center cursor-pointer text-black">
                                                                                                <IconContext.Provider value={{ color: "Black", size: "20px" }} >
                                                                                                    <BsThreeDots />
                                                                                                </IconContext.Provider>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    ))
                                                        }
                                                    </Collapsible>
                                                </div>
                                            ))
                                        }
                                    </Collapsible>
                                </div>
                            ))}
                        </div>
                    </div>
                    {this.state.copyLayout}
                    {this.state.timeSettingLayout}
                </div >
                {this.state.editBtn.btn}
                {this.state.dialogBox}
            </div >
        );
    }
}

export default ProcessChart;
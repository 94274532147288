import React from "react";
import { IconContext } from "react-icons";
import { FaSave } from "react-icons/fa";
import { variables } from "../Variables";

class Save extends React.Component {

    render() {
        let iconColor = "#0073CD";
        let textColor = "";
        let hoverBg = variables.HOVER_CSS;
        if (!this.props.isEnable) {
            iconColor = variables.COLOR_DISABLED;
            textColor = "text-[" + iconColor + "]";
            hoverBg = null;
        }
        return (
            <div onClick={this.props.isEnable ? this.props.onClick : ()=> void 0}
             className={"flex items-center h-[53px] w-fit mx-[15px] hover:cursor-pointer " + hoverBg} >
                <IconContext.Provider
                    value={{ color: iconColor, size: "16px" }}>
                    <FaSave />
                </IconContext.Provider>
                <div className={"mx-[5px] " + textColor}>保存</div>
            </div>
        );
    }
}
// Specifies the default values for props:
Save.defaultProps = {
    isEnable: false
};
export default Save;
import { React, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import EditorTitleBar from "./EditorTitleBar";
import ProcessList from "./process/ProcessList";
import WorkList from "./work/WorkList";
import DetailDoc from "./work/DetailDoc";
import WorkInstruction from "./work/WorkInstruction";
import ManageItem from "./work/ManageItem";
import AddWorkPicture from "./work/AddWorkPicture";
import { fetchProcessByChartID, resetProcessList } from "./process/processListSlice";
import { getSelectedWorkInfo, fetchWorkByProcsID } from "./work/workListSlice";
import { variables, StatusCode, CompanyLoginPath, OperationManagementType } from "../../Variables";
import ToolList from "./work/ToolList";
import { fetchMngItemByWIDs } from "./work/manageItemSlice";
import { fetchToolByWorkID } from "./work/toolListSlice";
import { setChart, selectChartID, setVersion, resetChart } from "./processChartSlice";
import { fetchDetailDocByWorkID } from "./work/detailDocSlice";
import { resetWorkList } from './work/workListSlice';
import { resetDetailDoc } from './work/detailDocSlice';
import { resetTool } from './work/toolListSlice';
import { resetMngItm } from './work/manageItemSlice';
import { selectTempSaveCount } from "./btnClickCountSlice";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import PageLoading from "../../components/PageLoading";
import { setIsLoading as setPageLoding } from "../Loading/pageLoadingSlice";
import { urlCompanyName } from "../util/commonFun";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');

function Editor(props) {
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const location = useLocation();
  const chartIDfromParam = location.state ? location.state.id : "";
  const itmNamefromParam = location.state ? location.state.itemName : "";
  const itmCodefromParam = location.state ? location.state.itemCode : -1;
  const versionfromParam = location.state ? location.state.version : -1;
  const itmManagementTypefromParam = location.state ? location.state.itemManagementType : OperationManagementType.NONE;
  const _chartID = useSelector(selectChartID);
  const tmpSaveCount = useSelector(selectTempSaveCount);
  const selectedWorkID = useSelector(getSelectedWorkInfo).workID;
  const [isRedirectToCompanyLogin, setIsRedirectToCompanyLogin] = useState(chartIDfromParam.length === 0);

  useEffect(() => {
    axios({
      method: 'GET',
      url: variables.LOGIN_URL + "/validate",
      headers: { 'company': urlCompanyName },
      withCredentials: true
    }).then(function (response) {
      if (response.data.statusCode !== StatusCode.AuthorizedWithAdministrator) {
        setIsRedirectToCompanyLogin(true);
      }
    }).catch(function (error) {
      appInsights.trackException({...error, errorFunction: "Editor.useEffect()"});  
    });

    dispatch(resetProcessList());
    dispatch(setChart({
      chartID: chartIDfromParam,
      itemCode: itmCodefromParam,
      itemName: itmNamefromParam,
      version: versionfromParam,
      itemManagementType: itmManagementTypefromParam,
    }));
    setIsFirstLoad(false);

    return function cleanup() {
      // エディター画面をUnmountする時、データをクリアして置く。
      clearData();
    };
  }, []);

  useEffect(() => {
    if (_chartID.length > 0 && !isFirstLoad) {
      dispatch(setPageLoding(true));
      axios({
        method: 'GET',
        url: variables.EDITOR_URL + "/get-pids-and-wids",
        params: {
          chartID: _chartID
        },
        headers: { 'company': urlCompanyName },
        withCredentials: true

      }).then(function (response) {
        if (response.data.processIDList.length > 0) {
          dispatch(setVersion(response.data.version));
          const fetchData = async () => {
            try {
              if (response.data.workIDList && response.data.workIDList.length > 0) {
                // fetch 詳細資料 + ファイル
                await dispatch(fetchDetailDocByWorkID(response.data.workIDList)).unwrap();
                // fetch 工具データ 
                await dispatch(fetchToolByWorkID(response.data.workIDList)).unwrap();
                // fetch　管理項目
                await dispatch(fetchMngItemByWIDs(response.data.workIDList)).unwrap();
              }
              // fetch 作業一覧　＋　作業指示
              await dispatch(fetchWorkByProcsID(response.data.processIDList)).unwrap();
              // fetch 工程一覧
              await dispatch(fetchProcessByChartID(response.data.chartID)).unwrap();
            } catch (error) {
              appInsights.trackException({...error, errorFunction: "Editor.useEffect()"});
            } finally {
              dispatch(setPageLoding(false));
            }
          }
          fetchData();
        }
        else {
          dispatch(setPageLoding(false));
        }
      }).catch(function (error) {
        dispatch(setPageLoding(false));
        let errResponseStatus = error.response.status;
        if (errResponseStatus === 401) {
          setIsRedirectToCompanyLogin(true);
        }
        else{
          appInsights.trackTrace({ params: JSON.stringify({ chartID: _chartID }) });
          appInsights.trackException({...error, errorFunction: "Editor.useEffect()"});
        }
      });
    }
  }, [dispatch, _chartID, isFirstLoad, tmpSaveCount]);

  /**
   * エディターの全データをクリアする。
   */
  function clearData() {
    dispatch(resetProcessList());
    dispatch(resetChart());
    dispatch(resetWorkList());
    dispatch(resetDetailDoc());
    dispatch(resetTool());
    dispatch(resetMngItm());
  }

  if (isRedirectToCompanyLogin) {
    return (
      <>
        <Navigate to={generatePath(CompanyLoginPath, {
          company: urlCompanyName
        })} />
      </>
    );
  }

  return (
    <>
      <div className="relative w-[1920px] h-[1080px] bg-[#FFFFFF] overflow-hidden">
        <PageLoading />
        <EditorTitleBar />
        <div className="flex">
          {/* 工程リストと作業リストのパネル*/}
          <div className="w-[537px] h-[1033px] px-[15px] border-r-2">
            <div className="h-[396px]">
              <ProcessList />
            </div>
            <div>
              <WorkList />
            </div>
          </div>
          {/* 右側の作業詳細、作業写真などのパネル */}
          <div className="w-[1383px] h-[1033px] px-[15px] border-l-2">
            <div>
              <div className="h-[112px]"></div>
              <div className="flex h-[684px]">
                <AddWorkPicture key={selectedWorkID} />
                <ToolList />
              </div>
              <div className="h-[237px] mt-3">
                {/* 3rd row */}
                <div className="flex">
                  <WorkInstruction key={selectedWorkID} />
                  <div className="mx-2">
                    <DetailDoc key={selectedWorkID} />
                  </div>
                  <div>
                    <ManageItem key={selectedWorkID}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Editor;

import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setTextFile,
  deleteTextFile,
  selectTextFileByWorkId,
} from "./detailDocSlice";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import { IconContext } from "@react-icons/all-files";
import { BsFileText } from "@react-icons/all-files/bs/BsFileText";
import CheckCircle from "../../../components/CheckCircle";
import TextInputBox from "./TextInputBox";
import ConfirmBox from "../../../components/ConfirmBox";
import { nanoid } from "nanoid";
import { getSelectedWorkInfo } from "./workListSlice";
import { variables } from "../../../Variables";
import DetailDocTextViewer from "../../../components/DetailDocTextViewer";
import { v4 as uuidv4 } from "uuid";

/**
 * 詳細資料のテキスト追加コンポネント
 * @param {*} props
 * @returns
 */
function DetailDocText(props) {
  const dispatch = useDispatch();
  const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
  const [showTextInput, setShowTextInput] = useState(false);
  const [isTextView, setTextView] = useState(false);
  const [docText, setDocText] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [txtFromFile, setTxtFromFile] = useState("");

  // text doc file
  const dtlDocText = useSelector(state => selectTextFileByWorkId(state, props.workID));
  let textObjUrl = (dtlDocText && dtlDocText.objUrl.length) ? dtlDocText.objUrl : "";
  // 追加ボタンのイベントハンドラ
  async function handleAddButton() {
    if (dtlDocText && dtlDocText.objUrl.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        // テキスト追加ボックスを表示する。
        setTxtFromFile(e.target.result);
        setShowTextInput(true);
      });
      let blob = await fetch(dtlDocText.objUrl).then((r) => r.blob());
      reader.readAsText(blob);
    } else {
      setTxtFromFile("");
      // テキスト追加ボックスを表示する。
      setShowTextInput(true);
    }
  }

  // 削除ボタンのイベントハンドラ
  function handleDeleteButton() {
    // 削除確認ボックスを表示する。
    setShowDeleteConfirm(true);
  }

  // 削除確認ボックスの「はい」ボタンのイベントハンドラ
  function handleDeleteConfirmYes() {
    if (dtlDocText && dtlDocText.objUrl.length > 0) {
      // 古いオブジェクトが有る場合、それを削除
      URL.revokeObjectURL(dtlDocText.objUrl);
    }
    dispatch(deleteTextFile({ workID: props.workID }));
    setShowDeleteConfirm(false);
  }

  // 削除確認ボックスの「いいえ」ボタンのイベントハンドラ
  function handleDeleteConfirmNo() {
    setShowDeleteConfirm(false);
  }

  function onTextInputBoxRegister(txt) {
    const txtfile = new File([txt], uuidv4() + ".txt");
    if (dtlDocText && dtlDocText.objUrl.length > 0) {
      // 古いオブジェクトが有る場合、それを削除
      URL.revokeObjectURL(dtlDocText.objUrl);
    }
    dispatch(setTextFile({ workID: props.workID, fileName: txtfile.name, objUrl: URL.createObjectURL(txtfile) }));
    setShowTextInput(false);
  }

  function onTextInputBoxClose() {
    setShowTextInput(false);
  }

  async function handelTextViewer() {
    try {
      if (textObjUrl.length > 0) {
        const reader = new FileReader();
        reader.addEventListener("loadend", (e) => {
          setDocText(e.target.result);
          setTextView(true);
        });
        let blob = await fetch(textObjUrl).then((r) => r.blob());
        reader.readAsText(blob);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const iconColor = selectedWork.isLock ? "#A19F9D" : "";
  const textColor = selectedWork.isLock ? " text-[#A19F9D]" : "";

  return (
    <div className={props.className || null}>
      <div className="flex items-center relative w-[281px] h-[56px] border-2 border-[#C8C6C4]">
        <div className="w-[60px] ml-1.5">
          <div className={"font-bold" + textColor}>テキスト</div>
          <div>
            <IconContext.Provider value={{ size: "27px", color: iconColor }}>
              {/* TODO: テキストアイコン作成 */}
              <BsFileText />
            </IconContext.Provider>
          </div>
        </div>
        <div className="w-[80px] ml-[-8px]">
          {dtlDocText && dtlDocText.objUrl.length > 0 ?
            <CheckCircle
              iconColor={"#0073CD"}
              textColor={"text-[#0073CD]"}
              hoverbgColor={variables.HOVER_CSS}
              isEnable={true}
              onClick={handelTextViewer} /> : null}
        </div>
        <div className="grow flex flex-row-reverse">
          <div>
            <DeleteButton
              className="w-[70px] h-[48px]"
              Text="削除"
              onClick={handleDeleteButton}
              isEnable={
                dtlDocText &&
                dtlDocText.objUrl.length > 0 &&
                !selectedWork.isLock
              }
            />
          </div>
          <div>
            <AddButton
              className="w-[70px] h-[48px]"
              Text="追加"
              isEnable={props.workID.length > 0 && !selectedWork.isLock}
              onClick={handleAddButton}
            />
          </div>
        </div>
      </div>

      {/* テキストファイル入力ボックス */}
      {showTextInput && <TextInputBox
        key={nanoid()}
        text={txtFromFile}
        onRegister={onTextInputBoxRegister}
        onClose={onTextInputBoxClose}
        className="absolute right-0 top-6" />}

      {/* 確認ボックス */}
      {showDeleteConfirm && (
        <ConfirmBox
          className="absolute right-[162px] top-[265px] w-[471px]"
          title="ファイル削除"
          message="削除しますか？"
          onYesClick={handleDeleteConfirmYes}
          onNoClick={handleDeleteConfirmNo}
        />
      )}
      {/* テキスト表示 */}
      {isTextView && (
        <DetailDocTextViewer
          text={docText}
          Close={() => setTextView(false)} />
      )}
    </div>
  );
}

export default DetailDocText;

import { React, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import { HiOutlineChevronDoubleRight, HiOutlineChevronDoubleLeft } from "react-icons/hi";
import { VscChecklist } from "@react-icons/all-files/vsc/VscChecklist";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { RiLinksFill } from "react-icons/ri";
import ConfirmBox from "../../../components/ConfirmBox";
import { WorkStatus, BunkiStatus, variables } from "../../../Variables";
import { errorCode } from "../../../ErrorCode";
import { useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';
import ErrorBox from "../../../components/ErrorBox";
import { setIsLoading as setPageLoading } from "../../Loading/pageLoadingSlice";
import {
  setSelectedWork,
  setWorkExpandMode,
  setPauseExpandMode,
  setNoneWorkStatusChange,
  setJustSelectWorkId,
  setSelectedItem,
  setSelectedUser,
  setInputLotNumber,
  fetchDetailDocByWorkID,
  fetchToolByWorkID,
  fetchLotNumberWorkList,
  setLotOperationLogLockId,
  setVersion,
  setLockKey,
  setIsManageItemTypeFocusable,
  fetchLotCompletedManageResult
} from "../lotOperationSlice";

import {
  NavigateToOperationLogin,
  NavigateToOperationStartWork,
  RegisterLotOperationLog,
} from "../../util/commonFun";
import { appInsights } from "../../util/ApplicationInsight";
import CheckMarkIcon from "../../../components/CheckMarkIcon";

let confirmMsg;
let moveWork;
function LotWorkCheckList() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currSelectedItem = location.state ? location.state.selectedItem : null;
  const currSelectedUser = location.state ? location.state.selectedUser : null;
  const currLotNumber = location.state ? location.state.lotNumber : null;
  const currLockKey = location.state ? location.state.lockKey : null;
  const currLotOperationLogLockId = location.state ? location.state.lotOperationLogLockId : -1;
  const currVersion = location.state ? location.state.version : -1;

  const items = useSelector((state) => state.lotOperation);
  const processObj = items.processObj;
  const workList = [...items.dataList.workList];
  const justSelectWorkId = items.justSelectWorkId;
  const selectedWork = items.selectedWork;
  const isExpand = items.isWorkExpandMode;
  const selectedWorkId = selectedWork && selectedWork.workId;
  const opLogList = items.dataList.lotOperationLogList;
  const [isShowConfirmBox, setShowConfirmBox] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isDisplayWorkList, setIsDisplayWorkList] = useState(true);
  const [writeLogErrorCode, setWriteLogErrorCode] = useState(0);
  const refInitialWork = useRef(true);

  useEffect(() => {
    if (currSelectedItem === null || currSelectedUser === null || currLotNumber === null || currLockKey === null
      || currLotOperationLogLockId === -1 || currVersion === -1) {
      NavigateToOperationStartWork(navigate);
    }
    else if (refInitialWork.current === true) {
      refInitialWork.current = false;
      dispatch(setSelectedItem(currSelectedItem));
      dispatch(setSelectedUser(currSelectedUser));
      dispatch(setInputLotNumber(currLotNumber));
      dispatch(setLotOperationLogLockId(currLotOperationLogLockId));
      dispatch(setVersion(currVersion));
      dispatch(setLockKey(currLockKey));
      const fetchData = async () => {
        dispatch(setPageLoading(true));
        try {
          await dispatch(fetchLotNumberWorkList({ lotNo: currLotNumber, lotOperationLogLockId: currLotOperationLogLockId, itemCode: currSelectedItem.itemCode })).unwrap();
          await dispatch(fetchLotCompletedManageResult({ lotOperationLogLockId: currLotOperationLogLockId, lotNo: currLotNumber })).unwrap();
        } catch (error) {
          let errResponseStatus = error.message;
          // eslint-disable-next-line eqeqeq
          if (errResponseStatus == 401) {
            NavigateToOperationLogin(navigate);
          }
          else {
            appInsights.trackException({ ...error, errorFunction: "LotWorkCheckList.useEffect()" });
            NavigateToOperationStartWork(navigate);
            console.error(error);
          }
        }
      }
      fetchData().finally(() => dispatch(setPageLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (selectedWorkId !== "") {
        const fetchData = async () => {
          dispatch(setPageLoading(true));
          let isFailed = await fetchDataByWorkId(selectedWorkId);
          if (isFailed) {
            NavigateToOperationStartWork(navigate);
          }
          else if (window.speechSynthesis.speaking === false) {
            dispatch(setIsManageItemTypeFocusable(true));
          }
        }
        fetchData().finally(() => dispatch(setPageLoading(false)));
      }
    }
    catch (error) {
      appInsights.trackException({ ...error, errorFunction: "LotWorkCheckList.useEffect()" });
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkId]);

  function onClickExpandCollapse(e) {
    if (items.isPauseExpandMode) {
      dispatch(setPauseExpandMode(false));
    }
    if (isExpand) {
      dispatch(setWorkExpandMode(false));
      dispatch(setJustSelectWorkId(""));
    } else {
      dispatch(setWorkExpandMode(true));
      setIsDisplayWorkList(true);
    }
  }

  /**
   * 作業リストの選択変更イベント
   * @param {*} work 
   */
  function handleSelectChangeWork(work) {
    const opLog = opLogList.find((log) => log.dispWorkId.toLowerCase() === work.dispWorkId.toLowerCase());
    if (opLog && opLog.workStatusId === WorkStatus.WORKOK) {
      dispatch(setJustSelectWorkId(""));
      moveWork = work;
      confirmMsg = (
        <>
          <p className="break-all">
            {"「工程No" + processObj.dispOrder + " : " + processObj.processName + "」"}
            <br />
            {"「作業No" + work.dispOrder + " : " + work.workName + "」"}
            <br />
            {"に作業を戻りますか？"}
          </p>
        </>
      );
      setShowConfirmBox(true);
    }
    else {
      dispatch(setJustSelectWorkId(work.workId));
    }
  }

  /**
   * 作業位置の変更ダイアログの「いいえ」ボタンの操作
   */
  function handleBackConfirmNo() {
    setShowConfirmBox(false);
    dispatch(setWorkExpandMode(false));
  }

  /**
   * 作業位置の変更ダイアログの「はい」ボタンの操作
   * @param {*} work 
   */
  async function handleBackConfirmYes(work) {
    setIsBtnDisabled(true);
    if (work) {
      window.speechSynthesis.cancel();
      let result = await writeLotOperationLog(WorkStatus.WORKMOVE, work);
      if (result.isFailed === false) {
        setShowConfirmBox(false);
        dispatch(setWorkExpandMode(false));
        dispatch(setSelectedWork(work));
        dispatch(setNoneWorkStatusChange({ dispWorkId: work.dispWorkId }));
      }
    }
    setIsBtnDisabled(false);
  }

  /**
   * 詳細資料と部品工程リスト取得する処理
   * @param {*} workId 
   * @returns 
   */
  async function fetchDataByWorkId(workId) {
    let isFailed = false;
    if (workId !== "") {
      try {
        const isDetailDocExist = items.dataList.detailDocList.some((item) => item.workId.toLowerCase() === workId.toLowerCase());
        if (isDetailDocExist === false) {
          await dispatch(fetchDetailDocByWorkID(workId)).unwrap();
        }
        const isToolDataExist = items.dataList.toolList.some((item) => item.workId.toLowerCase() === workId.toLowerCase());
        if (isToolDataExist === false) {
          await dispatch(fetchToolByWorkID(workId)).unwrap();
        }
      } catch (error) {
        isFailed = true;
        let errResponseStatus = error.message;
        // eslint-disable-next-line eqeqeq
        if (errResponseStatus == 401) {
          NavigateToOperationLogin(navigate);
        }
        else {
          appInsights.trackException({ ...error, errorFunction: "LotWorkCheckList.fetchDataByWorkId()" });
          console.error(error);
        }
      }
    }
    return isFailed;
  }

  /**
   * ロットオペレーションのログ履歴登録処理
   * @param {*} workStatusId 
   * @param {*} workObj 
   * @returns 
   */
  async function writeLotOperationLog(workStatusId, workObj) {
    let result = { isFailed: false, data: null };
    if (workObj.workId.length === 0) {
      result.isFailed = true;
      return result;
    }
    let description;
    let manageItemObj = items.dataList.manageItemList.find((item) => item.workId.toLowerCase() === workObj.workId.toLowerCase());
    if (manageItemObj) {
      description = { ...manageItemObj.description };
      description.okNgResult = "";
      description.txtInputText = "";
    }
    let lotOperation = {
      lockKey: items.lockKey,
      lotOperationLogLockId: items.lotOperationLogLockId,
      processChartID: items.processObj.processChartID,
      processId: items.processObj.processId,
      workId: workObj.workId,
      workStatusId: workStatusId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      userId: items.selectedUser.userId,
      manageResult: JSON.stringify(manageItemObj ? description : ""),
      manageItemTypeId: manageItemObj ? manageItemObj.manageItemType : 0,
      version: items.version,
      workDispOrder: workObj.dispOrder,
      dispProcessId: items.processObj.dispProcessId,
      dispWorkId: workObj.dispWorkId,
      matomesuu: items.matomesuu,
    }
    result = await RegisterLotOperationLog(lotOperation, navigate);
    if (result.data && result.data.errorCode > 0) {
      setWriteLogErrorCode(result.data.errorCode);
      dispatch(setWorkExpandMode(false));
    }
    return result;
  }

  /**
   * 作業リストの「🗸」表示する為に、作業OKか確認する
   * @param {*} work 
   * @returns 
   */
  const isSagyouOKStatus = (work) => {
    if (opLogList) {
      const opLog = opLogList.find((log) => log.dispWorkId.toLowerCase() === work.dispWorkId.toLowerCase());
      if (opLog && opLog.workStatusId === WorkStatus.WORKOK) {
        return true;
      }
    }
    return false;
  }

  return (
    <div className="flex w-[50px] h-[853px]">
      {isExpand === false ? (
        <div className="w-[40px] h-[853px] border-2 bg-zinc-100">
          <div
            className="w-[35px] h-[32px] cursor-pointer hover:bg-gray-200"
            onClick={(e) => onClickExpandCollapse(e)}
          >
            <IconContext.Provider value={{ size: "25px" }}>
              <HiOutlineChevronDoubleRight className="mt-[2px] ml-[8px]" />
            </IconContext.Provider>
          </div>
          <div className="w-[20px] ml-[10px]">
            <label className="font-bold">作業リスト／作業情報</label>
          </div>
        </div>
      ) : (
        <div className="z-10 w-[706px] h-[853px] drop-shadow-[0_6px_6px_rgba(96,94,92,1)]">
          <div className="flex w-[706px] h-[35px] border-2 border-[#C8C6C4] bg-[#F3F2F1]">
            <div
              className="w-[35px] h-[32px] items-center cursor-pointer hover:bg-gray-200"
              onClick={(e) => onClickExpandCollapse(e)}
            >
              <IconContext.Provider value={{ size: "25px" }}>
                <HiOutlineChevronDoubleLeft className="mt-[2px] ml-[5px]" />
              </IconContext.Provider>
            </div>
            <div className="ml-[10px]">
              <div className="flex">
                <div>
                  <div className={"w-[100px] h-[32px] font-bold text-[18px] text-center textmiddle cursor-pointer hover:bg-gray-200" + (isDisplayWorkList ? " bg-[#D8D7D5]" : "")}
                    onClick={() => { setIsDisplayWorkList(true); }}
                  >作業リスト</div>
                </div>
                <div className="ml-3">
                  <div className={"w-[100px] h-[32px] font-bold text-[18px] text-center textmiddle cursor-pointer hover:bg-gray-200" + (isDisplayWorkList === false ? " bg-[#D8D7D5]" : "")}
                    onClick={() => { setIsDisplayWorkList(false); }}
                  >作業情報</div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[706px] h-[818px] border-b-2 border-[#C8C6C4] bg-white overflow-y-scroll overflow-x-hidden">
            {isDisplayWorkList ?
              <>
                <div className="w-[706px] h-[30px] bg-white border-[2px] border-b-0 border-t-0 border-[#C8C6C4] sticky top-0">
                  <div className="inline-flex w-[70px] text-center border-[1px]"><span className="font-bold ml-[8px]">管理</span></div>
                  <div className="inline-flex w-[70px] text-center border-[1px]"><span className="font-bold ml-[4px]">状態</span></div>
                  <div className="inline-flex w-[70px] text-center border-[1px]"><span className="font-bold ml-[3px]">作業No.</span></div>
                  <div className="inline-flex w-[492px] text-left border-[1px]"><span className="font-bold ml-[4px]">作業名</span></div>
                </div>
                <table>
                  {processObj && (
                    <tbody key={processObj.processId}>
                      <tr>
                        <td className="h-[50px] text-[22px] text-[#0073CD]" colSpan={4} key={processObj.processId}>
                          <span className="font-bold ml-[15px]">{processObj.dispOrder}</span><span className="font-bold ml-[6px]">{processObj.processName}</span>
                        </td>
                      </tr>
                      {workList && workList.filter((item) => item.processId.toUpperCase() === processObj.processId.toUpperCase()).map((singleWork, idx) => (
                        <tr
                          className={selectedWork.workId.toUpperCase() === singleWork.workId.toUpperCase() ? "border-b-[1px] h-[52px] text-[22px] bg-[#0073CD] text-white" : (justSelectWorkId === singleWork.workId) ? "border-b-[1px] h-[52px] text-[22px] bg-[#D8D7D5]" : "border-b-[1px] h-[52px] text-[22px] " + variables.HOVER_CSS}
                          key={singleWork.workId}
                          onClick={() => handleSelectChangeWork(singleWork)
                          }
                        >
                          <td className="w-[70px] text-center bg-white ">
                            <div className="flex space-x-2 ml-[15px] text-black">
                              {(singleWork.bunkiStatus === BunkiStatus.BunkiMoto || singleWork.bunkiStatus === BunkiStatus.BunkiSakiAndKanri) ? <><VscTypeHierarchySub /><VscChecklist /></> :
                                singleWork.bunkiStatus === BunkiStatus.BunkiSaki ? <VscTypeHierarchySub /> :
                                  (singleWork.bunkiStatus === BunkiStatus.TextCompareMoto || singleWork.bunkiStatus === BunkiStatus.TextCompareSakiAndKanri) ? <><RiLinksFill /><VscChecklist /></> :
                                    singleWork.bunkiStatus === BunkiStatus.Kanri ? <VscChecklist className="ml-[28px]" /> : null
                              }
                            </div>
                          </td>
                          <td className="w-[70px] text-center bg-white"><div className="text-[#0073CD] mt-[2px] ml-[25px]">{isSagyouOKStatus(singleWork) === true ?
                            <CheckMarkIcon size={18} /> : ""}</div></td>
                          <td className="w-[70px] text-center">{singleWork.dispOrder}</td>
                          <td className="w-[477px] text-left"><span className="w-[470px] ellipse one-line ml-[7px] whitespace-nowrap">{singleWork.workName}</span></td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </> : <>
                <div className="font-bold text-[24px] ml-4 mt-6">
                  製品名
                </div>
                <div className="text-[20px] ml-8 mt-3 w-[620px] break-all">
                  <span>{items.selectedItem.itemName}</span><span className="ml-3">{items.version.toString().padStart(3, '0') + "版"}</span>
                </div>
                <div className="font-bold text-[24px] ml-4 mt-6">
                  {"製造 / シリアル / ロット 番号"}
                </div>
                <div className="text-[20px] ml-8 mt-3 w-[620px] break-all">{items.lotNumber}</div>
                <div className="font-bold text-[24px] ml-4 mt-6">
                  ユーザー名
                </div>
                <div className="text-[20px] ml-8 mt-3 w-[620px] break-all">{items.selectedUser.userName}</div>
              </>
            }
          </div>
        </div>
      )}
      {/* {ConfirmDialogBox} */}
      {(isShowConfirmBox && writeLogErrorCode === 0) && (
        <ConfirmBox
          className="absolute left-[670px] top-[350px] w-[471px]"
          title="作業位置の変更"
          message={confirmMsg}
          onYesClick={isBtnDisabled ? void (0) : () => handleBackConfirmYes(moveWork)}
          onNoClick={handleBackConfirmNo}
        />
      )}
      {/*ログ登録エラーメッセージ表示「1009 =>工程設定の変更,1011 =>作業継続不可」*/}
      {writeLogErrorCode > 0 && (
        <ErrorBox
          className={"absolute left-[700px] top-[365px] " + (writeLogErrorCode === errorCode.E1011 ? "w-[528px]" : "w-[600px]")}
          Title={writeLogErrorCode === errorCode.E1011 ? "作業継続不可" : "工程設定の変更"}
          Message={<>
            {errorCode[writeLogErrorCode].split("\n").map((msg, idx) => (
              <div key={idx}>{msg}</div>))
            }
          </>}
          onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
        />
      )}
    </div>
  );
}

export default LotWorkCheckList;
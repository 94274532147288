import { createSlice } from "@reduxjs/toolkit";

const initState = {
    tempSaveCount: 0,
};

export const btnClickCountSlice = createSlice({
    name: "btnClickCount",
    initialState: initState,
    reducers: {
        increaseTempSaveCount: (btnClickCount, action) => {
            btnClickCount.tempSaveCount = btnClickCount.tempSaveCount + 1;
        }
    }
});

export const selectTempSaveCount = (state) => {
    return state.btnClickCount.tempSaveCount;
}

export const { increaseTempSaveCount } = btnClickCountSlice.actions;
export default btnClickCountSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HindoKaisuSetting, variables } from "../../../Variables";
import { urlCompanyName } from "../../util/commonFun";
import { appInsights } from "../../util/ApplicationInsight";

const axios = require('axios');
export const fetchWorkByProcsID = createAsyncThunk("workList/fetchWorkByProcsID",
  async procIDs => {
    const response = await axios({
      method: 'POST',
      url: variables.WORK_URL + "/getbyprocessids",
      data: procIDs,
      headers: { 'company': urlCompanyName },
      withCredentials: true
    });
    return response.data;
  });

export const emptyWorkObj = {
  processID: "",
  workID: "",
  dispWorkID: "",
  workName: "",
  isLock: false,
  dispOrder: -1,
  workNote: "",
  fileName: "",
  objUrl: null,
  isIrregular: false
};

const initState = {
  selectedWork: { ...emptyWorkObj },
  multiSelectedWorks: [],
  dataList: [],
  backupDataList: []
};

export const workListSlice = createSlice({
  name: "workList",
  initialState: initState,
  reducers: {
    addWork: (workList, action) => {
      const work = action.payload;
      if (work && work.workID.length > 0) {
        let sortedList = workList.dataList.filter(w => w.processID.toUpperCase() === work.processID.toUpperCase());
        sortedList.sort((a, b) => a.dispOrder - b.dispOrder);
        if (sortedList.length > 0) {
          const selectedWIdx = sortedList.findIndex(w => w.workID.toUpperCase() === workList.selectedWork.workID.toUpperCase());
          if (selectedWIdx >= 0) {
            for (let idx = sortedList.length - 1; idx > selectedWIdx; idx--) {
              sortedList[idx].dispOrder = sortedList[idx].dispOrder + 1;
            }
            // dispOrder = dispOrder of selected + 1
            work.dispOrder = sortedList[selectedWIdx].dispOrder + 1;
          } else {
            // dispOrder = lastOrer + 1
            work.dispOrder = sortedList.at(-1).dispOrder + 1;
          }
        } else {
          work.dispOrder = 1;
        }
        workList.dataList.push(work);
        workList.selectedWork = work;
      }

    },
    setWorkName: (workList, action) => {
      const work = workList.dataList.find(
        (w) => w.workID === action.payload.workID
      );
      if (work) {
        work.workName = action.payload.workName;
        workList.selectedWork = work;
      }
    },

    deleteWorkByWorkIDList: (workList, action) => {
      let deleteWorkIDs = action.payload.workIDs;
      let processID = action.payload.processID;
      for (let i = 0; i < deleteWorkIDs.length; i++) {
        let idx = workList.dataList.findIndex((w) => w.workID.toUpperCase() === deleteWorkIDs[i].toUpperCase());
        if (idx >= 0) {
          workList.dataList.splice(idx, 1);
        }
      }
      let filteredWork = workList.dataList.filter(w => w.processID.toUpperCase() === processID.toUpperCase());
      // dispOrder を１から採番する。
      resetDispOrder(filteredWork);
      workList.multiSelectedWorks = [];
      // 現在選択作業を設定する、もしくは全部削除された場合、空に設定する
      const nextSelectedWork = workList.dataList.find((w) => w.workID === action.payload.nextSelectWorkID);
      workList.selectedWork = nextSelectedWork ? nextSelectedWork : { ...emptyWorkObj };
    },

    deleteWorkByProcessID: (workList, action) => {
      const wrk = workList.dataList.filter(
        (w) => w.processID === action.payload.processID
      );
      if (wrk.length > 0) {
        for (let i = 0; i < wrk.length; i++) {
          let idx = workList.dataList.findIndex(
            (w) => w.workID === wrk[i].workID
          );
          workList.dataList.splice(idx, 1);
        }
      }
    },

    moveUpwardInWork: (workList, action) => {
      // 工程IDで作業一覧をフィルターする。
      let filteredList = workList.dataList.filter(w => w.processID.toUpperCase() === action.payload.selectedProcID.toUpperCase());
      filteredList.sort((a, b) => a.dispOrder - b.dispOrder);

      // 複数選択作業リストを移動する場合、
      if (workList.multiSelectedWorks.length > 0) {
        let multiSelectWorks = workList.multiSelectedWorks;
        multiSelectWorks.sort((a, b) => a.dispOrder - b.dispOrder);
        // 移動する作業リストの最初の作業Indexを取って、フィルターリストの作業元Indexを取る
        // フィルターリストから作業元の「dispOrder」を移動する作業リストの最後の作業「dispOrder」に変更する
        let firstIdx = filteredList.findIndex(w => w.workID.toUpperCase() === workList.multiSelectedWorks[0].workID.toUpperCase());
        if (firstIdx >= 1) {
          filteredList[firstIdx - 1].dispOrder = workList.multiSelectedWorks.at(-1).dispOrder;

          // 移動する作業リストの「dispOrder」を対象「dispOrder」に変更する
          for (let i = 0; i < multiSelectWorks.length; i++) {
            const idx = filteredList.findIndex(w => w.workID.toUpperCase() === multiSelectWorks[i].workID.toUpperCase());
            if (idx > -1) {
              filteredList[idx].dispOrder = filteredList[idx].dispOrder - 1;
            }
          }
          // 複数選択作業リストを更新する
          let updatedMultiSelectWorks = [...filteredList.filter(x => multiSelectWorks.some(w => w.workID.toUpperCase() === x.workID.toUpperCase()))];
          updatedMultiSelectWorks.sort((a, b) => a.dispOrder - b.dispOrder);
          workList.multiSelectedWorks = updatedMultiSelectWorks;
        }
      }
      // 単一の作業を移動する場合、
      else {
        const currIdx = filteredList.findIndex(w => w.workID.toUpperCase() === action.payload.workID.toUpperCase());
        if (currIdx >= 1) {
          const selectedDispOrder = filteredList[currIdx].dispOrder;
          filteredList[currIdx].dispOrder = selectedDispOrder - 1;
          filteredList[currIdx - 1].dispOrder = selectedDispOrder;
        }
      }
      // 現在選択作業を更新する
      let updateSelectedWork = filteredList.find(w => w.workID.toUpperCase() === action.payload.workID.toUpperCase());
      if (updateSelectedWork) {
        workList.selectedWork = updateSelectedWork;
      }
    },

    moveWorkToOtherProc: (workList, action) => {
      let targetWork = workList.dataList.find(w => w.workID.toUpperCase() === action.payload.workID.toUpperCase());
      const oldProcessID = targetWork.processID;
      if (targetWork) {
        let wListOfTargetProcess = workList.dataList.filter(w =>
          w.processID.toUpperCase() === action.payload.targetProcessID.toUpperCase()
        );
        targetWork.processID = action.payload.targetProcessID;
        let workListOfSourceProcess = workList.dataList.filter(w =>
          w.processID.toUpperCase() === oldProcessID.toUpperCase()
        );
        if (wListOfTargetProcess && wListOfTargetProcess.length > 0) {
          wListOfTargetProcess.sort((a, b) => a.dispOrder - b.dispOrder);
          if (action.payload.isMoveToLast) {
            targetWork.dispOrder = wListOfTargetProcess.at(-1).dispOrder + 1;
            // 移動元にある作業リストのdispOrderを採番する。
            resetDispOrder(workListOfSourceProcess);
          } else {
            // 対象工程の作業リストのdispOrder を２から初めて採番する。
            for (let idx = wListOfTargetProcess.length - 1; idx >= 0; idx--) {
              wListOfTargetProcess[idx].dispOrder = wListOfTargetProcess[idx].dispOrder + 1;
            }
            targetWork.dispOrder = 1;
          }
        } else {
          targetWork.dispOrder = 1;
          // 移動元にある作業リストのdispOrderを採番する。
          resetDispOrder(workListOfSourceProcess);
        }
        workList.selectedWork = targetWork;
      }
    },

    moveMultiSelectWorkToOtherProc: (workList, action) => {
      let currDisplayWork = workList.dataList.find(w => w.workID.toUpperCase() === action.payload.workID.toUpperCase());
      const sourceProcessID = currDisplayWork.processID;
      if (currDisplayWork) {
        // 移動元にある作業リストを取る
        let workListOfSourceProcess = workList.dataList.filter(w => w.processID.toUpperCase() === sourceProcessID.toUpperCase());
        // 対象工程の作業リストを取る
        let workListOfTargetProcess = workList.dataList.filter(w => w.processID.toUpperCase() === action.payload.targetProcessID.toUpperCase());
        // 複数選択した作業リストをソートする
        let multiSelectedWorks = workList.multiSelectedWorks;
        multiSelectedWorks.sort((a, b) => a.dispOrder - b.dispOrder);

        // 対象工程で作業がある場合
        if (workListOfTargetProcess.length > 0) {
          // 対象工程の作業リストをソートする
          workListOfTargetProcess.sort((a, b) => a.dispOrder - b.dispOrder);
          // 対象工程の最後の作業へ移動する場合
          if (action.payload.isMoveToLast) {
            // 移動する作業リストの「dispOrder」「processID」を対象の「dispOrder」「processID」に変更する
            for (let i = 0; i < multiSelectedWorks.length; i++) {
              const idx = workListOfSourceProcess.findIndex(w => w.workID.toUpperCase() === multiSelectedWorks[i].workID.toUpperCase());
              if (idx > -1) {
                workListOfSourceProcess[idx].processID = action.payload.targetProcessID;
                workListOfSourceProcess[idx].dispOrder = workListOfTargetProcess.at(-1).dispOrder + (i + 1);
              }
            }
          }
          // 対象工程の最初の作業へ移動する場合
          else {
            let moveCount = multiSelectedWorks.length;
            // 対象工程の作業リストの順番を移動する作業カウントの次から変更する
            for (let idx = 0; idx < workListOfTargetProcess.length; idx++) {
              workListOfTargetProcess[idx].dispOrder = moveCount + (idx + 1);
            }

            // 移動する作業リストの「dispOrder」「processID」を対象の「dispOrder」「processID」に変更する
            for (let i = 0; i < multiSelectedWorks.length; i++) {
              const idx = workListOfSourceProcess.findIndex(w => w.workID.toUpperCase() === multiSelectedWorks[i].workID.toUpperCase());
              if (idx > -1) {
                workListOfSourceProcess[idx].processID = action.payload.targetProcessID;
                workListOfSourceProcess[idx].dispOrder = i + 1;
              }
            }
          }
        }
        // 対象工程で作業がない場合
        else {
          for (let i = 0; i < multiSelectedWorks.length; i++) {
            const idx = workListOfSourceProcess.findIndex(w => w.workID.toUpperCase() === multiSelectedWorks[i].workID.toUpperCase());
            if (idx > -1) {
              workListOfSourceProcess[idx].processID = action.payload.targetProcessID;
              workListOfSourceProcess[idx].dispOrder = i + 1;
            }
          }
        }

        // 移動した後、移動元の作業リストの「dispOrder」をリセットする
        workListOfSourceProcess = workList.dataList.filter(w => w.processID.toUpperCase() === sourceProcessID.toUpperCase());
        resetDispOrder(workListOfSourceProcess);
        // 複数選択作業リストを更新する
        let updatedMultiSelectWorks = [...workList.dataList.filter(w => w.processID.toUpperCase() === action.payload.targetProcessID.toUpperCase() &&
          multiSelectedWorks.some(m => m.workID.toUpperCase() === w.workID.toUpperCase()))];
        updatedMultiSelectWorks.sort((a, b) => a.dispOrder - b.dispOrder);
        workList.multiSelectedWorks = updatedMultiSelectWorks;
        workList.selectedWork = updatedMultiSelectWorks.find(w => w.workID.toUpperCase() === action.payload.workID.toUpperCase());
      }
    },

    moveDownwardInWork: (workList, action) => {
      // 工程IDで作業一覧をフィルターする。
      let filteredList = workList.dataList.filter(w => w.processID.toUpperCase() === action.payload.selectedProcID.toUpperCase());
      filteredList.sort((a, b) => a.dispOrder - b.dispOrder);

      // 複数選択作業リストを移動する場合、
      if (workList.multiSelectedWorks.length > 0) {
        let multiSelectWorks = workList.multiSelectedWorks;
        multiSelectWorks.sort((a, b) => a.dispOrder - b.dispOrder);
        // 移動する作業リストの最後の作業Indexを取って、フィルターリストの作業元Indexを取る
        // フィルターリストから作業元の「dispOrder」を移動する作業リストの最初の作業「dispOrder」に変更する
        let lastIdx = filteredList.findIndex(w => w.workID.toUpperCase() === workList.multiSelectedWorks.at(-1).workID.toUpperCase());
        if (filteredList && filteredList.length - 1 > lastIdx) {
          filteredList[lastIdx + 1].dispOrder = workList.multiSelectedWorks[0].dispOrder;

          // 移動する作業リストの「dispOrder」を対象「dispOrder」に変更する
          for (let i = 0; i < multiSelectWorks.length; i++) {
            const idx = filteredList.findIndex(w => w.workID.toUpperCase() === multiSelectWorks[i].workID.toUpperCase());
            if (idx > -1) {
              filteredList[idx].dispOrder = filteredList[idx].dispOrder + 1;
            }
          }
          // 複数選択作業リストを更新する
          let updatedMultiSelectWorks = [...filteredList.filter(x => multiSelectWorks.some(w => w.workID.toUpperCase() === x.workID.toUpperCase()))];
          updatedMultiSelectWorks.sort((a, b) => a.dispOrder - b.dispOrder);
          workList.multiSelectedWorks = updatedMultiSelectWorks;
        }
      }
      // 単一の作業を移動する場合、
      else {
        const currIdx = filteredList.findIndex(w => w.workID.toUpperCase() === action.payload.workID.toUpperCase());
        // selected index は最後のindexではない場合、したへ移動する。
        if (filteredList && filteredList.length - 1 > currIdx) {
          const selectedDispOrder = filteredList[currIdx].dispOrder;
          filteredList[currIdx].dispOrder = selectedDispOrder + 1;
          filteredList[currIdx + 1].dispOrder = selectedDispOrder;
        }
      }
      // 現在選択作業を更新する
      let updateSelectedWork = filteredList.find(w => w.workID.toUpperCase() === action.payload.workID.toUpperCase());
      if (updateSelectedWork) {
        workList.selectedWork = updateSelectedWork;
      }
    },

    setSelectedWork: (workList, action) => {
      const wrk = workList.dataList.find(
        (w) => w.workID === action.payload.workID
      );
      if (wrk) {
        workList.selectedWork = wrk;
      } else {
        workList.selectedWork = { ...emptyWorkObj };
      }
    },

    setMultiSelectedWorks: (workList, action) => {
      const currMultiSelectWorkObj = workList.dataList.find((w) => w.workID === action.payload.workID);
      if (currMultiSelectWorkObj) {
        let tempList = [...workList.multiSelectedWorks];
        let idx = workList.multiSelectedWorks.findIndex((item) => item.workID === workList.selectedWork.workID);
        if (idx === -1) {
          let updateSelectedWorkObj = workList.dataList.find((item) => item.workID === workList.selectedWork.workID);
          if (updateSelectedWorkObj) {
            tempList.push(updateSelectedWorkObj);
          }
        }
        idx = workList.multiSelectedWorks.findIndex((item) => item.workID === currMultiSelectWorkObj.workID);
        if (idx === -1) {
          tempList.push(currMultiSelectWorkObj);
        }
        tempList.sort((a, b) => a.dispOrder - b.dispOrder);
        workList.multiSelectedWorks = tempList;
      }
    },

    setClearMultiSelectedWorks: (workList, action) => {
      if (workList.multiSelectedWorks.length > 0) {
        workList.multiSelectedWorks = [];
      }
    },

    /**
     * 工程IDに対して、最初の作業を選択された作業とする。
     * param: processID[string]
     * @param {*} workList 
     * @param {*} action 
     */
    setSelectedWorkByProcessID: (workList, action) => {
      if (workList.dataList.length > 0) {
        let filteredList = workList.dataList.filter(w => w.processID.toUpperCase() === action.payload.processID.toUpperCase());
        if (filteredList && filteredList.length > 0) {
          // let sortedList = filteredList.slice();
          filteredList.sort((a, b) => a.dispOrder - b.dispOrder);
          //　対象工程の作業リスト内に既に選択された作業が無い場合はのみ、選択作業設定をする。
          if (filteredList.findIndex(w => w.workID.toUpperCase() === workList.selectedWork.workID.toUpperCase()) < 0) {
            workList.selectedWork = filteredList[0];
          }
        } else {
          workList.selectedWork = { ...emptyWorkObj };
        }

      } else {
        workList.selectedWork = { ...emptyWorkObj };
      }
    },
    /**
     * 作業指示テキストを設定する。
     * param: workID, text
     * @param {*} workList 
     * @param {*} action 
     */
    setWorkNote: (workList, action) => {
      const work = workList.dataList.find(
        (w) => w.workID === action.payload.workID
      );
      if (work) {
        work.workNote = action.payload.text;
        workList.selectedWork = work;
      }
    },

    setLockWork: (workList, action) => {
      const wrk = workList.dataList.find(
        (w) => w.workID === action.payload.workID
      );
      if (wrk) {
        wrk.isLock = action.payload.isLock ? false : true;
        workList.selectedWork = wrk;
      }
    },

    /**
     * 作業写真を設定する。
     * param: workID, fileName, objUrl
     * @param {*} workList 
     * @param {*} action 
     */
    setWorkPictByWorkID: (workList, action) => {
      const { workID, fileName, objUrl } = action.payload;
      const wrk = workList.dataList.find((d) => d.workID === workID);
      if (wrk) {
        wrk.fileName = fileName;
        wrk.objUrl = objUrl;
        workList.selectedWork = wrk
      }
    },
    resetWorkList: (workList, action) => {
      return { ...initState };
    },
    /**
     * 修正作業フラグを設定する。
     * action.payload: {workID, isIrregular}
     * @param {*} workList 
     * @param {*} action 
     */
    setIrregularWork: (workList, action) => {
      const work = workList.dataList.find(
        (w) => w.workID === action.payload.workID
      );
      if (work) {
        work.isIrregular = action.payload.isIrregular;
        workList.selectedWork = work;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkByProcsID.fulfilled, (workList, action) => {
      const responseWorkList = action.payload;
      if (responseWorkList && responseWorkList.length > 0) {
        workList.dataList = responseWorkList;
      }
      workList.backupDataList = workList.dataList.slice();
    });
    builder.addCase(fetchWorkByProcsID.rejected, (state, action) => {
      appInsights.trackException({ ...action.error, errorFunction: "workListSlice.builder.addCase(fetchWorkByProcsID.rejected)" });
    });

  },
});

/**
 * dispOrderを１から採番する。
 * @param {*} workList 
 */
function resetDispOrder(workList) {
  if (workList && workList.length > 0) {
    workList.sort((a, b) => a.dispOrder - b.dispOrder);
    for (let i = 0; i < workList.length; i++) {
      workList[i].dispOrder = i + 1;
    }
  }
}

export const selectWorkListByProcessId = (state, processID) => {
  let filteredResult = state.workList.dataList.filter((w) => w.processID === processID);
  return filteredResult.sort((firstItem, secondItem) => {
    return firstItem.dispOrder - secondItem.dispOrder;
  });
};

export const selectWorkListByProcessIdList = (state, lstProcessId) => {
  if (lstProcessId && lstProcessId.length > 0) {
    let filteredWList = state.workList.dataList.filter(w => lstProcessId.findIndex(pID => pID === w.processID) >= 0);
    filteredWList.sort((a, b) => a.dispOrder - b.dispOrder);
    return filteredWList;
  } else {
    return [];
  }
}

export const getSelectedWorkInfo = (state) => {
  return state.workList.selectedWork;
};

export const getMultiSelectedWorksInfo = (state) => {
  return state.workList.multiSelectedWorks;
};

export const getWorkNote = (state, workID) => {
  const wrk = state.workList.dataList.find(w => w.workID === workID);
  if (wrk) {
    return wrk.workNote;
  } else {
    return "";
  }
}

export const getAllWorkList = (state) => {
  let sortedList = state.workList.dataList.slice();
  sortedList.sort((a, b) => a.dispOrder - b.dispOrder);
  return sortedList;
};

export const getBackupWorkList = (state) => {
  return state.workList.backupDataList;
};

export const getCurrentWorkList = (state) => {
  return state.workList.dataList;
};

export const checkAllLocked = (state) => {
  if (state.workList.dataList.length <= 0) {
    return false;
  } else {
    if (state.workList.dataList.findIndex(w => w.isLock === false) >= 0) {
      return false;
    } else {
      return true;
    }
  }
}

/**
 * 頻度設定していない作業リストをフィルタリングする
 * @param {*} state 
 * @param {*} filteredWorkList 
 * @param {*} allMngItm 
 * @returns 
 */
export const getWorkListWithoutHindoSetting = (state, filteredWorkList, allMngItm) => {
  let worklist = [];
  if (filteredWorkList && filteredWorkList.length > 0) {
    let hindoManageItemList = allMngItm.filter(m => m.data.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Hindo);
    worklist = filteredWorkList.filter(w => hindoManageItemList.some(m => m.workID === w.workID) === false);
  }
  return worklist;
};

export const {
  addWork,
  setWorkName,
  deleteWorkByWorkIDList,
  deleteWorkByProcessID,
  moveUpwardInWork,
  moveWorkToOtherProc,
  moveDownwardInWork,
  setSelectedWorkByProcessID,
  setSelectedWork,
  setLockWork,
  setWorkNote,
  setWorkPictByWorkID,
  resetWorkList,
  setIrregularWork,
  setMultiSelectedWorks,
  setClearMultiSelectedWorks,
  moveMultiSelectWorkToOtherProc,
} = workListSlice.actions;
export default workListSlice.reducer;

import React from "react";
import { MdOutlineRestoreFromTrash } from "react-icons/md";
import { IconContext } from "react-icons";
import { variables } from "../Variables";

export function RestoreButton(props) {
    return (
        <div onClick={props.isEnable ? props.onClick : void (0)}
            className={props.className || "h-[53px] w-fit"}        >
            <div className={"flex flex-row h-full w-fit mx-[15px] hover:cursor-pointer " + (props.isEnable ? variables.HOVER_CSS : "")}>
                <IconContext.Provider
                    value={{ color: (props.isEnable ? "#0073CD" : variables.COLOR_DISABLED), size: "18px", className: "self-center" }}>
                    <MdOutlineRestoreFromTrash />
                </IconContext.Provider>
                <div className={"mx-[5px] font-bold self-center " + (props.isEnable ? "" : "text-[" + variables.COLOR_DISABLED + "]")}>{props.Text}</div>
            </div>
        </div >
    );
}
RestoreButton.defaultProps = {
    isEnable: false
};
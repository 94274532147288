import React from "react";
import { FaCopy } from "react-icons/fa";
import { IconContext } from "react-icons";
import { variables } from "../Variables";

function CopyButton(props) {
    return (
        <div onClick={props.isEnable ? props.onClick : void (0)}
            className={"flex items-center h-[53px] w-fit mx-[15px] cursor-pointer " + (props.isEnable ? variables.HOVER_CSS : "")}>
            <IconContext.Provider
                value={{ color: props.isEnable ? "#0073CD" : variables.COLOR_DISABLED, size: "16px", className: "self-center" }}>
                <FaCopy />
            </IconContext.Provider>
            <div className={"ml-[6px] " + (props.isEnable ? "text-[#000000]" : "text-[" + variables.COLOR_DISABLED + "]")}>
                複製
            </div>
        </div>
    );
}

export default CopyButton;
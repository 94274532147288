import React from 'react';
import { IconContext } from "@react-icons/all-files";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"

class CheckCircle extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenClick = this.handleOpenClick.bind(this);
  }

  handleOpenClick() {
    if (this.props.isEnable) {
      this.props.onClick();
    }
  }

  render() {
    let iconColor = this.props.iconColor;
    let textColor = this.props.textColor;
    let hoverbgColor = this.props.hoverbgColor;
    if (!this.props.isEnable) {
      iconColor = '#A19F9D';
      textColor = 'text-[#A19F9D]';
      hoverbgColor = '';
    }
    return (
      <div
        onClick={this.handleOpenClick}
        className={this.props.className || "w-[80px] h-[48px]"}
      >
        <div
          className={
            'flex items-center justify-center w-full h-full hover:cursor-pointer ' +
            hoverbgColor
          }
        >
          <IconContext.Provider value={{ color: iconColor }}>
            <FaCheckCircle />
          </IconContext.Provider>
          <div className={textColor}>登録済</div>
        </div>
      </div>
    );
  }
}

CheckCircle.defaultProps = {
  iconColor: "",
  textColor: "",
  hoverbgColor: "",
  onClick: () => { void (0) },
}
export default CheckCircle;
import { React, useState } from "react";
import bcrypt from "bcryptjs";
import { Salt } from "../Variables";
import { IconContext } from "react-icons";
import { VscError } from "react-icons/vsc";
import PasswordInput from "./PasswordInput";

function UserPassword(props) {
    // 古いパスワードを保存する
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, SetCurrentPasswordError] = useState(null);
    //　新しいパスワードを保存する。
    const [password, SetPassword] = useState("");
    const [passwordError, SetPasswordError] = useState(null);
    // 新しいパスワード（確認）を保存する。
    const [confirmPassword, SetConfirmPassword] = useState("");
    const [confirmPasswordError, SetConfirmPasswordError] = useState(null);

    /**
     * エラー表示用のElementを作成する。
     * @param {*} msg 
     * @returns 
     */
    function createErrorElement(msg) {
        return (
            <div>
                <label className="text-[#A4262C]">
                    <IconContext.Provider value={{ color: "#A4262C" }}>
                        <VscError className="w-[18px] h-[21px] inline-block pb-1" />
                        {msg}
                    </IconContext.Provider>
                </label>
            </div>
        );
    }

    /**
    * 現在パスワードのonChangeイベントハンドラ。
    * @param {*} e 
    */
    function onCurrentPasswordChange(e) {
        setCurrentPassword(e.target.value);
        if (e.target.value.length > 0) {
            const cryptedPs = bcrypt.hashSync(e.target.value, Salt);
            if (props.OldPassword !== cryptedPs) {
                SetCurrentPasswordError(
                    createErrorElement("現在のパスワードが正しくありません。")
                );
                props.onOldPsUnMatch();
            } else {
                props.onOldPsMatch(cryptedPs);
                SetCurrentPasswordError(null);
            }
        } else {
            SetCurrentPasswordError(null);
            props.onOldPsUnMatch();
        }
    }

    function onCurrentPasswordError(errorMsg) {
        SetCurrentPasswordError(
            createErrorElement(errorMsg)
        );
    }

    /**
    * 新しいパスワードのonChangeイベントハンドラ。
    * @param {*} e 
    */
    function onPasswordChange(e) {
        SetPassword(e.target.value);
        SetPasswordError(null);

        if (confirmPassword.length > 0) {
            if (confirmPassword === e.target.value) {
                SetConfirmPasswordError(null);
                // パスワードが一致する場合、それ変更パスワードとして設定して置く。
                props.onPsChange(e.target.value);
            } else {
                // パスワードが一致しない場合
                SetConfirmPasswordError(
                    createErrorElement("入力されたパスワードが一致しません。")
                );
                props.onPsUnMatch();
            }

        } else {
            SetConfirmPasswordError(null);
            props.onPsUnMatch();
        }
    }

    function onPasswordError(errorMsg) {
        SetPasswordError(
            createErrorElement(errorMsg)
        );
    }

    /**
     * 新しいパスワード（確認）のonChangeイベントハンドラ。
     * @param {*} e 
     */
    function onConfirmPasswordChange(e) {
        SetConfirmPassword(e.target.value);
        if (password === e.target.value) {
            SetConfirmPasswordError(null)
            if (password.length > 0) {
                // パスワードが一致する場合、それ変更パスワードとして設定して置く。
                props.onPsChange(e.target.value);
                return;
            }
        } else {
            SetConfirmPasswordError(
                createErrorElement("入力されたパスワードが一致しません。")
            );
        }
        props.onPsUnMatch();
    }

    function onConfirmPasswordError(errorMsg) {
        SetConfirmPasswordError(
            createErrorElement(errorMsg)
        );
    }

    return (
        <div>
            {
                props.OldPassword.length > 0 &&
                <>
                    <div className="flex flex-row items-start h-[65px]">
                        <div className="w-[135px]">
                            <label htmlFor="oldpassword">現在のパスワード</label>
                        </div>
                        <div className="text-[#CD0000] mx-3">
                            <strong>*</strong>
                        </div>
                        <div>
                            <PasswordInput
                                Password={currentPassword}
                                onChange={onCurrentPasswordChange}
                                onError={onCurrentPasswordError}
                                IsErrorState={currentPasswordError ? true : false} />
                            <div className="my-1">
                                {currentPasswordError}
                            </div>

                        </div>
                    </div>
                </>
            }
            <div className="flex flex-row items-start h-[65px]">
                <div className="w-[135px]">
                    <label>
                        {props.OldPassword.length > 0 ? "新しいパスワード" : "パスワード"}
                    </label>
                </div>
                <div className="text-[#CD0000] mx-3">
                    <strong>*</strong>
                </div>
                <div>
                    <PasswordInput
                        Password={password}
                        onChange={onPasswordChange}
                        onError={onPasswordError}
                        IsErrorState={passwordError ? true : false} />
                    <div className="my-1">
                        {passwordError}
                    </div>
                </div>

            </div>
            <div className="flex flex-row items-start h-[65px]">
                <div className="w-[135px]">
                    <label>
                        {props.OldPassword.length > 0 ? <>新しいパスワード<br />（確認）</> : "パスワード（確認）"}
                    </label>
                </div>
                <div className="text-[#CD0000] mx-3">
                    <strong>*</strong>
                </div>
                <div>
                    <PasswordInput
                        Password={confirmPassword}
                        onChange={onConfirmPasswordChange}
                        onError={onConfirmPasswordError}
                        IsErrorState={confirmPasswordError ? true : false} />
                    <div className="my-1">
                        <label>確認のため、もう一度入力してください。</label>
                    </div>
                    <div>
                        {confirmPasswordError}
                    </div>
                </div>
            </div>
        </div>
    );
}

UserPassword.defaultProps = {
    OldPassword: "",
}
export default UserPassword;
import { React, useEffect, useState } from "react";
import { variables, MasterPage } from "../../Variables";
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import vFile from "./versionInfo.txt";
import { v4 as uuidv4 } from "uuid";
import { urlCompanyName } from "../util/commonFun";

const YAML = require('yaml');

function Version(props) {
  const [verList, setVerList] = useState(null);
  useEffect(() => {
    readYamlFile();
  }, []);

  async function readYamlFile() {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      const value = YAML.parse(e.target.result);
      setVerList(value);
    });
    let blob = await fetch(vFile).then((r) => r.blob());
    reader.readAsText(blob);
  }

  return (
    <>
      <div className='grid grid-rows-1 bg-[#0073CD] opacity-90 h-[47px]'>
        <div className='text-[#FFFFFF] h-full w-fit mx-[15px] font-bold'>
          <Link to={generatePath("/:company", { company: urlCompanyName })} state={{ id: MasterPage.HOME }}>
            <div className='flex items-center w-fit h-full px-5 hover:bg-[#1980D1]'>
              Roland DG Assemble
            </div>
          </Link>
        </div>
      </div>
      <div className='flex flex-col w-[1920px] h-[1033px] bg-white overflow-y-auto'>
        <div className='mt-8 ml-8 mb-8'>
          <h1 className='text-3xl'>Latest Version: {variables.VERSION}</h1>
        </div>

        {/* 新しい履歴を上に書く */}

        {/* ここから */}
        {verList && verList.map((singleVer) => (
          <div className='mb-8 ml-8 mr-8' key={uuidv4()}>
            <div className='border-2 rounded-xl p-8'>
              <h2 className="text-xl">
                {singleVer.version}
              </h2>
              <div className='text-base mt-4'>
                <ul className='list-disc'>
                  {singleVer.description.map((singleDesc) => (
                    <div key={uuidv4()}>
                      {(singleDesc.title) ? <li className="ml-8">{singleDesc.title}</li> :
                        (singleDesc.subtitle) ? <div className="ml-8">{singleDesc.subtitle}</div> :
                          (singleDesc.label) ? <div className="ml-2">{singleDesc.label}</div> :
                            (singleDesc.subdetail) ? <div className=" ml-10">{singleDesc.subdetail}</div> :
                              (singleDesc.length === 0) ? <br /> : <li className="ml-16">{singleDesc}</li>
                      }
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
        {/* ここまで */}
      </div>
    </>
  );
};

export default Version;
import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddButton from "../../../components/AddButton";
import ImageInputBox from "./ImageInputBox";
import { getAllWorkList, getSelectedWorkInfo } from "./workListSlice";
import { IconContext } from "react-icons";
import { ImImages } from "react-icons/im";
import { setWorkPictByWorkID } from "./workListSlice";
import ErrorBox from "../../../components/ErrorBox";
import { v4 as uuidv4 } from "uuid";
import { FileUpload, NavigateToCompanyLogin } from "../../util/commonFun";
import SpinnerLoading from "../../../components/SpinnerLoading";
import { useNavigate } from 'react-router-dom';
import { appInsights } from "../../util/ApplicationInsight";

// 最大ファイルサイズ： 10 MB
const maxFileSize = 10;

let errMsg = "";
/**
 * 作業図追加コンポネント。
 * 写真ファイルを追加
 * @param {*} props
 * @returns
 */
function AddWorkPicture(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
  const workList = useSelector((state) => getAllWorkList(state));
  const [showFileInput, setShowFileInput] = useState(false);
  const [showError, setShowError] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(null);

  let bgColor = "";
  let iconColor = "#0073CD";
  let textColor = "text-[#0073CD]";
  if (selectedWork.workID === "" && selectedWork.objUrl === null) {
    bgColor = "bg-[#F3F2F1]";
    iconColor = "#A19F9D";
    textColor = "text-[#A19F9D]";
  }
  // ファイル追加ボタンのイベントハンドラ
  function handleImgFileAddButton() {
    setShowFileInput(true);
  }

  /**
   * 画像登録ボックスの閉じるボタンイベントハンドラ。
   */
  function onImageInputBoxClose() {
    // ダイアログを閉じる
    setShowFileInput(false);
  }

  /**
   * 画像登録ボックスの登録ボタンイベントハンドラ。
   * @param {*} objUrl 
   * @param {*} hashCode 
   * @param {*} fileName 
   */
  function onImageInputBoxRegister(objUrl, hashCode, fileName) {
    if (objUrl) {
      //ファイルの拡張子を取る     
      dispatch(
        setWorkPictByWorkID({
          workID: selectedWork.workID,
          fileName: fileName,
          objUrl: objUrl,
        })
      );
    }

    // ダイアログを閉じる
    setShowFileInput(false);
  }

  /**
   * ドラッグア&ドドロップイベントハンドラ。
   * @param {*} fileData
   */
  async function handleDrop(e) {
    e.preventDefault();
    if (selectedWork.workID === "" || selectedWork.isLock) {
      return;
    }
    let fileData = e.dataTransfer.files[0];
    if (fileData) {
      const fileSize = fileData.size / (1024 * 1024);
      //ファイルの拡張子を取る
      const fileExtension = "." + fileData.name.split(".").pop();
      let allowedFileExtensions = [".jpg", ".jpeg", ".jfif", ".png"];
      if (allowedFileExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
        // ファイル形式がjpg,jpeg,jfif,png以外の場合エラーメッセージを出す
        errMsg = (
          <>
            選択したファイルは登録できません。
            <br />
            登録できるファイルは「jpg」、「jpeg」、「jfif」、「png」です。
          </>
        );
        setShowError(true);
      } else if (fileSize > maxFileSize) {
        // ファイルサイズ　10 MB を超える場合エラーメッセージを出す。
        errMsg = "ファイル容量制限 10MB を超えています。";
        setShowError(true);
      } else {
        let isSuccessFileUpload = true;
        setSpinnerLoading(<SpinnerLoading />);
        let fileName = uuidv4() + fileExtension;
        let objUrl = null;
        try {
          // ファイルのアップロード
          objUrl = await FileUpload(fileData, fileName);
        } catch (error) {
          isSuccessFileUpload = false;
          if (error === 401) {
            NavigateToCompanyLogin(navigate);
          } else {
            errMsg = "登録に失敗しました。";
            setShowError(true);
            appInsights.trackException({ ...error, errorFunction: "AddWorkPicture.handleDrop()" });
          }
        } finally {
          setSpinnerLoading(null);
        }
        if (isSuccessFileUpload) {
          dispatch(
            setWorkPictByWorkID({
              workID: selectedWork.workID,
              fileName: fileName,
              objUrl: objUrl,
            })
          );
        }
      }
    }
  }

  function handleAllowDrop(e) {
    e.preventDefault();
  }

  return (
    <div>
      <div className="h-[396px] text-[24px] font-bold mt-[10px]">作業図</div>
      <div className="block mx-[80px] mt-[-397px]">
        <span className="text-3xl font-bold text-red-500">*</span>
      </div>
      <div className="h-[50px] mt-[-10px] hover:cursor-pointer">
        <AddButton
          Text="ファイル追加"
          isEnable={
            workList.length > 0 &&
            selectedWork.workID.length > 0 &&
            !selectedWork.isLock
          }
          onClick={handleImgFileAddButton}
        />
      </div>

      <div>
        <div
          onDrop={handleDrop}
          onDragOver={handleAllowDrop}
          className={"w-[792px] h-[599px] border-2 border-[#C8C6C4] " + bgColor}
        >
          {workList.length > 0 && selectedWork.objUrl !== null ? (
            <img
              src={selectedWork.objUrl}
              alt=""
              className="w-full h-full object-contain border-2 border-[#C8C6C4]"
            />
          ) : (
            <div>
              {workList.length !== 0 &&
                selectedWork.objUrl !== null &&
                selectedWork.workID !== "" ? (
                ""
              ) : (
                <div className="ml-[287px] mt-[140px] opacity-50">
                  <IconContext.Provider
                    value={{ size: "203px", color: iconColor }}
                  >
                    <ImImages />
                  </IconContext.Provider>
                  <span
                    className={"font-bold text-[24px] mx-[-35px] " + textColor}
                  >
                    ドラッグ＆ドロップで画像を追加
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* file upload box  */}
      {showFileInput && (
        <ImageInputBox
          className="absolute right-0 top-6"
          onClose={onImageInputBoxClose}
          onRegister={onImageInputBoxRegister}
          fileName=""
        />
      )}

      {/* サイズ制限とファイルタイプエラーボックス */}
      {showError && (
        <ErrorBox
          className="absolute right-[162px] top-[265px] w-[550px]"
          Title="ファイル登録"
          Message={errMsg}
          onYesClick={() => setShowError(false)}
        />
      )}
      {/* Loading... */}
      {spinnerLoading}
    </div>
  );
}
export default AddWorkPicture;

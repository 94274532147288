import React, { useRef, useState } from "react";
import { VscCalendar } from "react-icons/vsc";
import { ConvertDateTimeFormat, ConvertDisplayDateTimeFormat } from "../features/util/commonFun";

export function DateTimePicker(props) {
    const [isFocus, setIsFocus] = useState(false);
    const dateRef = useRef();

    function handleShowPopup(e) {
        if (dateRef) {
            dateRef.current.showPicker();
            setIsFocus(true);
        }
    }

    function handleDateInputChange(e) {
        props.onDateChange(e.target.value);
    }

    const getDisplayDateTimeValue = (selectDate) => {
        let result = "";
        if (selectDate.length > 0) {
            result = ConvertDisplayDateTimeFormat(selectDate);
        }
        return result;
    }

    return (
        <div className="relative flex">
            <div className={"flex w-fit h-[30px] items-center border-[1px] rounded-sm cursor-pointer " + (isFocus ? " border-[#0073CD]" : " border-[#707070]")}
                tabIndex={-1}
                onBlur={() => { setIsFocus(false); }}
                onClick={handleShowPopup}>
                <input type="text" className="h-full w-[140px] py-[0.8px] px-1 outline-none cursor-pointer"
                    value={getDisplayDateTimeValue(props.value)}
                    readOnly={true} />
                <VscCalendar className=" mr-1" style={{ color: 'black', fontSize: '18px' }} />
            </div>
            <input
                ref={dateRef}
                type="datetime-local"
                className={"absolute w-0 h-0 opacity-0 " + props.showPickerPositionCss}
                value={ConvertDateTimeFormat(props.value)}
                onChange={handleDateInputChange}
                min={ConvertDateTimeFormat(props.minDate)}
                max={ConvertDateTimeFormat(props.maxDate)}
                disabled={props.disabled} />
        </div>
    );
}

DateTimePicker.defaultProps = {
    value: "",
    minDate: "",
    maxDate: "",
    disabled: false,
    showPickerPositionCss: "ml-[165px]",
    onDateChange: () => { void (0) },
}
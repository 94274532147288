import { useDispatch } from "react-redux";
import { GetPort, GetReader } from "../util/SerialPortConnection";
import { setConnectionLost, setIncomingHardwareData } from "./SerialPortReaderSlice";
let hardwareData = "";
let result = "";
let nokoriData = "";
let originalData = "";
const regex = /(\[.*?\r\n)(.*)/;

function SerialPortReader(props) {
    let port = GetPort();
    let reader = GetReader();
    const dispatch = useDispatch();
    const fetchData = async () => {
        if (port !== null && port.readable && reader !== null) {
            try {
                while (true) {
                    const { value, done } = await reader.read();
                    if (done) {
                        reader.releaseLock();
                        break;
                    }
                    let decodeValue = new TextDecoder().decode(value);
                    originalData += nokoriData.concat(decodeValue);
                    nokoriData = "";
                    if (regex.test(originalData)) {
                        result = originalData.match(regex);
                        hardwareData = result[1];
                        nokoriData = result[2];
                        dispatch(setIncomingHardwareData(hardwareData));
                        originalData = "";
                        break;
                    }
                }
            }
            catch (error) {
                dispatch(setConnectionLost(true));
            }
        }
        else {
            dispatch(setConnectionLost(true));
        }
    }

    fetchData();
    return (<></>)
}
export default SerialPortReader;
import React from "react";
import { nanoid } from "nanoid";

function RadioButton(props) {
    const id = nanoid();

    function onRadioBtnChange() {
        if (!props.isLock) {
            props.onChange();
        }
    }

    return (
        <div className={props.className + ((props.checked && props.isLock === false) ? " border-2 border-[#0073CD] bg-[white]"
            : " bg-[#D8D7D5] border-2 " + (props.isLock === false ? "hover:border-[#707070] hover:opacity-100 opacity-60" : ""))}>
            <div className="flex relative">
                <label htmlFor={id} className="cursor-pointer w-full">
                    <div className="text-left mt-3 ml-1 text-[14px] font-bold">{props.text}</div>
                </label>
                <input
                    onChange={onRadioBtnChange}
                    className="absolute right-1 w-[12px] h-[12px] mt-[4px] cursor-pointer"
                    type="radio"
                    id={id}
                    name={props.name}
                    checked={props.checked}
                    disabled={props.isLock} />
            </div>
        </div>
    );
}

RadioButton.defaultProps = {
    isLock: false,
    checked: true,
};
export default RadioButton;
import { React, useState } from "react";
import { useDispatch } from "react-redux";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import { IconContext } from "react-icons";
import CheckCircle from "../../../components/CheckCircle";
import { ImImages } from "react-icons/im";
import ImageInputBox from "./ImageInputBox";
import ConfirmBox from "../../../components/ConfirmBox";
import { setMketWarnImgInfo } from "./manageItemSlice";
import MarketWarningViewer from "../../../components/MarketWarningViewer";
import { variables } from "../../../Variables";

/**
 * 写真ファイル追加コンポネント。
 * 
 * @param {*} props 
 * @returns 
 */
function AddDocPicture(props) {
    const mngItm = props.mngItm;
    const dispatch = useDispatch();
    const [showFileInput, setShowFileInput] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [isMarketWarningView, setMarketWarningView] = useState(false);
    const isPreview = true;

    function handelMarketWarningViewer(e) {
        setMarketWarningView(true);
    }

    // 追加ボタンのイベントハンドラ
    function handleAddButton() {
        setShowFileInput(true);
    }

    // 削除ボタンのイベントハンドラ
    function handleDeleteButton() {
        setShowDeleteConfirm(true);
    }

    // 削除確認ボックスの「はい」ボタンのイベントハンドラ
    function handleDeleteConfirmYes() {
        if (mngItm && mngItm.data.mketWarnImgInfo.objUrl.length > 0) {
            // 古いオブジェクトが有る場合、それを削除
            URL.revokeObjectURL(mngItm.data.mketWarnImgInfo.objUrl);
        }
        dispatch(setMketWarnImgInfo({
            workID: props.workID,
            fileName: "",
            objUrl: ""
        }));
        setShowDeleteConfirm(false);
    }

    // 削除確認ボックスの「いいえ」ボタンのイベントハンドラ
    function handleDeleteConfirmNo() {
        setShowDeleteConfirm(false);
    }

    /**
     * 画像登録ボックスの閉じるボタンイベントハンドラ。
     */
    function onImageInputBoxClose() {
        // ダイアログを閉じる
        setShowFileInput(false);
    }

    /**
     * 画像登録ボックスの登録ボタンイベントハンドラ。
     * @param {*} objUrl 
     * @param {*} hashCode 
     * @param {*} fileName
     */
    function onImageInputBoxResister(objUrl, hashCode, fileName) {
        if (objUrl) {
            //ファイルの拡張子を取る            
            dispatch(setMketWarnImgInfo({
                workID: props.workID,
                fileName: fileName,
                objUrl: objUrl
            }));
            // ダイアログを閉じる
            setShowFileInput(false);
        }
    }

    const iconColor = props.isLock ? " #A19F9D" : "";
    const textColor = props.isLock ? " text-[#A19F9D]" : "";

    return (
        <div>
            <div className="flex items-center relative w-[281px] h-[56px] border-2 border-[#C8C6C4]">
                <div className="ml-1 -mt-1">
                    <div className={"font-bold" + textColor}>
                        画像
                    </div>
                    <div>
                        <IconContext.Provider value={{ size: "25px", color: iconColor }}>
                            {/* 動画アイコン作成 */}
                            <ImImages />
                        </IconContext.Provider>
                    </div>
                </div>
                <div className="w-[60px] ml-3">
                    {
                        (mngItm.data.mketWarnImgInfo.fileName.length > 0) ?
                            <CheckCircle
                                iconColor={"#0073CD"}
                                textColor={"text-[#0073CD]"}
                                hoverbgColor={variables.HOVER_CSS}
                                isEnable={true}
                                onClick={handelMarketWarningViewer} /> : null
                    }
                </div>
                <div className="ml-[20px]">
                    <AddButton className="w-[69px] h-[48px]" Text="追加" isEnable={props.isLock ? false : true}
                        onClick={handleAddButton} />
                </div>
                <div>
                    <DeleteButton className="w-[80px] h-[48px]" Text="削除"
                        onClick={handleDeleteButton}
                        isEnable={mngItm.data.mketWarnImgInfo.fileName.length > 0 && !props.isLock ? true : false} />
                </div>
            </div>
            {/* 警告メッセージ表示 */}
            {isMarketWarningView && (
                <MarketWarningViewer
                    src={mngItm.data.mketWarnImgInfo.objUrl}
                    text={mngItm.data.mketWarnText}
                    Close={() => setMarketWarningView(false)}
                    isPreview={isPreview}
                />
            )}
            {
                showFileInput && <ImageInputBox
                    onClose={onImageInputBoxClose}
                    onRegister={onImageInputBoxResister}
                    fileName=""
                    className="absolute right-0 top-6" />
            }

            {/* 確認ボックス */}
            {showDeleteConfirm && <ConfirmBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                title="ファイル削除" message="削除しますか？"
                onYesClick={handleDeleteConfirmYes} onNoClick={handleDeleteConfirmNo} />}
        </div>
    );
}

export default AddDocPicture;
import React from "react";
import { IconContext } from "@react-icons/all-files";
import { FaUpload } from "@react-icons/all-files/fa/FaUpload"
import { variables } from "../Variables";

function HakkouButtonSm(props) {
    let iconColor = "#0073CD";
    let textColor = "";
    let hoverBg = variables.HOVER_CSS;
    if (!props.isEnable) {
        iconColor = variables.COLOR_DISABLED;
        textColor = "text-[" + iconColor + "]";
        hoverBg = null;
    }

    return (
        <div onClick={props.isEnable ? props.onClick : () => void 0}
            className={"flex items-center h-[35px] bg-white w-fit mx-[15px] px-[10px] hover:cursor-pointer " + hoverBg} >
            <IconContext.Provider
                value={{ color: iconColor, size: "16px" }}>
                <FaUpload />
            </IconContext.Provider>
            <div className={"ml-[20px] " + textColor}>{props.text}</div>
        </div>
    );
}

HakkouButtonSm.defaultProps = {
    isEnable: false,
    text: '発行'
};
export default HakkouButtonSm;
import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Discard from "./Discard";
import SectionTitle from "./SectionTitle";
import DropDown from "./DropDown";
import ItemDropDown from "./ItemDropDown";
import { popupcss, variables } from "../Variables";
import ConfirmBox from "./ConfirmBox";
import { urlCompanyName, NavigateToCompanyLogin, SetIsEditing, ToLocalDateTime } from "../features/util/commonFun";
import CopyButton from "./CopyButton";
import { setIsLoading as setPageLoading, getIsLoading as getIsPageLoading } from "../features/Loading/pageLoadingSlice";
import { useSelector, useDispatch } from "react-redux";
import { appInsights } from "../features/util/ApplicationInsight";

const axios = require("axios");

function ProcessChartCopy(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const isPageLoading = useSelector(getIsPageLoading);
    const [categorieList, setCategorieList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(-1);
    const [isDiscard, setIsDiscard] = useState(false);
    const [isCopyConfirm, setIsCopyConfirm] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState(null);

    useEffect(() => {
        if (categorieList.length === 0) {
            axios({
                method: 'GET',
                url: variables.CATEGORY_URL,
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                let data = response.data;
                data.sort((a, b) => a.name.localeCompare(b.name));
                setCategorieList(data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    // ログイン画面へ移動する。                       
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.useEffect()" });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        SetIsEditing(selectedCategory > -1);
        return function cleanup() {
            SetIsEditing(false);
        }
    }, [selectedCategory]);

    /**
     * カテゴリドロップダウンの選択変更処理
     * @param {*} e 
     */
    function handleCategorySelectedChange(e) {
        let categoryCode = e.target.value;
        setSelectedCategory(categoryCode);
        setItemList([]);
        if (categoryCode > -1) {
            let paramObj = { categoryCode: categoryCode };
            axios({
                method: 'GET',
                url: variables.ITEM_URL + "/get-itemlist-by-categorycode",
                params: paramObj,
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                let data = response.data;
                let itmList = data.filter((x) => x.itemCode !== props.processChartObj.itemCode).sort((a, b) => a.itemName.localeCompare(b.itemName)) ?? [];
                setItemList(itmList);
            }).catch((error) => {
                if (error.response.status === 401) {
                    // ログイン画面へ移動する。
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                    appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.handleCategorySelectedChange()" });
                }
            });
        }
    }

    /**
     * 製品名ドロップダウンの選択変更処理
     * @param {*} e 
     */
    function handleItemSelectedChange(e) {
        setSelectedItem(e.target.value);
    }

    /**
     * 複製の確認ボックスの「はい」ボタンのクリックイベントハンドラ
     */
    async function handleCopyConfirmBoxYesBtnClick() {
        if (isPageLoading) {
            return;
        }
        setIsCopyConfirm(false);
        dispatch(setPageLoading(true));
        let paramObj = { itemCode: selectedItem };
        await axios({
            method: 'GET',
            url: variables.PROCESS_CHART_URL + "/get-chart-by-itemcode",
            headers: { 'company': urlCompanyName },
            withCredentials: true,
            params: paramObj
        }).then(response => {
            // 既存の一時保存データが有る場合、確認メッセージを出す。
            if (response.data && response.data.userName.length > 0) {
                let confirmMsg = (
                    <p className='break-all'>
                        {`「${response.data.userName}」が「${ToLocalDateTime(response.data.updateDate)}」に作成したデータがあります。`}
                        <br />
                        {"上書きしますか。"}
                    </p>
                );
                setConfirmMessage(confirmMsg);
            } else {
                tempCopyAndSave();
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                NavigateToCompanyLogin(navigate);
            } else {
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.handleCopyConfirmBoxYesBtnClick()" });
                alert("送信できません。");
            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * 工程設定の複製処理
     */
    async function tempCopyAndSave() {
        if (isPageLoading) {
            return;
        }
        dispatch(setPageLoading(true));
        let paramObj = { motoProcessChartID: props.processChartObj.id, sakiItemCode: selectedItem };
        await axios({
            method: 'GET',
            url: variables.EDITOR_URL + "/temp-copy-and-save",
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(response => {
            let responseResult = response.data;
            if (responseResult) {
                props.onCopyBtnClick(selectedCategory, selectedItem);
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                // ログイン画面へ移動する。                       
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.tempCopyAndSave()" });

            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * 破棄ボタンのクリックイベントハンドラ
     */
    function handleDiscardBtnClick() {
        if (selectedCategory > -1 || selectedItem > -1) {
            setIsDiscard(true);
        }
        else {
            props.onDiscardBtnClick();
        }
    }

    return (
        <>
            <div className={popupcss}>
                <div className="h-[1033px] ml-[1320px] mt-[47px] w-[600px] bg-white border-l-2 shadow-md px-[15px] pt-[43px]">
                    <SectionTitle Text="工程設計の複製" />
                    <div className="flex h-[56px] mt-1">
                        <CopyButton onClick={() => { setIsCopyConfirm(true); }} isEnable={selectedCategory > -1 && selectedItem > -1} />
                        <Discard onClick={handleDiscardBtnClick} />
                    </div>
                    <div className="h-[2px] bg-[#C8C6C4] opacity-[.20] mx-[-15px]" />
                    <div className="h-[235px] mt-4">
                        <SectionTitle Text={"複製元"} />
                        <div className="font-bold text-[20px] ml-2 mt-5">製品名</div>
                        <div className=" text-[18px] ml-4 mt-2 w-[520px] break-all">
                            {props.processChartObj.itemName}
                        </div>
                        <div className="font-bold text-[20px] ml-2 mt-5">版</div>
                        <div className="text-[18px] ml-4 mt-2 w-[520px] break-all">
                            {props.processChartObj.version.toString().padStart(3, '0')}
                        </div>
                    </div>
                    <div className="h-[100px]">
                        <SectionTitle Text={"複製先"} />
                        <div className="items-center mb-8 ml-2 mt-5">
                            <div className="w-[135px] text-[20px] mb-2">
                                <label htmlFor="cateName">カテゴリ<span className="text-[#CD0000] ml-1 font-bold">*</span></label>
                            </div>
                            <DropDown
                                itemsSource={categorieList}
                                selectedValuePath={"id"}
                                displayMemberPath={"name"}
                                onChange={(e) => handleCategorySelectedChange(e)}
                                selectedValue={selectedCategory}
                                firstOption="カテゴリを選択して下さい。"
                            />
                        </div>
                        <div className="items-center mb-8 ml-2 mt-5">
                            <div className="w-[135px] text-[20px] mb-2">
                                <label htmlFor="itemCode">製品名<span className="text-[#CD0000] ml-1 font-bold">*</span></label>
                            </div>
                            <ItemDropDown
                                itemList={itemList}
                                onChange={(e) => handleItemSelectedChange(e)}
                                selectedValue={selectedItem}
                                firstOption="製品を選択して下さい。"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isDiscard && <ConfirmBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                title="変更の破棄"
                message={"作業が破棄されます。よろしいですか？"}
                onYesClick={props.onDiscardBtnClick}
                onNoClick={() => { setIsDiscard(false); }}
            />}
            {isCopyConfirm && <ConfirmBox
                className="absolute right-[20px] top-[150px] w-[445px]"
                title="工程設計の複製"
                message={<p className='break-all'>
                    {`「${props.processChartObj.itemName} ${props.processChartObj.version.toString().padStart(3, '0')}版」の工程設定を`}
                    <br />
                    {`「${itemList.find((itm) => itm.itemCode === Number(selectedItem))?.itemName}」の一時保存に複製します。`}
                    <br />
                    {"よろしいですか？"}
                </p>}
                onYesClick={() => handleCopyConfirmBoxYesBtnClick()}
                onNoClick={() => { setIsCopyConfirm(false); }}
            />}
            {confirmMessage && <ConfirmBox
                className="absolute right-[162px] top-[125px] w-[528px]"
                title="一時保存" message={confirmMessage}
                onYesClick={() => { tempCopyAndSave(); setConfirmMessage(null); }}
                onNoClick={() => { setConfirmMessage(null); }}
            />}
        </>
    )
}

export default ProcessChartCopy;
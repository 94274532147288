import { React, forwardRef } from "react";
/**
 * タブ入力可能のテキストエリア。
 * タブを4桁のスペースで交換して入力する。
 * @param {*} props 
 * @returns 
 */

const TextAreaTab = forwardRef((props, ref) => {
    // タブのキーコード
    const TABKEY = 9;
    // 交換文字
    const REPLACE_CHAR = "    ";

    function onInputKeyDown(e) {
        if (e.keyCode === TABKEY) {
            e.preventDefault();
            e.target.setRangeText(REPLACE_CHAR, e.target.selectionStart, e.target.selectionEnd, "end");
        }

        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    }

    function onInputKeyUp(e) {
        if (props.onKeyUp) {
            props.onKeyUp(e);
        }
    }

    return (
        <>
            <textarea {...props} ref={ref}
                onKeyDown={onInputKeyDown}
                onKeyUp={onInputKeyUp}
            />
        </>
    );
});

export default TextAreaTab;
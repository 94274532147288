import { IconContext } from "react-icons";
import { VscError } from "react-icons/vsc";

function ErrorText(props) {
    return (
        <>
            <div >
                <label className="text-red-600">
                    <IconContext.Provider value={{ color: "red" }}>
                        <VscError className="w-[18px] h-[21px] inline-block pb-1" />
                        {props.errMsg}
                    </IconContext.Provider>
                </label>
            </div>
        </>
    );
}

ErrorText.defaultProps = {
    errMsg: "",
}

export default ErrorText;
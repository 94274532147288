import { createSlice } from '@reduxjs/toolkit';

const intState = {
    visible: false,
}

export const errorBoxSlice = createSlice({
    name: 'errorBox',
    initialState: intState,
    reducers: {
        setVisible: (errorBox, action) => {
            errorBox.visible = action.payload;
        }
    }
});

export const getVisible = (state) => {
    return state.errorBox.visible;
}

export const { setVisible } = errorBoxSlice.actions;
export default errorBoxSlice.reducer;
import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkDropDown from "./WorkDropDown";
import {
    getSelectedWorkInfo,
    selectWorkListByProcessId,
    getWorkListWithoutHindoSetting,
} from "./workListSlice";
import {
    setBunkiWorkId,
    setBunkiBtnText,
    getAllMngItm
} from "./manageItemSlice";
import { selectProcessList } from "../process/processListSlice";
import { nanoid } from "nanoid";
import InputBox from "../../../components/InputBox";
import { TextType } from "../../../Variables";

function SagyouBunkiBtn(props) {
    const dispatch = useDispatch();
    const mngItm = props.mngItm;
    const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
    const selectedWorkID = selectedWork.workID;
    const selectedProcessId = selectedWork.processID;
    const processList = useSelector(selectProcessList);
    const bunkiDataList = [...mngItm.data.bunkiData].sort((a, b) => a.dispOrder - b.dispOrder);
    const allMngItm = useSelector(getAllMngItm);
    let selectedProcess = processList.filter((p) => p.processID === selectedProcessId);
    let workListByProcessId = useSelector((state) => selectWorkListByProcessId(state, selectedProcessId));
    workListByProcessId = workListByProcessId.filter((x) => x.workID !== selectedWork.workID);
    const wrklstWithoutHindoSetting = useSelector(state => getWorkListWithoutHindoSetting(state, workListByProcessId, allMngItm));

    function onSagyouBunkiDropDownChange(value, dispOrder) {
        dispatch(setBunkiWorkId({ dispOrder: dispOrder, bunkiWorkId: value, workID: selectedWorkID }));
    }

    function onSagyouBunkiInputTextChange(inputText, dispOrder) {
        dispatch(setBunkiBtnText({ dispOrder: dispOrder, bunkiBtnText: inputText, workID: selectedWorkID }));
    }

    return (
        <div className="flex">
            <div className="mt-[-7px]">
                <span className={"font-bold w-[41px] mr-[9px]"}>分岐1</span>
                <span className="text-2xl font-bold text-red-500 mt-[5px]">*</span>
                <WorkDropDown
                    key={nanoid()}
                    firstOption="分岐する作業を選択してください"
                    width={208}
                    height={29}
                    firstOptionLabelCss={"text-[15px] w-[190px] overflow-hidden whitespace-nowrap"}
                    selectedOption={bunkiDataList[0].workID}
                    onSelectedIndexChange={(val) => onSagyouBunkiDropDownChange(val, 1)}
                    isLock={selectedWork.isLock}
                    processList={selectedProcess}
                    workList={wrklstWithoutHindoSetting}
                />
                <InputBox
                    className={"w-[208px] h-[29px] pl-[5px] mt-1 border-[1px] rounded-[2px] resize-none border-[#C8C6C4] outline-none "}
                    placeholder="ボタンの表示（5文字以内）"
                    maxLength={5}
                    required={true}
                    value={bunkiDataList[0].btnText}
                    onChange={(e) => onSagyouBunkiInputTextChange(e.target.value, 1)}
                    disabled={selectedWork.isLock}
                    textType={TextType.ALL}>
                </InputBox>
            </div>
            <div className="mt-[-7px] ml-[6px]">
                <span className="font-bold w-[41px] mr-[9px] mt-1">分岐2</span>
                <span className="text-2xl font-bold text-red-500 mt-[5px]">*</span>
                <WorkDropDown
                    key={nanoid()}
                    firstOption="分岐する作業を選択してください"
                    width={208}
                    height={29}
                    firstOptionLabelCss={"text-[15px] w-[190px] overflow-hidden whitespace-nowrap"}
                    selectedOption={bunkiDataList[1].workID}
                    onSelectedIndexChange={(val) => onSagyouBunkiDropDownChange(val, 2)}
                    isLock={selectedWork.isLock}
                    processList={selectedProcess}
                    workList={wrklstWithoutHindoSetting}
                />
                <InputBox
                    className={"w-[208px] h-[29px] pl-[5px] mt-1 border-[1px] rounded-[2px] resize-none border-[#C8C6C4] outline-none "}
                    placeholder="ボタンの表示（5文字以内）"
                    maxLength={5}
                    required={true}
                    value={bunkiDataList[1].btnText}
                    onChange={(e) => onSagyouBunkiInputTextChange(e.target.value, 2)}
                    disabled={selectedWork.isLock}
                    textType={TextType.ALL}>
                </InputBox>
            </div>
        </div>
    );
}

export default SagyouBunkiBtn;
import React from "react";
import { IconContext } from "react-icons";
import { GoPlus } from "react-icons/go";
import { variables } from "../Variables";
function Add(props) {
    return (
        <div onClick={props.onClick}
            className={props.className || "h-[53px] w-fit"}
             >
            <div className={"flex flex-row h-full w-fit mx-[15px] hover:cursor-pointer " + variables.HOVER_CSS}>
                <IconContext.Provider
                    value={{ color: "#0073CD", size: "16px", className: "self-center" }}>
                    <GoPlus />
                </IconContext.Provider>
                <div className="mx-[5px] font-bold self-center">{props.Text}</div>
            </div>

        </div >
    );
}

export default Add;
import React from "react";
import { IconContext } from "react-icons";
import { MdOutlineClose } from "react-icons/md";
import { popupcss } from "../Variables";

function DetailDocTextViewer(props) {
    return (
        <div className={popupcss}>
            <div className="w-[620px] h-[1075px] ml-[1299px] bg-white shadow-md" >
                <div className="flex w-[620px] h-[81px] border-2 border-[#C8C6C4] ">
                    <label className="w-[500px] h-[60px] font-bold text-[29px] ml-[20px] mt-[24px]">詳細テキスト</label>
                    <IconContext.Provider value={{ size: "30px" }}>
                        <MdOutlineClose className="mt-[22px] mb-[2px] ml-[55px] bg-white cursor-pointer hover:bg-gray-200" onClick={props.Close} />
                    </IconContext.Provider>
                </div>
                <div className="w-[620px] h-[994px] border-2 border-[#C8C6C4]">
                    <textarea disabled
                        className={"w-[616px] h-[990px] font-bold text-[29px] px-4 py-4 leading-9 bg-white resize-none overflow-y-auto"}
                        value={props.text} />
                </div>
            </div>
        </div>
    );
}

export default DetailDocTextViewer;

import React, { useRef, useState } from "react";
import { VscCalendar } from "react-icons/vsc";
import { ConvertDateFormat, ConvertDisplayDateFormat } from "../features/util/commonFun";

export function DatePicker(props) {
    const [isFocus, setIsFocus] = useState(false);
    const dateRef = useRef();

    function handleShowPopup(e) {
        if (dateRef) {
            dateRef.current.showPicker();
            setIsFocus(true);
        }
    }

    function handleDateInputChange(e) {
        props.onDateChange(e.target.value);
    }

    const convertDateTimeFormat = (selectDate) => {
        let result = "";
        if (selectDate.length > 0) {
            result = ConvertDisplayDateFormat(selectDate);
        }
        return result;
    }

    return (
        <div className="flex">
            <div className={"flex w-fit h-[30px] items-center border-[1px] rounded-sm cursor-pointer " + (isFocus ? " border-[#0073CD]" : " border-[#707070]")}
                tabIndex={-1}
                onBlur={() => { setIsFocus(false); }}
                onClick={handleShowPopup}>
                <input type="text" className="h-full w-[110px] py-[0.8px] px-1 outline-none cursor-pointer"
                    value={convertDateTimeFormat(props.value)}
                    readOnly={true} />
                <VscCalendar className=" mr-1" style={{ color: 'black', fontSize: '18px' }} />
            </div>
            <input ref={dateRef} type="date" value={ConvertDateFormat(props.value)} className="w-0 h-0 opacity-0" onChange={handleDateInputChange} />
        </div>
    );
}

DatePicker.defaultProps = {
    value: "",
    onDateChange: () => { void (0) },
}
import React from "react";
import { TextType } from "../Variables";

function TextInput(props) {
  function onChange(e) {
    const str = e.target.value;
    if (props.textType === TextType.HALFWIDTH_INPUT) {
      if (str.match(/^[A-Za-z0-9]*$/)) {
        props.onChange(e);
      }
    } else if (props.textType === TextType.HALFWIDTH_NUMBER) {
      if (str.match(/^[0-9]*$/)) {
        props.onChange(e);
      }
    } else if (props.textType === TextType.HALFWIDTH_SYMBOL_INPUT) {
      if (str.match(/^[A-Za-z0-9#_-]*$/)) {
        props.onChange(e);
      }
    } else if (props.textType === TextType.ALL) {
      props.onChange(e);
    }
  }
  return (
    <>
      <input
        type="text"
        className="w-[373px] h-[29px] border-[1px] rounded-sm border-black outline-none outline-offset-0 focus:outline-[#0073CD] focus:border-0 text-ellipsis overflow-hidden"
        value={props.value}
        maxLength={props.maxLength}
        onChange={onChange}
      />
    </>
  );
}

TextInput.defaultProps = {
  textType: TextType.ALL,
  maxLength: null
};
export default TextInput;

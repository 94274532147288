import React from 'react';

function SectionTitle(props) {
  return (
    <div className='text-[26px] font-bold'>
      <div className={props.className || null}>{props.Text}</div>
    </div>
  );
}
// Specifies the default values for props:
SectionTitle.defaultProps = {
  Text: '',
};
export default SectionTitle;

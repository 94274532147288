import React from 'react'
  
const ProgressBar = ({bgcolor,max,progress,height,width}) => {     
    const Parentdiv = {
        height: height,
        width: width,
        backgroundColor: '#979797',             
      }      
      const Childdiv = {
        height: '100%',
        width: `${(progress / max) * 100}%`,
        backgroundColor: bgcolor,
      }
        
    return (
    <div className=' border-[1px] border-[#707070]' style={Parentdiv}>
      {(max > 0) && <div style={Childdiv} />}
    </div>
    )
}
  
export default ProgressBar;
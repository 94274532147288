import { React, useState } from 'react';
import KurikaeshiToggle from "./KurikaeshiToggle";
import EditButton from "./EditButton";
import HindoKaisuInput from "./HindoKaisuInput";
import { useDispatch, useSelector } from "react-redux";
import { selectMngItmByWorkID, setHindoKaisuSetting, emptyHindoKaisuSettingObj } from "../features/editor/work/manageItemSlice";
import ConfirmBox, { FocusButton } from './ConfirmBox';

function HindokaisuuSetting(props) {
  const dispatch = useDispatch();
  const selectedWorkID = props.workID;
  const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
  const hindoKaisuSettingObj = mngItm.data.hindoKaisuSetting;
  const [showEditTexthover, setShowEditTextHover] = useState(false);
  const [dialogBox, setDialogBox] = useState(null);

  const handleMouseEnter = () => {
    const hindoText = hindoKaisuTextVal();
    if (hindoText?.length > 13) {
      setShowEditTextHover(true);
    } else {
      setShowEditTextHover(false);
    }
  };

  const handleMouseLeave = () => {
    setShowEditTextHover(false);
  };

  const hindoKaisuTextVal = () => {
    const hindoSetting = hindoKaisuSettingObj.hindoSetting;
    const kaisuSetting = hindoKaisuSettingObj.kaisuSetting;
    let hindoSettingText = [];
    let kaisuSettingText = [];
    if (hindoSetting.shohin === true) {
      hindoSettingText.push("初品");
    }
    if (hindoSetting.chukanhin === true) {
      hindoSettingText.push("中間品");
    }
    if (hindoSetting.shuhin === true) {
      hindoSettingText.push("終品");
    }
    if (hindoSetting.toukankaku === true) {
      hindoSettingText.push("等間隔" + hindoSetting.toukankakuData);
    }

    if (hindoSettingText.length > 0) {
      return "頻度：" + hindoSettingText.join(", ");
    }

    if (kaisuSetting.batchSize === true) {
      kaisuSettingText.push("回数：バッチサイズ全数");
    };
    if (kaisuSetting.kotei === true) {
      kaisuSettingText.push("回数：固定" + kaisuSetting.koteiData + "回");
    }
    if (kaisuSetting.wariai === true) {
      kaisuSettingText.push("回数：割合" + kaisuSetting.wariaiData + "%");
    }
    if (kaisuSettingText.length > 0) {
      return kaisuSettingText.join(", ");
    }
    return null;
  };

  function handleToggleChangeConfirmYes() {
    dispatch(setHindoKaisuSetting({ hindoKaisuSetting: emptyHindoKaisuSettingObj, workID: selectedWorkID }));
    setDialogBox(null);
    props.onHindoKaisuToggleChange();
  }

  const handleHindoKaisuToggleChange = () => {
    if (props.isChecked === true && hindoKaisuTextVal()) {
      setDialogBox(
        <ConfirmBox
          className="absolute ml-[1400px] mt-[733px] w-[471px]"
          title="管理項目の変更"
          message={<>設定した内容が削除されます。<br />変更しますか？</>}
          foucsBtn={FocusButton.Btn1}
          onYesClick={() => { handleToggleChangeConfirmYes() }}
          onNoClick={() => setDialogBox(null)}
        />
      );
    } else {
      props.onHindoKaisuToggleChange();
    }
  };

  return (
    <>
      <div className="flex w-[525px] h-[30px]">
        <KurikaeshiToggle
          isChecked={props.isChecked}
          onChange={handleHindoKaisuToggleChange}
          isLock={props.isLock}
        />
        {props.isChecked && (
          <>
            <div>
              <EditButton
                className="w-[64px] h-[23px] mt-1 ml-[50px]"
                onClick={props.onEditClick}
                editBtnCss="flex items-center justify-center mt-[-6px] w-[70px] h-[38px] cursor-pointer bg-white"
                isLock={props.isLock}
              />
            </div>
            <div className="text-[#CD0000] mx-2 mt-2 text-[30px]">
              <strong>*</strong>
            </div>

            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="border-[1px] border-[#C8C6C4] p-1 w-[170px] h-[32px] whitespace-nowrap text-ellipsis overflow-hidden"
            >
              {hindoKaisuTextVal()}
            </div>
          </>
        )}
      </div>
      {props.showHindoKaisuInput && (
        <HindoKaisuInput
          workID={props.workID}
          onClose={props.onHindoKaisuInputClose}
        />
      )}
      {/*ツールチップ表示*/}
      {showEditTexthover && (
        <div
          className="text-black drop-shadow-[0_5px_5px_rgba(96,94,92,1)] ml-[314px] bg-white border p-1 rounded whitespace-pre-line w-[170px] min-h-[50px] max-h-[200px] overflow-auto"
          style={{
            transform: 'translateY(-142%)',
            zIndex: 10,
          }}
        >
          {hindoKaisuTextVal().split(', ').map((hoverMessage, index) => (
            <div key={index} className={`${index > 0 ? 'pl-12' : ''} text-left`}>
              {hoverMessage}
            </div>
          ))}
        </div>
      )}
      {dialogBox}
    </>
  );
};

HindokaisuuSetting.defaultProps = {
  onHindoKaisuToggleChange: () => void (0)
};

export default HindokaisuuSetting;

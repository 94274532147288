import { React } from "react";

/**
 * トグルボタン
 * @param {*} props
 * @returns
 */
function ToggleButton(props) {
  return (
    <div>
      <div className="relative w-[46px] h-[22px]">
        <input
          type="checkbox"
          id={props.inputID}
          checked={props.isChecked}
          onChange={props.onChange}
          disabled={props.isLock}
          className="peer hidden"
        />
        <label
          htmlFor={props.inputID}
          className={"absolute inline-flex items-center w-full h-full rounded-full cursor-pointer float-right bg-white border-2 peer-checked:hidden " + (props.isLock ? "border-[#A19F9D]" : "border-[#605E5C]")}
        >
          <div className={"w-[14px] h-[14px] ml-[2px] rounded-full " + (props.isLock ? "bg-[#A19F9D]" : "bg-[#605E5C]")} />
        </label>
        <span className={"absolute right-[-26px] peer-checked:hidden " + (props.isLock ? "text-[#A19F9D]" : "")}>オフ</span>
        <label
          htmlFor={props.inputID}
          className={"absolute inline-flex items-center justify-end invisible w-full h-full rounded-full cursor-pointer peer-checked:visible" + (props.isLock ? " bg-[#A19F9D]" : " bg-[#0073CD]")}
        >
          <div className="w-[14px] h-[14px] mr-1 rounded-full bg-white" />
        </label>
        <span className={"absolute right-[-26px] invisible peer-checked:visible " + (props.isLock ? "text-[#A19F9D]" : "")}>
          オン
        </span>
      </div>
    </div >
  );
}

ToggleButton.defaultProps = {
  isChecked: false,
};
export default ToggleButton;

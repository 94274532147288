import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setPdfFile,
  deletePdfFile,
  selectPdfFileByWorkId,
} from "./detailDocSlice";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import { IconContext } from "@react-icons/all-files";
import { VscFilePdf } from "@react-icons/all-files/vsc/VscFilePdf";
import CheckCircle from "../../../components/CheckCircle";
import PDFInputBox from "./PDFInputBox";
import ConfirmBox from "../../../components/ConfirmBox";
import { getSelectedWorkInfo } from "./workListSlice";
import { variables } from "../../../Variables";

/**
 * 詳細資料のPDF追加コンポネント
 * @param {*} props
 * @returns
 */
function DetailDocPDF(props) {
  const dispatch = useDispatch();
  const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
  const [showFileInput, setShowFileInput] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  // 詳細資料　PDf
  const dtlDocPdf = useSelector(state => selectPdfFileByWorkId(state, props.workID));
  let pdfObjUrl = (dtlDocPdf && dtlDocPdf.docFileName.length > 0) ? dtlDocPdf.objUrl : "";

  // 追加ボタンのイベントハンドラ
  function handleAddButton() {
    // PDF追加ボックスを表示する。
    setShowFileInput(true);
  }

  // 削除ボタンのイベントハンドラ
  function handleDeleteButton() {
    setShowDeleteConfirm(true);
  }

  // 削除確認ボックスの「はい」ボタンのイベントハンドラ
  function handleDeleteConfirmYes() {
    dispatch(deletePdfFile({ workID: props.workID }));
    setShowDeleteConfirm(false);
  }

  // 削除確認ボックスの「いいえ」ボタンのイベントハンドラ
  function handleDeleteConfirmNo() {
    setShowDeleteConfirm(false);
  }

  /**
   * PDFInputBox.jsx > 登録ボタン イベントハンドラ
   * 
   * @param {*} objUrl 
   * @param {*} fileName 
   */
  function onPDFInputBoxRegister(objUrl, fileName) {
    if (objUrl) {
      // dispatch pdf File
      dispatch(setPdfFile({ workID: props.workID, fileName: fileName, objUrl: objUrl }));
    }
    setShowFileInput(false);
  }

  function onPDFInputBoxClose() {
    // ダイアログを閉じる
    setShowFileInput(false);
  }

  async function handelPDFViewer() {
    window.open(pdfObjUrl, '_blank');
  }

  const iconColor = selectedWork.isLock ? "#A19F9D" : "";
  const textColor = selectedWork.isLock ? " text-[#A19F9D]" : "";

  return (
    <div className={props.className || null}>
      <div className="flex items-center relative w-[281px] h-[56px] border-2 border-[#C8C6C4]">
        <div className="w-[60px] ml-1.5">
          <div className={"font-bold" + textColor}>PDF</div>
          <div>
            <IconContext.Provider value={{ size: "27px", color: iconColor }}>
              {/* TODO: PDFアイコン作成 */}
              <VscFilePdf />
            </IconContext.Provider>
          </div>
        </div>
        <div className="w-[80px] ml-[-8px]">
          {dtlDocPdf && dtlDocPdf.docFileName.length > 0 ? (
            <CheckCircle
              iconColor={"#0073CD"}
              textColor={"text-[#0073CD]"}
              hoverbgColor={variables.HOVER_CSS}
              isEnable={true}
              onClick={handelPDFViewer} />
          ) : null}
        </div>
        <div className="grow flex flex-row-reverse">
          <div>
            <DeleteButton
              className="w-[70px] h-[48px]"
              Text="削除"
              onClick={handleDeleteButton}
              isEnable={
                dtlDocPdf &&
                dtlDocPdf.docFileName.length > 0 &&
                !selectedWork.isLock
              }
            />
          </div>
          <div>
            <AddButton
              className="w-[70px] h-[48px]"
              Text="追加"
              isEnable={props.workID.length > 0 && !selectedWork.isLock}
              onClick={handleAddButton}
            />
          </div>
        </div>
      </div>

      {/* PDFファイル入力ボックス */}
      {showFileInput && <PDFInputBox
        onRegister={onPDFInputBoxRegister}
        onClose={onPDFInputBoxClose}
        fileName={""}
        className="absolute right-0 top-6" />}

      {/* 確認ボックス */}
      {showDeleteConfirm && (
        <ConfirmBox
          className="absolute right-[162px] top-[265px] w-[471px]"
          title="ファイル削除"
          message="削除しますか？"
          onYesClick={handleDeleteConfirmYes}
          onNoClick={handleDeleteConfirmNo}
        />
      )}
    </div>
  );
}

export default DetailDocPDF;

import React from "react";
import { variables, popupcss } from "../Variables";

function Logout(props) {

    return (
        <div onClick={props.onContainerDivClick} className={popupcss}>
            <div className={props.className}>
                <div className={"w-[160px] h-[50px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white "}>
                    <div
                        onClick={props.onLogout}
                        className={"flex items-center h-full w-full px-[15px] cursor-pointer " + variables.HOVER_CSS}>
                        <div>
                            サインアウト
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Logout;
import React from "react";

function ReadOnlyTextBox(props) {
    return (
        <>
            <div
                className={'w-[373px] h-[29px] border-[1px] pl-[5px] rounded-sm outline-none outline-offset-0 cursor-pointer border-[#C8C6C4]'}
                onClick={props.onClick}
            >
                {props.text}
            </div>
        </>
    );
}

export default ReadOnlyTextBox;
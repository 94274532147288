import { React, useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { FaPhotoVideo } from "react-icons/fa";
import { HiOutlineChevronDoubleDown, HiOutlineChevronDoubleUp } from "react-icons/hi";
import { VscFilePdf } from "react-icons/vsc"
import { BsFileText } from "react-icons/bs"
import { useSelector, useDispatch } from "react-redux";
import { DetailDocType } from "../../../Variables";
import SerialToolListInfo from "./SerialToolListInfo";
import DetailDocMoviePlayer from "../../../components/DetailDocMoviePlayer";
import DetailDocTextViewer from "../../../components/DetailDocTextViewer";
import {
  setWorkExpandMode,
  setPauseExpandMode,
  setJustSelectWorkId,
  setIsWorkImageExpandMode,
} from "../serialOperationSlice";
import { getVisible as getConfirmBoxVisible } from "../../../components/confirmBoxSlice";
import { getVisible as getErrorBoxVisible } from "../../../components/errorBoxSlice";
import { getIsLoading as getIsPageLoading } from "../../Loading/pageLoadingSlice";
import { appInsights } from "../../util/ApplicationInsight";

/**
 * 次の作業ボタンのref
 */
let btnNextSagyou = null;
/**
 * 完了ボタンのref
 */
let btnKanryou = null;
let isMoviePlayerVisible = false;
let isTextViewVisible = false;
let isConfirmBoxVisible = false;
let isErrorBoxVisible = false;

function SerialWorkInfo(props) {
  const dispatch = useDispatch();
  const isPageLoading = useSelector(getIsPageLoading);
  const items = useSelector((state) => state.serialOperation);
  const selectedWork = items.selectedWork;
  const detailDocList = items.dataList.detailDocList.filter((item) => item.workId.toLowerCase() === selectedWork.workId.toLowerCase());
  let isExistMovie = detailDocList.some((item) => item.detailDocType === DetailDocType.MOVIE);
  let isExistPdf = detailDocList.some((item) => item.detailDocType === DetailDocType.PDF);
  let isExistText = detailDocList.some((item) => item.detailDocType === DetailDocType.TEXT);
  let processObj = items.processObj;
  let movieObjUrl = (isExistMovie === true) ? detailDocList.find((item) => item.detailDocType === DetailDocType.MOVIE).objUrl : "";
  let pdfObjUrl = (isExistPdf === true) ? detailDocList.find((item) => item.detailDocType === DetailDocType.PDF).objUrl : "";
  let textObjUrl = (isExistText === true) ? detailDocList.find((item) => item.detailDocType === DetailDocType.TEXT).objUrl : "";
  let isWorkImageExpandMode = items.isWorkImageExpandMode;

  const [isMoviePlay, setMoviePlay] = useState(false);
  const [isTextView, setTextView] = useState(false);
  const [docText, setDocText] = useState("");
  isConfirmBoxVisible = useSelector(getConfirmBoxVisible);
  isErrorBoxVisible = useSelector(getErrorBoxVisible);

  useEffect(() => {
    document.addEventListener("keyup", documentKeyup);
    return function cleanup() {
      document.removeEventListener("keyup", documentKeyup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Enterキーを押した時、
   * 次の作業ボタンが表示している場合、 次の作業ボタンのクリックエベントを発生する。
   * もしくは完了ボタンが表示している場合、 完了ボタンのクリックエベントを発生する。
   * @param {*} e 
   */
  function documentKeyup(e) {
    if (isMoviePlayerVisible || isTextViewVisible || isConfirmBoxVisible ||
      isErrorBoxVisible || isPageLoading === true || window.speechSynthesis.speaking) {
      return;
    }

    if (e.key === "Enter") {
      if (btnNextSagyou) {
        btnNextSagyou.click();
      } else if (btnKanryou) {
        btnKanryou.click();
      }
    }
  }

  /**
   *　次の作業ボタンのrefを設定する。
   * @param {*} element 
   */
  function setBtnNextSagyou(element) {
    btnNextSagyou = element;
  }

  /**
 *　完了ボタンのrefを設定する。
 * @param {*} element 
 */
  function setBtnKanryou(element) {
    btnKanryou = element;
  }

  function setMoviePlayState(val) {
    isMoviePlayerVisible = val;
    setMoviePlay(val);
  }

  function setTextViewState(val) {
    isTextViewVisible = val;
    setTextView(val);
  }

  /**
   * 詳細資料／作業指示の拡大又は倒壊する処理
   * @param {*} e 
   */
  function onClickExpandCollapse(e) {
    if (isWorkImageExpandMode) {
      dispatch(setIsWorkImageExpandMode(false));
    } else {
      dispatch(setIsWorkImageExpandMode(true));
    }
  }

  /**
   * 作業情報表示エリアをクリックすると作業リスト又は中断を倒壊する
   * @param {*} e 
   */
  function handleResetExpandMode(e) {
    dispatch(setWorkExpandMode(false));
    dispatch(setPauseExpandMode(false));
    dispatch(setJustSelectWorkId(""));
  }

  /**
   *  詳細資料／作業指示の動画プレーヤー
   * @param {*} e 
   */
  function handleMediaPlayer(e) {
    setMoviePlayState(true);
  }

  /**
   *  詳細資料／作業指示のPDFビューアー
   * @param {*} e 
   */
  async function handlePDFViewer(e) {
    window.open(pdfObjUrl, '_blank');
  }

  /**
   * 詳細資料／作業指示のテキストビューアー
   */
  async function handleTextViewer() {
    try {
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        setDocText(e.target.result);
        setTextViewState(true);
      });
      let blob = await fetch(textObjUrl).then((r) => r.blob());
      reader.readAsText(blob);
    } catch (error) {
      appInsights.trackException({ ...error, errorFunction: "SerialWorkInfo.handleTextViewer()" });
      console.error(error);
    }
  }

  return (
    <div className="flex w-[1870px] h-[1080px]" onClick={(e) => handleResetExpandMode(e)}>
      <div className="w-[1370px] h-[1080px]" key={selectedWork.workId}>
        <div className="flex h-[75px] ml-[35px] mt-[10px]">
          <div>
            <div className="w-[40px] h-[65px] font-bold text-[26px] text-right textmiddle">
              {processObj.dispOrder === -1 ? "" : processObj.dispOrder}
            </div>
          </div>
          <div className="w-[514px] h-[65px] ml-[5px] border-2 border-[#C8C6C4] overflow-hidden shadow-md">
            <div className="w-[510px] h-[60px] px-2 break-all leading-7 font-bold text-[26px] text-left textmiddle">
              {processObj.processName}
            </div>
          </div>
          <div>
            <div className="w-[40px] h-[65px] font-bold text-[26px] text-right textmiddle">
              {selectedWork.dispOrder === -1 ? "" : selectedWork.dispOrder}
            </div>
          </div>
          <div className="w-[679px] h-[65px] border-2 border-[#C8C6C4] ml-[5px] overflow-hidden shadow-md">
            <div className="w-[675px] h-[60px] px-2 break-all leading-7 font-bold text-[26px] text-left textmiddle">
              {selectedWork.workName}
            </div>
          </div>
        </div>
        {/* 作業写真データを取得して表示 */}
        {isWorkImageExpandMode === false ? (
          <div className="h-[763px]">
            <img
              src={selectedWork.objUrl}
              alt="..."
              className="w-[1024px] h-[761px] object-contain border-2 border-[#C8C6C4] ml-[160px] shadow-md"
            />
          </div>
        ) : (
          <div className="h-[952px]">
            <img
              src={selectedWork.objUrl}
              alt="..."
              className="w-[1276px] h-[950px] object-contain border-2 border-[#C8C6C4] ml-[42px] shadow-md"
            />
          </div>
        )}

        {isWorkImageExpandMode === false ? (
          <div className="w-[1343px] h-[223px] mt-[5px] ml-[15px] shadow-md">
            <div className="flex w-[1343px] h-[34px] border-2 bg-zinc-100">
              <div
                className="w-[35px] h-[32px] cursor-pointer hover:bg-gray-200"
                onClick={(e) => onClickExpandCollapse(e)}
              >
                <IconContext.Provider value={{ size: "25px" }}>
                  <HiOutlineChevronDoubleDown className="mt-[2px] ml-[8px]" />
                </IconContext.Provider>
              </div>
              <div>
                <div className="w-[250px] h-[30px] textmiddle">
                  <label className="font-bold text-[18px] ml-[5px]">
                    詳細資料／作業指示
                  </label>
                </div>
              </div>
            </div>
            <div className="flex w-[1343px] h-[189px]">
              <div className="w-[124px] border-2 border-[#C8C6C4]">
                <div className="w-[106px] h-[59px] ml-[7px] mt-[2px]">
                  {isExistMovie &&
                    <div className={"w-[106px] h-[59px] cursor-pointer " + (isMoviePlay === true ? " bg-[#0068B8] " : " bg-[#0073CD] hover:bg-[#0068B8]")} onClick={(e) => handleMediaPlayer(e)}>
                      <label className="font-bold text-[18px] cursor-pointer"><span className=" ml-[34px] text-white ">動画</span></label>
                      <IconContext.Provider value={{ size: "30px", color: "#FFFFFF" }}>
                        <FaPhotoVideo className="ml-[36px] cursor-pointer" />
                      </IconContext.Provider>
                    </div>
                  }
                </div>
                <div className="w-[106px] h-[59px] ml-[7px] mt-[2px]">
                  {isExistPdf &&
                    <div className={"w-[106px] h-[59px] bg-[#0073CD] cursor-pointer hover:bg-[#0068B8]"} onClick={(e) => handlePDFViewer(e)}>
                      <label className="font-bold text-[18px] cursor-pointer"><span className="text-white ml-[34px] ">PDF</span></label>
                      <IconContext.Provider value={{ size: "30px", color: "#FFFFFF" }}>
                        <VscFilePdf className="ml-[36px] cursor-pointer" />
                      </IconContext.Provider>
                    </div>
                  }
                </div>
                <div className="w-[106px] h-[59px] ml-[7px] mt-[2px]">
                  {isExistText &&
                    <div className={"w-[106px] h-[59px] cursor-pointer " + (isTextView === true ? " bg-[#0068B8] " : " bg-[#0073CD] hover:bg-[#0068B8]")} onClick={(e) => handleTextViewer(e)}>
                      <label className="font-bold text-[18px] ml-[2px] cursor-pointer"><span className="text-white ml-[23px]">テキスト</span></label>
                      <IconContext.Provider value={{ size: "29px", color: "#FFFFFF" }}>
                        <BsFileText className="ml-[36px] cursor-pointer" />
                      </IconContext.Provider>
                    </div>
                  }
                </div>
              </div>
              <div className="w-[1221px] border-2 border-[#C8C6C4]">
                <textarea disabled
                  className={"w-[1218px] h-[185px] font-bold text-[29px] leading-9 break-all px-2 bg-white overflow-x-hidden overflow-y-auto resize-none"}
                  value={selectedWork.workNote} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-[1343px] h-[34px] border-2 bg-zinc-100 mt-[5px] ml-[15px]">
            <div
              className="w-[35px] h-[32px] cursor-pointer hover:bg-gray-200"
              onClick={(e) => onClickExpandCollapse(e)}
            >
              <IconContext.Provider value={{ size: "25px" }}>
                <HiOutlineChevronDoubleUp className="mt-[2px] ml-[8px]" />
              </IconContext.Provider>
            </div>
            <div>
              <div className="w-[250px] h-[30px] textmiddle">
                <label className="font-bold text-[18px] ml-[5px]">
                  詳細資料／作業指示
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-[600px] h-[1080px] ml-[15px]">
        <SerialToolListInfo key={selectedWork.workId} inputRefNext={setBtnNextSagyou} inputRefKanryou={setBtnKanryou} />
      </div>
      {/* 動画表示 */}
      {isMoviePlay && (
        <DetailDocMoviePlayer
          src={movieObjUrl}
          Close={() => setMoviePlayState(false)} />
      )}
      {/* テキスト表示 */}
      {isTextView && (
        <DetailDocTextViewer
          text={docText}
          Close={() => setTextViewState(false)} />
      )}
    </div>
  );
}

export default SerialWorkInfo;
import { React, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProgressBar from "../../../components/ProgressBar";
import { IconContext } from "react-icons";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5";
import Draggable from "../../../components/Draggable";
import { ImImages } from "react-icons/im";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import MarketWarningViewer from "../../../components/MarketWarningViewer";
import ErrorBox from "../../../components/ErrorBox";
import { useNavigate } from 'react-router-dom';
import { CompareSource, ManageItemType, CompareMethod, WorkStatus, OkNgResult, variables, OperationManagementType, SagyouBunkiType, BunkiTxt, HindoKaisuSetting } from "../../../Variables";
import { errorCode } from "../../../ErrorCode";
import okAudioUrl from "../../../audioFiles/OK.mp3";
import ngAudioUrl from "../../../audioFiles/NG.mp3";
import hardwareAudioUrl from "../../../audioFiles/HWCount.wav";
import { getIsLoading as getIsPageLoading, setIsLoading as setPageLoading } from "../../Loading/pageLoadingSlice";
import { getVisible as getConfirmBoxVisible } from "../../../components/confirmBoxSlice";
import { getVisible as getErrorBoxVisible } from "../../../components/errorBoxSlice";
import LotMatomesuuChange from "./LotMatomesuuChange";
import TextAreaTab from "../../../components/TextAreaTab";
import LotKurikaeshiList from "./LotKurikaeshiList";
import {
  setSelectedWork,
  setWorkStatusChange,
  setInputTextChange,
  setJustSelectWorkId,
  setNoneWorkStatusChange,
  setLocalNewOperationLog,
  setIsManageItemTypeFocusable,
  setPauseExpandMode,
  setWorkExpandMode,
  resetInitState,
  fetchKurikaeshiList,
  fetchLotCompletedManageResult,
  setPauseConfirmMsg,
  resetCompletedManageResult,
  setShowTeishiInput,
  setLotOperationLogId
} from "../lotOperationSlice";

import {
  urlCompanyName,
  NavigateToOperationLogin,
  NavigateToOperationStartWork,
  RegisterLotOperationLog,
  NavigateToOperation,
  GetKaisuuCheckCountWithRoundUpAndDown
} from "../../util/commonFun";
import { appInsights } from "../../util/ApplicationInsight";
import ComboxBoxValueSearchable from "../../../components/ComboxBoxValueSearchable";
import { GetPort, CloseSerialPort, ConnectSerialPort, IsPortConnected } from "../../util/SerialPortConnection";
import SerialPortReader from "../SerialPortReader";
import { clearHardwareData, getIncomingHardwareData, setConnectionLost, getConnectionStatus } from "../SerialPortReaderSlice";
import LotNumberSaiKai from "./LotNumberSaiKai";
import ConfirmBox from "../../../components/ConfirmBox";
import TeishiInputBox from "../workCheck/TeishiInputBox";
import { v4 as uuidv4 } from "uuid";

const axios = require('axios');
const regex = /^[\d-]{1}\d*\.?\d*$/;
const standardVoltage = 2.5;
let incomingRotationTime = 0;
let incomingVoltage = 0;
let incomingHandleName = "";

function LotToolListInfo(props) {
  const dispatch = useDispatch();
  const isPageLoading = useSelector(getIsPageLoading);
  const isConfirmBoxVisible = useSelector(getConfirmBoxVisible);
  const isErrorBoxVisible = useSelector(getErrorBoxVisible);
  const [isMatomesuuExpand, setIsMatomesuuExpand] = useState(false);
  const items = useSelector((state) => state.lotOperation);
  const showTeishiInput = items.isTeishiInput;
  let isBusy = isPageLoading || isConfirmBoxVisible || isErrorBoxVisible || isMatomesuuExpand || showTeishiInput;
  const incomingHardwareData = useSelector(getIncomingHardwareData);
  const isConnectionLost = useSelector(getConnectionStatus);
  const selectedWork = items.selectedWork;
  const showPauseConfirmMsg = items.isPauseConfirmMsg;
  const toolList = [...items.dataList.toolList.filter((tool) => tool.workId.toLowerCase() === selectedWork.workId.toLowerCase())];
  toolList.sort((a, b) => a.displayOrder - b.displayOrder);
  const workList = items.dataList.workList;
  const manageItemList = items.dataList.manageItemList;
  const isManageItemTypeFocusable = items.isManageItemTypeFocusable;
  const tempIdx = manageItemList.findIndex((item) => item.workId === selectedWork.workId);
  const currManageItemType = (tempIdx === -1) ? 0 : manageItemList[tempIdx].manageItemType;
  const currDescription = (tempIdx === -1) ? "" : manageItemList[tempIdx].description;
  const maxVal = workList.length;
  const currIdx = (workList.length > 0 && selectedWork.workId !== "") ? workList.findIndex((item) => item.workId.toLowerCase() === selectedWork.workId.toLowerCase()) : 0;
  const opLogList = items.dataList.lotOperationLogList;
  const textCompareWorkList = items.dataList.textCompareWorkList;
  let regularWorkCnt = workList.filter((item) => item.isIrregular === false).length;
  let currProgressVal = workList.slice(0, currIdx + 1).filter((item) => item.isIrregular === false).length;
  const kurikaeshiList = items.dataList.kurikaeshiList;
  const kurikaeshiLstbyWorkId = kurikaeshiList.filter((item) => item.dispWorkId.toLowerCase() === selectedWork.dispWorkId.toLowerCase());
  let completedKurikaeNo = kurikaeshiLstbyWorkId?.length ?? 0;

  const completedManageResultObj = items.completedManageResultObj;
  const completedManageResult = completedManageResultObj.manageResult;
  const completedManageResultWorkId = completedManageResultObj.workId;
  const selectedWorkId = selectedWork.workId;
  const completedCount = (currManageItemType === ManageItemType.Hardware && selectedWorkId === completedManageResultWorkId
    && completedManageResult !== null) ? completedManageResult.completedCount : 0;
  const completedHardwareId = (currManageItemType === ManageItemType.Hardware && selectedWorkId === completedManageResultWorkId
    && completedManageResult !== null) ? completedManageResult.completedHardwareId : "";
  const completedHardware = (currManageItemType === ManageItemType.Hardware && selectedWorkId === completedManageResultWorkId
    && completedManageResult !== null && completedHardwareId !== "") ?
    completedManageResult.hardwareData.find(itm => itm.hardwareId.toLowerCase() === completedHardwareId.toLowerCase()) : null;
  const completedDispOrder = (currManageItemType === ManageItemType.Hardware && selectedWorkId === completedManageResultWorkId
    && completedHardware) ? completedHardware.dispOrder : 0;
  const registerCount = (currManageItemType === ManageItemType.Hardware && completedManageResultWorkId === selectedWorkId
    && completedHardware) ? completedHardware.count : 0;
  const completedHWIndex = (currManageItemType === ManageItemType.Hardware && completedManageResult !== null && completedManageResult.hardwareData) ?
    completedManageResult.hardwareData.findIndex(itm => itm.hardwareId.toLowerCase() === completedHardwareId.toLowerCase()) : -1;
  const lastHWIndex = (currManageItemType === ManageItemType.Hardware && currDescription !== "" && currDescription.hardwareData.length >= 1) ?
    currDescription.hardwareData.length - 1 : 0;
  const kaisuuCheckCount = GetKaisuuCheckCountWithRoundUpAndDown(currManageItemType, currDescription, items.matomesuu);

  const [isOpenToolListDraggable, setIsOpenToolListDraggable] = useState(false);
  const [toolDispOrder, setToolDispOrder] = useState(-1);
  const [isShowNextBtn, setShowNextBtn] = useState(false);
  const [isMarketWarningView, setMarketWarningView] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showNumericError, setNumericError] = useState(false);
  const [showCompareTextError, setCompareTextError] = useState(false);
  const [writeLogErrorCode, setWriteLogErrorCode] = useState(0);
  const [isworkComplete, setWorkComplete] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isShowNotifyOfEndDialog, setIsShowNotifyOfEndDialog] = useState(false);
  const [isShowNotifyOfEndDialogByBatchSize, setIsShowNotifyOfEndDialogByBatchSize] = useState(false);
  const [isShowLastProcessNoticeDialogByBatchSize, setIsShowLastProcessNoticeDialogByBatchSize] = useState(false);
  const [isShowLastProcessNoticeDialog, setIsShowLastProcessNoticeDialog] = useState(false);
  const [saikaiWorkDispProcessId, setSaikaiWorkDispProcessId] = useState("");
  const [saikaiWorkDispProcessOrder, setSaikaiWorkDispProcessOrder] = useState(-1);
  const [selectedBunkiOrder, setSelectedBunkiOrder] = useState("");
  const [showVoltageWarning, setShowVoltageWarning] = useState(false);
  const [showDiffHandleNameErr, setShowDiffHandleNameErr] = useState(false);
  const [showConnectionLostErr, setShowConnectionLostErr] = useState(IsPortConnected() === true ? false : true);
  const [showSaikaiMsg, setShowSaikaiMsg] = useState(false);
  const [batchSizeErrLst, setBatchSizeErrLst] = useState([]);

  const refNumericInput = useRef();
  const refTextCompareInput = useRef();
  const refTextInput = useRef();
  const refBunkiWorkComboBox = useRef();
  const refInitWork = useRef(true);
  let navigate = useNavigate();

  const currentHW = () => {
    if (currManageItemType === ManageItemType.Hardware) {
      if (completedHardware) {
        if (completedCount === registerCount && completedHWIndex < lastHWIndex) {
          //ハードウェアの最後のカウントが終わったら、次のハードウェアを設定
          return currDescription.hardwareData.find(itm => itm.dispOrder === completedDispOrder + 1);
        }
        else {
          //ハードウェアの最後のカウントが終わらない場合、現在のハードウェアを設定
          return currDescription.hardwareData.find(itm => itm.dispOrder === completedDispOrder);
        }
      }
      else {
        //ハードウェアが一つも完了していない場合、最初のハードウェアからスタート
        return currDescription.hardwareData.find(itm => itm.dispOrder === 1);
      }
    }
    else {
      return null;
    }
  }

  let currentHardware = currentHW();
  let currHWRegisterCnt = currentHardware !== null ? currentHardware.count : 0;
  let currentHandleName = currentHardware !== null ? currentHardware.handleName : "";
  let currentHardwareId = currentHardware !== null ? currentHardware.hardwareId : "";
  let currentHardwareIndex = (currDescription !== "" && currentHardwareId !== "") ?
    currDescription.hardwareData.findIndex(itm => itm.hardwareId.toLowerCase() === currentHardwareId.toLowerCase()) : -1;

  useEffect(() => {
    dispatch(clearHardwareData());
  }, [])

  useEffect(() => {
    handleincomingHWData(incomingHardwareData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingHardwareData])

  useEffect(() => {
    handleConnectionLost(isConnectionLost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionLost])

  useEffect(() => {
    if (isManageItemTypeFocusable === true && selectedWork.workId !== "" && refInitWork.current) {
      refInitWork.current = false;
      setWorkComplete(false);
      dispatch(setIsManageItemTypeFocusable(false));
      if (currManageItemType === ManageItemType.Audio) {
        playAudio(currDescription.audioText);
      }
      else if (currManageItemType === ManageItemType.Text || currManageItemType === ManageItemType.TextCompare || currManageItemType === ManageItemType.NumericalJudge) {
        focusTextInputArea();
      }
      else if (currManageItemType === ManageItemType.MarketWarning) {
        setMarketWarningView(true);
      }
      else if (currManageItemType === ManageItemType.SagyouBunki && currDescription.sagyouBunkiType === SagyouBunkiType.ListType) {
        refBunkiWorkComboBox.current.focus();
      }
      else {
        setShowNextBtn(currManageItemType === ManageItemType.None);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManageItemTypeFocusable, currManageItemType, currDescription, selectedWork.workId, dispatch]);

  /**
  *ログに登録するハードウェアのcompletedCount設定。
  * @returns registerHWCompletedCount
  */
  const registerHWCompletedCount = () => {
    //ハードウェアの全てのカウントが完了したかどうかチェック
    if (completedCount > 0 && completedCount === registerCount) {
      //最後のハードウェアの場合、完了したカウントを登録する。
      if (lastHWIndex === completedHWIndex) {
        return completedCount;
      }
      else {
        //最後のハードウェアではない場合、「１」で登録する。
        return 1;
      }
    }
    else {
      //ハードウェアの全てのカウントが完了していない場合、完了したカウントに「１」を追加する。
      return completedCount + 1;
    }
  }

  /**
   * 最後のハードウェアの最後のカウントかどうかチェック
   * @returns bool
   */
  const isLastHWLastCnt = () => {
    if (currentHardwareIndex === lastHWIndex && currHWRegisterCnt === registerHWCompletedCount()) {
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * 全てのハードウェアが完了したかどうかチェック
   * @returns bool
   */
  const isAllHWCompleted = () => {
    if (completedHWIndex === lastHWIndex && currHWRegisterCnt === completedCount
      && (currDescription.hardwareKurikaeshi === false || (currDescription.hardwareKurikaeshi === true && kaisuuCheckCount === completedKurikaeNo + 1))) {
      return true;
    }
    else {
      return false;
    }
  }

  const registerKurikaeNo = () => {
    let result = completedKurikaeNo;
    if (currDescription.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu
      || (currDescription.hardwareKurikaeshi === true && isAllHWCompleted() === true)) {
      result++;
    }
    return result;
  }

  /**
 * 工具の電池電圧が設定電圧以上かどうかチェック
 * @returns 
 */
  const isVoltageOK = () => {
    if (incomingVoltage >= standardVoltage) {
      return true;
    }
    else {
      return false;
    }
  }

  let displayCompletedCount = (completedCount !== registerCount || isAllHWCompleted() === true) ? completedCount : 0;

  const bunkiWorkDataList = () => {
    let bunkiWorkDataList = [];
    if (currDescription.sagyouBunkiType === SagyouBunkiType.ListType && currDescription.bunkiData.length > 0) {
      for (let i = 0; i < currDescription.bunkiData.length; i++) {
        let bunkiData = currDescription.bunkiData[i];
        let workData = workList.find(data => data.workId === bunkiData.workID);
        if (workData) {
          let bunkiOrder = BunkiTxt + bunkiData.dispOrder.toString().padStart(2, '0');
          bunkiWorkDataList.push({ bunkiOrder: bunkiOrder, workID: workData.workId, workName: workData.workName, workDispOrder: workData.dispOrder })
        }
      }
    }
    return bunkiWorkDataList;
  }

  useEffect(() => {
    if (refInitWork.current === false && isMatomesuuExpand === false) {
      focusTextInputArea();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatomesuuExpand]);

  /**
   * ハードウェアからもらったデータを管理する
   * @param {*} incomingHardwareData 
   */
  function handleincomingHWData(incomingHardwareData) {
    if (incomingHardwareData.length > 0 && !incomingHardwareData.toUpperCase().includes("NON")) {
      const splitedHWData = incomingHardwareData.split(",");
      if (splitedHWData.length >= 4) {
        let startIndex = splitedHWData[0].indexOf("[");
        let lastIndex = splitedHWData[0].lastIndexOf(":");
        incomingHandleName = splitedHWData[0].substring(startIndex + 1, lastIndex);
        incomingRotationTime = splitedHWData[1];
        incomingVoltage = splitedHWData[3];

        if (currManageItemType === ManageItemType.Hardware && isAllHWCompleted() === false && showVoltageWarning === false) {
          if (currentHandleName.toLowerCase() === incomingHandleName.toLowerCase()) {
            //ハードウェアのハンドルネームが正しい場合、違い工具のエラーメッセージを閉じる。
            if (showDiffHandleNameErr === true) {
              setShowDiffHandleNameErr(false);
              isBusy = false;
            }

            if (isBusy === false && isBtnDisabled === false) {
              if (isVoltageOK() === false) {
                setShowVoltageWarning(true);
              }
              playOKNGAudio(hardwareAudioUrl);
              writeHardwareLog(WorkStatus.HARDWAREOK)
            }
          }
          else {
            //違う工具の場合、エラーメッセージを表示して、ハードウェアNGログを書く。
            playOKNGAudio(ngAudioUrl);
            setShowDiffHandleNameErr(true);
            writeHardwareLog(WorkStatus.HARDWARENG)
          }
        }
      }
      dispatch(clearHardwareData());
    }
  }

  /**
   * 管理項目の文字列登録又は文字列比較の変更イベント
   * @param {*} e 
   */
  function handleTextChange(e) {
    let inputTxt = e.target.value;
    inputTxt = inputTxt.replace(/\r?\n|\r/g, "");
    dispatch(setInputTextChange({ text: inputTxt, workId: selectedWork.workId }));
    const idx = opLogList.findIndex((item) => item.dispWorkId.toLowerCase() === selectedWork.dispWorkId.toLowerCase());
    if (idx === -1) {
      dispatch(setLocalNewOperationLog({ dispWorkId: selectedWork.dispWorkId, workDispOrder: currIdx + 1, manageResult: currDescription }));
    }
  }

  /**
   * 管理項目の数値判定の変更イベント
   * @param {*} e 
   */
  function handleNumberChange(e) {
    const number = e.target.value;
    if (number === "" || regex.test(number)) {
      dispatch(setInputTextChange({ text: number, workId: selectedWork.workId }));
      const idx = opLogList.findIndex((item) => item.dispWorkId.toLowerCase() === selectedWork.dispWorkId.toLowerCase());
      if (idx === -1) {
        dispatch(setLocalNewOperationLog({ dispWorkId: selectedWork.dispWorkId, workDispOrder: currIdx + 1, manageResult: currDescription }));
      }
    }
  }

  /**
   * 管理項目の数値判定のキーアップイベント
   * @param {*} e 
   */
  async function handleNumericEnterKeyUp(e) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      const inputValue = e.target.value;
      if (inputValue === "") {
        return;
      }
      if (isNaN(inputValue) || (inputValue !== "" && (Number(inputValue) < Number(currDescription.numJudgeLower) || Number(inputValue) > Number(currDescription.numJudgeUpper)))) {
        playOKNGAudio(ngAudioUrl);
        setNumericError(true);
      }
      else {
        playOKNGAudio(okAudioUrl);
        if (currDescription.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
          handleRepeatWork()
        }
        else {
          doCompleteWorkOrMoveNextWork();
        }
      }
    }
  }

  /**
   * 管理項目の警告メッセージ表示
   * @param {*} e 
   */
  function handelMarketWarningViewer(e) {
    setMarketWarningView(true);
  }

  /**
   * 管理項目の文字列登録のキーアップイベント
   * @param {*} e 
   * @returns 
   */
  function handleTextInputEnterKeyUp(e) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      if (e.target.value === "") {
        return;
      }
      playOKNGAudio(okAudioUrl);
      if (currDescription.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
        handleRepeatWork()
      }
      else {
        doCompleteWorkOrMoveNextWork();
      }
    }
  }

  /**
   * 管理項目の文字列比較のキーアップイベント
   * @param {*} e 
   * @returns 
   */
  async function handleTextCompareEnterKeyUp(e) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      const inputText = e.target.value;
      if (inputText === "") {
        return;
      }
      let isSuccess = false;
      if (currDescription.txtCompSource === CompareSource.Shitei) {
        isSuccess = compareTextMethod(currDescription.txtCompText, inputText, currDescription.txtCompMethod);
      }
      if (currDescription.txtCompSource === CompareSource.Work) {
        const workObj = workList.find((w) => w.workId.toLowerCase() === currDescription.txtCompWorkID.toLowerCase());
        if (workObj) {
          const compareObj = opLogList.find((item) => item.dispWorkId.toLowerCase() === workObj.dispWorkId.toLowerCase());
          if (compareObj) {
            isSuccess = compareTextMethod(compareObj.manageResult.txtInputText, inputText, currDescription.txtCompMethod);
          }
          else {
            // バッチサイズ変更中断した作業が管理項目の分岐有り文字比較の場合、
            const otherCompareObj = textCompareWorkList.find((item) => item.workId.toLowerCase() === currDescription.txtCompWorkID.toLowerCase());
            if (otherCompareObj) {
              isSuccess = compareTextMethod(otherCompareObj.manageResult.txtInputText, inputText, currDescription.txtCompMethod);
            }
          }
        }
        else {
          // 管理項目の分岐有り文字比較作業が別の工程の作業に設定されている場合、
          const otherCompareObj = textCompareWorkList.find((item) => item.workId.toLowerCase() === currDescription.txtCompWorkID.toLowerCase());
          if (otherCompareObj) {
            isSuccess = compareTextMethod(otherCompareObj.manageResult.txtInputText, inputText, currDescription.txtCompMethod);
          }
        }
      }
      if (currDescription.txtCompSource === CompareSource.LotSerialNo) {
        isSuccess = compareTextMethod(items.lotNumber, inputText, currDescription.txtCompMethod);
      }
      if (isSuccess === true) {
        playOKNGAudio(okAudioUrl);
        if (currDescription.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
          handleRepeatWork()
        }
        else {
          doCompleteWorkOrMoveNextWork();
        }
      }
      else {
        playOKNGAudio(ngAudioUrl);
        setCompareTextError(true);
      }
    }
  }

  /**
   * 文字列比較方法
   * @param {*} compareText 
   * @param {*} inputText 
   * @param {*} method 
   * @returns 
   */
  const compareTextMethod = (compareText, inputText, method) => {
    if (method === CompareMethod.AllMatch) {
      if (compareText === inputText) {
        return true;
      }
      return false;
    }

    if (method === CompareMethod.PartialMatch) {
      if (compareText !== "" && (compareText.includes(inputText) || inputText.includes(compareText))) {
        return true;
      }
      return false;
    }
  }

  /**
   * 管理項目の警告メッセージ表示の内容を確認ボタンイベント
   */
  function handleConfirmBtnClick() {
    setMarketWarningView(false);
    onDisplayBtnChange();
  }

  /**
   * 次の作業ボタン又は作業完了の切り替わる処理
   */
  function onDisplayBtnChange() {
    if (currIdx === (maxVal - 1)) {
      setWorkComplete(true);
    }
    else {
      setShowNextBtn(true);
    }
    dispatch(setLocalNewOperationLog({ dispWorkId: selectedWork.dispWorkId, workDispOrder: currIdx + 1, manageResult: currDescription }));
  }

  /**
   * 作業完了又は次の作業の操作
   */
  function doCompleteWorkOrMoveNextWork() {
    if (currIdx === (maxVal - 1)) {
      handleCompleteWork();
    }
    else {
      handleMoveNextWork();
    }
  }

  /**
   * 管理項目のOK/NG判定のNGボタンイベント
   * @param {*} e 
   */
  async function handleNGBtnClick(e) {
    playOKNGAudio(ngAudioUrl);
    if (currDescription.ngWorkID !== "") {
      const moveIdx = workList.findIndex((item) => item.workId.toLowerCase() === currDescription.ngWorkID.toLowerCase());
      workByOkNG(moveIdx, WorkStatus.NG);
    }
    else {
      setShowError(true);
    }
  }

  /**
   * 管理項目のOK/NG判定のOKボタンイベント
   * @param {*} e 
   */
  function handleOKBtnClick(e) {
    playOKNGAudio(okAudioUrl);
    if (currDescription.okWorkID !== "") {
      const moveIdx = workList.findIndex((item) => item.workId.toLowerCase() === currDescription.okWorkID.toLowerCase());
      workByOkNG(moveIdx, WorkStatus.WORKOK);
    }
    else {
      if (currDescription.hindoKaisuSetting?.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) {
        handleRepeatWork()
      }
      else {
        doCompleteWorkOrMoveNextWork();
      }
    }
  }

  /**
   * 管理項目の作業分岐ボタンタイプのイベント
   * @param {*} workID 
   */
  function handleBunkiBtnClick(workID) {
    const moveIdx = workList.findIndex((item) => item.workId.toLowerCase() === workID.toLowerCase());
    workByOkNG(moveIdx, WorkStatus.WORKOK);
  }

  /**
   * コンボボックスのSelectedChangeのイベント
   * @param {*} e 
   */
  function handleBunkiWorkChange(e) {
    const bunkiOrder = e.value;
    const workID = e.targetValue;
    const moveIdx = workList.findIndex((item) => item.workId.toLowerCase() === workID.toLowerCase());
    setSelectedBunkiOrder(bunkiOrder);
    workByOkNG(moveIdx, WorkStatus.WORKOK);
  }
  /**
   * ハードウェアのログを書く。
   * @param {*} workStatusId 
   */
  async function writeHardwareLog(workStatusId) {
    //最後の作業の最後のハードウェアのカウントが自動移動「ON」場合
    if (currIdx === (maxVal - 1) && currDescription.hardwareAutoTransition === true &&
      isLastHWLastCnt() === true && isVoltageOK() === true && workStatusId === WorkStatus.HARDWAREOK &&
      ((currDescription.hardwareKurikaeshi === false) || (currDescription.hardwareKurikaeshi === true && kaisuuCheckCount === completedKurikaeNo + 1))) {
      handleCompleteWork(workStatusId);
    }
    //最後ではない作業の最後のハードウェアのカウントが自動移動「ON」の場合
    else if (currDescription.hardwareAutoTransition === true &&
      isLastHWLastCnt() === true && isVoltageOK() === true && workStatusId === WorkStatus.HARDWAREOK &&
      ((currDescription.hardwareKurikaeshi === false) || (currDescription.hardwareKurikaeshi === true && kaisuuCheckCount === completedKurikaeNo + 1))) {
      handleMoveNextWork(workStatusId);
    } else {
      dispatch(setPageLoading(true));
      setIsBtnDisabled(true);
      let result = await writeLotOperationLog(workStatusId, selectedWork);
      if (result.isFailed === false) {
        try {
          await dispatch(fetchLotCompletedManageResult({ lotOperationLogLockId: items.lotOperationLogLockId, lotNo: items.lotNumber })).unwrap();
          if (currDescription.hardwareKurikaeshi) {
            await dispatch(fetchKurikaeshiList({ kurikaeshiWorkID: selectedWork.workId, itemCode: items.selectedItem.itemCode, lotOperationLogLockId: items.lotOperationLogLockId, lotNo: items.lotNumber })).unwrap();
          }
        }
        catch (error) {
          let errResponseStatus = error.message;
          // eslint-disable-next-line eqeqeq
          if (errResponseStatus == 401) {
            NavigateToOperationLogin(navigate);
          }
          else {
            appInsights.trackException({ ...error, errorFunction: "lotToolListInfo.writeHardwareLog()" });
            console.error(error);
          }
        }
      }
      setIsBtnDisabled(false);
      dispatch(setPageLoading(false));
    }
  }

  /**
   * 管理項目のOK/NG判定と作業分岐（ボタンとリスト）の処理
   * @param {*} moveIdx 
   * @param {*} workStatusId 
   */
  async function workByOkNG(moveIdx, workStatusId) {
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    setIsOpenToolListDraggable(false);
    if (moveIdx > -1) {
      const moveWorkObj = workList[moveIdx];
      // 現在表示する作業のオペレーションログ登録処理
      let result = await writeLotOperationLog(workStatusId, selectedWork);
      if (result.isFailed === false) {
        // 移動する作業のオペレーションログ登録処理
        result = await writeLotOperationLog(WorkStatus.WORKMOVE, moveWorkObj);
        if (result.isFailed === false) {
          // 現在表示する作業にチェックマーク入れて変更
          dispatch(setWorkStatusChange({ dispWorkId: selectedWork.dispWorkId, workStatusId: workStatusId, workDispOrder: currIdx + 1, manageResult: currDescription }));
          dispatch(setSelectedWork(moveWorkObj));
          const idx = opLogList.findIndex((item) => item.dispWorkId.toLowerCase() === moveWorkObj.dispWorkId.toLowerCase());
          if (idx === -1) {
            const tempIdx = manageItemList.findIndex((item) => item.workId === moveWorkObj.workId);
            const description = (tempIdx === -1) ? "" : manageItemList[tempIdx].description;
            dispatch(setLocalNewOperationLog({ dispWorkId: moveWorkObj.dispWorkId, workDispOrder: moveIdx + 1, manageResult: description }));
          }
          dispatch(setNoneWorkStatusChange({ dispWorkId: moveWorkObj.dispWorkId }));
        }
      }
    }
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  /**
   * 工程完了又は全作業完了ボタンの処理
   */
  async function handleCompleteWork(workStatus = WorkStatus.FINISHPROCESS) {
    if (window.speechSynthesis.speaking) {
      return;
    }
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    let result = await writeLotOperationLog(workStatus, selectedWork);
    if (result.isFailed === false) {
      if (items.processObj.isLastProcess) {
        if (result.data.isCheckBatchSizeFlg === true) {
          setIsShowLastProcessNoticeDialogByBatchSize(true);
        }
        else {
          setIsShowLastProcessNoticeDialog(true);
        }
      }
      else if (items.processObj.notifyOfEnd) {
        if (result.data.isCheckBatchSizeFlg === true) {
          setIsShowNotifyOfEndDialogByBatchSize(true);
        }
        else {
          setIsShowNotifyOfEndDialog(true);
        }
      }
      else {
        await doStartWorkOfNextProcess(result.data.nextDispProcessId);
      }
    }
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  /**
   * 工程完了通知フラグが「OFF」の場合、次の工程の最初の作業へ行く処理
   * @param {*} nextDispProcessId 
   * @returns 
   */
  async function doStartWorkOfNextProcess(nextDispProcessId) {
    if (nextDispProcessId.length === 0) {
      return;
    }
    let paramobj = {
      userId: items.selectedUser.userId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      nextDispProcessId: nextDispProcessId,
      version: items.version,
      matomesuu: items.matomesuu
    }
    await axios({
      method: 'GET',
      url: variables.OPERATION_URL + "/start-work-of-next-process-by-lotno",
      params: paramobj,
      headers: { 'company': urlCompanyName },
      withCredentials: true,
    }).then(function (response) {
      let responseData = response.data;
      let err = responseData.errorCode;
      if (err === errorCode.E1009 || err === errorCode.E1010) {
        setWriteLogErrorCode(err);
      }
      else {
        dispatch(resetInitState());
        let dataObj = {
          selectedItem: items.selectedItem,
          selectedUser: items.selectedUser,
          lotNumber: items.lotNumber,
          managementType: OperationManagementType.LOTNUMBER,
          lockKey: responseData.newLockKey,
          lotOperationLogLockId: responseData.lotOperationLogLockId,
          version: items.version
        };
        NavigateToOperation(navigate, dataObj);
      }
    }).catch(function (error) {
      let errResponseStatus = error.response.status;
      if (errResponseStatus === 401) {
        NavigateToOperationLogin(navigate);
      }
      else {
        appInsights.trackTrace({ params: JSON.stringify(paramobj) });
        appInsights.trackException({ ...error, errorFunction: "LotToolListInfo.doStartWorkOfNextProcess()" });
        console.error(error);
      }
    });
  }

  /**
   * 次の作業ボタンの処理
   */
  async function handleMoveNextWork(workStatus = WorkStatus.WORKOK) {
    if (window.speechSynthesis.speaking) {
      return;
    }
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    setIsOpenToolListDraggable(false);
    const nextIdx = workList.findIndex((item) => item.workId.toLowerCase() === selectedWork.workId.toLowerCase()) + 1;
    const nextWork = workList[nextIdx];
    if (nextWork) {
      let result = await writeLotOperationLog(workStatus, selectedWork);
      if (result.isFailed === false) {
        // 現在表示する作業にチェックマーク入れて変更
        dispatch(setWorkStatusChange({ dispWorkId: selectedWork.dispWorkId, workStatusId: WorkStatus.WORKOK, workDispOrder: currIdx + 1, manageResult: currDescription }));
        dispatch(setJustSelectWorkId(""));
        // 移動する作業に選択するように変更
        dispatch(setSelectedWork(nextWork));
        // CompleteManageResultをレセットする。
        dispatch(resetCompletedManageResult());
        const nextWorkManageItem = manageItemList.find((item) => item.workId === nextWork.workId);
        if (nextWorkManageItem && nextWorkManageItem.manageItemType === ManageItemType.Audio) {
          playAudio(nextWorkManageItem.description.audioText);
        }
      }
    }
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  /**
  * 繰り返しの処理
  */
  async function handleRepeatWork() {
    //最後作業の最後が繰り返しの場合
    if (currIdx === (maxVal - 1) && registerKurikaeNo() === kaisuuCheckCount) {
      handleCompleteWork(WorkStatus.KURIKAESHI);
    }
    //作業の最後が繰り返しの場合
    else if (registerKurikaeNo() === kaisuuCheckCount) {
      handleMoveNextWork(WorkStatus.KURIKAESHI);
    }
    else {
      dispatch(setPageLoading(true));
      setIsBtnDisabled(true);
      let result = await writeLotOperationLog(WorkStatus.KURIKAESHI, selectedWork);
      if (result.isFailed === false) {
        try {
          await dispatch(fetchKurikaeshiList({ kurikaeshiWorkID: selectedWork.workId, itemCode: items.selectedItem.itemCode, lotOperationLogLockId: items.lotOperationLogLockId, lotNo: items.lotNumber })).unwrap();
          dispatch(setInputTextChange({ text: "", workId: selectedWork.workId }));
          focusTextInputArea();
        }
        catch (error) {
          let errResponseStatus = error.message;
          // eslint-disable-next-line eqeqeq
          if (errResponseStatus == 401) {
            NavigateToOperationLogin(navigate);
          }
          else {
            appInsights.trackException({ ...error, errorFunction: "LotToolListInfo.handleRepeatWork()" });
            console.error(error);
          }
        }
      }
      setIsBtnDisabled(false);
      dispatch(setPageLoading(false));
    }
  }

  /**
   * テキストボックスにカーソルする処理
   */
  function focusTextInputArea() {
    if (currManageItemType === ManageItemType.Text) {
      refTextInput.current.focus();
    }
    else if (currManageItemType === ManageItemType.TextCompare) {
      refTextCompareInput.current.focus();
    }
    else if (currManageItemType === ManageItemType.NumericalJudge) {
      refNumericInput.current.focus();
    }
  }

  /**
   * ロットオペレーションのログ履歴登録処理
   * @param {*} workStatusId 
   * @param {*} workObj 
   * @returns 
   */
  async function writeLotOperationLog(workStatusId, workObj) {
    let result = { isFailed: false, data: null };
    if (workObj.workId.length === 0) {
      result.isFailed = true;
      return result;
    }
    let kurikaeshiNo = completedKurikaeNo;
    let isLastHWAutoTransition = false;
    if (!(workStatusId === WorkStatus.NG || workStatusId === WorkStatus.HARDWARENG)) {
      kurikaeshiNo = registerKurikaeNo();
    }
    let description;
    let manageItemObj = items.dataList.manageItemList.find((item) => item.workId.toLowerCase() === workObj.workId.toLowerCase());
    if (manageItemObj) {
      let result = "";
      description = { ...manageItemObj.description };
      if (workStatusId === WorkStatus.WORKMOVE) {
        result = "";
        description.txtInputText = "";
      }
      else if (workStatusId === WorkStatus.NG) {
        result = OkNgResult.NG;
      }
      else if (manageItemObj.manageItemType === ManageItemType.TextCompare
        || manageItemObj.manageItemType === ManageItemType.OkNgJudge
        || manageItemObj.manageItemType === ManageItemType.NumericalJudge) {
        result = OkNgResult.OK;
      }
      description.okNgResult = result;
      if (manageItemObj.manageItemType === ManageItemType.NumericalJudge && description.txtInputText.length > 0) {
        description.txtInputText = isNaN(description.txtInputText) ? description.txtInputText : Number(description.txtInputText).toString();
      }
      if (manageItemObj.manageItemType === ManageItemType.Hardware) {
        description.completedHardwareId = (workStatusId === WorkStatus.SUSPENSION) ? completedHardwareId : currentHardwareId;
        description.handleName = (workStatusId === WorkStatus.SUSPENSION) ? "" : incomingHandleName;
        description.rotationTime = (isAllHWCompleted() === true || workStatusId === WorkStatus.HARDWARENG || workStatusId === WorkStatus.SUSPENSION) ? 0 : incomingRotationTime;
        if (currDescription.hardwareKurikaeshi === true) {
          isLastHWAutoTransition = currDescription.hardwareAutoTransition === true && isVoltageOK() === true && isLastHWLastCnt() === true && completedKurikaeNo + 1 === kaisuuCheckCount;
        }
        else {
          isLastHWAutoTransition = currDescription.hardwareAutoTransition === true && isVoltageOK() === true && isLastHWLastCnt() === true;
        }
        if (workStatusId === WorkStatus.SUSPENSION) {
          description.completedCount = completedCount;
        }
        else if (workStatusId === WorkStatus.HARDWARENG) {
          if ((completedCount === registerCount)) {
            description.completedCount = 0;
          }
          else {
            description.completedCount = completedCount;
          }
        }
        else {
          description.completedCount = registerHWCompletedCount();
        }
      }
    }
    let lotOperation = {
      lockKey: items.lockKey,
      lotOperationLogLockId: items.lotOperationLogLockId,
      processChartID: items.processObj.processChartID,
      processId: items.processObj.processId,
      workId: workObj.workId,
      workStatusId: workStatusId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      userId: items.selectedUser.userId,
      manageResult: JSON.stringify(manageItemObj ? description : ""),
      manageItemTypeId: manageItemObj ? manageItemObj.manageItemType : 0,
      version: items.version,
      workDispOrder: workObj.dispOrder,
      dispProcessId: items.processObj.dispProcessId,
      dispWorkId: workObj.dispWorkId,
      matomesuu: items.matomesuu,
      kurikaeshiNo: kurikaeshiNo,
      isLastWork: currIdx === (maxVal - 1),
      isLastHWAutoTransition: isLastHWAutoTransition,
      isLastHWLastCntKurikaeshi: (currManageItemType === ManageItemType.Hardware && currDescription.hardwareKurikaeshi === true && isLastHWLastCnt() === true && completedKurikaeNo + 1 !== kaisuuCheckCount),
      kaisuuCheckCount: kurikaeshiNo > kaisuuCheckCount ? kurikaeshiNo : kaisuuCheckCount,
    }
    result = await RegisterLotOperationLog(lotOperation, navigate);
    if (result.data) {
      if (result.data.errorCode > 0) {
        setWriteLogErrorCode(result.data.errorCode);
      }
      else {
        setSaikaiWorkDispProcessId(result.data.saikaiWorkDispProcessId);
        setSaikaiWorkDispProcessOrder(result.data.saikaiWorkDispProcessOrder);
        dispatch(setLotOperationLogId(result.data.lotOperationLogId));
      }
    }
    return result;
  }

  /**
   * 管理項目の音声出力のプレー
   * @param {*} text 
   */
  function playAudio(text) {
    setIsBtnDisabled(true);
    let audio = new SpeechSynthesisUtterance(text);
    audio.addEventListener('end', function (event) {
      setIsBtnDisabled(false);
    });
    audio.addEventListener('error', function (event) {
      setIsBtnDisabled(false);
    });
    audio.lang = 'ja-JP';
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(audio);
  }

  /**
   * OK/NG音声プレー
   * @param {*} url 
   */
  function playOKNGAudio(url) {
    let audio = new Audio(url);
    audio.play();
    audio.currentTime = 0;
  }

  /**
   * 次の作業ボタンを表示するか確認する
   * @param {*} itemType 
   * @returns 
   */
  const checkToShowNextWork = (itemType) => {
    if (itemType === ManageItemType.None || itemType === ManageItemType.Audio ||
      (itemType === ManageItemType.Hardware && showVoltageWarning === false && isAllHWCompleted() === true)) {
      return true;
    }
    return false;
  }

  /**
   * 工程完了又は全作業完了ボタンを表示するか確認する
   * @returns 
   */
  const checkToShowCompleteWork = () => {
    if (isworkComplete || (currIdx === (maxVal - 1) && checkToShowNextWork(currManageItemType) === true)) {
      return true;
    }
    return false;
  }

  /**
   * NGボタンの変化ダイアログの「はい」ボタンの操作
   */
  async function handleNGBtnShowError() {
    setIsBtnDisabled(true);
    let result = await writeLotOperationLog(WorkStatus.NG, selectedWork);
    if (result.isFailed === false) {
      setShowError(false);
    }
    setIsBtnDisabled(false);
  }

  /**
   * 数値判定エラーダイアログの「はい」ボタンの操作
   */
  async function handleYesNumericError() {
    setIsBtnDisabled(true);
    let result = await writeLotOperationLog(WorkStatus.NG, selectedWork);
    if (result.isFailed === false) {
      setNumericError(false);
      dispatch(setInputTextChange({ text: "", workId: selectedWork.workId }));
      refNumericInput.current.focus();
    }
    setIsBtnDisabled(false);
  }

  /**
   * 文字列比較エラーダイアログの「はい」ボタンの操作
   */
  async function handleYesTextCompareError() {
    setIsBtnDisabled(true);
    let result = await writeLotOperationLog(WorkStatus.NG, selectedWork);
    if (result.isFailed === false) {
      setCompareTextError(false);
      dispatch(setInputTextChange({ text: "", workId: selectedWork.workId }));
      refTextCompareInput.current.focus();
    }
    setIsBtnDisabled(false);
  }

  /**
   * 電池エラーメッセージの「はい」ボタンの操作
   */
  function handleYesVoltageWarning() {
    setShowVoltageWarning(false);
    if (currDescription.hardwareAutoTransition === true && isAllHWCompleted() === true) {
      if (currIdx === (maxVal - 1)) {
        handleCompleteWork();
      }
      else {
        handleMoveNextWork();
      }
    }
  }

  /**
   * 部品工程のドラッガブルボックス表示処理
   * @param {*} order 
   */
  function onClickExpand(order) {
    setToolDispOrder(order);
    setIsOpenToolListDraggable(true);
  }

  /**
   * 部品工程ドラッガブルボックスの「次へ」ボタン操作
   * @param {*} order 
   */
  function gotoNextTool(order) {
    setToolDispOrder(order);
  }

  /**
   * 部品工程ドラッガブルボックスの「前へ」ボタン操作
   * @param {*} order 
   */
  function gotoPreviousTool(order) {
    setToolDispOrder(order);
  }

  /**
   * 部品工程ドラッガブルボックスを閉じる処理
   */
  function toolListDraggablePopupClose() {
    setIsOpenToolListDraggable(false);
  }

  /**
   * バッチサイズ変更エリアを表示する処理。
   * @param {*} e 
   */
  function handleDisplayMatomesuuChangeDialog(e) {
    if (items.isWorkExpandMode) {
      dispatch(setWorkExpandMode(false));
    }
    if (items.isPauseExpandMode) {
      dispatch(setPauseExpandMode(false));
    }
    setIsMatomesuuExpand(true);
    window.speechSynthesis.cancel();
  }

  /**
   * 接続失敗の処理
   * @param {*} isConnectionLost 
   */
  function handleConnectionLost(isConnectionLost) {
    if (isConnectionLost) {
      CloseSerialPort();
      if (showPauseConfirmMsg === false) {
        if (showTeishiInput === false) {
          setShowDiffHandleNameErr(false);
          setShowVoltageWarning(false);
          setShowConnectionLostErr(true);
        }
      }
    }
  }
  /**
   * 再接続の処理
   */
  async function handleReConnect() {
    dispatch(setPageLoading(true));
    let result = await ConnectSerialPort();
    if (result === true) {
      dispatch(setConnectionLost(false));
      setShowConnectionLostErr(false);
      setShowSaikaiMsg(true);
    }
    else {
      dispatch(setConnectionLost(true));
      setShowConnectionLostErr(true);
    }
    dispatch(setPageLoading(false));
  }
  /**
   *中断の処理
   */
  async function handlePauseWork() {
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    window.speechSynthesis.cancel();
    let result = await writeLotOperationLog(WorkStatus.SUSPENSION, selectedWork);
    if (result.isFailed === false) {
      setShowSaikaiMsg(false);
      setShowConnectionLostErr(false);
      dispatch(setPauseConfirmMsg(true));
    }
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  function handleClose() {
    dispatch(setPauseConfirmMsg(true));
    dispatch(setShowTeishiInput(false));
  }

  /**
   *工程完了通知ダイアログ（ボタン2つ）で「はい」を押したときの処理
   */
  async function handleYesNotifyOfEndDialog() {
    dispatch(setPageLoading(true));
    setIsBtnDisabled(true);
    let paramobj = {
      userId: items.selectedUser.userId,
      itemCode: items.selectedItem.itemCode,
      lotNo: items.lotNumber,
      dispProcessId: saikaiWorkDispProcessId,
      version: items.version,
      ischeckPlanQty: saikaiWorkDispProcessOrder === 1,
      matomesuu: items.matomesuu
    }
    await axios({
      method: 'GET',
      url: variables.OPERATION_URL + "/start-work-by-lotno",
      params: paramobj,
      headers: { 'company': urlCompanyName },
      withCredentials: true,
    }).then(function (response) {
      let responseData = response.data;
      let errLst = responseData.errorCodeList;
      if (errLst.length > 0) {
        setBatchSizeErrLst(errLst);
        setIsShowNotifyOfEndDialogByBatchSize(false);
        setIsShowLastProcessNoticeDialogByBatchSize(false);
      }
      if (errLst.length === 0) {
        dispatch(resetInitState());
        let dataObj = {
          selectedItem: items.selectedItem,
          selectedUser: items.selectedUser,
          lotNumber: items.lotNumber,
          managementType: OperationManagementType.LOTNUMBER,
          lockKey: responseData.newLockKey,
          lotOperationLogLockId: responseData.lotOperationLogLockId,
          version: items.version
        };
        NavigateToOperation(navigate, dataObj);
      }
    }).catch(function (error) {
      let errResponseStatus = error.response.status;
      if (errResponseStatus === 401) {
        NavigateToOperationLogin(navigate);
      }
      else {
        appInsights.trackTrace({ params: JSON.stringify(paramobj) });
        appInsights.trackException({ ...error, errorFunction: "LotToolListInfo.handleYesNotifyOfEndDialog()" });
        console.error(error);
      }
    });
    setIsBtnDisabled(false);
    dispatch(setPageLoading(false));
  }

  return (
    <>
      {isMatomesuuExpand && <LotMatomesuuChange setIsMatomesuuExpand={setIsMatomesuuExpand} />}
      <div className="w-[600px] h-[1080px]">
        <div className="w-[490px] h-[84px] mt-[5px] mr-[5px]">
          <div className="w-[490px] h-[34px] border-2 bg-zinc-100 flex">
            <div className="w-[90px] pt-[4px]">
              <label className="font-bold text-[18px] ml-[2px]">作業進捗</label>
            </div>
            <div className="ml-[160px] flex">
              <div className="w-[200px] items-center justify-end font-bold text-right text-[18px] pt-[4px]">
                <label className="ml-auto">
                  バッチサイズ： <span className="text-[25px] w-auto items-stretch md:items-center font-bold">{items.matomesuu}</span>
                </label>
              </div>
              <div className="w-[37px] pt-[4px] cursor-pointer hover:bg-gray-200" onClick={(e) => handleDisplayMatomesuuChangeDialog(e)}>
                <IconContext.Provider value={{ size: "20px" }}>
                  <HiOutlineChevronDoubleDown className="mt-[4px] ml-[8px]" />
                </IconContext.Provider>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="w-[490px] h-[50px] border-2 border-[#C8C6C4] shadow-md">
              <div className="w-[316px] ml-[325px]">
                <label className="font-bold text-right">
                  <span className="mr-[5px]">
                    <span className="text-[22px]">{currProgressVal.toString().padStart(3, '0')}</span>
                    作業
                  </span><span className="text-[22px]">/</span><span className="ml-[5px]">
                    <span className="text-[22px]">{regularWorkCnt.toString().padStart(3, '0')}</span>作業</span>
                </label>
              </div>
              <div className="mt-[3px] ml-[5px]">
                <ProgressBar
                  bgcolor="#D2FAE0"
                  max={regularWorkCnt}
                  progress={currProgressVal}
                  height={15}
                  width={475}
                />
              </div>
            </div>
            {selectedWork.isIrregular &&
              <div className="absolute inset-0">
                <div className="w-[490px] h-[50px] bg-[#95989A] font-bold text-[26px] text-center textmiddle bg-opacity-50 ">
                  <label>修正作業中</label>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="w-[490px] h-[754px] mt-[5px] mr-[5px]">
          <div className="w-[490px] h-[34px] border-2 bg-zinc-100 textmiddle">
            <label className="font-bold text-[18px] ml-[2px]">部品/工具リスト</label>
          </div>
          <div className="w-[490px] h-[717px] border-2 border-[#C8C6C4] shadow-md overflow-y-scroll">
            {toolList.map((singleTool, index) =>
              <div key={index}>
                <div onClick={() => onClickExpand(singleTool.displayOrder)} className="w-[460px] h-[239px] border-2 border-[#C8C6C4] border-b hover:border-[#0068B8] focus:otuline-none focus:border-[#0068B8]">
                  <div className="flex flex-row">
                    <div className="ml-[3px] w-[200px]">
                      <div className="flex items-center ml-[15px]">
                      </div>
                      <div className="ml-[2px] pt-[10px] h-[25px]"><label htmlFor="name" className="ml-[0px]">
                        <label className="underline">{index + 1}</label>/<label className="underline">{toolList.length}</label>
                      </label>
                        <img
                          src={singleTool.objUrl}
                          alt="..."
                          className="w-[200px] h-[175px] object-contain border-2 border-[#eeebe9]"
                        />
                        <div className="flex">
                          <label htmlFor="count" className="ml-auto">
                            員数 <span className="text-[25px] w-auto items-stretch md:items-center font-bold">{singleTool.memberCount}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[5px] w-[200px]">
                      <label htmlFor="code">識別コード</label>
                      <textarea
                        disabled
                        className={"w-[245px] h-[49px] pl-[5px] text-[20px] bg-white resize-none border-2 border-[#eeebe9] focus:outline-none ellipse two-lines"}
                        value={singleTool.identifyCode} />
                      <label htmlFor="name" className="ml-[0px]">
                        名称
                      </label>
                      <textarea
                        disabled
                        className={"w-[245px] h-[125px] pl-[5px] bg-white text-[20px] overflow-hidden resize-none border-2 border-[#eeebe9] focus:outline-none font-bold"}
                        value={singleTool.toolName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isOpenToolListDraggable &&
              <Draggable
                className="z-[8]"
                bounds={{ left: 0, top: 0, right: 1920, bottom: 1080 }}
                defaultPosition={{ x: 252, y: 220 }} >
                <div>
                  {toolList.filter((tool) => tool.displayOrder === toolDispOrder).map((tool, index) =>
                    <div key={index}>
                      <div className="w-[1090px] h-[460px] border-[1px] border-[#000000] border-b bg-white">
                        <div className="flex h-[460px]">
                          <div className="ml-[3px] w-[460px]">
                            <div className="w-[40px] h-[38px] mt-[30px] ml-[23px] mb-[3px] text-[30px] text-[#000000]">
                              <label className="underline text-[29px]">{toolDispOrder}</label>/<label className="underline text-[29px]">{toolList.length}</label>
                            </div>
                            <div className="h-[334px] w-[444px] ml-[23px] ">
                              <img
                                className="h-[330px] w-[440px] object-contain border-2 border-[#eeebe9]"
                                src={tool.objUrl}
                                alt="..."
                              />
                            </div>
                            <div className="flex mt-[14px]">
                              <label htmlFor="count" className="ml-auto w-[58px] h-[38px] text-[29px] font-bold mt-1">
                                員数</label> <span className="text-[48px] pl-[20px] pb-1 w-auto items-stretch md:items-center font-bold">{tool.memberCount}</span>
                            </div>
                          </div>
                          <div className="ml-[23px] w-[569px]">
                            <div className="mt-[21px] w-[119px] mr-[200px] inline-block">
                              <label className="w-[119px] h-[38px] text-[29px] font-bold" htmlFor="code">識別コード</label>
                            </div>
                            <div className="inline-block mt-0 w-[250px]">
                              {toolDispOrder === 1 ?
                                <button type="button" className="w-[67px] h-[50px] btn">
                                  <IconContext.Provider value={{ color: "#C8C6C4", size: "16px", className: "inline-block" }}>
                                    <HiArrowNarrowLeft />
                                  </IconContext.Provider>
                                  <h6 className="color:[#C8C6C4] inline-flex pl-[10px]">前へ</h6>
                                </button> :
                                <button type="button" className="w-[67px] h-[50px] btn hover:bg-gray-200" onClick={isBusy ? void (0) : () => gotoPreviousTool(tool.displayOrder - 1)}>
                                  <IconContext.Provider value={{ color: "#0073CD", size: "16px", className: "inline-block" }}>
                                    <HiArrowNarrowLeft />
                                  </IconContext.Provider>
                                  <h6 className="font-bold inline-flex pl-[10px]">前へ</h6>
                                </button>
                              }
                              {toolDispOrder === toolList.length ?
                                <button type="button" className="ml-[10px]  w-[67px] h-[50px] btn">
                                  <IconContext.Provider value={{ color: "#C8C6C4", size: "16px", className: "inline-block" }}>
                                    <HiArrowNarrowRight />
                                  </IconContext.Provider>
                                  <h6 className="color:[#C8C6C4] inline-block pl-[10px]">次へ</h6>
                                </button> :
                                <button type="button" className="ml-[10px] w-[67px] h-[50px] btn hover:bg-gray-200" onClick={isBusy ? void (0) : () => gotoNextTool(tool.displayOrder + 1)}>
                                  <IconContext.Provider value={{ color: "#0073CD", size: "16px", className: "inline-block" }}>
                                    <HiArrowNarrowRight />
                                  </IconContext.Provider>
                                  <h6 className="font-bold inline-block pl-[10px]">次へ</h6>
                                </button>
                              }
                              <button type="button" className="ml-[10px] w-[67px] h-[50px] btn hover:bg-gray-200" onClick={isBusy ? void (0) : () => toolListDraggablePopupClose()}>
                                <IconContext.Provider value={{ color: "#0073CD", size: "16px", className: "inline-block" }}>
                                  <IoCloseOutline />
                                </IconContext.Provider>
                                <h6 className="font-bold inline-block pl-[10px]">閉じる</h6>
                              </button>
                            </div>
                            <div>
                              <textarea
                                disabled
                                className={"w-[569px] leading-10 text-[32px] mt-[12px] h-[149px] bg-white px-3 py-2 mb-[24px] overflow-hidden resize-none border-[1px] focus:outline-none"}
                                value={tool.identifyCode} />
                            </div>
                            <div>
                              <label className="mb-[29px] w-[58px] h-[38px] text-[29px] font-bold" htmlFor="name">
                                名称
                              </label>
                              <textarea
                                disabled
                                className={"w-[569px] leading-10 h-[149px] text-[32px] bg-white px-3 py-2 mt-[12px] mb-[17px] overflow-hidden resize-none border-2 border-[#eeebe9] focus:outline-none"}
                                value={tool.toolName} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)}
                </div>
              </Draggable>
            }
            {((currManageItemType === ManageItemType.NumericalJudge && currDescription.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) ||
              (currManageItemType === ManageItemType.Text && currDescription.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) ||
              (currManageItemType === ManageItemType.TextCompare && currDescription.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) ||
              (currManageItemType === ManageItemType.OkNgJudge && currDescription.hindoKaisuSetting.hindoKaisuSettingType === HindoKaisuSetting.Kaisu) ||
              (currManageItemType === ManageItemType.Hardware && currDescription.hardwareKurikaeshi === true)) &&
              <LotKurikaeshiList manageItemType={currManageItemType} description={currDescription} isShowFullPercentage={checkToShowNextWork(currManageItemType) || checkToShowCompleteWork(currDescription) || isShowLastProcessNoticeDialog || isShowNotifyOfEndDialog || isShowLastProcessNoticeDialogByBatchSize || isShowNotifyOfEndDialogByBatchSize} />
            }
          </div>
        </div>
        <div className="w-[490px] h-[223px] mt-[5px] mr-[5px]">
          <div className="w-[490px] h-[34px] border-2 bg-zinc-100 textmiddle">
            <label className="font-bold text-[18px] ml-[2px]">管理項目</label>
          </div>
          {(checkToShowCompleteWork() === true) ? (
            <div ref={props.inputRefKanryou} className="w-[490px] h-[189px] border-2 border-[#C8C6C4] bg-[#0073CD] cursor-pointer hover:border-[#0068B8]" onClick={isBtnDisabled || isBusy ? void (0) : () => handleCompleteWork()}>
              <div className="w-[490px] h-[189px] mt-[80px]">
                <label className={"font-bold text-[42px] text-white cursor-pointer " + (items.processObj.isLastProcess ? "ml-[142px]" : "ml-[158px]")}>{items.processObj.isLastProcess ? "全作業完了" : "工程完了"}</label>
              </div>
            </div>
          ) : (isShowNextBtn === true || checkToShowNextWork(currManageItemType) === true) ? (
            <div ref={props.inputRefNext} className="w-[490px] h-[189px] border-2 border-[#C8C6C4] bg-[#D3EDFB] cursor-pointer hover:border-[#0068B8]" onClick={isBtnDisabled || isBusy ? void (0) : () => handleMoveNextWork()}>
              <div className="w-[490px] h-[189px] mt-[80px]">
                <label className="font-bold text-[42px] ml-[154px] cursor-pointer">次の作業</label>
              </div>
            </div>
          ) : (currManageItemType === ManageItemType.Hardware) ? (
            <div className="w-[490px] h-[189px] bg-[#0068B8]">
              <div className="font-bold text-white text-[30px] ml-[155px] pt-[15px]">締結カウント</div>
              <div className="ml-[30px] pt-[55px] flex">
                <div className="w-[147px] h-[155px] text-center font-bold text-white text-[110px]">{isShowLastProcessNoticeDialog === true || isShowNotifyOfEndDialog === true || isShowLastProcessNoticeDialogByBatchSize === true || isShowNotifyOfEndDialogByBatchSize === true ? displayCompletedCount + 1 : displayCompletedCount}</div>
                <div className="w-[130px] h-[155px] text-center font-bold text-white text-[110px]">/</div>
                <div className="w-[147px] h-[155px] text-center font-bold text-white text-[110px]">{currHWRegisterCnt}</div>
              </div>
            </div>
          ) : (
            <div className={"w-[490px] h-[189px] border-2 border-[#C8C6C4] " + (currManageItemType === 2 ? "bg-[#FAEFAF]" : "bg-[#FFFFFF]")}>
              {(currManageItemType === ManageItemType.MarketWarning) && (
                <div className="w-[490px] h-[189px] mt-[30px]">
                  <label className="font-bold text-[32px] ml-[150px]">警告メッセージ</label>
                  <div className="w-[77px] h-[72px] border-2 border-[#0068B8] bg-white ml-[206px] mt-[30px] cursor-pointer hover:bg-gray-200" onClick={(e) => handelMarketWarningViewer(e)}>
                    <label className="font-bold text-[18px] cursor-pointer"><span className="text-[#0073CD] ml-[17px]">画像</span></label>
                    <IconContext.Provider value={{ size: "41px", color: "#0073CD" }}>
                      <ImImages className="ml-[16px] cursor-pointer" />
                    </IconContext.Provider>
                  </div>
                </div>
              )}
              {(currManageItemType === ManageItemType.Text) && (
                <div className="w-[490px] h-[189px] mt-[30px]">
                  <label className="font-bold text-[32px] ml-[155px]">文字列登録</label>
                  <TextAreaTab className="w-[470px] leading-9 h-[87px] text-[32px] ml-[8px] mt-[20px] overflow-hidden resize-none border-2 border-[#C8C6C4] focus:outline-none focus:border-[#0073CD]"
                    placeholder="テキストを入力してください。"
                    readOnly={isBusy}
                    onChange={isBusy || isBtnDisabled ? void (0) : (e) => handleTextChange(e)}
                    onKeyUp={isBusy || isBtnDisabled ? void (0) : (e) => handleTextInputEnterKeyUp(e)}
                    value={currDescription.txtInputText}
                    ref={refTextInput}
                  />
                </div>
              )}
              {(currManageItemType === ManageItemType.TextCompare) && (
                <div className="w-[490px] h-[189px] mt-[30px]">
                  <label className="font-bold text-[32px] ml-[155px]">文字列比較</label>
                  <TextAreaTab className="w-[470px] h-[87px] text-[32px] ml-[8px] mt-[20px] overflow-hidden resize-none border-2 border-[#C8C6C4] focus:outline-none focus:border-[#0073CD]"
                    placeholder="テキストを入力してください。"
                    readOnly={isBusy}
                    onChange={isBusy || isBtnDisabled ? void (0) : (e) => handleTextChange(e)}
                    onKeyUp={isBusy || isBtnDisabled ? void (0) : (e) => handleTextCompareEnterKeyUp(e)}
                    value={currDescription.txtInputText}
                    ref={refTextCompareInput}
                  />
                </div>
              )}
              {(currManageItemType === ManageItemType.NumericalJudge) && (
                <div className="w-[490px] h-[189px] mt-[30px]">
                  <label className="font-bold text-[32px] ml-[175px]">数値判定</label>
                  <input className="w-[470px] h-[37px] text-[32px] ml-[8px] mt-[20px] border-2 border-[#C8C6C4] focus:outline-none focus:border-[#0073CD]"
                    placeholder="テキストを入力してください。"
                    readOnly={isBusy}
                    onChange={isBusy || isBtnDisabled ? void (0) : (e) => handleNumberChange(e)}
                    onKeyUp={isBusy || isBtnDisabled ? void (0) : (e) => handleNumericEnterKeyUp(e)}
                    value={currDescription.txtInputText}
                    ref={refNumericInput}
                  />
                </div>
              )}
              {(currManageItemType === ManageItemType.OkNgJudge) && (
                <div className="w-[490px] h-[189px] mt-[30px]">
                  <label className="font-bold text-[32px] ml-[155px]">OK/NG判定</label>
                  <div className="flex mt-[35px]">
                    <button
                      type="button"
                      className="w-[212px] h-[74px] text-[36px] font-bold ml-[15px] bg-[#96FAB9] border-2 border-[#C8C6C4] border-solid hover:border-[#000000] rounded-full"
                      onClick={isBtnDisabled || isBusy ? void (0) : (e) => handleOKBtnClick(e)}
                    >OK</button>
                    <button
                      type="button"
                      className="w-[212px] h-[74px] text-[36px] font-bold ml-[32px] bg-[#FA96A5] border-2 border-[#C8C6C4] border-solid hover:border-[#000000] rounded-full"
                      onClick={isBtnDisabled || isBusy ? void (0) : (e) => handleNGBtnClick(e)}
                    >NG</button>
                  </div>
                </div>
              )}
              {(currManageItemType === ManageItemType.SagyouBunki && currDescription.sagyouBunkiType === SagyouBunkiType.BtnType) && (
                <div className="w-[490px] h-[189px] mt-[30px]">
                  <label className="font-bold text-[32px] ml-[155px]">作業分岐</label>
                  <div className="flex mt-[35px]">
                    <button
                      type="button"
                      className="w-[212px] h-[74px] text-[36px] text-white font-bold bg-[#3D8ECC] ml-[15px] border-2 border-[#C8C6C4] border-solid hover:border-[#000000] rounded-full"
                      onClick={isBtnDisabled || isBusy ? void (0) : () => handleBunkiBtnClick(currDescription.bunkiData[0].workID)}
                    >{currDescription.bunkiData[0].btnText}</button>
                    <button
                      type="button"
                      className="w-[212px] h-[74px] text-[36px] text-white font-bold bg-[#3D8ECC] ml-[15px] border-2 border-[#C8C6C4] border-solid hover:border-[#000000] rounded-full"
                      onClick={isBtnDisabled || isBusy ? void (0) : () => handleBunkiBtnClick(currDescription.bunkiData[1].workID)}
                    >{currDescription.bunkiData[1].btnText}</button>
                  </div>
                </div>
              )}
              {(currManageItemType === ManageItemType.SagyouBunki && currDescription.sagyouBunkiType === SagyouBunkiType.ListType) && (
                <div className="w-[490px] h-[189px] mt-[30px]">
                  <label className="font-bold text-[32px] ml-[155px]">作業分岐</label>
                  <div className="h-[65px] mt-[24px] ml-[15px]">
                    <ComboxBoxValueSearchable
                      width={460}
                      height={65}
                      menuPlacement={"top"}
                      maxMenuHeight={580}
                      isSearchable={true}
                      selectedOption={selectedBunkiOrder}
                      refComboBox={refBunkiWorkComboBox}
                      placeholder={"バーコード入力、またはリストから作業選択"}
                      itemsSource={bunkiWorkDataList()}
                      targetValue={"workID"}
                      displayMemberPath={"workName"}
                      displayOrder={"workDispOrder"}
                      selectedValuePath={"bunkiOrder"}
                      onSelectedChange={handleBunkiWorkChange}>
                    </ComboxBoxValueSearchable>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* 警告メッセージ表示 */}
        {isMarketWarningView && (
          <MarketWarningViewer
            src={currDescription.mketWarnImgInfo.objUrl}
            text={currDescription.mketWarnText}
            Close={() => setMarketWarningView(false)}
            OnClick={() => handleConfirmBtnClick()}
          />
        )}
        {/* NGボタンの変化 */}
        {(showError && writeLogErrorCode === 0) && (
          <ErrorBox
            yellowBG={true}
            className="absolute left-[700px] top-[365px] w-[528px]"
            Title="OK/NG判定"
            Message="リトライをするか、中断して管理者へ報告をしてください。"
            onYesClick={isBtnDisabled ? void (0) : () => handleNGBtnShowError()}
          />
        )}
        {/* 数値判定エラー */}
        {(showNumericError && writeLogErrorCode === 0) && (
          <ErrorBox
            yellowBG={true}
            className="absolute left-[700px] top-[365px] w-[528px]"
            Title="数値判定"
            Message={<>
              {"閾値を超えています。"} <br />
              {"リトライをするか、中断して管理者へ報告をしてください。"}
            </>}
            onYesClick={isBtnDisabled ? void (0) : () => handleYesNumericError()}
          />
        )}
        {/* 文字列比較エラー */}
        {(showCompareTextError && writeLogErrorCode === 0) && (
          <ErrorBox
            yellowBG={true}
            className="absolute left-[700px] top-[365px] w-[528px]"
            Title="文字列比較"
            Message={<>
              {"登録された文字列と違っています。"} <br />
              {"リトライするか、中断して管理者へ報告をしてください。"}
            </>}
            onYesClick={isBtnDisabled ? void (0) : () => handleYesTextCompareError()}
          />
        )}
        {/* 全作業完了（バッチサイズ >= (計画数-仕掛かり数-完了数)の場合） */}
        {isShowLastProcessNoticeDialogByBatchSize && (
          <ConfirmBox
            key={uuidv4()}
            className="absolute left-[700px] top-[365px] w-[528px]"
            title="全作業完了"
            message={<>
              {"すべての作業が完了しました。"} <br />
              {"同じバッチサイズで次のバッチを作業しますか？"}
            </>}
            btn1Width={92}
            btn2Width={180}
            btn1Text="はい"
            btn2Text="作業開始画面へ"
            onYesClick={isBtnDisabled ? void (0) : () => { handleYesNotifyOfEndDialog(); }}
            onNoClick={() => { NavigateToOperationStartWork(navigate); }}
          />
        )}
        {/* 全作業完了（バッチサイズ < (計画数-仕掛かり数-完了数)の場合） */}
        {isShowLastProcessNoticeDialog && (
          <ErrorBox
            className="absolute left-[700px] top-[365px] w-[528px]"
            Title="全作業完了"
            Message={<>
              {"すべての作業が完了しました。"} <br />
              {"作業開始画面に戻ります。"}
            </>}
            onYesClick={() => { NavigateToOperationStartWork(navigate); setIsShowLastProcessNoticeDialog(false); }}
          />
        )}
        {/* 工程完了通知フラグが「ON」&& バッチサイズが計画数あるいは前の工程の完了数以下の場合 */}
        {isShowNotifyOfEndDialogByBatchSize && (
          <ConfirmBox
            key={uuidv4()}
            className="absolute left-[700px] top-[365px] w-[528px]"
            title="工程完了"
            message={<>
              {"「工程No" + items.processObj.dispOrder + " : " + items.processObj.processName + "」"} <br />
              {"すべての作業が完了しました。"} <br />
              {"同じバッチサイズで次のバッチを作業しますか？"}
            </>}
            btn1Width={92}
            btn2Width={180}
            btn1Text="はい"
            btn2Text="作業開始画面へ"
            onYesClick={isBtnDisabled ? void (0) : () => { handleYesNotifyOfEndDialog(); }}
            onNoClick={() => { NavigateToOperationStartWork(navigate); }}
          />
        )}
        {/* 工程完了通知フラグが「ON」&& バッチサイズが計画数あるいは前の工程の完了数を上回るの場合 */}
        {isShowNotifyOfEndDialog && (
          <ErrorBox
            className="absolute left-[700px] top-[365px] w-[528px]"
            Title="工程完了"
            Message={<>
              {"「工程No" + items.processObj.dispOrder + " : " + items.processObj.processName + "」"} <br />
              {"すべての作業が完了しました。"} <br />
              {"作業開始画面に戻ります。"}
            </>}
            onYesClick={() => { NavigateToOperationStartWork(navigate); }}
          />
        )}
        {/*ログ登録エラーメッセージ表示「1009 =>工程設定の変更,1010 =>作業開始不可,1011 =>作業継続不可」*/}
        {(writeLogErrorCode > 0 || (batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1009, errorCode.E1010, errorCode.E1011].includes(err)))) && (
          <ErrorBox
            className={"absolute left-[700px] top-[365px] " + ((writeLogErrorCode === errorCode.E1009 || batchSizeErrLst.some(errCode => errCode === errorCode.E1009)) ? "w-[600px]" : (writeLogErrorCode === errorCode.E1010 || batchSizeErrLst.some(errCode => errCode === errorCode.E1010) ? "w-[830px]" : "w-[528px]"))}
            Title={(writeLogErrorCode === errorCode.E1009 || batchSizeErrLst.some(errCode => errCode === errorCode.E1009)) ? "工程設定の変更" : (writeLogErrorCode === errorCode.E1010 || batchSizeErrLst.some(errCode => errCode === errorCode.E1010)) ? "作業開始不可" : "作業継続不可"}
            Message={<>
              {errorCode[writeLogErrorCode > 0 ? writeLogErrorCode : batchSizeErrLst.find(err => [errorCode.E1009, errorCode.E1010, errorCode.E1011].includes(err))].split("\n").map((msg, idx) => (
                <div key={idx}>{msg}</div>))
              }
            </>}
            onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
          />
        )}
        {/* 工程完了通知・全作業完了ダイアログのエラーメッセージ表示
        ダイアログが表示されてから一定時間放置した間に別のユーザーが工程を完了させた場合の考慮 */}
        {(batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1007, errorCode.E1016].includes(err))) && (
          <ErrorBox
            className={"absolute left-[700px] top-[365px] w-[528px]"}
            Title={"作業継続不可"}
            Message={<>
              {"同じバッチサイズで次のバッチを作業できません。"} <br />
              &nbsp;{"原因："}
              {errorCode[batchSizeErrLst.find(err => [errorCode.E1007, errorCode.E1016].includes(err))].split("\n").map((msg, idx) => (
                <span key={idx}>{msg}</span>))
              } <br />
              {"作業開始画面に戻ります。"}
            </>}
            onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
          />
        )}
        {/* 工程完了通知・全作業完了ダイアログのエラーメッセージ表示「1003, 1004」は
        エラー発生頻度が非常に少ない && オペレーション開始画面に戻った際に確認できることからダイアログを共通化して表示する */}
        {(batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1003, errorCode.E1004].includes(err))) && (
          <ErrorBox
            className={"absolute left-[700px] top-[365px] w-[528px]"}
            Title={"作業継続不可"}
            Message={<>
              {"管理者によって作業を継続できない状況になりました。"} <br />
              {"管理者へ報告をしてください。"}
            </>}
            onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
          />
        )}
        {/* 工程完了通知・全作業完了ダイアログのエラーメッセージ表示「1005, 1006, 1015」は
         ユーザー操作では発生しないため、例外エラーとして処理する*/}
        {(batchSizeErrLst.length > 0 && batchSizeErrLst.some(err => [errorCode.E1005, errorCode.E1006, errorCode.E1015].includes(err))) && (
          <ErrorBox
            className={"absolute left-[700px] top-[365px] w-[528px]"}
            Title={"作業継続不可"}
            Message={<>
              {"工程情報、製品マスタ、生産計画のいずれかが登録されていません。"} <br />
              {"作業開始画面に戻ります。"}
            </>}
            onYesClick={() => { setWriteLogErrorCode(0); NavigateToOperationStartWork(navigate); }}
          />
        )}
        {/* 電池残量が少なく場合 */}
        {(showVoltageWarning && writeLogErrorCode === 0) && (
          <ErrorBox
            yellowBG={true}
            className="absolute left-[700px] top-[365px] w-[600px]"
            Title="ハードウエア連携"
            Message={<>
              {"電池残量が少なくなりました。"}<br />
              {"電池の交換をしてください。"} </>}
            onYesClick={isBtnDisabled ? void (0) : () => handleYesVoltageWarning()}
          />
        )}
        {/* 間違った工具を使用している場合。 */}
        {(showDiffHandleNameErr && writeLogErrorCode === 0) && (
          <ErrorBox
            yellowBG={true}
            className="absolute left-[700px] top-[365px] w-[600px]"
            Title="ハードウエア連携"
            Message={<>
              {"間違った工具を使用しています。 "}<br />
              {"正しい工具を使用してください。"} </>}
            onYesClick={isBtnDisabled ? void (0) : () => setShowDiffHandleNameErr(false)}
          />
        )}
        {/* COM接続がされていない場合 */}
        {(currManageItemType === ManageItemType.Hardware && showConnectionLostErr && writeLogErrorCode === 0) && (
          <ConfirmBox
            key={uuidv4()}
            className="absolute left-[700px] top-[365px] w-[600px]"
            title="ハードウエア連携"
            message={<>
              {"COM接続がされていません。 "}<br />
              {"接続してください。"} </>}
            btn1Width={92}
            btn2Width={92}
            btn1Text="再接続"
            btn2Text="中断"
            onYesClick={isBtnDisabled ? void (0) : () => handleReConnect()}
            onNoClick={isBtnDisabled ? void (0) : () => handlePauseWork()} />
        )}
        {/* 再接続の後 */}
        {(currManageItemType === ManageItemType.Hardware && showSaikaiMsg && writeLogErrorCode === 0) && (
          <ConfirmBox
            key={uuidv4()}
            className="absolute left-[700px] top-[365px] w-[600px]"
            title="ハードウエア連携"
            message={<>
              {"ハードウエアの接続をしました。"}<br />
              {" リトライをするか、中断して管理者へ報告をしてください。"} </>}
            btn1Width={92}
            btn2Width={92}
            btn1Text="再開"
            btn2Text="中断"
            onYesClick={isBtnDisabled ? void (0) : () => setShowSaikaiMsg(false)}
            onNoClick={isBtnDisabled ? void (0) : () => handlePauseWork()} />
        )}
        {/* 作業中断確認メッセージ */}
        {(currManageItemType === ManageItemType.Hardware && showPauseConfirmMsg && writeLogErrorCode === 0) && (
          <LotNumberSaiKai
            setWriteLogErrorCode={setWriteLogErrorCode} />
        )}
        {showTeishiInput && (<TeishiInputBox
          onClose={handleClose}
          className="absolute right-0 bottom-0"
          logId={items.lotOperationLogId}
          managementType={OperationManagementType.LOTNUMBER} />
        )}
        {GetPort() && isConnectionLost === false && <SerialPortReader />}
      </div>
    </>
  );
}

LotToolListInfo.defaultProps = {
  inputRefNext: null,
  inputRefKanryou: null,
}
export default LotToolListInfo;
import { React } from "react";
import { useSelector } from 'react-redux';
import { getIsLoading as getIsPageLoading } from "../features/Loading/pageLoadingSlice";

/**
 * 透明のdivを設定する。
 * なので、後ろに有る項目「ボタン、テストボックス」が押せなくなる。
 * getIsPageLoading　が true の場合、return [透明の　div]。
 * getIsPageLoading　が false の場合、return null。
 * @param {*} props 
 * @returns 
 */
function PageLoading(props) {
    const loading = useSelector(getIsPageLoading);
    if (!loading) {
        return null;
    }
    return (
        <div className={"flex items-center justify-center absolute inset-0 z-[20]"}>
            <div>
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

export default PageLoading;
import React from "react";
import { ManageItemType } from "../../../Variables";
import { useSelector } from "react-redux";
import { getSelectedWorkInfo } from "./workListSlice";
import Select from "react-select";

let grpList = ["補助", "文字列", "判定"];
let optionList = [
  { group: "", label: "管理項目を選択して下さい", value: ManageItemType.None },
  { group: "補助", label: "ハードウェア連携", value: ManageItemType.Hardware },
  { group: "補助", label: "作業分岐", value: ManageItemType.SagyouBunki },
  { group: "補助", label: "音声出力", value: ManageItemType.Audio },
  { group: "補助", label: "警告メッセージ", value: ManageItemType.MarketWarning },
  { group: "文字列", label: "文字列登録", value: ManageItemType.Text },
  { group: "文字列", label: "文字列比較", value: ManageItemType.TextCompare },
  { group: "判定", label: "数値判定", value: ManageItemType.NumericalJudge },
  { group: "判定", label: "OK/NG判定", value: ManageItemType.OkNgJudge },
];

const colourStyles = {
  option: (base, state) => {
    return {
      ...base,
      backgroundColor: state.isFocused ? "#D8D7D5" : null,
      "&:active": {
        backgroundColor: "#D8D7D5",
      },
      color: "#050505",
    };
  },
  control: (base, state) => ({
    ...base,
    boxShadow: 0,
    borderColor: state.isFocused ? "#0073CD" : base.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? "#0073CD" : base.borderColor
    }
  }),
};

function ManageItemDropDown(props) {
  const selectedWork = useSelector((state) => getSelectedWorkInfo(state));

  const getOptions = () => {
    let options = [];
    options.push({ label: <><div>{"管理項目を選択して下さい"}</div></>, value: ManageItemType.None });
    grpList.forEach(grp => {
      let obj = {};
      let subOptions = [];
      optionList.filter((opt) => opt.group === grp).forEach(opt => {
        let subObj = {};
        subObj['value'] = opt.value;
        subObj['label'] = <><div className="ml-[18px] overflow-hidden whitespace-nowrap overflow-ellipsis">{opt.label}</div></>;
        subOptions.push(subObj);
      })
      obj['options'] = subOptions;
      obj['label'] = <><div className="font-bold text-[18px] border-t-2 pt-2 text-[#0073CD] overflow-hidden whitespace-nowrap overflow-ellipsis">{grp}</div></>;
      options.push(obj);
    });
    return options;
  }

  const selectedValue = () => {
    let option = optionList.find((option) => option.value === props.selectedOption);
    return option;
  }

  function onSelectedChange(e) {
    props.onSelectedIndexChange(e.value);
  }

  return (
    <div >
      {/* TODO: デザイン変更 */}
      <Select
        className="react-select w-[397px]"
        options={getOptions()}
        classNamePrefix="select"
        menuPlacement={"top"}
        maxMenuHeight={540}
        isSearchable={false}
        isDisabled={selectedWork.isLock}
        value={selectedValue()}
        onChange={onSelectedChange}
        styles={colourStyles}
      >
      </Select>
    </div>
  );
}

ManageItemDropDown.defaultProps = {
  selectedOption: ManageItemType.None,
};

export default ManageItemDropDown;

import { React, useEffect, useState } from "react";
import { variables, OperationManagementType, SerialPortStatus, UrlPath, Sort, WorkStatus } from "../../Variables";
import SerialNumberStartWork from "./workCheck/SerialNumberStartWork";
import LotNumberStartWork from "./workCheck/LotNumberStartWork";
import { v4 as uuidv4 } from "uuid";
import { ConvertDisplayDateFormat, ConvertDisplayDateTimeFormat, DisplayDateFormatChange, MeasureText, NavigateToOperationLogin, ToLocalDateTime, ToUTCDateTime, urlCompanyName, Validate } from "../util/commonFun";
import PageLoading from "../../components/PageLoading";
import {
    IsPortConnected,
    GetPort,
    ConnectSerialPort,
    CloseSerialPort
} from "../util/SerialPortConnection";
import { useNavigate } from "react-router";
import { appInsights } from "../util/ApplicationInsight";
import Update from "../../components/Update";
import { DateTimePicker } from "../../components/DateTimePicker";
import ComboBox from "../../components/ComboBox";
import ToggleButton from "../../components/ToggleButton";
import TableTitle from "../../components/TableTitle";
import SectionTitle from "../../components/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import { setResetProcessList, setSelectedDispProcessId } from "./operationStartWorkSlice";
import { setIsLoading as setPageLoading } from "../Loading/pageLoadingSlice";

const sortColumns = {
    none: 0,
    serialNo: 1,
    displayDateOrStatus: 2,
}
let initialTimeout = 800;
const sagyouchuu = "作業中";
const chuudan = "中断";
const axios = require('axios');
const tdWidth = 300;
const tblFontSize = 22;
function OperationStartWork(props) {
    const dispatch = useDispatch();
    const type = sessionStorage.getItem('managementType') ? Number(sessionStorage.getItem('managementType')) : OperationManagementType.SERIALNUMBER;
    const currLotNumber = sessionStorage.getItem('lotNo') ? sessionStorage.getItem('lotNo') : "";
    const [managementType, setManagementType] = useState(type);
    const [displayTooltip, setTooltip] = useState(null);
    const [seisanInfoList, setSeisanInfoList] = useState([]);
    const [lotNumber, setLotNumber] = useState(currLotNumber);
    const [dialogBox, setDialogBox] = useState(null);
    const [connectionLabel, setConnectionLabel] = useState(IsPortConnected() ? SerialPortStatus.Connected : SerialPortStatus.Disconnected);
    const [keiKaKuSuu, setKeiKaKuSuu] = useState(0);
    let navigate = useNavigate();
    const sevenDaysAgoDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    // Set time to 00:00:00
    sevenDaysAgoDate.setHours(0, 0, 0);
    let tempStartDate = sessionStorage.getItem('startDate') ? sessionStorage.getItem('startDate') : "";
    let currentStartDate = tempStartDate.length > 0 && new Date(tempStartDate).getDate() >= new Date(sevenDaysAgoDate).getDate() ? new Date(tempStartDate) : new Date().setHours(0, 0, 0);
    let currentEndDate = new Date();
    const items = useSelector((state) => state.operationStartWork);
    const selectedUserId = items.selectedUser?.userId ?? 0;
    const selectedItemCode = items.selectedItem?.itemCode ?? 0;
    const processList = selectedItemCode === 0 ? [] : [...items.dataList.processList];
    const selectedDispProcessId = items.selectedDispProcessId;
    const [serialCompletedInfoList, setSerialCompletedInfoList] = useState([]);
    let currSortColumn = sessionStorage.getItem('sortColumn') ? JSON.parse(sessionStorage.getItem('sortColumn')) : sortColumns.none;
    let currSortType = sessionStorage.getItem('sortType') ? JSON.parse(sessionStorage.getItem('sortType')) : Sort.NONE;
    let currUserFilter = sessionStorage.getItem('userFilter') ? JSON.parse(sessionStorage.getItem('userFilter')) : true;
    const [serialNoSortType, setSerialNoSortType] = useState(currSortColumn === sortColumns.serialNo ? currSortType : Sort.NONE);
    const [completedDateSortType, setCompletedDateSortType] = useState(currSortColumn === sortColumns.displayDateOrStatus ? currSortType : Sort.NONE);
    const [isUserFilterON, setIsUserFilterON] = useState(currUserFilter);
    const [updateTime, setUpdateTime] = useState(DisplayDateFormatChange(currentEndDate));
    const [startDate, setStartDate] = useState(ConvertDisplayDateTimeFormat(currentStartDate));
    const [endDate, setEndDate] = useState(ConvertDisplayDateTimeFormat(currentEndDate));

    useEffect(() => {
        const validate = async () => {
            try {
                await Validate(UrlPath.Operation);
            } catch (error) {
                if (error === 401) {
                    NavigateToOperationLogin(navigate);
                }
                else {
                    console.error(error);
                    appInsights.trackException({ ...error, errorFunction: "OperationStartWork.useEffect()" });
                }
            }
        }
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (lotNumber.length === 0) {
            setSeisanInfoList([]);
        }
    }, [lotNumber])

    useEffect(() => {
        const timeOutID = setTimeout(function () {
            getSerialCompletedInfo();
        }, initialTimeout);

        return function cleanUp() {
            clearTimeout(timeOutID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, updateTime, selectedUserId, selectedItemCode, selectedDispProcessId, isUserFilterON]);

    function handleBtnClick(type) {
        setTooltip(null);
        if (managementType === type) {
            return;
        }
        sessionStorage.setItem('managementType', type);
        setManagementType(type);
        setLotNumber("");
        setKeiKaKuSuu(0);
        setSeisanInfoList([]);
        dispatch(setResetProcessList());
        dispatch(setSelectedDispProcessId(""));
        sessionStorage.removeItem('lotNo');
        sessionStorage.removeItem('dispProcessId');
        sessionStorage.removeItem('matomesuu');
        sessionStorage.removeItem('itemCode');
        if (type === OperationManagementType.SERIALNUMBER) {
            handleUpdateClick();
        }
    }

    function handleMouseOverToDisplayTooltip(processName, idx, e) {
        if (displayTooltip === null && MeasureText(processName, tblFontSize, tdWidth)) {
            e.stopPropagation();
            e.preventDefault();
            let positionY = e.pageY + (e.currentTarget.clientHeight / 2);
            setTooltip(
                <div id={idx} style={{ position: 'absolute', left: '1440px', top: positionY + 'px' }} onMouseLeave={() => { setTooltip(null); }}>
                    <div className={"max-w-[450px] break-all px-2 py-2 rounded-sm border-2 border-[#777777] bg-white text-[" + tblFontSize + "px]"}>
                        {processName}
                    </div>
                </div>
            );
        }
    }

    async function onClickConnection() {
        let result = await ConnectSerialPort();
        if (result === true) {
            setConnectionLabel(SerialPortStatus.Connected);
        }
        else {
            setConnectionLabel(SerialPortStatus.Disconnected);
        }
    }

    if (GetPort() !== null) {
        GetPort().addEventListener("disconnect", function (event) {
            CloseSerialPort();
            setConnectionLabel(SerialPortStatus.Disconnected);
        });
    }

    /**
     * 更新ボタンの操作
     */
    function handleUpdateClick() {
        var updateDateTime = new Date();
        // YYYY/MM/DD HH:MM:SS
        setUpdateTime(DisplayDateFormatChange(updateDateTime));
        // YYYY/MM/DD HH:MM
        setEndDate(ConvertDisplayDateTimeFormat(updateDateTime));
    }

    /**
     * StartDate変更処理
     * @param {*} value 
     */
    function handleStartDateChange(value) {
        setStartDate(value);
        sessionStorage.setItem('startDate', ConvertDisplayDateTimeFormat(value));
    }

    /**
    * EndDate変更処理
    * @param {*} value 
    */
    function handleEndDateChange(value) {
        setEndDate(value);
    }

    /**
     * ユーザーのフィルタートグル操作
     */
    function handleUserFilterONOFF() {
        let value = !isUserFilterON;
        setIsUserFilterON(value);
        sessionStorage.setItem('userFilter', value);
    }

    /**
     * シリアル番号、完了日時カラムのソーティング
     * @param {*} sortColumn 
     */
    function handleSortColumn(sortColumn) {
        let sortType = Sort.DESC;
        if (sortColumn === sortColumns.serialNo) {
            if (serialNoSortType === Sort.DESC) {
                sortType = Sort.ASC;
            }
            let sortedList = serialCompletedInfoList.sort(function (firstItem, secondItem) {
                if (sortType === Sort.ASC) {
                    return firstItem.serialNo.localeCompare(secondItem.serialNo)
                } else {
                    return secondItem.serialNo.localeCompare(firstItem.serialNo)
                }
            });
            setSerialCompletedInfoList(sortedList);
            setSerialNoSortType(sortType);
            setCompletedDateSortType(Sort.NONE);
        }
        if (sortColumn === sortColumns.displayDateOrStatus) {
            if (completedDateSortType === Sort.DESC) {
                sortType = Sort.ASC;
            }
            let sortedList = serialCompletedInfoList.sort(function (firstItem, secondItem) {
                if (sortType === Sort.ASC) {
                    return firstItem.displayDateOrStatus.localeCompare(secondItem.displayDateOrStatus)
                } else {
                    return secondItem.displayDateOrStatus.localeCompare(firstItem.displayDateOrStatus)
                }
            });
            setSerialCompletedInfoList(sortedList);
            setCompletedDateSortType(sortType);
            setSerialNoSortType(Sort.NONE);
        }
        sessionStorage.setItem('sortColumn', sortColumn);
        sessionStorage.setItem('sortType', sortType);
    }

    /**
     * 工程コンボボックスの選択イベント
     * @param {*} e 
     */
    async function handleKouteiSelectedChange(e) {
        let dispProcessId = e.targetValue;
        if (selectedDispProcessId !== dispProcessId) {
            dispatch(setSelectedDispProcessId(dispProcessId));
            sessionStorage.setItem('dispProcessId', dispProcessId);
        }
    }

    /**
     * 完了実績表示エリアのデータ取得処理
     * @returns 
     */
    async function getSerialCompletedInfo() {
        if (selectedItemCode <= 0 || selectedDispProcessId.length === 0) {
            setSerialCompletedInfoList([]);
            return;
        }
        if (isUserFilterON === true && selectedUserId <= 0) {
            setSerialCompletedInfoList([]);
            return;
        }
        if (isDateCombinationOK()) {
            var startDateUTC = ToUTCDateTime(new Date(startDate));
            var endDateUTC = ToUTCDateTime(new Date(endDate));
            dispatch(setPageLoading(true));
            let paramobj = { startDate: startDateUTC, endDate: endDateUTC, userId: selectedUserId, itemCode: selectedItemCode, dispProcessId: selectedDispProcessId, isUserFilterON: isUserFilterON };
            await axios({
                method: 'GET',
                url: variables.OPERATION_URL + "/get-serial-completed-info",
                params: paramobj,
                headers: { 'company': urlCompanyName },
                withCredentials: true,
            }).then(function (response) {
                let responseDataList = response.data;
                if (responseDataList && responseDataList.length > 0) {
                    let dataList = responseDataList.map(item => {
                        return {
                            serialNo: item.serialNo,
                            displayDateOrStatus: getDisplayDateOrStatus(item.workStatusId, item.completedDate),
                        };
                    });
                    if (serialNoSortType === Sort.NONE && completedDateSortType === Sort.NONE) {
                        setSerialNoSortType(Sort.DESC);
                        let sortedList = dataList.sort(function (firstItem, secondItem) {
                            return secondItem.serialNo.localeCompare(firstItem.serialNo)
                        });
                        setSerialCompletedInfoList(sortedList);
                    }
                    else {
                        if (serialNoSortType !== Sort.NONE) {
                            let sortedList = dataList.sort(function (firstItem, secondItem) {
                                if (serialNoSortType === Sort.ASC) {
                                    return firstItem.serialNo.localeCompare(secondItem.serialNo)
                                } else {
                                    return secondItem.serialNo.localeCompare(firstItem.serialNo)
                                }
                            });
                            setSerialCompletedInfoList(sortedList);
                        }
                        else if (completedDateSortType !== Sort.NONE) {
                            let sortedList = dataList.sort(function (firstItem, secondItem) {
                                if (completedDateSortType === Sort.ASC) {
                                    return firstItem.displayDateOrStatus.localeCompare(secondItem.displayDateOrStatus)
                                } else {
                                    return secondItem.displayDateOrStatus.localeCompare(firstItem.displayDateOrStatus)
                                }
                            });
                            setSerialCompletedInfoList(sortedList);
                        }
                    }
                }
                else {
                    setSerialCompletedInfoList([]);
                }
            }).catch(function (error) {
                setSerialCompletedInfoList([]);
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    NavigateToOperationLogin(navigate);
                }
                else {
                    appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                    appInsights.trackException({ ...error, errorFunction: "OperationStartWork.getSerialCompletedInfo()" });
                    console.error(error);
                }
            });
            dispatch(setPageLoading(false));
        }
        else {
            setSerialCompletedInfoList([]);
        }
    }

    /**
     * 期限指定の組み合わせは適当であるか確認する
     * @returns 
     */
    function isDateCombinationOK() {
        if (startDate.length > 0 && endDate.length > 0) {
            let firstDate = new Date(startDate);
            let secondDate = new Date(endDate);
            return firstDate <= secondDate;
        }
        return false;
    }

    /**
     * 完了実績表示エリアの完了日時カラムに表示するデータ交換
     * @param {*} workStatusId 
     * @param {*} completedDate 
     * @returns 
     */
    const getDisplayDateOrStatus = (workStatusId, completedDate) => {
        let result = "";
        if ([WorkStatus.SUSPENSION, WorkStatus.UNLOCK].includes(workStatusId)) {
            result = chuudan;
        }
        else if ([WorkStatus.WORKOK, WorkStatus.STARTWORK, WorkStatus.WORKMOVE, WorkStatus.NG, WorkStatus.RESUMEWORK, WorkStatus.STARTPROCESS, WorkStatus.HARDWAREOK, WorkStatus.HARDWARENG].includes(workStatusId)) {
            result = sagyouchuu;
        }
        else if ([WorkStatus.WORKCOMPLETED, WorkStatus.FINISHPROCESS].includes(workStatusId)) {
            result = ToLocalDateTime(completedDate);
        }
        return result;
    }

    return (
        <div className="flex w-[1920px] h-[1080px] bg-[#FFFFFF]">
            <PageLoading />
            <div className="w-[420px] h-[1080px] bg-[#0073CD]">
                <div className="absolute top-[460px] left-[20px]">
                    <span className="w-[410px] pb-[10px] h-[160px] text-[#FFFFFF] text-[40px] text-center font-black">Roland DG Assemble<center className="pt-[30px] text-[32px]">for Operator</center></span>
                    <div className="w-[70px] h-[28px] mt-[40px] ml-[155px] text-[#0073CD] text-center bg-white rounded-full">{variables.VERSION}</div>
                    <div className="w-[200px] h-[82px] bg-white mt-[35px] ml-[95px]">
                        <div className="font-bold text-[18px] ml-[54px] pt-[9px]">COM接続</div>
                        <div className="mt-[8px] ml-[17px]">
                            <button
                                type="button"
                                className={"w-[166px] h-[33px] border-2 border-[#C8C6C4] " + variables.HOVER_CSS}
                                onClick={onClickConnection}>
                                {connectionLabel}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[960px] h-[1080px] relative">
                <div className="absolute font-bold text-[26px] top-5 left-[107px]">管理方法</div>
                <div className="absolute flex h-[113px] top-12 left-[107px]">
                    <div className={"w-[365px] h-[80px] cursor-pointer text-center mr-4 mt-4 rounded-[2px]"
                        + (managementType === OperationManagementType.SERIALNUMBER ? " border-2 border-[#0073CD] bg-[white]"
                            : " bg-[#D8D7D5] border-2 hover:border-[#707070] hover:opacity-100 opacity-60")}
                        onClick={() => handleBtnClick(OperationManagementType.SERIALNUMBER)}
                        key={uuidv4()}>
                        <div className="flex relative">
                            <div className="text-left mt-3 ml-1 text-[22px] font-bold">製造番号 / シリアル番号</div>
                            <input
                                key={uuidv4()}
                                className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                                type="radio"
                                id={"type" + OperationManagementType.SERIALNUMBER}
                                name={OperationManagementType.SERIALNUMBER}
                                checked={managementType === OperationManagementType.SERIALNUMBER}
                                readOnly={true} />
                        </div>
                        <div className=" text-left mt-1 ml-1 text-[18px]">製品個々に連続した識別番号がある場合</div>
                    </div>
                    <div className={"w-[365px] h-[80px] cursor-pointer text-center mr-4 mt-4 rounded-[2px]"
                        + (managementType === OperationManagementType.LOTNUMBER ? " border-2 border-[#0073CD] bg-[white]"
                            : " bg-[#D8D7D5] border-2 hover:border-[#707070] hover:opacity-100 opacity-60")}
                        onClick={() => handleBtnClick(OperationManagementType.LOTNUMBER)}
                        key={uuidv4()}>
                        <div className="flex relative">
                            <div className=" text-left mt-3 ml-1 text-[22px] font-bold">ロット番号</div>
                            <input
                                key={uuidv4()}
                                className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                                type="radio"
                                id={"type" + OperationManagementType.LOTNUMBER}
                                name={OperationManagementType.LOTNUMBER}
                                checked={managementType === OperationManagementType.LOTNUMBER}
                                readOnly={true}
                            />
                        </div>
                        <div className="text-left mt-1 ml-1 text-[18px]">複数台に1つの識別番号がある場合</div>
                    </div>
                </div>
                <div className="absolute top-[158px] left-[50px] w-[836px] border-t-[3px] border-[#D8D7D5] ml-3" />
                <div className="absolute top-[175px] left-[16px]">
                    {managementType === OperationManagementType.SERIALNUMBER ?
                        <SerialNumberStartWork key={managementType} />
                        :
                        <LotNumberStartWork key={managementType} setLotNumber={setLotNumber} lotNumber={lotNumber} setKeiKaKuSuu={setKeiKaKuSuu} keiKaKuSuu={keiKaKuSuu}
                            setSeisanInfoList={setSeisanInfoList} seisanInfoList={seisanInfoList} setDialogBox={setDialogBox} />
                    }
                </div>
                <div />
            </div>
            {/* ロット番号で管理する場合、表示する部分 */}
            {(managementType === OperationManagementType.LOTNUMBER) &&
                <div className="w-[540px] h-[1080px] border-2 border-[#C8C6C4] shadow-md">
                    <div className="ml-4 mt-5"><SectionTitle Text="ロット番号" /></div>
                    <div className=" font-bold text-[26px] ml-4 mt-3 break-all">{lotNumber}</div>
                    {lotNumber.length > 0 ? <div className="absolute top-[128px] right-0 mr-8 font-bold text-[26px] ">計画数 : {keiKaKuSuu}</div> : null}
                    <div className={"absolute top-[158px] text-[24px] w-[537px] h-[918px] border-t-[3px] border-[#D8D7D5]"}>
                        <div className=" flex w-[537px] h-[60px] font-bold border-b-2 border-[#D8D7D5]">
                            <div className="w-[340px] py-4"><span className="ml-10">工程</span></div>
                            <div className="w-[70px] py-4"><span className="ml-2">完了</span></div>
                            <div className="w-[120px] py-4"><span className="ml-1">仕掛かり</span></div>
                        </div>
                        <div className={"w-[537px] h-[858px] border-0 overflow-y-scroll overflow-x-hidden text-[" + tblFontSize + "px]"}>
                            <table width={510}>
                                {seisanInfoList.map((singleObj, idx) => (
                                    <tbody key={uuidv4()}>
                                        <tr className="h-[52px] border-b-2" key={uuidv4()}>
                                            <td className="w-[350px]">
                                                <div className="flex">
                                                    <div className="w-[25px] text-right ml-2 mr-2">{singleObj.dispOrder}</div>
                                                    <div className={"w-[" + tdWidth + "px] ellipse one-line overflow-hidden"}
                                                        onMouseOver={(e) => handleMouseOverToDisplayTooltip(singleObj.processName, idx, e)}
                                                        onMouseLeave={() => { setTooltip(null); }}
                                                    >{singleObj.processName}</div>
                                                </div>
                                            </td>
                                            <td className="w-[60px] font-bold ">
                                                <div className="w-[38px] text-right">{singleObj.completedCount}</div>
                                            </td>
                                            <td className="w-[100px] font-bold">
                                                <div className="w-[72px] text-right ml-2">{singleObj.inProgressCount}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            }
            {/* シリアル番号で管理する場合、完了実績部分 */}
            {(managementType === OperationManagementType.SERIALNUMBER) &&
                <div className="w-[540px] h-[1080px] border-2 border-[#C8C6C4] shadow-md">
                    <div className="ml-3 mt-5"><SectionTitle Text="完了実績" /></div>
                    <div className="flex mb-[5px] ml-[4px] mt-3">
                        <Update className="w-[84px]"
                            onClick={handleUpdateClick}
                            Text="更新"
                        />
                        <div className="w-[290px] h-[36px] ml-1 border-[1px] rounded border-opacity-1 border-solid border-color[#D6D5D3] text-[#605E5C]">
                            <div className="w-[270px] h-[21px] text-[16px] text-[#605E5C] ml-[8px] mt-[5px] mb-[8px] mr-[9px] whitespace-nowrap">最終更新：{updateTime}</div>
                        </div>
                    </div>
                    <div className="ml-3">
                        <div className="mt-4 mb-[3px] font-bold">期間指定（完了日）</div>
                        <div className="flex h-[50px]">
                            <DateTimePicker
                                showPickerPositionCss={"mt-[35px]"}
                                minDate={ConvertDisplayDateFormat(sevenDaysAgoDate)}
                                maxDate={ConvertDisplayDateFormat(currentEndDate)}
                                value={startDate}
                                onDateChange={(value) => handleStartDateChange(value)}
                            />
                            <span className="px-3">～</span>
                            <DateTimePicker
                                showPickerPositionCss={"mt-[35px] ml-[-77px]"}
                                minDate={ConvertDisplayDateFormat(sevenDaysAgoDate)}
                                maxDate={ConvertDisplayDateFormat(currentEndDate)}
                                value={endDate}
                                onDateChange={(value) => handleEndDateChange(value)}
                            />
                        </div>
                        <div className="h-[125px]">
                            <div className="mb-[3px] font-bold">工程名</div>
                            <div className="w-[515px] h-[65px] mt-1">
                                <ComboBox
                                    width={"w-[370px]"}
                                    height={50}
                                    isSearchable={false}
                                    displayMemberPath={"displayText"}
                                    selectedValuePath={"dispProcessId"}
                                    selectedOption={selectedDispProcessId}
                                    itemsSource={processList}
                                    onSelectedChange={handleKouteiSelectedChange}
                                    placeholder={"工程を選択"}
                                />
                            </div>
                            <div className="flex flex-row">
                                <div className="font-bold mr-1">ユーザーのフィルター</div>
                                <div className="mt-[1px]">
                                    <ToggleButton
                                        isChecked={isUserFilterON}
                                        onChange={handleUserFilterONOFF}
                                        inputID={"userFilter"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[530px] ml-1 border-t-[3px] border-[#D8D7D5]" />
                    <div className="flex ml-6 mt-3 mb-[2px]">
                        <SectionTitle Text={"完了数 :"} /><span className="text-[37px] font-bold ml-2">{serialCompletedInfoList.filter(x => x.displayDateOrStatus !== chuudan && x.displayDateOrStatus !== sagyouchuu && x.displayDateOrStatus.length !== 0).length}</span>
                    </div>
                    <div className="h-[708px] w-[525px]">
                        <div className="relative inset-0 top-[51px] left-[8px] w-[500px] bg-white border-b-[2px] border-[#D8D7D5]" />
                        <div className="overflow-x-scroll overflow-y-scroll h-full w-full ml-2 mt-2 border-2 border-[#D8D7D5]">
                            <table className="border-collapse">
                                <thead>
                                    <tr>
                                        <th className="header">
                                            <div className="w-[290px] mt-[6px] h-[32px] border-r-2 border-[#D8D7D5] min-w-[50px] max-w-[1100px] resizable inner-resizer-disabled">
                                                <TableTitle
                                                    fontSizeCss={"text-[22px]"}
                                                    onClick={() => handleSortColumn(sortColumns.serialNo)}
                                                    Sort={serialNoSortType}
                                                    Text="製造/シリアル番号"
                                                />
                                            </div>
                                        </th>
                                        <th className="header">
                                            <div className="w-[200px] mt-[6px] h-[32px] border-r-2 border-[#D8D7D5] min-w-[50px] max-w-[900px] resizable inner-resizer-disabled">
                                                <TableTitle
                                                    fontSizeCss={"text-[22px]"}
                                                    onClick={() => handleSortColumn(sortColumns.displayDateOrStatus)}
                                                    Sort={completedDateSortType}
                                                    Text="完了日時"
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-[22px]">
                                    {serialCompletedInfoList.map((singleObj, idx) => (
                                        <tr key={idx}>
                                            <td className="w-[50px] min-w-[50px] h-[39px] text-left pl-[15px] text-black">
                                                <span className="w-full one-line">{singleObj.serialNo}</span>
                                            </td>
                                            <td className="w-[50px] min-w-[50px] h-[39px] text-left pl-[15px] text-black">
                                                <span className="w-full one-line">{singleObj.displayDateOrStatus}</span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {displayTooltip}
            {dialogBox}
        </div >
    );
}

export default OperationStartWork;
import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setMovieFile,
  deleteMovieFile,
  selectMovieFileByWorkId,
} from "./detailDocSlice";
import AddButton from "../../../components/AddButton";
import DeleteButton from "../../../components/DeleteButton";
import { IconContext } from "react-icons";
import { FaPhotoVideo } from "react-icons/fa";
import CheckCircle from "../../../components/CheckCircle";
import MovieInputBox from "./MovieInputBox";
import ConfirmBox from "../../../components/ConfirmBox";
import { getSelectedWorkInfo } from "./workListSlice";
import { variables } from "../../../Variables";
import DetailDocMoviePlayer from "../../../components/DetailDocMoviePlayer";

/**
 * 詳細資料の動画追加コンポネント。
 *
 * @param {*} props
 * @returns
 */
function DetailDocMovie(props) {
  const dispatch = useDispatch();
  // movie file name
  const dtlDocMovie = useSelector(state => selectMovieFileByWorkId(state, props.workID));
  const [showFileInput, setShowFileInput] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
  const [isMoviePlay, setMoviePlay] = useState(false);
  let movieObjUrl = (dtlDocMovie && dtlDocMovie.docFileName.length > 0) ? dtlDocMovie.objUrl : "";

  // 追加ボタンのイベントハンドラ
  function handleAddButton() {
    // 動画追加ボックスを表示
    setShowFileInput(true);
  }

  // 削除ボタンのイベントハンドラ
  function handleDeleteButton() {
    // 削除確認ボックスを表示
    setShowDeleteConfirm(true);
  }

  // 削除確認ボックスの「はい」ボタンのイベントハンドラ
  function handleDeleteConfirmYes() {
    dispatch(deleteMovieFile({ workID: props.workID }));
    setShowDeleteConfirm(false);
  }

  // 削除確認ボックスの「いいえ」ボタンのイベントハンドラ
  function handleDeleteConfirmNo() {
    setShowDeleteConfirm(false);
  }

  /**
   * MovieInputBox.jsx > 登録ボタン　イベントハンドラ
   * @param {*} objUrl 
   * @param {*} fileName
   */
  function onMovieInputBoxRegister(objUrl, fileName) {
    if (objUrl) {
      dispatch(setMovieFile({ workID: props.workID, fileName: fileName, objUrl: objUrl }));
    }
    setShowFileInput(false);
  }

  function onMovieInputBoxClose() {
    // ダイアログを閉じる
    setShowFileInput(false);
  }

  function handelMediaPlayer() {
    setMoviePlay(true);
  }

  const iconColor = selectedWork.isLock ? "#A19F9D" : "";
  const textColor = selectedWork.isLock ? " text-[#A19F9D]" : "";

  return (
    <div className={props.className || null}>
      <div className="flex items-center relative w-[281px] h-[56px] border-2 border-[#C8C6C4]">
        <div className="w-[60px] ml-1.5">
          <div className={"font-bold" + textColor}>動画</div>
          <div>
            <IconContext.Provider value={{ size: "27px", color: iconColor }}>
              {/* 動画アイコン作成 */}
              <FaPhotoVideo />
            </IconContext.Provider>
          </div>
        </div>
        <div className="w-[80px] ml-[-8px]">
          {dtlDocMovie && dtlDocMovie.docFileName.length > 0 ? (
            <CheckCircle
              iconColor={"#0073CD"}
              textColor={"text-[#0073CD]"}
              hoverbgColor={variables.HOVER_CSS}
              isEnable={true}
              onClick={handelMediaPlayer} />
          ) : null}
        </div>
        <div className="grow flex flex-row-reverse">
          <div>
            <DeleteButton
              className="w-[70px] h-[48px]"
              Text="削除"
              onClick={handleDeleteButton}
              isEnable={
                dtlDocMovie &&
                dtlDocMovie.docFileName.length > 0 &&
                !selectedWork.isLock
              }
            />
          </div>
          <div>
            <AddButton
              className="w-[70px] h-[48px]"
              Text="追加"
              isEnable={props.workID.length > 0 && !selectedWork.isLock}
              onClick={handleAddButton}
            />
          </div>
        </div>
      </div>

      {/* 動画ファイル入力ボックス */}
      {showFileInput && <MovieInputBox
        onRegister={onMovieInputBoxRegister}
        onClose={onMovieInputBoxClose}
        fileName={""}
        className="absolute right-0 top-0" />}

      {/* 確認ボックス */}
      {showDeleteConfirm && (
        <ConfirmBox
          className="absolute right-[162px] top-[265px] w-[471px]"
          title="ファイル削除"
          message="削除しますか？"
          onYesClick={handleDeleteConfirmYes}
          onNoClick={handleDeleteConfirmNo}
        />
      )}
      {/* 動画表示 */}
      {isMoviePlay && (
        <DetailDocMoviePlayer
          src={movieObjUrl}
          Close={() => setMoviePlay(false)} />
      )}
    </div>
  );
}

export default DetailDocMovie;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { variables, ManageItemType, CompareSource, CompareMethod, SagyouBunkiType, HardwareType, HindoKaisuSetting } from '../../../Variables';
import { urlCompanyName } from '../../util/commonFun';
import { appInsights } from "../../util/ApplicationInsight";
import { v4 as uuidv4 } from "uuid";

const axios = require('axios');
const iniBunkiDataList = [{ dispOrder: 1, btnText: "", workID: "" }, { dispOrder: 2, btnText: "", workID: "" }];
const iniHardwareList = [{ dispOrder: 1, hardwareId: uuidv4(), hardwareType: HardwareType.None, handleName: "", tramsmitData: "", count: 0 }];
export const emptyHindoKaisuSettingObj = {
    hindoKaisuSettingType: HindoKaisuSetting.NONE,
    hindoSetting: {
        shohin: false,
        chukanhin: false,
        shuhin: false,
        toukankaku: false,
        toukankakuData: 0,
    },
    kaisuSetting: {
        batchSize: false,
        kotei: false,
        koteiData: 0,
        wariai: false,
        wariaiData: 0,
    }
};

const emptyMngItmObj = {
    mngItmID: -1,
    workID: '',
    mngItmType: ManageItemType.None,
    data: {
        audioText: '',
        mketWarnText: '',
        mketWarnImgInfo: {
            fileName: '',
            objUrl: ''
        },
        txtInputText: '',
        txtInputKurikaeshi: false,
        txtCompSource: CompareSource.Shitei,
        txtCompText: '',
        txtCompWorkID: '',
        txtCompMethod: CompareMethod.AllMatch,
        txtCompKurikaeshi: false,
        numJudgeUpper: '',
        numJudgeLower: '',
        numJudgeKurikaeshi: false,
        okNgBranch: false,
        okWorkID: '',
        ngWorkID: '',
        okNgKurikaeshi: false,
        sagyouBunkiKurikaeshi: false,
        sagyouBunkiType: SagyouBunkiType.BtnType,
        bunkiData: iniBunkiDataList,
        hardwareData: iniHardwareList,
        hardwareKurikaeshi: false,
        hardwareAutoTransition: false,
        hindoKaisuSetting: emptyHindoKaisuSettingObj,
    }
};

export const fetchMngItemByWIDs = createAsyncThunk("manageItem/fetchMngItemByWIDs",
    async workIDs => {
        // workIDs = ["workid1", "workid2"]
        const response = await axios({
            method: 'POST',
            url: variables.MNGITM_URL + "/getbyworkids",
            data: workIDs,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        });

        return response.data;
    });

const initState = {
    selectedBunkiOrder: -1,
    dataList: [],
    backupDataList: [],
}

/**
 * 管理項目の Slice
 */
export const manageItemSlice = createSlice({
    name: 'manageItem',
    initialState: initState,
    reducers: {
        /**
         * 作業の空白の管理項目を追加する。
         * @param {*} manageItem 
         * @param {*} action 
         */
        addEmptyMngItm: (manageItem, action) => {
            const idx = manageItem.dataList.findIndex(itm => itm.workID === action.payload.workID);
            if (idx < 0) {
                manageItem.dataList.push(
                    {
                        ...emptyMngItmObj,
                        workID: action.payload.workID
                    }
                );
            }
        },
        /**
         * 作業IDで管理項目のデータを削除する。
         * param: {workID: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        deleteMngItm: (manageItem, action) => {
            const idx = manageItem.dataList.findIndex(itm => itm.workID === action.payload.workID);
            if (idx >= 0) {
                manageItem.dataList.splice(idx, 1);
            }
        },
        /**
         * 作業IDで管理項目タイプを設定する。
         * param: {workID: <string>, mngItmType: <int>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setType: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.mngItmType = action.payload.mngItmType;
                mngItm.data = { ...emptyMngItmObj.data };
            }
        },
        /**
         * 作業IDで音声出力テキストを設定する。
         * param: {workID: <string>, text: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setAudioText: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.audioText = action.payload.text;
            }
        },
        /**
         * 作業IDで警告メッセージテキストを設定する。
         * param: {workID: <string>, text: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setMketWarnText: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.mketWarnText = action.payload.text;
            }
        },
        /**
         * 作業IDで市場不良警告の画像情報を設定する。
         * param: {workID: <string>, path: <string>, fileName: <string>,  objUrl: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setMketWarnImgInfo: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.mketWarnImgInfo.fileName = action.payload.fileName;
                mngItm.data.mketWarnImgInfo.objUrl = action.payload.objUrl;
            }
        },
        /**
        * 作業IDで文字列登録の頻度/回数設定する。
        *  param: {workID: <string>, txtInputKurikaeshi: <bool>}
        * @param {*} manageItem 
        * @param {*} action 
        */
        setTxtInputKurikaeshi: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.txtInputKurikaeshi = action.payload.txtInputKurikaeshi;
                if (action.payload.txtInputKurikaeshi === false) {
                    mngItm.data.hindoKaisuSetting = emptyHindoKaisuSettingObj;
                }
            }
        },
        /**
        * 作業IDで文字列比較先を設定する。
        * param: {workID: <string>, txtCompSource: <string>}
        * @param {*} manageItem 
        * @param {*} action 
        */
        setTxtCompSource: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.txtCompSource = action.payload.txtCompSource;
                mngItm.data.txtCompWorkID = '';
                mngItm.data.txtCompText = '';
            }
        },
        /**
         * 作業IDで文字列比テキストを設定する。
         * param: {workID: <string>, txtCompText: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setTxtCompText: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.txtCompText = action.payload.txtCompText;
            }
        },
        /**
         * 作業IDで文字列比の作業IDを設定する。
         * param: {workID: <string>, txtCompWorkID: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setTxtCompWorkID: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.txtCompWorkID = action.payload.txtCompWorkID;
            }
        },
        /**
         * 作業IDで文字列比較先を設定する。
         * param: {workID: <string>, txtCompMethod: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setTxtCompMethod: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.txtCompMethod = action.payload.txtCompMethod;
            }
        },
        /**
         * 作業IDで文字列比較の頻度/回数設定する。
         *  param: {workID: <string>, txtCompKurikaeshi: <bool>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setTxtCompKurikaeshi: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.txtCompKurikaeshi = action.payload.txtCompKurikaeshi;
                if (action.payload.txtCompKurikaeshi === false) {
                    mngItm.data.hindoKaisuSetting = emptyHindoKaisuSettingObj;
                }
            }
        },
        /**
         * 作業IDで上限値を設定する。
         * param: {workID: <string>, text: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setNumJudgeUpper: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.numJudgeUpper = action.payload.text;
            }
        },
        /**
        * 作業IDで下限値を設定する。
        * param: {workID: <string>, text: <string>}
        * @param {*} manageItem 
        * @param {*} action 
        */
        setNumJudgeLower: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.numJudgeLower = action.payload.text;
            }
        },
        /**
         * 作業IDで数値判定の頻度/回数設定する。
         *  param: {workID: <string>, numJudgeKurikaeshi: <bool>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setNumJudgeKurikaeshi: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.numJudgeKurikaeshi = action.payload.numJudgeKurikaeshi;
                if (action.payload.numJudgeKurikaeshi === false) {
                    mngItm.data.hindoKaisuSetting = emptyHindoKaisuSettingObj;
                }
            }
        },
        /**
         * 作業IDでOK/NG判定の頻度/回数設定する。
         *  param: {workID: <string>, okNgKurikaeshi: <bool>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setOkNgKurikaeshi: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.okNgKurikaeshi = action.payload.okNgKurikaeshi;
                if (action.payload.okNgKurikaeshi === false) {
                    mngItm.data.hindoKaisuSetting = emptyHindoKaisuSettingObj;
                }
            }
        },
        /**
         * 作業IDで分岐設定のフラグを切替する。
         * param: {workID: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        toggleOkNgBranch: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                if (mngItm.data.okNgBranch) {
                    mngItm.data.okWorkID = "";
                    mngItm.data.ngWorkID = "";
                }
                mngItm.data.okNgBranch = !mngItm.data.okNgBranch;
            }
        },
        /**
         * 作業IDでOKの作業をを設定する。
         * param: {workID: <string>, okWorkID: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setOkWorkID: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.okWorkID = action.payload.okWorkID;
            }
        },
        /**
         * 作業IDでNGの作業を設定する。
         * param: {workID: <string>, ngWorkID: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setNgWorkID: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.ngWorkID = action.payload.ngWorkID;
            }
        },
        /**
         * 作業IDで作業分岐の頻度/回数設定する。
         */
        setSagyouBunkiKurikaeshi: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.sagyouBunkiKurikaeshi = action.payload.sagyouBunkiKurikaeshi;
                if (action.payload.sagyouBunkiKurikaeshi === false) {
                    mngItm.data.hindoKaisuSetting = emptyHindoKaisuSettingObj;
                }
            }
        },
        /**
         * 作業IDで作業分岐タイプを設定する。
         * param: {workID: <string>, sagyouBunkiType: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setSagyouBunkiType: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.sagyouBunkiType = action.payload.sagyouBunkiType;
            };
        },
        /**
        * BunkiDataListをクリアする。
        * param: {workID: <string>}
        * @param {*} manageItem 
        * @param {*} action 
        */
        clearBunkiData: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.bunkiData = iniBunkiDataList;
            };
        },
        /**
         * Bunki Work Idを設定する。
         * param: {dispOrder:<int>,bunkiWorkId:<string>,workID: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setBunkiWorkId: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                const bunkiData = mngItm.data.bunkiData.find(
                    (data) => data.dispOrder === action.payload.dispOrder
                );
                if (bunkiData) {
                    bunkiData.workID = action.payload.bunkiWorkId;
                };
            };
        },
        /**
         * 分岐ボタンのテキストを設定する。
         * param: {dispOrder:<int>,bunkiBtnText: <string>,workID: <string>}
         * @param {*} manageItem 
         * @param {*} action 
         */
        setBunkiBtnText: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                const bunkiData = mngItm.data.bunkiData.find(
                    (data) => data.dispOrder === action.payload.dispOrder
                );
                if (bunkiData) {
                    bunkiData.btnText = action.payload.bunkiBtnText;
                };
            }
        },
        /**
        * WorkIDで分岐データを設定する。
        * param: {workID: <string>, bunkiData:<objlist>}
        * @param {*} manageItem 
        * @param {*} action 
        */
        setBunkiData: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.bunkiData = action.payload.bunkiData;
            }
        },
        /**
         * workIDでハードウエアデータを設定する。
         * @param {*} manageItem 
         * @param {*} action 
         */
        setHardwareData: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.hardwareData = action.payload.hardwareData;
            }
        },
        /**
         * workIDで頻度/回数データを設定する。
         * @param {*} manageItem 
         * @param {*} action 
         */
        setHindoKaisuSetting: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.hindoKaisuSetting = action.payload.hindoKaisuSetting;
            }
        },
        /**
         * workIDでハードウエア実行回数データを設定する。
         * @param {*} manageItem 
         * @param {*} action 
        */
        setHardwareKurikaeshi: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.hardwareKurikaeshi = action.payload.hardwareKurikaeshi;
            }
        },
        /**
         *  workIDでハードウエア連携の自動遷移設定をする。
         */
        setHardwareAutoTransition: (manageItem, action) => {
            const mngItm = manageItem.dataList.find(itm => itm.workID === action.payload.workID);
            if (mngItm) {
                mngItm.data.hardwareAutoTransition = action.payload.hardwareAutoTransition;
            }
        },
        /**
         * 作業IDリストで管理項目を削除する。
         * param: array of workID
         * @param {*} detailDoc 
         * @param {*} action 
         */
        deleteMngItmByWorkIDList: (manageItem, action) => {
            const lstWorkID = action.payload;
            if (lstWorkID && lstWorkID.length > 0) {
                const filteredList = manageItem.dataList.filter(m => lstWorkID.includes(m.workID) === false);
                if (filteredList) {
                    manageItem.dataList = filteredList;
                } else {
                    manageItem.dataList = [];
                }
            }
        },
        resetMngItm: (manageItem, action) => {
            return { ...initState };
        }
    },
    /**
     * fetchMngItemByWIDs のReducer
     * @param {*} builder 
     */
    extraReducers: (builder) => {
        // fulfilled(完了)/pending(処理中)/rejected(エラー)時の処理を書く
        // 完了
        builder.addCase(fetchMngItemByWIDs.fulfilled, (mngItmState, action) => {
            const mngResponseList = action.payload;
            if (mngResponseList && mngResponseList.length > 0) {
                mngItmState.dataList = mngResponseList;
            } else {
                mngItmState.dataList = [];
            }
            mngItmState.backupDataList = mngItmState.dataList.slice();
        });
        // エラー
        // エラー時はエラー内容が返されることを期待している
        builder.addCase(fetchMngItemByWIDs.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "manageItemSlice.builder.addCase(fetchMngItemByWIDs.rejected)" });
        });
    },
});

/**
 * 作業IDで管理項目を取得
 * @param {*} state
 * @param {string} workID
 * @returns
 */
export const selectMngItmByWorkID = (state, workID) => {
    const mngItm = state.manageItem.dataList.find((d) => d.workID === workID);
    if (mngItm) {
        return mngItm;
    }
    return emptyMngItmObj;
};

/**
 * 全ての管理項目を取得
 * @param {*} state
 * @param {string} workID
 * @returns
 */
export const getAllMngItm = (state) => {
    return state.manageItem.dataList;
};

export const getBackupMngItm = (state) => {
    return state.manageItem.backupDataList;
};

/**
 * 管理項目タイプで取得する。
 * @param {*} state 
 * @param {*} mngType 
 * @returns 
 */
export const getMngItmByType = (state, mngType) => {
    if (mngType && mngType >= 0 && state.manageItem.dataList.length > 0) {
        return state.manageItem.dataList.filter(m => m.mngItmType === mngType);
    } else {
        return state.manageItem.dataList
    }
}

/**
 * 頻度/回数「OFF」の文字列登録の管理項目を取得する。
 * @param {*} state 
 * @returns 
 */
export const getMngItmsOfTxtInputKuOFF = (state) => {
    return state.manageItem.dataList.filter(d => d.mngItmType === ManageItemType.Text && d.data.txtInputKurikaeshi === false);
}

/**
 * 文字列比較先の登録作業として登録されている管理項目を取得する。
 * @param {*} state 
 * @returns 
 */
export const getCmpSrcWorkMngItm = (state) => {
    return state.manageItem.dataList.filter(m => m.data.txtCompSource === CompareSource.Work && m.data.txtCompWorkID.length > 0);
}

export const selectBunkiAriToNashiWork = (state) => {
    let bunkiAriToNashiWork = {
        bunkiMotoList: [],
        bunkiSakiList: [],
        kanriWorkList: []
    };
    for (let idx = 0; idx < state.manageItem.dataList.length; idx++) {
        const mng = state.manageItem.dataList[idx];
        if ((mng.mngItmType === ManageItemType.TextCompare && mng.data.txtCompSource === CompareSource.Work && mng.data.txtCompWorkID !== "")
            || (mng.mngItmType === ManageItemType.OkNgJudge && mng.data.okNgBranch === true && (mng.data.okWorkID !== "" || mng.data.ngWorkID !== ""))) {
            bunkiAriToNashiWork.bunkiMotoList.push({ workId: mng.workID, mngType: mng.mngItmType });
            if (mng.data.txtCompWorkID.length > 0) {
                bunkiAriToNashiWork.bunkiSakiList.push({ workId: mng.data.txtCompWorkID, mngType: mng.mngItmType });
            }
            else {
                if (mng.data.okWorkID.length > 0) {
                    bunkiAriToNashiWork.bunkiSakiList.push({ workId: mng.data.okWorkID, mngType: mng.mngItmType });
                }
                if (mng.data.ngWorkID.length > 0) {
                    bunkiAriToNashiWork.bunkiSakiList.push({ workId: mng.data.ngWorkID, mngType: mng.mngItmType });
                }
            }
        }
        else if (mng.mngItmType === ManageItemType.SagyouBunki && mng.data.bunkiData.some(data => data.workID !== "")) {
            let bunkiData = mng.data.bunkiData;
            bunkiAriToNashiWork.bunkiMotoList.push({ workId: mng.workID, mngType: mng.mngItmType })
            for (let i = 0; i < bunkiData.length; i++) {
                let pickedBunkiData = bunkiAriToNashiWork.bunkiSakiList.findIndex(x => x.workID === bunkiData[i].workID && x.mngItm === ManageItemType.SagyouBunki);
                if (pickedBunkiData === -1) {
                    bunkiAriToNashiWork.bunkiSakiList.push({ workId: bunkiData[i].workID, mngType: mng.mngItmType });
                }
            }
        }
        else if (mng.mngItmType !== "" && mng.mngItmType !== 0) {
            bunkiAriToNashiWork.kanriWorkList.push(mng.workID);
        }
    }
    return bunkiAriToNashiWork;
}

export const {
    addEmptyMngItm,
    deleteMngItm,
    setType,
    setAudioText,
    setMketWarnText,
    setMketWarnImgInfo,
    setTxtInputKurikaeshi,
    setTxtCompSource,
    setTxtCompText,
    setTxtCompWorkID,
    setTxtCompMethod,
    setTxtCompKurikaeshi,
    setNumJudgeUpper,
    setNumJudgeLower,
    setNumJudgeKurikaeshi,
    toggleOkNgBranch,
    setOkWorkID,
    setNgWorkID,
    setOkNgKurikaeshi,
    setSagyouBunkiType,
    clearBunkiData,
    setBunkiWorkId,
    setBunkiData,
    setBunkiBtnText,
    deleteMngItmByWorkIDList,
    resetMngItm,
    setHardwareData,
    setHardwareAutoTransition,
    setHardwareKurikaeshi,
    setHindoKaisuSetting,
    setSagyouBunkiKurikaeshi,
} = manageItemSlice.actions
export default manageItemSlice.reducer
import { createSlice } from "@reduxjs/toolkit";
const intState = {
    incomingHardwareData: "",
    isConnectionLost: false,
}

export const SerialPortReaderSlice = createSlice({
    name: 'serialPortReader',
    initialState: intState,
    reducers: {
        setIncomingHardwareData: (serialPortReader, action) => {
            serialPortReader.incomingHardwareData = action.payload;
        },
        clearHardwareData: (serialPortReader, action) => {
            serialPortReader.incomingHardwareData = "";
        },
        setConnectionLost: (serialPortReader, action) => {
            serialPortReader.isConnectionLost = action.payload;
        },
    }
});

export const getIncomingHardwareData = (state) => {
    return state.serialPortReader.incomingHardwareData;
}

export const getConnectionStatus = (state) => {
    return state.serialPortReader.isConnectionLost;
}

export const {
    setIncomingHardwareData,
    clearHardwareData,
    setConnectionLost
} = SerialPortReaderSlice.actions;
export default SerialPortReaderSlice.reducer;
import React from "react";
import { BiExport } from "react-icons/bi";
import { IconContext } from "react-icons";
import { variables } from "../Variables";

export function ExcelExport(props) {
    let iconColor = '#0073CD';
    let textColor = '';
    let hoverbgColor = variables.HOVER_CSS;
    if (!props.isEnable) {
      iconColor = '#A19F9D';
      textColor = 'text-[#A19F9D]';
      hoverbgColor = '';
    }

    return (
        <div onClick={props.onClick}
            className={props.className || "h-[53px] w-fit"}        >
            <div className={"flex flex-row h-full w-fit mx-[15px] hover:cursor-pointer " + hoverbgColor}>
                <IconContext.Provider
                    value={{ color: iconColor, size: "16px", className: "self-center" }}>
                    <BiExport />
                </IconContext.Provider>
                <div className={"mx-[5px] font-bold self-center " + textColor}>{props.Text}</div>
            </div>
        </div >
    );
}
import { React, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import SectionTitle from "./SectionTitle";
import { setVisible } from "./errorBoxSlice";
import { popupcss, popupWithYellowBG } from "../Variables";

function ErrorBox(props) {
  const dispatch = useDispatch();
  let yesBtnRef = useRef(null);

  useEffect(() => {
    yesBtnRef.current.focus();
    dispatch(setVisible(true));
    return function cleanup() {
      dispatch(setVisible(false));
    }
  }, [dispatch]);

  function handleYesBtnKeyUp(e) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      props.onYesClick();
    }
  }

  return (
    <div className={props.yellowBG ? popupWithYellowBG : popupcss}>
      <div className={props.className}>
        <div className="p-[15px] mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white rounded-sm">
          <div>
            <SectionTitle
              className="text-[26px] font-bold"
              Text={props.Title}
            />
            <div className="m-[10px] text-[22px] break-all">{props.Message}</div>
            <div className="mt-[15px] text-[22px]">
              <div
                tabIndex={0}
                ref={yesBtnRef}
                onClick={props.onYesClick}
                onKeyUp={handleYesBtnKeyUp}
                className="w-[83px] h-[40px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid cursor-pointer textmiddle text-center hover:bg-[#0068B8] rounded-sm"
              >
                はい
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorBox.defaultProps = {
  onYesClick: () => void (0),
  yellowBG: false,
}
export default ErrorBox;
